import { DownloadedDocument, MappedDocumentInfo } from 'materialidad-outsourcing/dist/types';
import mime from 'mime-types';


type HandleVaultParams = [onDownloadDocument: (documentId: string) => Promise<DownloadedDocument>];

export const useHandleVault = (...[onDownloadDocument]: HandleVaultParams) => {
  const downloadDocument = async (documentId: string): Promise<MappedDocumentInfo> => {
    const { documentInfo, document } = await onDownloadDocument(documentId);
    const MIME = mime.lookup(documentInfo.extension?.toLocaleLowerCase());
    const blob = new Blob([document], { type: MIME || undefined });
    return {
      name: `${documentInfo.name}${documentInfo.extension}`,
      MIME,
      blob,
      url: URL.createObjectURL(blob)
    };
  };
  return {
    downloadDocument
  };
};
