import { axiosRequest, axiosProviderRequest, authHeader } from "../../configs/axios.config";
import colors from "../../styles/colors";

function getColor() {
  const color = {
    i: (Math.floor(Math.random() * 16) + 1)
  }  
  const out = colors['primary-500'][color.i];
  
  return out;
}
export class UserHttp {

  findAll(success, error) {
    axiosRequest.get('/users', {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  findAllActives(success, error) {
    axiosRequest.get('/users/actives', {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  async findUsersById({id}, success, error) {
    await axiosRequest.get(`/users/${id}`, {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  createUser(user, success, error) {
    let companies = ''
    user.companies = user.companies || [];
    user.companies.forEach((company, index) => {
      if ((index+1) === user.companies.length) {
        companies += `companyId=${company.value}`
      } else {
        companies += `companyId=${company.value}&`
      }
    })

    if (user.companies.length === 0) {
      companies = 'companyId='
    }

    const formNewUser = new FormData();

    formNewUser.append('email', user.email);
    formNewUser.append('completeName', user.completeName.trimStart().trimEnd());
    formNewUser.append('color',  getColor());

    if (user.roles) {
      user.roles.forEach((role, i) => {
        formNewUser.append(`roles[${i}].id`, role.value);
      });
    }

    // const newUser = {
    //   email: user.email,
    //   completeName: user.completeName.trimStart().trimEnd(),
    //   roles: user.roles ? user.roles.map(role => ({id: role.value})) : [],
    //   color: getColor()
    // }
    console.log(formNewUser.entries());
    axiosRequest.post(
      `/users?${companies}`,
      formNewUser,
      {headers: {...authHeader(), 'Content-Type': 'multipart/form-data'}}
    )
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  createUserMateriality(user, success, error) {
    const idProvider = `providerId=${sessionStorage.getItem("id_provider")}`
    let newUser = {};

    if (user.name) newUser["name"] = user.name.trimStart().trimEnd();
    if (user.email) newUser["email"] = user.email;
    if (user.comments) newUser["comments"] = user.comments;
    if (user.identification) newUser["identification"] = user.identification;
    if (user.curp) newUser["curp"] = user.curp;
    if (user.rfc) newUser["rfc"] = user.rfc;
    newUser["isProviderAdmin"] = user.isProviderAdmin;
    newUser["isLegalRepresentative"] = user.isLegalRepresentative;
    newUser["company"] = {id: user.company};
    axiosProviderRequest.post(`/users?${idProvider}`, newUser, {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  editUser(user, id, success, error) {
    let companies = ''
    user.companies = user.companies || [];
    user.companies.forEach((company, index) => {
      if ((index+1) === user.companies.length) {
        companies += `companyId=${company.value}`
      } else {
        companies += `companyId=${company.value}&`
      }
    })

    if (user.companies.length === 0) {
      companies = 'companyId='
    }
    
    const newUser = {
      email: user.email,
      completeName: user.completeName.trimStart().trimEnd(),
      roles: user.roles ? user.roles.map(role => ({id: role.value})) : [],
      curp: user.curp,
      rfc: user.rfc
    }
    axiosRequest.put(`/users/${id}?${companies}`,
    newUser, {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  editUserMateriality(user, success, error) {
    const idProvider = `providerId=${sessionStorage.getItem("id_provider")}`
    let newUser = {};

    if (user.name) newUser["name"] = user.name.trimStart().trimEnd();
    if (user.email) newUser["email"] = user.email;
    if (user.comments) newUser["comments"] = user.comments;
    if (user.identification) newUser["identification"] = user.identification;
    if (user.adminProviderId) newUser["adminProviderId"] = user.adminProviderId;
    newUser["curp"] = user.curp;
    newUser["rfc"] = user.rfc;
    newUser["isProviderAdmin"] = user.isProviderAdmin;
    newUser["isLegalRepresentative"] = user.isLegalRepresentative;
    newUser["company"] = {id: user.company};
    axiosProviderRequest.put(`/users/${user.id}/?${idProvider}`,
      newUser, {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  blockUserMateriality(id, success, error) {
    axiosProviderRequest.put(`/users/inactive/${id}`, {}, {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  unlockUserMateriality(id, success, error) {
    axiosProviderRequest.put(`/users/active/${id}`, {}, {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  blockUser({
      blocked,
      id
    }, success, error) {
    axiosRequest.put(`/users/block/${id}/${blocked}`, {}, {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  deleteUser({id}, success, error) {
    axiosRequest.put(`/users/delete/${id}`, {}, {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  deleteUserMateriality(id, success, error) {
    axiosProviderRequest.put(`/users/delete/${id}`, {}, {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  assignRoles({
    id,
    roles}, success, error) {
    axiosRequest.put(`/users/assign-roles/${id}`, {
      roles
    }, {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

}
