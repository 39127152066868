import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const AdminIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18">
      <path d="M4.17749 8.6875C3.72316 8.6875 3.35352 9.05714 3.35352 9.51147C3.35352 9.96581 3.72316 10.3356 4.17749 10.3356C4.63194 10.3356 5.00158 9.96581 5.00158 9.51147C5.00158 9.05714 4.63194 8.6875 4.17749 8.6875Z"/>
      <path d="M5.47852 5.68359H9.52148V0.439453C9.52148 0.196724 9.32476 0 9.08203 0H5.91797C5.67524 0 5.47852 0.196724 5.47852 0.439453V5.68359Z"/>
      <path d="M14.5605 3.92578H10.4004V5.68359H10.7227C10.9654 5.68359 11.1621 5.88032 11.1621 6.12305C11.1621 6.36578 10.9654 6.5625 10.7227 6.5625H4.27734C4.03461 6.5625 3.83789 6.36578 3.83789 6.12305C3.83789 5.88032 4.03461 5.68359 4.27734 5.68359H4.59961V3.92578H0.439453C0.196724 3.92578 0 4.12251 0 4.36523V14.5605C0 14.8033 0.196724 15 0.439453 15H14.5605C14.8033 15 15 14.8033 15 14.5605V4.36523C15 4.12251 14.8033 3.92578 14.5605 3.92578ZM6.26301 12.7644C6.22776 12.7731 6.1924 12.7772 6.15761 12.7772C5.96008 12.7772 5.78064 12.6431 5.73132 12.4427C5.55485 11.7257 4.91592 11.2249 4.17755 11.2249C3.43929 11.2249 2.80048 11.7256 2.62402 12.4425C2.56599 12.6782 2.32796 12.8222 2.09221 12.7642C1.85658 12.7061 1.71249 12.4681 1.77052 12.2325C1.9368 11.5569 2.36813 11.0053 2.93781 10.6769C2.65079 10.3719 2.47467 9.9614 2.47467 9.51061C2.47467 8.57151 3.23868 7.80762 4.17767 7.80762C5.11665 7.80762 5.88055 8.57162 5.88055 9.51061C5.88055 9.9614 5.70442 10.3719 5.4174 10.6769C5.98721 11.0054 6.41853 11.557 6.5847 12.2327C6.64272 12.4683 6.49864 12.7065 6.26301 12.7644ZM10.4883 12.7148H8.4082C8.16547 12.7148 7.96875 12.5181 7.96875 12.2754C7.96875 12.0327 8.16547 11.8359 8.4082 11.8359H10.4883C10.731 11.8359 10.9277 12.0327 10.9277 12.2754C10.9277 12.5181 10.731 12.7148 10.4883 12.7148ZM12.832 10.957H8.40443C8.1617 10.957 7.96497 10.7603 7.96497 10.5176C7.96497 10.2748 8.1617 10.0781 8.40443 10.0781H12.832C13.0748 10.0781 13.2715 10.2748 13.2715 10.5176C13.2715 10.7603 13.0748 10.957 12.832 10.957ZM12.832 9.19922H8.41175C8.16902 9.19922 7.9723 9.00249 7.9723 8.75977C7.9723 8.51704 8.16902 8.32031 8.41175 8.32031H12.832C13.0748 8.32031 13.2715 8.51704 13.2715 8.75977C13.2715 9.00249 13.0748 9.19922 12.832 9.19922Z"/>
    </SvgIcon>
  )
}

export default AdminIcon;