import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

export interface RenderCheckboxProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  label?: JSX.Element;
  checked: boolean;
  name?: string;
  color?: "default" | "primary" | "secondary";
}


const RenderCheckbox = ({ onChange, label, checked, name, color = 'primary' }: RenderCheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          color={color}
          onChange={onChange}
          checked={checked}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<RadioButtonCheckedIcon />}
        />
      }
      label={ label }
    />
  );
}

export default RenderCheckbox;
