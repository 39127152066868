import React, { VFC } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';

interface RenderSwitchFieldProps {
  input: any;
  label: string;
}

const RenderSwitchField: VFC<RenderSwitchFieldProps> = (props) => {
    const {input, label} = props;

    return (
      <FormControlLabel
        labelPlacement="top"
        control={
          <Switch
            onChange={ input.onChange }
            checked={input.value ? true : false}
          />
        }
        label={ label }
      />
    );
  }

export default RenderSwitchField;
