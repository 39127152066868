import {
  Accordion,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import useCustomToast from "../../../core/hooks/useCustomToast";
import { SatgeDocsSummary, ServiceStage } from "../../../core/models/service.models";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SatCheckIcon from "../../icons/icons-v2/SatCheckIcon";
import SatWarningIcon from "../../icons/icons-v2/SatWarningIcon";
import { ServicesService } from "../../../core/services/services.service";
import CheckIconSm from "../../icons/icons-v2/CheckIconSm";
import WarnignIconSmYell from "../../icons/icons-v2/WarnignIconSmYell";
import WarnignIconSmRed from "../../icons/icons-v2/WarnignIconSmRed";
import WarningIconSmGray from "../../icons/icons-v2/WarningIconSmGray";

const IconTooltip = ({ status }: { status: string }) => {
  switch (status) {
    case "ACEPTADO":
      return (
        <Tooltip title="Aceptado">
          <div>
            <CheckIconSm />
          </div>
        </Tooltip>
      );
    case "BAJO_REVISION":
      return (
        <Tooltip title="Bajo revisión">
          <div>
            <WarnignIconSmYell />
          </div>
        </Tooltip>
      );
    case "RECHAZADO":
      return (
        <Tooltip title="Rechazado">
          <div>
            <WarnignIconSmRed />
          </div>
        </Tooltip>
      );
    case "FALTANTE":
      return (
        <Tooltip title="Faltante">
          <div>
            <WarningIconSmGray size={20} />
          </div>
        </Tooltip>
      );
    default:
      <Tooltip title="Desconocido">
        <div>
          <WarnignIconSmYell />
        </div>
      </Tooltip>;
  }
};

const IconStatus= ({ status }: { status: string }) =>{
  switch (status) {
    case "ACEPTADO":
      return <SatCheckIcon />
    case "BAJO_REVISION":
      return <WarnignIconSmYell />
    case "RECHAZADO":
      return <SatWarningIcon />
    case "FALTANTE":
      return <WarningIconSmGray />
    default:
          <WarnignIconSmYell />    
     }
}

const StageSummaryDialog = ({ onClose, serviceId, selectedStage }: { onClose: any; serviceId: string; selectedStage: ServiceStage }) => {
  const classes = useStyles();
  const toast = useCustomToast();
  const [isLoading, setIsLoading] = useState(false);
  const [currentData, setCurrentData] = useState<SatgeDocsSummary>(null);
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function getData() {
    setIsLoading(true);
    const res = await ServicesService.getInstance().buildServiceAsTree(serviceId);
    let data: SatgeDocsSummary = null;
    if (res?.codigo === 0) {
      const currentYear = new Date().getFullYear();
      data = res.respuesta?.find((stage) => stage.stageName === selectedStage?.name);
      data.resumenPeriodList = data.resumenPeriodList?.filter((period) => period.year <= currentYear);
      setCurrentData(data);
    } else {
      setCurrentData(null);
      toast.error("Error al obtener los datos");
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log("esumenPeriodList",currentData?.resumenPeriodList)

  const formatRequirimentName = (name) => {
    switch (name) {
      case 'CÉDULA DE DETERMINACIÓN DE CUOTAS "SUA MENSUAL"':
        return name.replace('CÉDULA DE DETERMINACIÓN DE CUOTAS "SUA MENSUAL"', 'Cédula de determinación de cuotas "SUA MENSUAL"');
      case 'CÉDULA DE DETERMINACIÓN DE CUOTAS "SUA BIMESTRAL"':
        return name.replace('CÉDULA DE DETERMINACIÓN DE CUOTAS "SUA BIMESTRAL"', 'Cédula de determinación de cuotas "SUA BIMESTRAL"');
      case 'RESUMEN DE LIQUIDACIÓN MENSUAL':
        return name.replace('RESUMEN DE LIQUIDACIÓN MENSUAL', 'Resumen de liquidación mensual');
      case 'RESUMEN DE LIQUIDACIÓN BIMESTRAL':
        return name.replace('RESUMEN DE LIQUIDACIÓN BIMESTRAL', 'Resumen de liquidación bimestral');
      case 'DECLARACIÓN DE ISR RETENIDO POR SALARIOS':
        return name.replace('DECLARACIÓN DE ISR RETENIDO POR SALARIOS', 'Declaración de ISR retenido por salarios');
      case 'DECLARACIÓN DE IVA':
        return name.replace('DECLARACIÓN DE IVA', 'Declaración de IVA');
      default:
        return name;
    }
  };

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <DialogTitle
        disableTypography
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span className={classes.dialogTitleText}>Resumen de {selectedStage?.name ?? "Etapa"}</span>
        <IconButton edge="end" onClick={onClose}>
          <CloseIcon style={{ color: "#7A92C5" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoading && (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "300px" }}>
            <p>Cargando información...</p>
          </div>
        )}
        {!isLoading &&
          currentData?.resumenPeriodList?.map((stage) => (
            <Accordion key={stage.yearMonth} elevation={0} className={classes.periodAccordion} expanded={expanded === stage.yearMonth} onChange={handleChange(stage.yearMonth)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div
                  style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} // paddingRight: "1rem"
                >
                  <div style={{ fontSize: "1rem", textTransform: "capitalize" }}>
                    {stage.monthString?.toLowerCase()} {stage.year}
                  </div>
                  {/*stage.status !== "ACEPTADO" ? <SatWarningIcon size={29} /> : <SatCheckIcon size={29} />*/}
                  {<IconStatus status={stage.status}/>}
                </div>
              </AccordionSummary>
              <div style={{ paddingLeft: "2rem" }}>
                <div>
                  <Table size="small" aria-label="a dense table">
                    <TableBody>
                      {stage.resumenRequirementList?.map((req) => (
                        <TableRow key={req?.requirementName}>
                          <TableCell component="th" scope="row">
                            {formatRequirimentName(req.requirementName)}
                          </TableCell>
                          <TableCell align="right">
                            <IconTooltip status={req.status}/>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Accordion>
          ))}

        {/* <Grid container justifyContent="center" style={{ padding: "2rem 0 1rem 0" }}>
            <MatFisButton variant="contained" color="primary" type="button" onClick={handleDownload}>
              Descargar
            </MatFisButton>
          </Grid> */}
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  periodAccordion: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    marginBottom: "8px"
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContentRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 20,
  },
  dialogTitleText: {
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "21.33px",
  },
  dialogContentText: {
    fontSize: "0.875rem",
    lineHeight: "17px",
    color: "#7A92C5",
    marginBottom: 40,
  },
}));

export default StageSummaryDialog;
