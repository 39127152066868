import { Dialog } from "@material-ui/core";
import { Contract } from "../../../../core/models/service.models";
import WatchContract from "../watch-contract/WatchContract";

const ShowContractDialog = ({ onClose, contract }: { onClose: any; contract: Contract }) => {
  return (
    <Dialog fullScreen open={true} onClose={onClose}>
      <WatchContract name={contract?.name} contract={contract} onReturn={onClose} />
    </Dialog>
  );
};

export default ShowContractDialog;
