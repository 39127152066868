import { axiosRequest, authHeader } from "../../configs/axios.config";

export class PermissionsHttp {
  fetchAll(success, error) {
    axiosRequest.get('/permissions', {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp));
  }

  getPermissionsGroup(success, error) {
    axiosRequest.get('/permission-group', {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp));
  }
}