import { Grid, Typography } from "@material-ui/core";
import { FormikErrors, FormikTouched } from "formik";
import ProfileInput from "./ProfileInput";

type TaxResidenceFormProps = {
  title: string;
  values: ProfileFormFields;
  errors: FormikErrors<ProfileFormFields>;
  touchedFields: FormikTouched<ProfileFormFields>;
  handleChangeField: (e: React.ChangeEvent<any>) => void;
  isEditing?: boolean;
};

const TaxResidenceForm = ({ title, isEditing, values, errors, touchedFields, handleChangeField }: TaxResidenceFormProps) => {
  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid container item xs={12} justifyContent="space-between" style={{ marginBottom: "1rem" }}>
        <Typography variant="h6">{title}</Typography>
      </Grid>

      <ProfileInput
        label="Calle"
        id="street"
        error={errors.street}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields.street}
        value={values.street}
      />
      <ProfileInput
        label="No exterior"
        id="extNumber"
        error={errors.extNumber}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields.extNumber}
        value={values.extNumber}
      />
      <ProfileInput
        label="No interior"
        id="intNumber"
        error={errors.intNumber}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields.intNumber}
        value={values.intNumber}
      />
      <ProfileInput
        label="Colonia"
        id="suburb"
        error={errors.suburb}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields.suburb}
        value={values.suburb}
      />
      <ProfileInput
        label="Ciudad"
        id="city"
        error={errors.city}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields.city}
        value={values.city}
      />
      <ProfileInput
        label="Código postal"
        id="postalCode"
        error={errors.postalCode}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields.postalCode}
        value={values.postalCode}
      />
      <ProfileInput
        label="País"
        id="country"
        error={errors.country}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields.country}
        value={values.country}
      />
    </Grid>
  );
};

export default TaxResidenceForm;
