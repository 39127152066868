import { axiosIpfsRequest, authHeader, axiosCompanyRequestV1, axiosPagareRequest } from "../../configs/axios.config";
import { GenericResponse } from "../../types/global";

export class UserService {
  private static instance: UserService;

  private constructor() {}

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  async listUsers() {
    try {
      const { data } = await axiosIpfsRequest.get<GenericResponse<ListUsersResponse>>(`api/v1/users`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }
  //https://companyqa.axeleratum.com/api/v1/companies/615b0540108a88577e907fac/users
  async listUsersNew() {
    let companyID = sessionStorage.getItem("companyId");
    try {
      const { data } = await axiosCompanyRequestV1.get(`/companies/${companyID}/users`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getUser(userId: string) {
    try {
      const { data } = await axiosIpfsRequest.get<GetUserResponse>(`api/v1/users/${userId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateUser2(userBody: any) {
    try {
      const { data } = await axiosPagareRequest.patch<GetUserResponse>(`/general/user/`, userBody, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //TODO: CAMBIAR DE LUGAR
  async htmlToPdf(contenido: string) {
    try {
      const { data } = await axiosPagareRequest.post(
        `/general/util/htmlToPdf`,
        { contenido, fileName: "random" },
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        }
      );
      const pdfBlob = new Blob([data], { type: "application/pdf" });
      return pdfBlob;
    } catch (error) {
      throw error;
    }
  }

  async createUser(body: CreateUserRequestBody) {
    try {
      const { data } = await axiosIpfsRequest.post<GetUserResponse>(`api/v1/users`, body, { headers: authHeader() });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async editUser(body: Partial<CreateUserRequestBody>) {
    try {
      const { data } = await axiosIpfsRequest.put<GenericResponse<GetUserResponse>>(`api/v1/users`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async acceptAlta(body: Partial<CreateUserRequestBody>) {
    try {
      const { data } = await axiosIpfsRequest.put<GenericResponse<GetUserResponse>>(`api/v1/users/acceptAlta`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async deleteUser(userId: string) {
    try {
      await axiosIpfsRequest.delete<GetUserResponse>(`api/v1/users/${userId}`, { headers: authHeader() });
    } catch (error) {
      throw error;
    }
  }
}
