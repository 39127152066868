
export default function SatPendingIcon() {
  return (
    <svg
    width={28}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.999 27.997c7.73 0 13.998-6.267 13.998-13.998C27.997 6.267 21.73 0 14 0 6.267 0 0 6.267 0 13.999c0 7.73 6.267 13.998 13.999 13.998Zm-4.6-15.118a3.48 3.48 0 1 1 6.96 0 3.48 3.48 0 0 1-6.96 0Zm3.48-5.48a5.48 5.48 0 1 0 3.103 9.997l2.909 2.91a1 1 0 0 0 1.414-1.415l-2.91-2.91A5.48 5.48 0 0 0 12.879 7.4Z"
      fill="#FBB64F"
    />
  </svg>
  );
}
