import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import { Notification } from "../core/models/notification.models";
import { NotificationsService } from "../core/services/notifications.service";

interface State {
  //notifications: Notification[];
  currentNotificationsLength: number;
  notificationsCurrentSize: number;
  unReadNotifications: Notification[];
  readNotifications: Notification[];
  setUnreadNotifications: (notifications: Notification[]) => void;
  setReadNotifications: (notifications: Notification[]) => void;
  getNotifications: () => Promise<any>;
  getNotigicationsLength: () => Promise<number>;
  // Generadores
  currenGeneradorestNotificationsLength: number;
  notificationsGeneradoresCurrentSize: number;
  unReadGeneradoresNotifications: Notification[];
  readGeneradoresNotifications: Notification[];
  setUnreadGeneradoresNotifications: (notifications: Notification[]) => void;
  setReadGeneradoresNotifications: (notifications: Notification[]) => void;
  getGeneradoresNotifications: () => Promise<any>;
  getGeneradoresNotigicationsLength: () => Promise<number>;
  //

  getCurrentNotificationsLenght: () =>Promise<any>;
  notificationsOldSize: number;
  //setNotifications: (notifications: Notification[]) => void;
  savePrevNotification: (size: any) => void;
  reset: () => void;
}

const useNotificationState = create<State>((set, get) => ({
  //notifications: [],
  currentNotificationsLength: 0,
  unReadNotifications: [],
  readNotifications: [],
  notificationsOldSize: 0,
  notificationsCurrentSize: 0,
  currenGeneradorestNotificationsLength: 0,
  notificationsGeneradoresCurrentSize: 0,
  unReadGeneradoresNotifications: [],
  readGeneradoresNotifications: [],
  getNotifications: getNotifications(set, get),
  getNotigicationsLength: getNotificationsLength(set, get),
  getGeneradoresNotifications:getGeneradoresNotifications(set, get),
  getGeneradoresNotigicationsLength:getGeneradoresNotigicationsLength(set, get),

  getCurrentNotificationsLenght : getCurrentNotificationsLenght ( set,get),
  savePrevNotification : savePrevNotification (set,get),
  // setNotifications: (notifications: Notification[]) => {
  //   set({ notifications: notifications });
  // },
  setUnreadNotifications: (notifications: Notification[]) => {
    set({ unReadNotifications: notifications });
  },
  setReadNotifications: (notifications: Notification[]) => {
    set({ readNotifications: notifications });
  },
  setUnreadGeneradoresNotifications: (notifications: Notification[]) => {
    set({ unReadGeneradoresNotifications: notifications });
  },
  setReadGeneradoresNotifications: (notifications: Notification[]) => {
    set({ readGeneradoresNotifications: notifications });
  },
  reset: () => {
    set({ /*notifications: [],*/ unReadNotifications: [], readNotifications: [],unReadGeneradoresNotifications:[],readGeneradoresNotifications: [] });
  },
}));

export default useNotificationState;

//FUNCTIONS
function getNotifications(set: NamedSet<State>, get: GetState<State>) {
  return async () => {
    const notifications: any = await NotificationsService.getInstance().getAllNotifications();
    if (notifications?.length === 0) return;
    const unread = notifications
      .filter((n: Notification) => !n.read)
      .sort((a: Notification, b: Notification) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    const read = notifications.filter((n: Notification) => n.read);
    set({ currentNotificationsLength: notifications.length, unReadNotifications: unread, readNotifications: read });
  };
}

function getNotificationsLength(set: NamedSet<State>, get: GetState<State>) {
  return async () => {
    const res: any = await NotificationsService.getInstance().getNotificationsSize();
    if (res?.codigo === 0) {
      set({notificationsCurrentSize:res.respuesta});
      return res.respuesta;
    }
    console.log("falied to get notifications length");
    return null;
  };
}

// Generadores Notifacations


function getGeneradoresNotifications(set: NamedSet<State>, get: GetState<State>) {
  return async () => {
    const notifications: any = await NotificationsService.getInstance().getAllGeneradoresNotifications();
    if (notifications?.length === 0) return;
    const unread = notifications
      .filter((n: Notification) => !n.read)
      .sort((a: Notification, b: Notification) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    const read = notifications.filter((n: Notification) => n.read);
    set({ currentNotificationsLength: notifications.length, unReadNotifications: unread, readNotifications: read });
  };
}

function getGeneradoresNotigicationsLength(set: NamedSet<State>, get: GetState<State>) {
  return async () => {
    const res: any = await NotificationsService.getInstance().getGeneradoresNotificationsSize();
    if (res?.codigo === 0) {
      set({notificationsCurrentSize:res.respuesta});
      return res.respuesta;
    }
    console.log("falied to get notifications length");
    return null;
  };
}


//////////

function getCurrentNotificationsLenght(set: NamedSet<State>, get: GetState<State>) {
  return async () => {
    const res: any = await NotificationsService.getInstance().getNotificationsSize();
    if (res?.codigo === 0) {
      console.log("gewt 1 size",res.respuesta)
      set({notificationsCurrentSize:res.respuesta});
      set({notificationsOldSize:res.respuesta});
      return res;
    }
    console.log("falied to get notifications expired");
    return null;
  };
}

function savePrevNotification(set: NamedSet<State>, get: GetState<State>) {
  return async (size:any) => {
      set({notificationsCurrentSize:size});
   
  };
}
