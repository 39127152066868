import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import AlertInfo from "@material-ui/lab/Alert";
import FormTemplate from "./FormTemplate";
import { useHandleDialog } from "../../../core/hooks/useHandleDialog";
import ManagementTable from "../management-table/ManagementTable";
import { toBase64 } from "../../../core/utils/ntpUtils";
import { ServicesService } from "../../../core/services/services.service";
import useGlobalState from "../../../store/globalState";
import useManagementState from "../../../store/managementState";

const Templates = () => {
  const setAlert = useManagementState((s) => s.setAlert);
  const mainCompanyId = useGlobalState((s) => s.mainCompany?.id);
  const [MaterialityDialog, handleShowModal, handleCloseModal] =
    useHandleDialog();
  const [loadingSets, setLoadingSets] = useState(false);
  const [dialogTitle] = useState("");
  const [documentSets, setDocumentSets] = useState<DocumentSet[]>([]); // List of companies retreived by the back-end

  const servicesService = ServicesService.getInstance();

  useEffect(() => {
    findServiceTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createDocumentSet = async (data: FormTemplateFields) => {
    const JsonRequest: CreateDocumentSetReqBody = {
      documentHash: await toBase64(data.setFile as File),
      fileExtension: "xslx",
      traceabilityPoints: 65,
      requiredPoints: 50,
      serviceTypeName: data.name,
      companyId: mainCompanyId,
    };
    servicesService
      .createDocumentSet(JsonRequest)
      .then(() => {
        setAlert({
          open: true,
          type: "success",
          title: "Set de documentos creado correctamente.",
        });
        findServiceTypes();

        handleCloseModal();
      })
      .catch(() => {
        setAlert({
          open: true,
          type: "error",
          title: "Hubo un error al crear el set de documentos.",
        });
      });
  };

  const findServiceTypes = async () => {
    try {
      setLoadingSets(true);
      const serviceTypes = await servicesService.findAllServiceTypes(
        mainCompanyId
      );
      setDocumentSets(serviceTypes.data);
    } catch (error) {
      setAlert({
        open: true,
        type: "error",
        title: "No se pudieron recuperar los sets de documentos.",
      });
    } finally {
      setLoadingSets(false);
    }
  };

  const handleDeleteDocumentSetAlert = (documentSet: DocumentSet) => {
    setAlert({
      open: true,
      title: "¿Seguro que desea borrar el set de documentos seleccionado?",
      onConfirm: () => handleDeleteDocumentSet(documentSet),
      addCancelButton: true,
      holdOnConfirm: true,
    });
  };

  const handleDeleteDocumentSet = async (documentSet: DocumentSet) => {
    try {
      await servicesService.deleteDocumentSet({
        id: documentSet.id,
        name: documentSet.name,
      });
      const newDocumentSets = documentSets.filter(
        (set) => set.id !== documentSet.id
      );
      setDocumentSets(newDocumentSets);
      setAlert({
        open: true,
        title: "Set de documentos borrado correctamente",
        type: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        title: "Ocurrió un error al intentar borrar el set de documentos",
        type: "error",
      });
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <ManagementTable
            addButtonOnTop
            addButtonProps={{
              label: "Nuevo set de documentos",
              onAdd: handleShowModal,
            }}
            tableItems={documentSets}
            header={[{ key: "name", title: "Servicio" }]}
            menuItems={[
              {
                label: "Borrar",
                action: handleDeleteDocumentSetAlert,
              },
            ]}
          />
        </Grid>

        {!loadingSets && documentSets.length === 0 && (
          <Grid container style={{ justifyContent: "center", width: "100%" }}>
            <AlertInfo severity="info">No hay información!</AlertInfo>
          </Grid>
        )}

        {loadingSets && (
          <Grid container style={{ justifyContent: "center", width: "100%" }}>
            <AlertInfo severity="info">Cargando templates...</AlertInfo>
          </Grid>
        )}
      </Grid>

      <MaterialityDialog>
        <FormTemplate
          onCancel={handleCloseModal}
          onSubmit={createDocumentSet}
          title={dialogTitle}
        />
      </MaterialityDialog>
    </>
  );
};

export default Templates;
