import { authHeader, axiosBPMRequestV1, axiosPaymentRequest } from "../../configs/axios.config";
import {  GetPurchasesByEmalResponse } from "../../types/payment-service";

export class PaymentService {
  private static instance: PaymentService;
  private constructor() {}

  public static getInstance(): PaymentService {
    if (!PaymentService.instance) {
      PaymentService.instance = new PaymentService();
    }
    return PaymentService.instance;
  }

  async getAllProducts() {
    const { data } = await axiosPaymentRequest.get(`/products/all`);
    return data;
  }

  async createOrder(body: any) {
    const { data } = await axiosBPMRequestV1.post('/bpm/pay/subscription', body, { headers: authHeader() });
    return data?.id;
  }

  async getPurchaseLink(email: string) {
    const { data } = await axiosPaymentRequest.get<GetPurchasesByEmalResponse>(`/buy/email/${email}/all`, { headers: authHeader() });
    return data;
  }
}
