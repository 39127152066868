import { Redirect, Route, useRouteMatch } from "react-router";
import Management from "../../pages/ManagementPage";
import ProfilePage from "../../pages/ProfilePage";
import usePlanState from "../../store/planState";
import PageNotFound from "../../components/onboarding/ValidatePagePath";
import SettingsPage from "../../pages/SettingsPage";

const ManagementRouting = () => {
  const match = useRouteMatch();
  const canAccessAdmin = usePlanState(s=>s.canAccesAdministation)
  return (
    <>
      <Route exact path={`${match.path}/`}>
        {canAccessAdmin ? <Management /> : <Redirect to="/dashboard/home" />}
      </Route>
      <Route path={`${match.path}/edit-profile`}>
        <ProfilePage />
      </Route>
      <Route path={`${match.path}/settings`}>
        <SettingsPage />
      </Route>
      {/*<Route path="/*" component={PageNotFound} />*/}

    </>
  );
};

export default ManagementRouting;
