import { Button, IconButton, makeStyles, Popover, Tooltip, useTheme } from "@material-ui/core";
import { useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ContextualMenuItem, useContextualMenu } from "../hooks/useContextualMenu";
import UploadFileDialog from "./UploadFileDialog";
import { useHandleModal } from "../hooks/useHandleModal";
import { DocumentValidationStatus, Month } from "materialidad-outsourcing/dist/types";
import { dateFormat, replaceAll, toBase64 } from "../utils";
import ShowDocumentDialog from "./watch-document/ShowDocumentDialog";
import usePlanState from "../../../store/planState";
import { RequirementDocument, StageRequirement } from "../../../core/models/stage.models";
import useStagesState from "../../../store/stagesState";
import useGeneradoresStagesState from "../../../store/generadores/generadoresStagesState";
import useGlobalState from "../../../store/globalState";
import { NTPService } from "../../../core/services/NTPService.service";
import useUiState from "../../../store/uiState";
import { StagesService } from "../../../core/services/stages.service";
import { StagesGeneratorService } from "../../../core/services/stages.generators.service";
import useCustomToast from "../../../core/hooks/useCustomToast";
import ContractHelpDialog from "./ContractHelpDialog";
import HelperIcon from "../../../assets/img/help-icon.png";
import XLSIcon from "../../../assets/img/xls.png";
import { expediente, cumplimiento, reporteo } from "../comps/helper-document/stages-documents.js";

const DocumentCard = ({
  doc,
  onShow,
  onDelete,
}: {
  doc: RequirementDocument;
  onShow: (docID: string) => any;
  onDelete: (doc: RequirementDocument) => any;
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();
  const { canUploadDocuments } = usePlanState();
  const menuOptions: ContextualMenuItem[] = [
    { text: "Abrir", action: () => onShow(doc.uid) },
    // { text: "Firmar", action: () => {} /*props.onSignDocument?.(props.document.id)*/, disabled: true },

    canUploadDocuments.onExpedient
      ? {
          text: "Eliminar",
          action: () => onDelete(doc), //handleConfirmDelete,
          disabled: false /*!props.onDeleteDocument || props.document.validationStatus.status !== 'Bajo revisión'*/,
        }
      : undefined,
  ];
  const [Menu, openMenu] = useContextualMenu(menuOptions.filter((option) => !!option));

  const statusColor = () => {
    switch (doc.validationStatus?.status) {
      case "Aceptado":
        return "success";
      case "Rechazado":
        return "error";
      case "Bajo revisión":
      default:
        return "warning";
    }
  };

  return (
    <div style={{ padding: ".5rem" }}>
      <div
        style={{
          padding: ".8rem",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px dashed #a6a6a6",
        }}
      >
        <div>
          <p style={{ lineHeight: "1.2", minHeight: "2.4rem", wordBreak: "break-all" }}>
            <b>{doc.name}</b>
          </p>
          <p style={{ fontSize: ".8rem" }}>
            Año correspondiente: <b>{doc.year || "---"}</b>
          </p>
          <p style={{ fontSize: ".8rem" }}>
            Mes correspondiente: <b>{doc.month}</b>
          </p>
          <p style={{ fontSize: ".8rem" }}>
            Agregado el: <b>{dateFormat(new Date(doc.createdAt)) ?? "---"}</b>
          </p>
        </div>
        <div>
          <div style={{ color: "#0d0d0d", textAlign: "right" }}>
            <IconButton
              color="inherit"
              onClick={() => {
                onShow(doc.uid);
              }}
              size="small"
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton color="inherit" onClick={openMenu} size="small">
              <MoreVertIcon />
            </IconButton>
            <Menu />
          </div>

          <Button
            size="small"
            variant="contained"
            onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
            onMouseLeave={() => setAnchorEl(null)}
            disableElevation
            style={{
              color: "white",
              backgroundColor: theme.palette[statusColor()].main,
            }}
          >
            {/* @ts-ignore */}
            <nobr>{doc.validationStatus?.status}</nobr>
          </Button>
          <Popover
            style={{ pointerEvents: "none" }}
            id="mouse-over-popover"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            classes={{
              paper: classes.popoverPaper,
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={() => setAnchorEl(null)}
            disableRestoreFocus
          >
            <div
              style={{ fontSize: "12px" }}
              dangerouslySetInnerHTML={{ __html: replaceAll(doc?.validationStatus?.comments || "", "\n", "<br/> *") || "Sin comentarios" }}
            />
          </Popover>
        </div>
      </div>
    </div>
  );
};

function useServiceState(typeService) {
  const generadoresState = useGeneradoresStagesState();
  const stagesState = useStagesState();

  if (typeService === 'generators') {
    return generadoresState;
  } else {
    return stagesState;
  }
}

const RequirementCard = ({ requirement, refresh }: { requirement: StageRequirement; refresh: any }) => {
  const theme = useTheme();
  const classes = useStyles();
  const toast = useCustomToast();
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showAllDocs, setShowAllDocs] = useState(false);
  const companyId = useGlobalState((s) => s.mainCompany?.id);
  const setShowGenericLoader = useUiState((s) => s.setShowGenericLoader);
  const [showContractHelpDialog, setShowContractHelpDialog] = useState(false);
  const [tempRequirement, setTempRequirement] = useState(null);
  const { typeService } = useGlobalState();
  const {
    currentService,
    currentStage,
    uploadRequirementDocument,
    uploadServiceRequirementDocument,
    deleteRequirementDocument,
    deleteServiceRequirementDocument,
  } = useServiceState(typeService);

  // const daysLeft = requirement.deliveryDate
  //   ? Math.ceil((new Date(requirement.deliveryDate).getTime() - new Date().getTime()) / (1000 * 24 * 3600))
  //   : undefined;
  const [showModal, handleShowModal, handleCloseModal] = useHandleModal(); // Handles the upload document modal.;
  const [showDocument, handleShowDocument, handleCloseDocument] = useHandleModal();
  const canUploadDocuments = usePlanState((s) => s.canUploadDocuments);

  const onShowDocument = (documentId: string) => {
    setSelectedDocument(requirement.documents?.find((doc) => doc.uid === documentId));
    //const i = props.files.findIndex((file) => file.id === documentId);
    //setDocumentIndex(i);
    handleShowDocument();
  };

  const handleDeleteCardDocument = async (document: RequirementDocument) => {
    try {
      let deleted;
      setShowGenericLoader(true);
      if (currentStage?.stageTemplateId) {
        deleted = await deleteRequirementDocument(companyId, currentStage.stageTemplateId, requirement.uid, document?.uid);
      } else {
        deleted = await deleteServiceRequirementDocument(currentService.id, requirement.uid, document);
      }
      setShowGenericLoader(false);
      if (!deleted) {
        throw new Error("Document not deleted");
      } else {
        refresh();
        toast.success("Documento eliminado");
      }
    } catch (error) {
      return toast.error("Error al eliminar el documento");
    }
  };

  const handleValidateStatus = async (status: DocumentValidationStatus) => {
    const body = {
      uid: selectedDocument.uid,
      validationStatus: status,
    };
    let res;
    setShowGenericLoader(true);
    if (!currentService) {
      if (typeService === "generators") {
        res = await StagesGeneratorService.getInstance().validateStageRequirement(companyId, currentStage.stageTemplateId, requirement.uid, body);
      } else {
        res = await StagesService.getInstance().validateStageRequirement(companyId, currentStage.stageTemplateId, requirement.uid, body);
      }
    } else {
      if (typeService === "generators") {
        res = await StagesGeneratorService.getInstance().updateServiceRequirementDocStatus(currentService.id, requirement.uid, body);
      } else {
        res = await NTPService.getInstance().updateServiceRequirementDocStatus(currentService.id, requirement.uid, body);
      }
    }
    setShowGenericLoader(false);
    if (res?.ok || res?.codigo === 0) {
      refresh();
      toast.success("Documento validado correctamente");
    } else {
      toast.error("Error al validar el documento");
    }
  };

  const uploadDocument = async (file: File, description: string, month: Month, year: string) => {
    const filename = file.name.split(".") ?? ["", ""];
    const extension = "." + filename?.pop() ?? "";

    const body = {
      name: filename.join("."),
      extension,
      comments: description,
      b64File: await toBase64(file),
      month,
      year,
    };

    try {
      let uploaded;
      setShowGenericLoader(true);
      if (currentStage?.stageTemplateId) {
        uploaded = await uploadRequirementDocument(companyId, currentStage?.stageTemplateId, requirement.uid, body as any);
      } else {
        uploaded = await uploadServiceRequirementDocument(currentService?.id, requirement.uid, body as any);
      }
      setShowGenericLoader(false);
      if (uploaded.ok) {
        toast.success("Se ha cargado el documento");
        refresh();
      } else {
        setShowGenericLoader(false);
        toast.error(
          <div dangerouslySetInnerHTML={{ __html: replaceAll(uploaded.error || "", "\n", "<br/> *") || "Error al cargar el documento" }} />
        );
      }
    } catch (error) {
      setShowGenericLoader(false);
      
      if (error?.response?.data?.message?.includes("File exist")) {
        return toast.error("Ya existe un archivo con el mimso nombre.");
      }
      if (error?.response?.data?.mensaje){
        toast.error(error?.response?.data?.mensaje);
      }
      toast.error("Error al cargar el documento");
    }
  };

  const handleFileUpload = async (file: File, description: string, month: Month, year: string) => {
    if (file) {
      uploadDocument(file, description, month, year);
      handleCloseModal();
    } else {
      toast.warnign("No se ha seleccionado algún archivo para subir.");
    }
  };


  const showHelpIcon = ({ name }) => {
    const nameDocuments = [...expediente, ...cumplimiento, ...reporteo];
    let matchFound = false;
    nameDocuments.forEach((nom) => {
      if (nom.replace(/"/g, "").toLowerCase() === name.replace(/"/g, "").toLowerCase()) {
        matchFound = true;
        //console.log("",nom.replace(/"/g, '').toLowerCase() +"_"+ name.replace(/"/g, '').toLowerCase().toLowerCase())
      }
    });

    return matchFound ? (
      <>
        <span style={{ paddingLeft: "1rem" }}>
          <Tooltip title={"Ayuda"}>
            <IconButton onClick={() => openModalDocumentHelper(name)}>
              <img src={HelperIcon} alt="Help" />
            </IconButton>
          </Tooltip>
        </span>
      </>
    ) : (
      <div style={{ padding: "1.7rem" }}></div>
    );
  };

  function onDownloadNominaXls() {
    window.open("/docs/Lista_nomina.xlsx", "_blank");
  }

  const showDownloadIcon = ({ name }) => {
    let matchFound = false;

      if ('Lista de nómina pagada'.replace(/"/g, "").toLowerCase() === name.replace(/"/g, "").toLowerCase()) {
        matchFound = true;
      }

    return matchFound ? (
      <>
        <span style={{ paddingLeft: "1rem" }}>
          <Tooltip title={"Descargar XLS Lista de Nómina"}>
            <IconButton onClick={() => onDownloadNominaXls()}>
            <img src={XLSIcon} alt="Help" style={{width:'32px',height:'30px',mixBlendMode: 'multiply'}} />
            </IconButton>
          </Tooltip>
        </span>
      </>
    ) : (
      <div></div>
    );
  };

  const formatRequirimentName = (name) => {
    switch (name) {
      case 'CÉDULA DE DETERMINACIÓN DE CUOTAS "SUA MENSUAL"':
        return name.replace('CÉDULA DE DETERMINACIÓN DE CUOTAS "SUA MENSUAL"', 'Cédula de determinación de cuotas "SUA MENSUAL"');
      case 'CÉDULA DE DETERMINACIÓN DE CUOTAS "SUA BIMESTRAL"':
        return name.replace('CÉDULA DE DETERMINACIÓN DE CUOTAS "SUA BIMESTRAL"', 'Cédula de determinación de cuotas "SUA BIMESTRAL"');
      case 'RESUMEN DE LIQUIDACIÓN MENSUAL':
        return name.replace('RESUMEN DE LIQUIDACIÓN MENSUAL', 'Resumen de liquidación mensual');
      case 'RESUMEN DE LIQUIDACIÓN BIMESTRAL':
        return name.replace('RESUMEN DE LIQUIDACIÓN BIMESTRAL', 'Resumen de liquidación bimestral');
      case 'DECLARACIÓN DE ISR RETENIDO POR SALARIOS':
        return name.replace('DECLARACIÓN DE ISR RETENIDO POR SALARIOS', 'Declaración de ISR retenido por salarios');
      case 'DECLARACIÓN DE IVA':
        return name.replace('DECLARACIÓN DE IVA', 'Declaración de IVA');
      default:
        return name;
    }
  };

  const orderDocumentByYearMonth = (a, b) => {

    const meses = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    // Compara por año
    if (a.year < b.year) {
      return 1;
    } else if (a.year > b.year) {
      return -1;
    } else {
      // Si los años son iguales, compara por mes en orden numérico inverso
      const mesA = meses.indexOf(a.month);
      const mesB = meses.indexOf(b.month);
  
      // Usa el índice inverso para ordenar en orden numérico inverso
      if (mesA < mesB) {
        return 1;
      } else if (mesA > mesB) {
        return -1;
      } else {
        return 0; // Igual en año y mes
      }
    }
  };

  const openModalDocumentHelper = (name) => {
    setTempRequirement(name);
    setShowContractHelpDialog(true);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "#F3F3F3",
          display: "flex",
          flexDirection: "column",
          height: "max-content",
        }}
      >
        <div
          style={{
            backgroundColor: "#0092F61A",
            padding: ".5rem",
            width: "100%",
            textAlign: "center",
          }}
        >
          <h4
            style={{
              fontSize: "1.2rem",
              lineHeight: "1.3rem",
              minHeight: "2.5rem",
              fontWeight: "bold",
              color: theme.palette.secondary.main,
            }}
          >
            {formatRequirimentName(requirement.name)}

          </h4>
          <p style={{ color: theme.palette.secondary.main, fontSize: ".8rem" }}>
            <span>Entrega: {requirement.deliveryDate ? dateFormat(new Date(requirement.deliveryDate)) : "---"}</span>
            <span style={{ padding: "0 5px" }}> | </span>
            <span>Formato requerido: {requirement.fileType}</span>
           
          </p>
          <span>
          {showHelpIcon(requirement)}

          {showDownloadIcon(requirement)}
          </span>
        </div>

        {requirement.documents?.length ? (
          <div style={{ maxHeight: "240px", overflowY: "auto" }}>
            {requirement.documents
           // .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) // Convierte a objetos de fecha y luego compara
            .sort(orderDocumentByYearMonth)
            .slice(0, showAllDocs ? requirement.documents.length : 1)
            .map((doc) => (
              <DocumentCard key={doc.uid} doc={doc} onShow={onShowDocument} onDelete={handleDeleteCardDocument} />
            ))}
          </div>
        ) : (
          <div style={{ flex: 1 }}></div>
        )}

        {canUploadDocuments.onExpedient && (
          <div
            style={{
              padding: ".8rem",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button size="small" variant="contained" color="secondary" onClick={handleShowModal} disabled={requirement.name === "Generador"}>
              Subir documentos
            </Button>{""}

            <Button size="small" variant="contained" color="secondary" startIcon={<VisibilityIcon fontSize="inherit" />} disabled>
              Ver documentos anteriores
            </Button>
          </div>
        )}

        <div
          style={{
            backgroundColor: "#BCC5CB80",
            padding: ".2rem",
            width: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* {requirement.documents?.length > 0 &&
          requirement.documents[requirement.documents.length - 1]?.validationStatus?.status === "Aceptado" ? (
            <CheckCircleIcon htmlColor={theme.palette.success.main} />
          ) : (
            <WarningIcon htmlColor={theme.palette.error.main} />
          )} */}

          {/* {requirement.deliveryDate && (
            <>
              <NotificationsActiveIcon
                style={{ marginLeft: 8 }}
                htmlColor={(daysLeft as number) >= 0 ? "#00709E" : "#F44336"}
                fontSize="small"
              />
              <Typography
                variant="caption"
                className={`${classes.notification} ${(daysLeft as number) < 0 ? classes.notificationError : ""}`}
              >
                {(daysLeft as number) >= 0
                  ? `Actualizar en ${daysLeft} días`
                  : `Tu entrega lleva atrasada ${0 - (daysLeft as number)} días.`}
              </Typography>
            </>
          )} */}
          <Tooltip title={showAllDocs ? "Ver menos" : "Ver más"}>
            <IconButton
              aria-expanded={showAllDocs}
              aria-label="show more"
              color="default"
              className={[classes.expand, showAllDocs ? classes.expandOpen : null].join(" ")}
              onClick={() => setShowAllDocs(!showAllDocs)}
              size="small"
              disabled={!(requirement.documents?.length > 1)}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <UploadFileDialog
        cardTitle={requirement.name}
        open={showModal}
        onClose={handleCloseModal}
        onUploadFile={handleFileUpload}
        fileType={requirement.fileType}
      />
      {showDocument && (
        <ShowDocumentDialog onClose={handleCloseDocument} doc={selectedDocument} handleValidateStatus={handleValidateStatus} />
      )}
      {showContractHelpDialog && (
        <ContractHelpDialog
          open={true}
          onClose={() => {
            setShowContractHelpDialog(false);
            setTempRequirement(null);
          }}
          document={tempRequirement}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  infoSection: {
    backgroundColor: "white",
    padding: "1rem",
  },
  actionBtn: {
    padding: "10px 5px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  notification: {
    color: "#00709E",
    fontSize: "0.75rem",
    fontWeight: "bold",
  },
  notificationError: {
    color: theme.palette.error.main,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  popoverPaper: {
    padding: "0.5rem",
    color: theme.palette.error.main,
    maxWidth: "300px",
  },
}));

export default RequirementCard;
