import { Button, ButtonProps, darken, makeStyles } from '@material-ui/core';
import React from 'react';

interface ColoredButtonProps {
  color?: string;
  isIconButton?: boolean;
  rounded?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ColoredButton = ({ color, isIconButton, ...props }: Omit<ButtonProps, 'color'> & ColoredButtonProps) => {
  const classes = useStyles({ color, isIconButton });
  return <Button {...props} classes={{ root: classes.root, contained: classes.contained, outlined: classes.outlined }} />;
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 'bold',
    borderRadius: ({ rounded }: ColoredButtonProps) => !rounded && 10,
    padding: ({ isIconButton }: ColoredButtonProps) => isIconButton && 6,
    minWidth: ({ isIconButton }: ColoredButtonProps) => isIconButton && 'unset',

  },
  contained: ({ color }: ColoredButtonProps) =>
    color
      ? {
          color: theme.palette.common.white,
          backgroundColor: color,
          '&:hover': {
            backgroundColor: darken(color, 0.3)
          }
        }
      : {},
  outlined: ({ color }: ColoredButtonProps) =>
    color
      ? {
          color: color,
          borderColor: color
        }
      : {}
}));

export default ColoredButton;
