import create from 'zustand';

interface ImageFileState {
  imageFile: any;
  setImageFile: (imageFile: any) => void;
}

const useImageFileStore = create<ImageFileState>((set) => ({
  imageFile: null,
  setImageFile: (imageFile) => set({ imageFile }),
}));

export default useImageFileStore;
