import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

export const useBrowserButtons = (backAction: VoidFunction, forwardAction: VoidFunction) => {

  const [ locationKeys, setLocationKeys ] = useState<string[]>([]);
  const history = useHistory();

  useEffect(() => {
    setLocationKeys([...locationKeys, history.location.key]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen(( location: any ) => {
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ _, ...keys ]) => keys);
          forwardAction();
        } else {
          setLocationKeys((keys) => [ location.key, ...keys ]);
          backAction();

        }
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ locationKeys ]);
}

