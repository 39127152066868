import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const AxeleratumIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="28" height="32" viewBox="0 0 28 32">
      <path d="M5.09596 23.1819C4.81742 23.1882 4.54522 23.1819 4.28567 23.1565C4.55155 23.1692 4.82376 23.1755 5.09596 23.1819C6.64058 23.1376 8.4574 22.5615 10.3059 21.6689L12.7051 16.3324L14.2877 19.314C16.9085 17.4782 19.2001 15.2752 20.3838 13.3951L14.0345 0L1.88013 25.5747C1.88013 25.5747 2.82968 25.828 8.52703 25.6381L9.90072 22.5741C8.23583 22.992 6.60259 23.1945 5.09596 23.1819Z"/>
      <path d="M21.2764 15.2751C19.9344 17.2502 17.7567 19.1557 14.9777 20.618L17.6428 25.638H26.1888L21.2764 15.2751Z"/>
      <path d="M2.51316 31.1454H1.11415L0.848271 31.9431H0L1.44333 28.0752H2.18398L3.63364 31.9431H2.77904L2.51316 31.1454ZM1.32938 30.4997H2.29793L1.81049 29.0501L1.32938 30.4997Z"/>
      <path d="M5.03263 29.9237L5.51374 29.0627H6.33669L5.52007 30.4744L6.37467 31.9431H5.55172L5.03896 31.0378L4.5262 31.9431H3.70325L4.55785 30.4744L3.7349 29.0691H4.55785L5.03263 29.9237Z"/>
      <path d="M7.98267 31.9936C7.55853 31.9936 7.21669 31.867 6.95082 31.6074C6.68494 31.3479 6.552 31.0061 6.552 30.5756V30.4996C6.552 30.2084 6.60898 29.9552 6.71659 29.7273C6.83054 29.4994 6.9888 29.3222 7.19137 29.2019C7.39394 29.0816 7.62817 29.0183 7.89404 29.0183C8.28653 29.0183 8.59672 29.1449 8.82461 29.3918C9.0525 29.6387 9.16645 29.9932 9.16645 30.449V30.7655H7.33697C7.36229 30.9554 7.43826 31.101 7.55853 31.215C7.68514 31.3289 7.8434 31.3859 8.03331 31.3859C8.33084 31.3859 8.55873 31.2783 8.72965 31.063L9.10948 31.4872C8.99553 31.6517 8.83727 31.7784 8.64103 31.867C8.43846 31.9493 8.21689 31.9936 7.98267 31.9936ZM7.89404 29.6324C7.74211 29.6324 7.61551 29.683 7.52055 29.7843C7.42559 29.8856 7.36229 30.0375 7.33697 30.2274H8.4068V30.1641C8.40047 29.9932 8.35616 29.8603 8.26754 29.7653C8.17891 29.6767 8.0523 29.6324 7.89404 29.6324Z"/>
      <path d="M10.4072 31.943H9.63489V27.8599H10.4072V31.943Z"/>
      <path d="M12.3633 31.9936C11.9391 31.9936 11.5973 31.867 11.3314 31.6074C11.0656 31.3479 10.9326 31.0061 10.9326 30.5756V30.4996C10.9326 30.2084 10.9896 29.9552 11.0972 29.7273C11.2112 29.4994 11.3694 29.3222 11.572 29.2019C11.7746 29.0816 12.0088 29.0183 12.2747 29.0183C12.6671 29.0183 12.9773 29.1449 13.2052 29.3918C13.4331 29.6387 13.5471 29.9932 13.5471 30.449V30.7655H11.7176C11.7429 30.9554 11.8189 31.101 11.9391 31.215C12.0658 31.3289 12.224 31.3859 12.4139 31.3859C12.7115 31.3859 12.9393 31.2783 13.1103 31.063L13.4901 31.4872C13.3761 31.6517 13.2179 31.7784 13.0216 31.867C12.8254 31.9493 12.6038 31.9936 12.3633 31.9936ZM12.281 29.6324C12.1291 29.6324 12.0025 29.683 11.9075 29.7843C11.8125 29.8856 11.7492 30.0375 11.7239 30.2274H12.7938V30.1641C12.7874 29.9932 12.7431 29.8603 12.6545 29.7653C12.5659 29.6767 12.4392 29.6324 12.281 29.6324Z"/>
      <path d="M15.6044 29.7845C15.4967 29.7719 15.4081 29.7655 15.3258 29.7655C15.0346 29.7655 14.8447 29.8668 14.7561 30.0631V31.9432H13.9901V29.0692H14.7181L14.7371 29.411C14.889 29.1451 15.1043 29.0122 15.3765 29.0122C15.4588 29.0122 15.5411 29.0249 15.617 29.0439L15.6044 29.7845Z"/>
      <path d="M17.5604 31.9432C17.5225 31.8735 17.4971 31.7912 17.4845 31.6836C17.3009 31.8925 17.054 31.9938 16.7565 31.9938C16.4716 31.9938 16.2437 31.9115 16.0538 31.7469C15.8702 31.5823 15.7753 31.3798 15.7753 31.1329C15.7753 30.829 15.8892 30.5948 16.1171 30.4302C16.345 30.2656 16.6679 30.1833 17.0983 30.1833H17.4528V30.0187C17.4528 29.8858 17.4212 29.7782 17.3515 29.7022C17.2819 29.6199 17.1743 29.5819 17.0287 29.5819C16.9021 29.5819 16.8008 29.6136 16.7248 29.6769C16.6489 29.7402 16.6172 29.8225 16.6172 29.9301H15.8512C15.8512 29.7655 15.9019 29.6073 16.0032 29.468C16.1044 29.3287 16.25 29.2148 16.44 29.1325C16.6299 29.0502 16.8388 29.0122 17.073 29.0122C17.4275 29.0122 17.706 29.1008 17.9149 29.2781C18.1238 29.4553 18.2251 29.7085 18.2251 30.0314V31.2785C18.2251 31.5507 18.2631 31.7596 18.3391 31.8989V31.9432H17.5604ZM16.9211 31.4114C17.035 31.4114 17.1363 31.3861 17.2376 31.3355C17.3325 31.2848 17.4022 31.2152 17.4528 31.1329V30.6391H17.168C16.7818 30.6391 16.5792 30.7721 16.5539 31.0379V31.0822C16.5539 31.1772 16.5856 31.2595 16.6552 31.3165C16.7122 31.3798 16.8071 31.4114 16.9211 31.4114Z"/>
      <path d="M19.7318 28.3599V29.0689H20.2255V29.6323H19.7318V31.0693C19.7318 31.1769 19.7508 31.2528 19.7951 31.2972C19.8331 31.3415 19.9154 31.3668 20.0293 31.3668C20.1116 31.3668 20.1876 31.3605 20.2572 31.3478V31.9302C20.1053 31.9745 19.9533 31.9998 19.7951 31.9998C19.257 31.9998 18.9848 31.7276 18.9721 31.1832V29.6323H18.5543V29.0689H18.9721V28.3599H19.7318Z"/>
      <path d="M22.3716 31.6516C22.1817 31.8795 21.9222 31.9998 21.5866 31.9998C21.2765 31.9998 21.0422 31.9112 20.884 31.7339C20.7194 31.5567 20.6371 31.2971 20.6371 30.9553V29.0688H21.3967V30.93C21.3967 31.2275 21.536 31.3794 21.8082 31.3794C22.0678 31.3794 22.245 31.2908 22.3463 31.1072V29.0688H23.1186V31.9428H22.3969L22.3716 31.6516Z"/>
      <path d="M24.4162 29.069L24.4416 29.3918C24.6441 29.1449 24.9227 29.0183 25.2708 29.0183C25.6443 29.0183 25.8976 29.1639 26.0368 29.4551C26.2394 29.1639 26.5243 29.0183 26.8978 29.0183C27.2079 29.0183 27.4422 29.1069 27.5941 29.2905C27.746 29.4741 27.822 29.7463 27.822 30.1135V31.9493H27.0497V30.1135C27.0497 29.9489 27.018 29.8286 26.9547 29.759C26.8914 29.683 26.7775 29.645 26.6192 29.645C26.3913 29.645 26.2267 29.7526 26.1381 29.9742V31.9493H25.3721V30.1198C25.3721 29.9552 25.3405 29.8349 25.2708 29.759C25.2075 29.683 25.0936 29.645 24.9353 29.645C24.7201 29.645 24.5618 29.7336 24.4606 29.9172V31.9493H23.6946V29.0753H24.4162V29.069Z"/>
    </SvgIcon>
  )
}

export default AxeleratumIcon;
