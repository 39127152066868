export default function SatCheckIcon({ size }: { size?: number }) {
  return (
    <svg width={size || 28} height={size || 28} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.999 0C6.279 0 0 6.28 0 13.999s6.28 13.998 13.999 13.998S27.997 21.717 27.997 14C27.997 6.279 21.717 0 14 0Zm7.095 11.032-7.583 7.583a1.163 1.163 0 0 1-1.65 0l-3.79-3.792a1.165 1.165 0 1 1 1.649-1.65l2.966 2.967 6.758-6.757a1.165 1.165 0 1 1 1.65 1.65Z"
        fill="#95D97D"
      />
    </svg>
  );
}
