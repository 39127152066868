import { FC } from "react";
import { Box, makeStyles } from "@material-ui/core";
import CompleteKivitIcon from "../icons/CompleteKivitIcon";
import onboardingBG from "../../assets/img/OnboardingBG.png";
import AxeleratumIconLarge from "../icons/AxeleratumIconLarge";

type OnboardingTemplateProps = {
  hasWelcomeText?: boolean;
};

const OnboardingTemplate: FC<OnboardingTemplateProps> = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.banner}>
      {props.hasWelcomeText && (
        <Box fontSize="h2.fontSize" textAlign="center" fontWeight={300}>
          Te damos la bienvenida a
        </Box>
      )}
      <CompleteKivitIcon className={classes.kivitIcon} />
      <Box
        bgcolor="white"
        borderRadius="20px"
        className="animate__animated animate__backInUp"
        color="text.primary"
        display="flex"
        flexDirection="column"
        margin="0 0 auto 0"
        minWidth={{ sm: 410, lg: 550 }}
        padding="40px"
      >
        {props.children}
      </Box>
      <AxeleratumIconLarge style={{ fontSize: 48, marginTop: 20 }} />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  banner: {
    padding: "1%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "inherit",
    color: "white",
    backgroundImage: `url(${onboardingBG})`,
    backgroundColor: "#060707",
    backgroundSize: "cover",
  },
  kivitIcon: {
    fontSize: 107,
    marginBottom: "3%",
    marginTop: "2%",
    [theme.breakpoints.down('md')]: {
      fontSize: 57
    }
  }
}));

export default OnboardingTemplate;
