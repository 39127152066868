import { makeStyles, Button, Typography } from '@material-ui/core';
import { Dispatch, SetStateAction } from 'react';

interface ButtonCatalogProps {
  onclick: () => void;
}

const ButtonAddCatalog = ({onclick}: ButtonCatalogProps) => {
  const classes = useStyles();

  return (
    <Button className={classes.uploadButton} onClick={onclick}>
      <Typography className={classes.buttonTitle}>
        Subir catálogo
      </Typography>
      <Typography className={classes.buttonSubtitle}>
        Formato requerido: xls
      </Typography>
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  uploadButton: {
    backgroundColor: 'rgba(82, 198, 186, 1)',
    color: 'white',
    borderRadius: '8px',
    padding: theme.spacing(1, 3),
    textAlign: 'center',
    display: 'block',
    minWidth: '300px',
    '&:hover': {
      backgroundColor: 'rgba(82, 198, 186, 0.9)',
    },
  },
  buttonTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    width: '100%'
  },
  buttonSubtitle: {
    fontSize: '12px',
    fontWeight: 'normal',
  },
}));

export default ButtonAddCatalog;
