/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  FormControlLabel,
  FormGroup,
  Radio,
  Backdrop,
  CircularProgress,
  Select as MatSelect,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Box,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import TextField from "../../inputs/inputs-v2/RenderTextFieldV2";
import { FormikErrors, FormikTouched, useFormik } from "formik";
import { CompaniesService } from "../../../core/services/companies.service";
import LogoutOnLeave from "../../LogoutOnLeave";
//import { EditCompanyInfoReqBody } from "../../types/companies-service";
//import { ContactList, , EditCompanyInfoReqBody } from "../../types/companies-service";
//import { EditClientCompanyInfoReqBody } from "../../types/companies-service";
import { EditClientCompanyInfoReqBody, EditClientGeneratorCompanyInfoReqBod, EditCompanyInfoReqBody } from "../../../types/companies-service";

import useGlobalState from "../../../store/globalState";
import { SERVICES_LIST } from "../../../configs/constants";
import usePlanState from "../../../store/planState";
import useCustomToast from "../../../core/hooks/useCustomToast";
import CustomAccordion from "./CustomAccordion";
import useSessionState from "../../../store/sessionState";
import { AuthService } from "../../../core/services";
import AlertContinue from "../../UI/AlertContinue";
import { Alert } from "@material-ui/lab";




// const curpReg = new RegExp(
//   /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
// );
const rfcReg = new RegExp(/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i);

type personType = "moral" | "fisica";


type FormFields = {
  personType: personType | "";
  name?: string;
  comercialName?: string;
  assignSupplierNum?: string;
  rfc: string;
  street: string;
  exteriorNumber: string;
  interiorNumber: string;
  colony: string;
  city: string;
  postalCode: string;
  country: string;
  employesNumber?: number;
  services: string[];
  legalRepresentatives: ClientLegalRepresentative[];
  contacts: Contacts[];
  REPSEActivities: string[];
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface GeneratorPropsCreateEdit {
  isEditing?: boolean
  setClose?: () => void
  onFinish?: () => void
}

const FormNewUserData = ({ isEditing = false, setClose, onFinish }: GeneratorPropsCreateEdit) => {
  const classes = useStyles();
  const history = useHistory();
  const toast = useCustomToast()
  const [loading, setLoading] = useState(false);
  const { userInfo, mainCompany, resetState, setMainCompany } = useGlobalState();
  const { logout } = useSessionState();
  const { invitedAs, plan } = usePlanState();
  const authService = new AuthService();
  //const { setMustUpdateCompany } = useSessionState();
  const companiesService = CompaniesService.getInstance();
  const idProvider = mainCompany?.id;
  const mainCompanyId = sessionStorage.getItem("companyId");

  const [idFormGenerator, setIdFormGenerator] = useState("");
  const [servivesGenerator, setServiceGenerator] = useState([]);
  const [showAlertContinue, setShowAlertContinue] = useState(false);

  const idProviderGenerator = mainCompany.externalId;
  const idCompanyClient = mainCompany.clients;
  const externalSapId = mainCompany.externalSapId;
  //console.log("info servivesGenerator V2", servivesGenerator);


  const handleSubmit = async (values: FormFields) => {
    const body: EditCompanyInfoReqBody = {
      id: mainCompanyId,
      idProvider: idFormGenerator, // API DE GENERADORES
      name: values.name,
      type: values.personType,
      rfc: values.rfc,
      externalId: idProviderGenerator,
      externalSapId: externalSapId,
      taxResidence: {
        city: values.city,
        colonia: values.colony,
        cp: values.postalCode,
        interiorNumber: values.interiorNumber,
        outdoorNum: values.exteriorNumber,
        street: values.street,
        country: values.country,
      },
      sector: '',
      employeesSize: 0,
      web: '',
      legalRepresentatives: values.legalRepresentatives.map((value) => ({
        amaterno: value.mname,
        apaterno: value.pname,
        curp: value.curp,
        name: value.name,
      })),
      //contacts: values.contacts,
      servicesOffered: values.services?.join("|"),
      status: mainCompany?.status || "FREE",
      updatedData: true,
      repses: [{ name: "--" }],
      user: {
        ...mainCompany.user,
        name: values.comercialName
      }
    };

    setLoading(true);
    try {
      const res: any = await CompaniesService.getInstance()
        .updateCompany(body)
        .finally(() => setLoading(false));
      if (res?.code === 409) {
        toast.warnign("Ya existe una empresa registrada con el mismo Nombre o RFC")
        return
      }
      if (res?.status) {
        setMainCompany({
          ...mainCompany,
          ...body
        })
        const bodyG: EditClientGeneratorCompanyInfoReqBod = {
          contactList: values.contacts.map((e) => ({
            curp: e?.curp!,
            email: e.mail,
            name: e.name,
            lastName: e.pname,
            motherLastName: e.mname,
            phone: e.phone,
            rfc: e?.rfc
          })),
          id: idFormGenerator, // API DE GENERADORES
          idCompany: mainCompanyId,
          legalRepresentativesList: values.legalRepresentatives.map((value) => ({
            curp: value.curp,
            email: value.mail,
            lastName: value.pname,
            motherLastName: value.mname,
            name: value.name,
            phone: value.phone,
            rfc: value.rfc
          })),
          serviceOfferedIdList: values.services,
          socialObjectPredominantList: values.REPSEActivities.map(activity => activity) || [],
          supplierAssignedCode: values.assignSupplierNum
        }
        try {
          const res: any = await CompaniesService.getInstance()
            .updateAdditionalGeneradoresInfo(bodyG)
            .finally(() => setLoading(false));
          if (res?.codigo !== 0) {
            toast.warnign("Error al actualizar información adicional")
            return
          } else {
            if (isEditing) {
              toast.success('Cambios guardados con éxito')
              setClose()
              onFinish()
            } else {
              setShowAlertContinue(true);
            }
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          toast.error("Ocurrió un error inesperado");
          setLoading(false);
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
      toast.error("Ocurrió un error inesperado");
      setLoading(false);
    }
  };

  const handleClickAlert = () => {
    setShowAlertContinue(false);
    resetState();
    logout();
    history.push("/");
    window.location.reload();
  };

  const formik = useFormik<FormFields>({
    initialValues: {
      personType: (mainCompany?.type as any) || "",
      rfc: mainCompany?.rfc || "",
      name: mainCompany?.name || "",
      comercialName: mainCompany?.user.name,
      assignSupplierNum: idProviderGenerator,
      street: mainCompany?.taxResidence?.street || "",
      exteriorNumber: mainCompany?.taxResidence?.outdoorNum || "",
      interiorNumber: mainCompany?.taxResidence?.interiorNumber || "",
      colony: mainCompany?.taxResidence?.colonia || "",
      city: mainCompany?.taxResidence?.city || "",
      postalCode: mainCompany?.taxResidence?.cp || "",
      country: mainCompany?.taxResidence?.country || "",
      employesNumber: mainCompany?.employeesSize || 0,
      services: [" "],
      legalRepresentatives: [
        {
          name: "",
          curp: "",
          pname: "",
          mname: "",
          phone: "",
          mail: "",
          rfc: ""
        },
      ],
      REPSEActivities: [""],
      contacts: [
        {
          name: "",
          pname: "",
          mname: "",
          phone: "",
          mail: "",
        },
        {
          name: "",
          pname: "",
          mname: "",
          phone: "",
          mail: "",
        },
        {
          name: "",
          pname: "",
          mname: "",
          phone: "",
          mail: "",
        },
      ]
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      assignSupplierNum: Yup.string().required("Campo requerido"),
      name: Yup.string().required("Campo requerido"),
      comercialName: Yup.string().required("Campo requerido"),
      personType: Yup.string().required("Seleccionar una opción"),
      rfc: Yup.string().matches(rfcReg, { message: "Formato inválido" }).required("Campo requerido"),
      street: Yup.string().required("Campo requerido"),
      exteriorNumber: Yup.string(),
      interiorNumber: Yup.string(),
      colony: Yup.string().required("Campo requerido"),
      city: Yup.string().required("Campo requerido"),
      postalCode: Yup.string().required("Campo requerido"),
      country: Yup.string().required("Campo requerido"),
      employesNumber: Yup.number().required("Campo requerido").min(0),
      services: Yup.array(Yup.string().min(2, "Seleccione almenos 1 servicio")).required("Campo requerido"),
      legalRepresentatives: Yup.array(
        Yup.object({
          name: Yup.string().required("Campo requerido"),
          curp: Yup.string().required("Campo requerido"),
          pname: Yup.string().required("Campo requerido"),
          phone: Yup.string().required("Campo requerido"),
          rfc: Yup.string().required("Campo requerido"),
          mail: Yup.string()
            .email("Debe ser un correo válido")
            .required("Campo requerido"),
        })
      ).min(1, "Debe tener al menos un representante legal"),
      contacts: Yup.array(
        Yup.object({
          name: Yup.string().required("Campo requerido"),
          pname: Yup.string().required("Campo requerido"),
          phone: Yup.string().required("Campo requerido"),
          mail: Yup.string()
            .email("Debe ser un correo válido")
            .required("Campo requerido"),
        })
      ).min(1, "Debe tener al menos un contacto"),
      REPSEActivities: Yup.array().min(1, "Debe tener al menos un objeto social"),
    }),
  });

  const onPersonTypeChange = (e: any) => {
    formik.setFieldValue("personType", e.target.value);
  };

  const [servicesSelected, setServices] = React.useState([" "]);

  const handleChange = (event) => {
    const values: string[] = event.target.value;
    if (values.length === 0) {
      setServices([" "]);
    } else {
      setServices(values.filter((e) => e !== " "));
    }
  };

  const handleAddREPSE = () => {
    const aux = [...formik.values.REPSEActivities];
    aux.push("");
    formik.setFieldValue("REPSEActivities", aux);
  };

  const handleAddLegalRep = () => {
    const aux = [...formik.values.legalRepresentatives];
    aux.push({
      name: "",
      pname: "",
      mname: "",
      curp: "",
      mail: "",
      phone: "",
      rfc: ""
    });
    formik.setFieldValue("legalRepresentatives", aux);
  };

  const handleDeleteREPSE = (index: number) => {
    const aux = [...formik.values.REPSEActivities];
    aux.splice(index, 1);
    formik.setFieldValue("REPSEActivities", aux);
  };

  const handleDeleteLegalRep = (index: number) => {
    const aux = [...formik.values.legalRepresentatives];
    aux.splice(index, 1);
    formik.setFieldValue("legalRepresentatives", aux);
  };

  useEffect(() => {
    formik.setFieldValue("services", servicesSelected);
    // eslint-disable-next-line
  }, [servicesSelected]);




  const fetchServiceOffered = async (companyId: string) => {
    try {
      const servicesG = await CompaniesService.getInstance().getServiceOffered(companyId);
      console.log("res servicesG", servicesG.respuesta); // Muestra la respuesta en la consola

      const serviceDescriptions = servicesG.respuesta.map(service => service.description);

      setServiceGenerator(serviceDescriptions); // Guarda la respuesta en el estado
    } catch (error) {
      console.error("Error fetching service offered:", error); // Maneja el error
    }
  };



  const geTID = async (companyId: string) => {

    const clientId = idCompanyClient[0].clientId;
    try {
      //645d783c45429d56d1922a8b
      const servicesG = await CompaniesService.getInstance().getInitAdditionalGeneradoresInfo(clientId, companyId);

      // console.log("res getInitAdditionalGeneradoresInfo", servicesG.respuesta); 
      // console.log("form getInitAdditionalGeneradoresInfo", servicesG.respuesta.id); 

      setIdFormGenerator(servicesG.respuesta.id);
    } catch (error) {
      console.error("Error fetching service offered:", error);
    }
  };

  useEffect(() => {
    fetchServiceOffered(mainCompanyId);
    companiesService.getAdditionalInfo(idProvider).then(async (data) => {
      const additionalInfoo: EditClientGeneratorCompanyInfoReqBod = data.respuesta
      formik.setFieldValue('legalRepresentatives', additionalInfoo?.legalRepresentativesList?.map((e) => ({
        curp: e.curp,
        mail: e.email,
        mname: e.motherLastName,
        name: e.name,
        phone: e.phone,
        pname: e.lastName,
        rfc: e.rfc
      })))
      formik.setFieldValue('contacts', additionalInfoo?.contactList?.map((e) => ({
        name: e.name,
        pname: e.lastName,
        mname: e.motherLastName,
        phone: e.phone,
        mail: e.email,
      })))
      formik.setFieldValue('REPSEActivities', additionalInfoo.socialObjectPredominantList)
      formik.setFieldValue('services', additionalInfoo.serviceOfferedIdList)
      setIdFormGenerator(additionalInfoo.id);
    });
    if (!isEditing) {
      geTID(mainCompanyId);
    }
  }, []);

  return (
    <>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        {!isEditing && <LogoutOnLeave />}
        <Grid container>
          <Grid item md={12}>
            <h2 className={classes.formTitle}>
              {
                isEditing ? 'Actualizar datos de empresa' : 'Para poder iniciar necesitamos algunos datos'
              }
            </h2>

            <div className={classes.subContainer}>
              {/* <div className={classes.titleInputDoubleRow}> */}
              <Box marginTop={1}
                marginBottom={1}
                fontSize="body2.fontSize"
                fontWeight={600}
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                Datos relevantes:
                <TextField
                  size="small"
                  label="Id de proveedor asignado"
                  error={formik.errors.assignSupplierNum}
                  onChange={formik.handleChange}
                  touched={formik.touched.assignSupplierNum}
                  value={formik.values.assignSupplierNum}
                  placeholder="No. de proveedor asignado*"
                  name="assignSupplierNum"
                  disabled={true}
                  fullWidth={false}
                />
              </Box>
              {/* </div> */}
              <TextField
                size="small"
                error={formik.errors.name}
                onChange={formik.handleChange}
                touched={formik.touched.name}
                value={formik.values.name}
                placeholder="Nombre de tu empresa (razón social)*"
                name="name"
              />
              <TextField
                size="small"
                error={formik.errors.comercialName}
                onChange={formik.handleChange}
                touched={formik.touched.comercialName}
                value={formik.values.comercialName}
                placeholder="Nombre comercial*"
                name="comercialName"
              />

              <FormGroup row className={classes.personSelect}>
                <FormControlLabel
                  className="mr-5"
                  control={
                    <Radio
                      className="mr-3"
                      checked={formik.values.personType === "moral"}
                      onChange={onPersonTypeChange}
                      value="moral"
                      name="moral"
                    />
                  }
                  label="Persona Moral*"
                />

                <FormControlLabel
                  control={
                    <Radio
                      className="mr-3"
                      checked={formik.values.personType === "fisica"}
                      onChange={onPersonTypeChange}
                      value="fisica"
                      name="fisica"
                    />
                  }
                  label="Persona Física*"
                />
                <FormHelperText className={classes.errorMessageRadio}>{formik.touched.personType && formik.errors.personType}</FormHelperText>
              </FormGroup>

              <TextField
                size="small"
                error={formik.errors.rfc}
                onChange={formik.handleChange}
                touched={formik.touched.rfc}
                value={formik.values.rfc}
                placeholder="RFC *"
                name="rfc"
              />

              <FormControl variant="outlined" fullWidth margin="dense" error={!!formik.touched.services && !!formik.errors.services}>
                <MatSelect
                  name="services"
                  value={formik.values.services}
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  onChange={handleChange}
                  input={<OutlinedInput id="select-multiple-chip" />}
                  renderValue={(selected: any) => {
                    return (
                      <div>
                        {selected[0] === " " && <span>Servicios ofrecidos (uno o varios) *</span>}
                        {selected.map(
                          (value: string, idx: number) =>
                            !!value &&
                            value !== " " && (
                              <span key={value}>
                                {idx > 0 && ", "}
                                {value.substr(0, 24)} {value.length > 24 && "..."}
                              </span>
                            )
                        )}
                      </div>
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  {servivesGenerator.map((name) => (
                    <option
                      key={name}
                      value={name}
                      style={{
                        backgroundColor: `${servicesSelected.includes(name) ? "#3297FD" : "unset"}`,
                        color: `${servicesSelected.includes(name) ? "#fff" : "unset"}`,
                        cursor: "pointer",
                      }}
                    >
                      {name}
                    </option>
                  ))}
                </MatSelect>
                <FormHelperText>{!!formik.touched.services && formik.errors.services}</FormHelperText>
              </FormControl>

              <Box marginTop={1} marginBottom={1} fontSize="body2.fontSize" fontWeight={600}>
                Domicilio Fiscal:
              </Box>

              <TextField
                size="small"
                error={formik.errors.street}
                onChange={formik.handleChange}
                touched={formik.touched.street}
                value={formik.values.street}
                placeholder="Calle *"
                name="street"
              />

              <div className={classes.inputDoubleRow}>
                <TextField
                  size="small"
                  error={formik.errors.exteriorNumber}
                  onChange={formik.handleChange}
                  touched={formik.touched.exteriorNumber}
                  value={formik.values.exteriorNumber}
                  placeholder="Número exterior *"
                  name="exteriorNumber"
                  type="text"
                />

                <TextField
                  size="small"
                  error={formik.errors.interiorNumber}
                  onChange={formik.handleChange}
                  touched={formik.touched.interiorNumber}
                  value={formik.values.interiorNumber}
                  placeholder="Número interior *"
                  name="interiorNumber"
                  type="text"
                />
              </div>

              <div className={classes.inputDoubleRow}>
                <TextField
                  size="small"
                  error={formik.errors.colony}
                  onChange={formik.handleChange}
                  touched={formik.touched.colony}
                  value={formik.values.colony}
                  placeholder="Colonia *"
                  name="colony"
                />

                <TextField
                  size="small"
                  error={formik.errors.city}
                  onChange={formik.handleChange}
                  touched={formik.touched.city}
                  value={formik.values.city}
                  placeholder="Ciudad *"
                  name="city"
                />
              </div>

              <div className={`${classes.inputDoubleRow} mb-3`}>
                <TextField
                  size="small"
                  error={formik.errors.postalCode}
                  onChange={formik.handleChange}
                  touched={formik.touched.postalCode}
                  value={formik.values.postalCode}
                  placeholder="Código postal *"
                  name="postalCode"
                  type="number"
                />

                <TextField
                  size="small"
                  error={formik.errors.country}
                  onChange={formik.handleChange}
                  touched={formik.touched.country}
                  value={formik.values.country}
                  placeholder="País *"
                  name="country"
                />
              </div>
              <CustomAccordion info={
                <ContactFieldsForm key={0} number={0} dataInputs={formik} classes={classes} />} title="Contactos (3)">
                <div>
                  <ContactFieldsForm key={1} number={1} dataInputs={formik} classes={classes} />
                  <ContactFieldsForm key={2} number={2} dataInputs={formik} classes={classes} />
                </div>
              </CustomAccordion>

              <Box
                marginTop={1}
                marginBottom={1}
                fontSize="body2.fontSize"
                fontWeight={600}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                Representante(s) legal(es):
                <Button startIcon={<AddIcon />} variant="contained" color="primary" size="small" onClick={handleAddLegalRep}>
                  Agregar Representante legal
                </Button>
              </Box>

              {formik.values.legalRepresentatives.map((legalRep, i) => (
                <Box marginTop={1}
                  marginBottom={1}
                  fontSize="body2.fontSize"
                  fontWeight={600}
                  display="flex"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  key={i}
                >
                  <Box width="96%" paddingLeft="10px">
                    <div className={classes.inputTripleRow}>
                      <TextField
                        size="small"
                        error={(formik.errors.legalRepresentatives?.[i] as FormikErrors<ClientLegalRepresentative>)?.name}
                        onChange={formik.handleChange}
                        touched={(formik.touched.legalRepresentatives?.[i] as FormikTouched<ClientLegalRepresentative>)?.name}
                        value={legalRep.name}
                        placeholder="Nombre(s)*"
                        name={`legalRepresentatives[${i}].name`}
                      />
                      <TextField
                        size="small"
                        error={(formik.errors.legalRepresentatives?.[i] as FormikErrors<ClientLegalRepresentative>)?.pname}
                        onChange={formik.handleChange}
                        touched={(formik.touched.legalRepresentatives?.[i] as FormikTouched<ClientLegalRepresentative>)?.pname}
                        value={legalRep.pname}
                        placeholder="Apellido Paterno*"
                        name={`legalRepresentatives[${i}].pname`}
                      />
                      <TextField
                        size="small"
                        error={(formik.errors.legalRepresentatives?.[i] as FormikErrors<ClientLegalRepresentative>)?.mname}
                        onChange={formik.handleChange}
                        touched={(formik.touched.legalRepresentatives?.[i] as FormikTouched<ClientLegalRepresentative>)?.mname}
                        value={legalRep.mname}
                        placeholder="Apellido Materno"
                        name={`legalRepresentatives[${i}].mname`}
                      />
                    </div>

                    <div className={classes.inputDoubleRow}>


                      <TextField
                        size="small"
                        error={(formik.errors.legalRepresentatives?.[i] as FormikErrors<ClientLegalRepresentative>)?.curp}
                        onChange={formik.handleChange}
                        touched={(formik.touched.legalRepresentatives?.[i] as FormikTouched<ClientLegalRepresentative>)?.curp}
                        value={legalRep.curp}
                        placeholder="CURP*"
                        name={`legalRepresentatives[${i}].curp`}
                      />
                      <TextField
                        size="small"
                        error={(formik.errors.legalRepresentatives?.[i] as FormikErrors<ClientLegalRepresentative>)?.rfc}
                        onChange={formik.handleChange}
                        touched={(formik.touched.legalRepresentatives?.[i] as FormikTouched<ClientLegalRepresentative>)?.rfc}
                        value={legalRep.rfc}
                        placeholder="RFC*"
                        name={`legalRepresentatives[${i}].rfc`}
                      />
                    </div>
                    <div className={classes.inputDoubleRow}>
                      <TextField
                        size="small"
                        error={(formik.errors.legalRepresentatives?.[i] as FormikErrors<ClientLegalRepresentative>)?.phone}
                        onChange={formik.handleChange}
                        touched={(formik.touched.legalRepresentatives?.[i] as FormikTouched<ClientLegalRepresentative>)?.phone}
                        value={legalRep.phone}
                        placeholder="Teléfono*"
                        name={`legalRepresentatives[${i}].phone`}
                      />
                      <TextField
                        size="small"
                        error={(formik.errors.legalRepresentatives?.[i] as FormikErrors<ClientLegalRepresentative>)?.mail}
                        onChange={formik.handleChange}
                        touched={(formik.touched.legalRepresentatives?.[i] as FormikTouched<ClientLegalRepresentative>)?.mail}
                        value={legalRep.mail}
                        placeholder="Correo"
                        name={`legalRepresentatives[${i}].mail`}
                      />
                    </div>
                  </Box>
                  {formik.values.legalRepresentatives.length > 1 && (
                    <Box marginTop={0.5} width="2%">
                      <IconButton size="small" onClick={() => handleDeleteLegalRep(i)}>
                        <CloseIcon color="primary" />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              ))}

              <Box
                marginTop={1}
                marginBottom={1}
                fontSize="body2.fontSize"
                fontWeight={600}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                Objeto social preponderante:
                <Button startIcon={<AddIcon />} variant="contained" color="primary" size="small" onClick={handleAddREPSE}>
                  Agregar Objeto social preponderante
                </Button>
              </Box>

              {formik.values.REPSEActivities.map((activity, i) => (
                <Box display="flex" key={i}>
                  <TextField
                    size="small"
                    error={formik.errors.REPSEActivities?.[i] as string}
                    onChange={formik.handleChange}
                    touched={formik.touched.REPSEActivities}
                    value={activity}
                    placeholder="Escribir tal cual aparecen en el acta constitutiva*"
                    name={`REPSEActivities[${i}]`}
                  />
                  {formik.values.REPSEActivities.length > 1 && (
                    <IconButton size="small" onClick={() => handleDeleteREPSE(i)}>
                      <CloseIcon color="primary" />
                    </IconButton>
                  )}
                </Box>
              ))}
            </div>

            <div className="text-red">
              {
                ((formik.errors.legalRepresentatives && typeof formik.errors.legalRepresentatives === 'string') && formik.touched.REPSEActivities) &&
                <Alert variant="filled" severity="error" className="mb-2">
                  {formik.errors.legalRepresentatives}
                </Alert>
              }
              {
                (formik.errors.REPSEActivities && formik.touched.REPSEActivities) &&
                <Alert variant="filled" severity="error">
                  {formik.errors.REPSEActivities}
                </Alert>
              }
            </div>
            <Grid container item xs={12} justifyContent="center">
              <div className="text-center">
                <Typography style={{ display: "block", textAlign: 'center' }} variant="caption">
                  * Campos obligatorios
                </Typography>
                {
                  isEditing && <Button type="button" className="mt-2 mr-2" variant="contained" disabled={loading} onClick={setClose}>
                    CANCELAR
                  </Button>
                }
                <Button type="submit" className="mt-2" variant="contained" color="primary" disabled={loading}>
                  {isEditing ? 'GUARDAR CAMBIOS' : 'SIGUIENTE'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Backdrop style={{ zIndex: 2000 }} open={loading} onClick={() => { }}>
          <CircularProgress color="secondary" />
        </Backdrop>
      </form>
      {showAlertContinue && (
        <AlertContinue
          title="IMPORTANTE"
          text="Es necesario que inicies sesión de nuevo para cargar tu información o configuración. Serás redirigido a la página de inicio de sesión, por favor, vuelve a ingresar tus credenciales para continuar."
          severity="info"
          onCancel={() => handleClickAlert()}
          onSubmit={() => handleClickAlert()}
        />
      )}
    </>
  );
};

function ContactFieldsForm({ number, dataInputs, classes }) {
  return (
    <Box marginTop={1}
      marginBottom={1}
      fontSize="body2.fontSize"
      fontWeight={600}
      display="flex"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <Box width="2%">
        {number + 1}.
      </Box>
      <Box width="96%" paddingLeft="10px">
        <div className={classes.inputTripleRow}>
          <TextField
            size="small"
            error={(dataInputs.errors.contacts?.[number] as FormikErrors<Contacts>)?.name}
            onChange={dataInputs.handleChange}
            touched={(dataInputs.touched.contacts?.[number] as FormikTouched<Contacts>)?.name}
            value={dataInputs.values.contacts[number]?.name}
            placeholder="Nombre (s) *"
            name={`contacts[${number}].name`}
          />
          <TextField
            size="small"
            error={(dataInputs.errors.contacts?.[number] as FormikErrors<Contacts>)?.pname}
            onChange={dataInputs.handleChange}
            touched={(dataInputs.touched.contacts?.[number] as FormikTouched<Contacts>)?.pname}
            value={dataInputs.values.contacts[number]?.pname}
            placeholder="Apellido Paterno *"
            name={`contacts[${number}].pname`}
          />
          <TextField
            size="small"
            error={(dataInputs.errors.contacts?.[number] as FormikErrors<Contacts>)?.mname}
            onChange={dataInputs.handleChange}
            touched={(dataInputs.touched.contacts?.[number] as FormikTouched<Contacts>)?.mname}
            value={dataInputs.values.contacts[number]?.mname}
            placeholder="Apellido Materno"
            name={`contacts[${number}].mname`}
          />
        </div>
        <div className={classes.inputDoubleRow}>
          <TextField
            size="small"
            error={(dataInputs.errors.contacts?.[number] as FormikErrors<Contacts>)?.phone}
            onChange={dataInputs.handleChange}
            touched={(dataInputs.touched.contacts?.[number] as FormikTouched<Contacts>)?.phone}
            value={dataInputs.values.contacts[number]?.phone}
            placeholder="Teléfono *"
            name={`contacts[${number}].phone`}
          />
          <TextField
            size="small"
            error={(dataInputs.errors.contacts?.[number] as FormikErrors<Contacts>)?.mail}
            onChange={dataInputs.handleChange}
            touched={(dataInputs.touched.contacts?.[number] as FormikTouched<Contacts>)?.mail}
            value={dataInputs.values.contacts[number]?.mail}
            placeholder="Correo *"
            name={`contacts[${number}].mail`}
          />
        </div>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  formTitle: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: 400,
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: '30px'
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  subtitle: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  subContainer: {
    marginBottom: theme.spacing(2),
  },
  personSelect: {
    padding: theme.spacing(1),
  },
  inputDoubleRow: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "1rem",
    marginBottom: "5px"
  },
  accordionStyle: {
    border: 0,
    boxShadow: "none",
    "&::before": {
      display: "none",
    }
  },
  accordionHead: {
    padding: 0,
    display: 'inline-flex'
  },
  inputTripleRow: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gap: "1rem",
    marginBottom: "5px"
  },
  errorMessageRadio: {
    width: "100%",
    color: "#F45F5F",
    margin: "0 5px"
  }
}));

export default FormNewUserData;
