import create from "zustand";
import { PhotographicFormInfoReqBody } from "../../types/companies-service";
import { defaultPhotographicBody } from "./defaultForms";

interface PhotographicFormState {
    photographicFormBody: PhotographicFormInfoReqBody;
    formControl: boolean;
    setFormControl: () => void;
    updatePhotographicForm: (form: PhotographicFormInfoReqBody) => void;
}

const usePhotographicFormState = create<PhotographicFormState>((set) => ({
    photographicFormBody: defaultPhotographicBody,
    formControl: true,
    updatePhotographicForm: (form: PhotographicFormInfoReqBody) =>
        set((state) => ({
            ...state,
            photographicFormBody: form,
            formControl: true,
        })),
    setFormControl: () =>
        set((state) => ({
            ...state,
            formControl: !state.formControl,
        })),
}));


export default usePhotographicFormState;