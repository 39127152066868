import { axiosPagareRequest, authHeader } from "../../configs/axios.config";

export class TableroRiesgoService {
  private static instance: TableroRiesgoService;

  public static getInstance(): TableroRiesgoService {
    if (!TableroRiesgoService.instance) {
      TableroRiesgoService.instance = new TableroRiesgoService();
    }
    return TableroRiesgoService.instance;
  }

  async getPowerBiUrl() {
    try {
      const { data } = await axiosPagareRequest.get(`/general/util/powerbi/url`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async downloadPowerBiReport() {
    try {
      const { data } = await axiosPagareRequest.get(`/general/util/descargaDataPowerBi`, {
        headers: authHeader(),
        responseType: "blob",
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
}
