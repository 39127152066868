import { Box, Button, CircularProgress, Dialog, DialogContent, Grid, makeStyles, ThemeProvider, Typography } from "@material-ui/core";
import { ArrowDownward } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import GoBackButton from "../components/general/GoBackButton";
import RequirementCard from "../components/reportes/comps/RequirementCard";
import useGlobalState from "../store/globalState";
import useReportesState from "../store/reportesState";
import { kivitReportes } from "../styles/common";
import WarningIcon from "@material-ui/icons/Warning";
import StatusBar from "../components/stages/comps/StatusBar";
import useCustomToast from "../core/hooks/useCustomToast";

const ReportesPage = () => {
  const params: { clientId: string } = useParams();
  const classes = useStyles();
  const toast = useCustomToast();
  const { mainCompany } = useGlobalState();

  const [loading, setLoading] = useState(false);
  const [showUploadAlert, setShowUploadAlert] = useState(false);
  const { reporte, getReportes } = useReportesState();

  useEffect(() => {
    if (mainCompany?.id && params.clientId) {
      setLoading(true);
      getReportes(params.clientId, mainCompany.id)
        .then((succes) => {
          if (!succes) {
           toast.error("Ocurrió un error al obtener reporte");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    //eslint-disable-next-line
  }, [mainCompany.id]);

  return (
    <>
      <ThemeProvider theme={kivitReportes}>
        <Box display="flex" justifyContent="space-between" alignItems="center" style={{ gap: "1rem" }}>
          <Grid item>
            <GoBackButton />
          </Grid>
          <StatusBar value={0} stageCompleted />
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" py={4} style={{ gap: "1.5rem" }}>
          <Typography variant="h3" color="secondary">
            MIS REPORTES
          </Typography>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ width: "46%" }}>
              Queremos hacer tu proceso más fácil descarga nuestras plantillas para llenar con tu información y entregar a las instituciones
              correspendientes
            </span>
            <Button
              variant="contained"
              color="secondary"
              style={{ padding: "1rem" }}
              endIcon={<ArrowDownward fontSize="inherit" />}
              onClick={() => {
                window.location.replace("/docs/ISCOE.xlsm");
                setShowUploadAlert(true);
              }}
            >
              DESCARGAR PLANTILLA ISCOE
            </Button>

            <Button
              variant="contained"
              color="secondary"
              style={{ padding: "1rem" }}
              endIcon={<ArrowDownward fontSize="inherit" />}
              onClick={() => {
                window.location.replace("/docs/sisub-templates.zip");
                setShowUploadAlert(true);
              }}
            >
              DESCARGAR 3 PLANTILLAS SISUB
            </Button>
          </Box>
        </Box>

        {loading && (
          <div className={classes.placeholderWrapper}>
            <CircularProgress color="primary" />
          </div>
        )}
        {!loading && !reporte?.groups?.length && (
          <div className={classes.placeholderWrapper}>
            <Typography variant="subtitle2">No se encontraron datos</Typography>
          </div>
        )}
        {reporte?.groups?.map((group, idx) => (
          <Grid
            key={group.name + idx}
            container
            style={{
              backgroundColor: "#fff",
              padding: "1rem",
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
          >
            <Grid item xs={12} style={{ borderBottom: "1px solid #000", paddingBottom: ".5rem" }}>
              <Typography variant="h4">{group.name}</Typography>
            </Grid>

            <Grid
              container
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill,minmax(360px,1fr))",
                gridGap: "1rem",
                marginTop: "1rem",
              }}
            >
              {group.requirements?.map((req) => (
                <RequirementCard
                  key={req.uid}
                  requirement={{ ...req, documents: req.documents?.reverse() }}
                  reportId={reporte?.id}
                  getReporte={getReportes}
                />
              ))}
            </Grid>
          </Grid>
        ))}
        {showUploadAlert && <UploadAlertDialog open={true} onClose={() => setShowUploadAlert(false)} />}
      </ThemeProvider>
    </>
  );
};

const UploadAlertDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onclose}>
      <DialogContent>
        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem", padding: "1rem 2rem" }}>
          <WarningIcon color="error" fontSize="large" />
          <Typography color="primary" style={{ fontSize: "2rem" }}>
            Cuidado
          </Typography>
          <Typography style={{ fontSize: "1.2rem", textAlign: "center", maxWidth: "360px", lineHeight: 1.3, marginTop: "2rem" }}>
            Recuerda que debes <b>ser cuidadoso</b> con el llenado de información en nuestras plantillas antes de enviarlo a las autoridades
            correspondientes, <b>Kivit no se hace responsable de la información entregada fuera de nuestra plataforma</b>.
          </Typography>
          <Button onClick={onClose} size="medium" color="primary" variant="contained" style={{ padding: ".4rem 3rem", marginTop: "2rem" }}>
            Cerrar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ReportesPage;

const useStyles = makeStyles((theme) => ({
  infoSection: {
    backgroundColor: "white",
    padding: "1rem",
  },
  actionBtn: {
    padding: "10px 5px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  notification: {
    color: "#00709E",
    fontSize: "0.75rem",
    fontWeight: "bold",
  },
  notificationError: {
    color: theme.palette.error.main,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  placeholderWrapper: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
