import { axiosNtpRequestV1, authHeader } from "../../configs/axios.config";

export class ExpedienteService {
  private static instance: ExpedienteService;

  public static getInstance(): ExpedienteService {
    if (!ExpedienteService.instance) {
      ExpedienteService.instance = new ExpedienteService();
    }
    return ExpedienteService.instance;
  }

  async getExpediente(companyId: string) {
    try {
      const { data } = await axiosNtpRequestV1.get<any>(`/expedientes/${companyId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getExpedienteProgress(companyId: string) {
    try {
      const { data } = await axiosNtpRequestV1.get<any>(`/expedientes/${companyId}/progress`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }


  async createExpediente(companyId: string) {
    try {
      const { data } = await axiosNtpRequestV1.get<any>(`/expedientes/${companyId}/create`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async uploadDocument(
    companyId: string,
    requirementId: string,
    body: { name: string; extension: string; month: string; year: string; comments: string; b64File: string }
  ) {
    try {
      const { data } = await axiosNtpRequestV1.post(`/expedientes/${companyId}/requirements/${requirementId}`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async validateDocument(
    companyId: string,
    requirementId: string,
    body: { name: string; month: string; year: string; comments: string; b64File: string }
  ) {
    try {
      const { data } = await axiosNtpRequestV1.post(`/expedientes/${companyId}/requirements/${requirementId}`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteDocument(companyId: string, requirementId: string, docId: string) {
    try {
      const { data } = await axiosNtpRequestV1.put(
        `/expedientes/${companyId}/requirements/${requirementId}/documents/${docId}`,
        {},
        {
          headers: authHeader(),
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async downloadDocuments(
    companyId: string,
    body: string[]
  ) {
    try {
      const { data } = await axiosNtpRequestV1.post(`/expedientes/${companyId}/download`, body, {
        headers: authHeader(),
        responseType:"arraybuffer"
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
}


