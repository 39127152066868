import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { PropTypes } from 'prop-types';

export class AlertConfirm extends Component {
  render() {
    const { open, title, textContent, onConfirm, onCancel } = this.props;

    return (
      <Dialog open={ open }>
        <DialogTitle>
          { title }
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <strong>{ textContent }</strong>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button 
            onClick={ () => onConfirm && onConfirm() } 
            color="primary"
            variant="contained"
          >
            Aceptar
          </Button>

          <Button 
            onClick={ () => onCancel && onCancel() } 
            color="primary" 
            variant="outlined" 
            autoFocus
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AlertConfirm.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  textContent: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}
