import { useParams } from "react-router";
import useStagesState from "../store/stagesState";
import { Box, Button, CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import useGlobalState from "../store/globalState";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import GoBackButton from "../components/general/GoBackButton";
import { capitalizeFirstLetter } from "../core/utils/utils";
import DownloadDocsDialog from "../components/stages/comps/DownloadDocsStageDialog";
import StageGroupCard from "../components/stages/comps/StageGroupCard";
// import StageSingleCounter from "../components/stages/comps/StageSingleCounter";
// import StatusBar from "../components/stages/comps/StatusBar";
const StagePage = () => {
  const { stageId }: any = useParams();
  const companyId = useGlobalState((s) => s.mainCompany?.id);
  const { currentStage, getCompanyStage, setCurrentStage } = useStagesState();

  //const theme = useTheme();
  const classes = useStyles();
  //const [counters, setCounters] = useState<Counter[]>([]);
  const [loading, setLoading] = useState(false);
  const [showDownloadDocsDialog, setShowDownloadDocsDialog] = useState(false);

  useEffect(() => {
    if (companyId && stageId) {
      setLoading(true);
      getCompanyStage(companyId, stageId).finally(() => setLoading(false));
    }

    return () => setCurrentStage(null);
    // eslint-disable-next-line
  }, [stageId]);

  //proceso información expediente
  useEffect(() => {
    if (currentStage) {
      const temp = {
        uploaded: 0,
        underRevision: 0,
        accepted: 0,
      };

      currentStage.groups?.forEach((group) => {
        group.requirements?.forEach((req) => {
          if (req.documents?.length) {
            req.documents.forEach((doc) => {
              temp.uploaded += 1;
              switch (doc.validationStatus.status) {
                case "Bajo revisión":
                  temp.underRevision += 1;
                  break;
                case "Aceptado":
                  temp.accepted += 1;
                  break;
                default:
                  break;
              }
            });
          }
        });
      });

      // setCounters([
      //   {
      //     backgroundColor: theme.palette.primary.main,
      //     color: "white",
      //     count: temp.uploaded,
      //     icon: DescriptionIcon,
      //     text: "Subidos",
      //     total: 20,
      //   },
      //   {
      //     backgroundColor: theme.palette.warning.main,
      //     color: "white",
      //     count: temp.underRevision,
      //     icon: ConversationIcon,
      //     text: "Bajo revisión",
      //     total: 20,
      //   },
      //   {
      //     backgroundColor: theme.palette.success.main,
      //     color: "white",
      //     count: temp.accepted,
      //     icon: CheckIcon,
      //     text: "Aceptados",
      //     total: 20,
      //   },
      // ]);
    }
    //eslint-disable-next-line
  }, [currentStage]);

  if (!currentStage) {
    return (
      <div className={classes.placeholderWrapper}>
        <CircularProgress color="primary" />
      </div>
    );
  }


  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" style={{ gap: "1rem" }}>
        <Grid item>
          <GoBackButton />
        </Grid>
        {/* <StatusBar value={currentStage?.percent} stageCompleted /> */}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" py={4} style={{ gap: "1rem" }}>
        <Typography variant="h3" color="secondary">
          {capitalizeFirstLetter(currentStage?.stageTemplateName)}
        </Typography>
        {/* <StageSingleCounter countersList={counters} /> */}
        <Button
          variant="contained"
          color="secondary"
          style={{ padding: "1rem" }}
          endIcon={<ArrowDownward fontSize="inherit" />}
          onClick={() => setShowDownloadDocsDialog(true)}
        >
          DESCARGAR DOCUMENTOS
        </Button>
      </Box>

      {/* <Grid container className={classes.infoSection} justifyContent="space-between">
        <Grid item xs={4}>
          <Typography variant="h5">Actividad(es) registrada(s) en el REPSE</Typography>
        </Grid>
        <Grid item xs={6}>
          <ul style={{ fontSize: "1rem" }}>{repses?.length && repses.map((act, idx) => <li key={idx}>{act.name}</li>)}</ul>
        </Grid>
        <Box style={{ alignSelf: "center", padding: "1rem 0" }}>
          <Button size="small" variant="contained" disableElevation style={{ color: "white", backgroundColor: theme.palette.warning.main }}>
            Bajo revisión
          </Button> */}
      {/* <Tooltip title="Opciones">
            <IconButton aria-controls="options-menu" aria-label="options" onClick={() => {}} className={classes.actionBtn}>
              <MoreVertIcon fontSize="large" />
            </IconButton>
          </Tooltip> */}
      {/* </Box>
      </Grid> */}

      {loading && (
        <div className={classes.placeholderWrapper}>
          <CircularProgress color="primary" />
        </div>
      )}
      {!loading && !currentStage?.groups?.length && (
        <div className={classes.placeholderWrapper}>
          <Typography variant="subtitle2">No se encontraron datos</Typography>
        </div>
      )}

      {currentStage?.groups?.map((group, idx) => (
        <StageGroupCard key={group.name + idx} group={group} refresh={() => getCompanyStage(companyId, stageId)} />
      ))}

      {showDownloadDocsDialog && (
        <DownloadDocsDialog stage={currentStage} companyId={companyId} onClose={() => setShowDownloadDocsDialog(false)} />
      )}
    </>
  );
};

export default StagePage;

const useStyles = makeStyles((theme) => ({
  infoSection: {
    backgroundColor: "white",
    padding: "1rem",
  },
  actionBtn: {
    padding: "10px 5px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  notification: {
    color: "#00709E",
    fontSize: "0.75rem",
    fontWeight: "bold",
  },
  notificationError: {
    color: theme.palette.error.main,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  placeholderWrapper: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
