import axios from "axios";
import { axiosIpfsRequest } from "../../configs/axios.config";

export class AuthService {
  login = (username, password) => {
    return axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/oauth/token?username=${username}&password=${password}&grant_type=password`,
      {},
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`,
        },
      }
    );
  };

  logout = () => {
    /** */
    return axios.post(
      `${process.env.REACT_APP_BASE_SECURITY_KEYCLOACK}/api/v1/auth/logout`,
      {},
      {
        headers: this._getAuhtHeaders()
      }
    )
  }

  signUp = (user) => {
    return axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/public/activate-user`,
      {
        email: user.email,
        password: user.password,
        completeName: user.completeName,
      },
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`,
        },
      }
    );
  };

  sendEmailPassword = (email) => {
    return axiosIpfsRequest.get(`/api/v1/users/resetPass/${email}`);
  };

  restorePassword = (uuid, password) => {
    return axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/public/restore-password/${uuid}`,
      {
        password: password,
      },
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`,
        },
      }
    );
  };

  refreshToken = async (token) => {
    return await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/oauth/token?grant_type=token&refresh_token=${token}`,
      {},
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`,
        },
      }
    );
  };
  // NEW APIS LOGIN GET
  // https://pagaregeneralqa.axeleratum.com/api/general/auth/login

  async ipfsToken(email, password) {
    try {
      const { data } = await axios.post(
        //   `${process.env.REACT_APP_BASE_SECURITY_KEYCLOACK}/api/v1/auth/login`,
        `${process.env.REACT_APP_API_PAGARE_URL}/api/general/auth/login`,
        {
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async newRefreshToken(token) {
    //REACT_APP_BASE_SECURITY_KEYCLOACK

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_SECURITY_KEYCLOACK}/api/v1/auth/refresh`,
        {
          token: token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async resendEmailInvitedUser(email, nombreEmpresa) {
    //REACT_APP_BASE_SECURITY_KEYCLOACK

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_SECURITY_KEYCLOACK}/api/v1/users/${email}/${nombreEmpresa}/invitation`,
        {},
        {
          headers: this._getAuhtHeaders(),
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async validateResetPassword(token) {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_SECURITY_KEYCLOACK}/api/v1/users/resetPass/validate/${token}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async confirmRestPassword(token, body) {

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_SECURITY_KEYCLOACK}/api/v1/users/resetPass/confirm/${token}`,
        body,
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async confirmResetPassword(body) {
    try {
      return await axiosIpfsRequest.put(`/api/v1/users/acceptAlta`, body).catch(error => {
        return error.response;
      });
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  _getAuhtHeaders() {
    let token = sessionStorage.getItem("access_token");

    if (token) {
      return { Authorization: "Bearer " + token };
    } else {
      return {};
    }
  }

  //   async getUserAuth(token,userId) {

  //     try {
  //       const { data } = await axios.get( `${process.env.REACT_APP_BASE_SECURITY_KEYCLOACK}/api/v1/users/${userId}`,
  //       {
  //         token:token

  //       }, {
  //         headers: {
  //           "Content-Type": "application/json"
  //         }
  //       })
  //       return data;
  //     } catch (error) {
  //       throw error;
  //     }
  //   }
}
