import React, { useEffect, useState, VFC } from "react";
import AppBar from "@material-ui/core/AppBar";
import { Avatar, Hidden, IconButton, makeStyles, SvgIconProps, Tooltip, useMediaQuery, useTheme } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MenuIcon from "@material-ui/icons/Menu";

import AxeleratumIcon from "../icons/AxeleratumIcon";
import MainMenu from "./MainMenu";
import DrawerMenu from "./DrawerMenu";
import QuestionIcon from "../icons/icons-v2/QuestionIcon";
import LogoutIcon from "../icons/icons-v2/LogoutIcon";
import Notifications from "./NotificationsV2";
import { useBlurBackdrop } from "../../core/hooks/useBlurBackdrop";
import KivitIcon from "../icons/KivitIcon";
import useGlobalState from "../../store/globalState";
import HelpMenu from "./HelpMenu";
import useNotificationState from "../../store/notificationState";

let notisTimeOut = null;

interface TopBarProps {
  /** Items shown on the top menu */
  menuItems: {
    /**Item tex */
    text: string;
    /** identifier each item used on map function as key prop. */
    name: string;
    /** Icon shown beside the name. */
    icon: (props: SvgIconProps) => JSX.Element;
    /** Link where the item will be redirect when the user clicks on it. */
    link: string;
    show: boolean;
  }[];
  /** Name of the logged user. Used in avatar showing the first letter of the user name. */
  username: string;
  /** Event executed when the user clicks on 'Cerrar sesión' button. */
  onLogout: () => void;
}

/** Top bar menu shown across the whole project. */
const TopBar: VFC<TopBarProps> = ({ menuItems, username, onLogout }) => {
  const theme = useTheme();
  const classes = useStyles();
  const mainCompany = useGlobalState((s) => s.mainCompany);
  const { getNotifications, getNotigicationsLength } = useNotificationState();
  const { getGeneradoresNotifications, getGeneradoresNotigicationsLength } = useNotificationState();

  const isWide = useMediaQuery(theme.breakpoints.up("sm"));

  const [secondMenu, setSecondMenu] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorNotificatonsEl, setAnchorNotificationsEl] = React.useState<HTMLElement>();
  const [anchorHelpEl, setAnchorHelpEl] = React.useState<HTMLElement>();
  const [handleBlurMenu, handleUnblurMenu] = useBlurBackdrop();
  const hasNewNotifications = useNotificationState((s) => s.unReadNotifications.length > 0);
  const { typeService } = useGlobalState();

console.log("VER GEN",typeService)
  const toggleMenu = () => setSecondMenu(!secondMenu);

  function handleLogout() {
    if (window.confirm("¿Cerrar sesión?")) {
      onLogout();
    }
  }

  const handleClickNotificationIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleBlurMenu();
    setAnchorNotificationsEl(event.currentTarget);
  };

  const handleCloseNotifications = () => {
    handleUnblurMenu();
    setAnchorNotificationsEl(undefined);
  };

  const handleHelp = (e) => {
    setAnchorHelpEl(e.currentTarget);
  };

  const handleHelpDownload = (doc: "corp" | "docsGuide" | "validations") => {
    switch (doc) {
      case "corp":
        window.open("/docs/kivit_manual_corporativo.pdf", "_blank");
        break;
      case "docsGuide":
        window.open("/docs/guia_documentos_kivit.pdf", "_blank");
        break;
      case "validations":
        window.open("/docs/restricciones_validaciones.pdf", "_blank");
        break;
    }
  };

  let previousNotificationsSize = 0;

  const handleGetNotifications = async () => {
    if (notisTimeOut) clearTimeout(notisTimeOut);
  
    const currentNotificationsLength =  await getNotigicationsLength();

    //console.log("currentNotificationsLength",currentNotificationsLength)
    //console.log("previousNotificationsSize",previousNotificationsSize)
    //console.log("notification V2",currentNotificationsLength > previousNotificationsSize)

    if (currentNotificationsLength > previousNotificationsSize) {
      await getNotifications();
      previousNotificationsSize = currentNotificationsLength;
    }
    notisTimeOut = setTimeout(handleGetNotifications, 25000);
  };
  
  const handleGetGeneratorsNotifications = async () => {
    if (notisTimeOut) clearTimeout(notisTimeOut);
  
    const currentNotificationsLength =  await getGeneradoresNotigicationsLength();

    if (currentNotificationsLength > previousNotificationsSize) {
      await getGeneradoresNotifications();
      previousNotificationsSize = currentNotificationsLength;
    }
    notisTimeOut = setTimeout(handleGetGeneratorsNotifications, 25000);
  };

  //console.log("notification V2")
  
  useEffect(() => {
    if(typeService === "generators"){

      handleGetGeneratorsNotifications();
        return () => {
          clearTimeout(notisTimeOut);
        };

    } else {

        handleGetNotifications();
        return () => {
          clearTimeout(notisTimeOut);
        };
    }
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeService]);

  return (
    <AppBar className={classes.appBar} onMouseLeave={() => setSecondMenu(false)}>
      <Hidden smUp>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setOpenDrawer(true)}>
          <MenuIcon />
        </IconButton>
      </Hidden>
      <div className={classes.logoWrapper}>
        {isWide ? <KivitIcon htmlColor="white" fontSize="small" /> : <AxeleratumIcon htmlColor="white" fontSize="large" />}
      </div>
      <Hidden xsDown>{!!mainCompany && <MainMenu show={!secondMenu} menuItems={menuItems} />}</Hidden>

      {/* <SecondaryMenu show={false} onLogout={onLogout} /> */}

      <div style={{ display: "flex", flex: 1, justifyContent: "flex-end", alignItems: "center" }}>
        {/* Button that shows user notifications. Useless for now. */}
        <Tooltip title="Ayuda">
          <IconButton className={classes.iconButtons}>
            <QuestionIcon fontSize="small" onClick={handleHelp} />
          </IconButton>
        </Tooltip>
        {!!mainCompany && (
          <Tooltip title="Notificaciones">
            <div style={{ position: "relative" }}>
              {hasNewNotifications && (
                <div
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 16,
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    backgroundColor: "red",
                    zIndex: 10,
                  }}
                />
              )}
              <IconButton className={classes.iconButtons} onClick={handleClickNotificationIcon}>
                <NotificationsIcon htmlColor="white" fontSize="small" />
              </IconButton>
            </div>
          </Tooltip>
        )}
        {/* User avatar that shows the first letter of the user name. In the future, it will show user image too. */}
        <Tooltip title={username}>
          <Avatar onClick={toggleMenu} className={classes.avatar}>
            {username ? username.toUpperCase().charAt(0) : ""}
          </Avatar>
        </Tooltip>
        <Tooltip title="Cerrar sesión">
          <IconButton className={classes.iconButtons} onClick={handleLogout}>
            <LogoutIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <DrawerMenu menuItems={menuItems} open={openDrawer} onClose={() => setOpenDrawer(false)} />
      <Notifications onClose={handleCloseNotifications} anchorElement={anchorNotificatonsEl} />
      <HelpMenu anchorEl={anchorHelpEl} onClose={() => setAnchorHelpEl(undefined)} handleDownload={handleHelpDownload} />
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    iconButtons: {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
      marginLeft: 8,
      marginRight: 8,
      padding: 10,
    },
    avatar: {
      backgroundColor: "white",
      color: theme.palette.primary.main,
      cursor: "pointer",
      marginLeft: 10,
      marginRight: 12,
      transition: "color 0.3s, background-color 0.3s",
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
      },
    },
    appBar: {
      alignItems: "center",
      borderBottomLeftRadius: 25,
      borderBottomRightRadius: 25,
      boxShadow: "0 0 black",
      flexDirection: "row",
      height: 72,
      padding: "25px 50px 25px 60px",
      //zIndex: 1400
    },
    logoWrapper: {
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        marginRight: theme.spacing(1),
      },
    },
    newUserBtn: {
      minWidth: "240px",
      color: "#fff",
      backgroundColor: theme.palette.orange.main,
      "&:hover": {
        backgroundColor: theme.palette.orange.dark,
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(2),
      },
    },
  };
});

export default TopBar;
