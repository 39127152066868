import { axiosCompanyRequest, authHeader,axiosCompanyRequestV1} from '../../configs/axios.config';

export class BusinessHttp {

  findAllBusiness(success, error) {
    axiosCompanyRequestV1.get('/companies/owner', {headers: authHeader()})
      .then(({ data }) => {

        let companies = data.companies;
        const out = [];
        companies.forEach(business => {
          const usersData = [];
          
          if (business.usersInfo) {
            business.usersInfo.forEach(user => {
              if ((!user.delete) && user.enabled) {
                usersData.push({
                  ...user,
                  label: user.username,
                  value: user.id
                });
              }
            });
          }

          out.push({
            id: business.id,
            name: business.name,
            users: usersData,
            tags: business.tags ? business.tags.map(tag => ({
              label: tag,
              value: tag
            })) : [],
            enabled: business.enabled,
            userKaleido: business.userKaleido,
            passwordKaleido: business.passwordKaleido,
            urlApiKaleido: business.urlApiKaleido,
          });
        });

        success(out);
      }).catch((respErr) => {
        error(respErr);
      });
  }

  findBusinessByUid(uid, success, error) {
    axiosCompanyRequest.get(`/companies/${uid}`, {headers: authHeader()})
      .then(({ data }) => {
        success(data);
      }).catch((respErr) => {
        error(respErr);
      });
  }

  findBusinessById = (id, success, error) => {
    axiosCompanyRequest.get(`/companies/${id}`, {headers: authHeader()})
    .then((response) => {success(response.data)})
    .catch((respErr) => error(respErr))
  }

  saveBusiness({ name, users, tags, userKaleido, passwordKaleido, urlApiKaleido }, success, error) {
    const usersData = [];
    const tagsData = [];

    if (users) {
      users.forEach(({ value }) => {
        usersData.push(value);
      });
    }

    if (tags) {
      tags.forEach(({ label }) => {
        tagsData.push(label);
      })
    }

    if (!urlApiKaleido.toLowerCase().endsWith('/documents') || !urlApiKaleido.toLowerCase().endsWith('/documents/')) {
      urlApiKaleido += '/documents';
    }

    axiosCompanyRequest.post('/companies', {
      name: name.trimStart().trimEnd(),
      users: usersData,
      tags: tagsData,
      userKaleido: userKaleido,
      passwordKaleido: passwordKaleido,
      urlApiKaleido: urlApiKaleido,
    }, {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  async updateBusiness({ id, name, users, tags, userKaleido, passwordKaleido, urlApiKaleido, enabled }, success, error) {
    const usersData = [];

    if (users) {
      users.forEach(user => {
        usersData.push(user.value);
      })

      await axiosCompanyRequest.put(`/companies/assign-users/${id}`, { users: usersData }, 
      { headers: authHeader() });
    } else {
      await axiosCompanyRequest.put(`/companies/assign-users/${id}`, { users: [] }, 
      { headers: authHeader() });
    }

    axiosCompanyRequest.put(`/companies/${id}`, {
      name: name.trimStart().trimEnd(),
      enabled,
      tags,
      userKaleido,
      passwordKaleido,
      urlApiKaleido,
    }, {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  getActiveCompanies(success, error) {
    axiosCompanyRequest.get(`/companies/actives`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  findAllCompaniesUser(success, error) {
    axiosCompanyRequest.get(`/companies/all-companies-by-user`, {headers: authHeader()})
      .then(({ data }) => {
        const out = [];

        data.forEach((item) => {
          out.push({
            value: item.companyId,
            label: item.name
          });
        });

        success(out);
      }).catch(errorResp => { error(errorResp) });
  }

}
