import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useState } from "react";
import { Contract } from "../../../core/models/service.models";
import { Appendix } from "../../../types/ntp.types";
import WatchPdf from "./watch-pdf/WatchPdf";

const ShowAnnexsDialog = ({ onClose, contract }: { onClose: any; contract: Contract }) => {
  const annexs: Appendix[] = contract?.appendixs;
  const theme = useTheme();
  const [currentAnnexToShow, setCurrentAnnex] = useState<Appendix>(null);

  function handleShowAnnex(annex: Appendix) {
    setCurrentAnnex(annex);
  }

  return (
    <>
      <Dialog open={true} maxWidth="sm" fullWidth>
        <DialogTitle
          disableTypography
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Anexos
          </span>
          <IconButton edge="end" onClick={onClose}>
            <CloseIcon style={{ color: "#7A92C5" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ul style={{ display: "flex", flexDirection: "column", gap: "1rem", paddingBottom: "1rem" }}>
            {annexs?.length ? (
              annexs?.map((annex) => (
                <li
                  key={annex.uid}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: "1rem",
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: "8px",
                    boxShadow: theme.shadows[2],
                  }}
                >
                  <Typography variant="subtitle1">{annex.name}</Typography>
                  <Button variant="contained" color="primary" onClick={() => handleShowAnnex(annex)}>
                    Ver
                  </Button>
                </li>
              ))
            ) : (
              <Typography variant="subtitle1">No hay anexos</Typography>
            )}
          </ul>
        </DialogContent>
      </Dialog>
      {Boolean(currentAnnexToShow) && <ShowAnnexDocDialog onClose={() => setCurrentAnnex(null)} annex={currentAnnexToShow} />}
    </>
  );
};

export default ShowAnnexsDialog;

const ShowAnnexDocDialog = ({ onClose, annex }: { onClose: any; annex: Appendix }) => {
  return (
    <Dialog fullScreen open={true} onClose={onClose}>
      <WatchPdf fileId={annex?.fileId} name={annex?.name} onReturn={onClose} />
    </Dialog>
  );
};
