import { axiosGeneradoresRequest, authHeader } from "../../configs/axios.config";

export class StagesGeneratorService {
  private static instance: StagesGeneratorService;

  private constructor() {}

  public static getInstance(): StagesGeneratorService {
    if (!StagesGeneratorService.instance) {
        StagesGeneratorService.instance = new StagesGeneratorService();
    }
    return StagesGeneratorService.instance;
  }

  //GET ​/api​/v1​/stage​/companyId​/{companyId}
  async getCompanyStages(companyId: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get(`/stage/companyId/${companyId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //GET /api/v1/stage/companyId/{companyId}/stageTemplateId/{stageTemplateId}
  async getCompanyStage(companyId: string, stageTemplateId: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get(`/stage/companyId/${companyId}/stageTemplateId/${stageTemplateId}`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //POST /api/v1/stage/companyId/{companyId}/stageTemplateId/{stageTemplateId}/download
  async downloadCompanyStagesDocuments(companyId: string, stageTemplateId: string, reqIds?: string[]) {
    try {
      const { data } = await axiosGeneradoresRequest.post(`/stage/companyId/${companyId}/stageTemplateId/${stageTemplateId}/download`, reqIds, {
        headers: authHeader(),
        responseType: "arraybuffer",
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //POST ​/api​/v1​/stage​/companyId​/{companyId}​/stageName​/{stageName}​/serviceId​/{serviceId}​/download
  async downloadServiceStagesDocuments(companyId: string, stageName: string, serviceId: string, reqIds?: string[]) {
    try {
      const { data } = await axiosGeneradoresRequest.post(`/stage/companyId/${companyId}/stageName/${stageName}/serviceId/${serviceId}/download`, reqIds, {
        headers: authHeader(),
        responseType: "arraybuffer",
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //GET ​/api​/v1​/stage​/companyId​/{companyId}​/stageTemplateId​/{stageTemplateId}​/progress
  async getCompanyStageProgress(companyId: string, stageTemplateId: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get(`/stage/companyId/${companyId}/stageTemplateId/${stageTemplateId}/progress`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //POST ​/api​/v1​/stage​/companyId​/{companyId}​/stageTemplateId​/{stageTemplateId}​/requirements​/{requirementUuid}
  async uploadStageRequirementDocument(companyId: string, stageTemplateId: string, requirementUuid: string, body: any) {
    try {
      const { data } = await axiosGeneradoresRequest.post(`/stage/companyId/${companyId}/stageTemplateId/${stageTemplateId}/requirements/${requirementUuid}`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //POST ​/api​/v1​/services​/{serviceId}​/requirements​/{requirementUid}
  async uploadServiceRequirementDocument(serviceId: string, requirementUid: string, body: any) {
    try {
      const { data } = await axiosGeneradoresRequest.post(`/services/${serviceId}/requirements/${requirementUid}`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async uploadGeneratorServiceRequirementDocument(serviceId: string, requirementUid: string, body: any) {
    try {
      const { data } = await axiosGeneradoresRequest.post(`/services/${serviceId}/requirements/${requirementUid}`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
  

  //PUT ​/api​/v1​/stage​/companyId​/{companyId}​/stageTemplateId​/{stageTemplateId}​/requirements​/{requirementUuid}
  async validateStageRequirement(companyId: string, stageTemplateId: string, requirementUuid: string, body: any) {
    try {
      const { data } = await axiosGeneradoresRequest.put(`/stage/companyId/${companyId}/stageTemplateId/${stageTemplateId}/requirements/${requirementUuid}`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateServiceRequirementDocStatus(serviceId: string, requirementUid: string, body: any) {
    try {
      const { data } = await axiosGeneradoresRequest.put<any>(
        `/services/${serviceId}/requirements/${requirementUid}`,
        body,
        {
          headers: { ...authHeader(), 'Content-Type': 'application/json' },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  //DELETE ​/api​/v1​/stage​/companyId​/{companyId}​/stageTemplateId​/{stageTemplateId}​/requirements​/{requirementUuid}​/documents​/{documentUuid}
  async deleteStageRequirementDocument(companyId: string, stageTemplateId: string, requirementUuid: string, documentUuid: string) {
    try {
      const { data } = await axiosGeneradoresRequest.delete(
        `/stage/companyId/${companyId}/stageTemplateId/${stageTemplateId}/requirements/${requirementUuid}/documents/${documentUuid}`,
        { headers: authHeader() }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  //DELETE ​/api​/v1​/services​/{serviceId}​/requirements​/{requirementUid}
  async deleteServiceRequirementDocument(serviceId: string, requirementUid: string, document: any) {
    //todo: quitar document x documentId
    try {
      const res = await fetch(`${process.env.REACT_APP_API_GENERADORES_URL}/api/v1/services/${serviceId}/requirements/${requirementUid}`, {
        method: "DELETE",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(document),
      }).then((res) => res.json());
      return res;
    } catch (error) {
      throw error;
    }
  }

  //GET ​/api​/v1​/stage​/create​/companyId​/{companyId}​/stageTemplateId​/{stageTemplateId}
  async createCompanyStage(companyId: string, stageTemplateId: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get(`/stage/create/companyId/${companyId}/stageTemplateId/${stageTemplateId}`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getServiceStages(serviceId: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get(`/services/${serviceId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getGeneratorServiceStages(serviceId: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get(`/services/${serviceId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPeriodCfdis(serviceId: string, periodUid: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get(`/services/${serviceId}/periods/${periodUid}/cfdis`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
}
