import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import { CompaniesService } from "../core/services/companies.service";
import useDashboardState from "./dashboardState";

interface UserDialogArgs {
  isNewUser?: boolean;
  isSubmited?: boolean;
  formType: UserFormType;
  user?: any;
}

interface State {
  userToUpdate: any;
  isNewUser: boolean;
  isSubmited: boolean;
  providers: CompanyCollaborator[];
  clients: CompanyCollaborator[];
  serviceTypes: any;
  isLoadingCollaborator: boolean;
  activatedGenerator: boolean;
  showRepseService: boolean;
  showGeneratorService: boolean;
  deleteProviderOrClient: (type: "provider" | "client" | null, companyToDeleteId: string) => Promise<any>;
  setServiceTypes: (arg: any) => any;
  setUserToUpdate: (arg: any) => any;
  handleOpenUserDialog: (args: UserDialogArgs) => void;
  handleCloseUserDialog: () => void;
  setClients: (clients: CompanyCollaborator[]) => any;
  setProviders: (clients: CompanyCollaborator[]) => any;
  setIsLoadingCollaborator: (isLoading: boolean) => any;
  setActivatedGenerator: ( flag: boolean ) => void;
  reset: () => any;

}

const useDashboardUsersState = create<State>((set, get) => ({
  userToUpdate: null,
  isNewUser: false,
  isSubmited: false,
  providers: [],
  clients: [],
  serviceTypes: [],
  isLoadingCollaborator: false,
  activatedGenerator: false,
  showRepseService: false,
  showGeneratorService: false,
  deleteProviderOrClient: deleteProviderOrClient(set, get),
  setServiceTypes: (arg: any[]) => set({ serviceTypes: arg }),
  setUserToUpdate: (arg: any) => set({ userToUpdate: arg }),
  handleOpenUserDialog: handleOpenUserDialog(set, get),
  handleCloseUserDialog: handleCloseUserDialog(set, get),
  setClients: setClients(set, get),
  setProviders: setProviders(set, get),
  setIsLoadingCollaborator: (isLoading: boolean) => set({ isLoadingCollaborator: isLoading }),
  setActivatedGenerator: ( flag: boolean ) => set({ activatedGenerator: flag }),
  reset: () => {
    set({
      userToUpdate: null,
      isNewUser: false,
      isSubmited: false,
      providers: [],
      clients: [],
      serviceTypes: [],
      isLoadingCollaborator: false,
      activatedGenerator: false,
      showRepseService: false,
      showGeneratorService: false,
    });
  }
}));

export default useDashboardUsersState;

//FUNCTIONS
function setClients(set: NamedSet<State>, get: GetState<State>) {
  return (clients) => {
    set({ clients });
  };
}

function setProviders(set: NamedSet<State>, get: GetState<State>) {
  return (providers) => {
    set({ providers });
  };
}

function handleOpenUserDialog(set: NamedSet<State>, get: GetState<State>) {
  return ({ isSubmited, formType, isNewUser, user }: UserDialogArgs) => {
    set({ isSubmited, isNewUser, userToUpdate: user });
    useDashboardState.getState().showDialogForm({ formType });
  };
}

function handleCloseUserDialog(set: NamedSet<State>, get: GetState<State>) {
  return () => {
    set({ isNewUser: false, userToUpdate: undefined });
    useDashboardState.getState().closeForm();
  };
}

function deleteProviderOrClient(set: NamedSet<State>, get: GetState<State>) {
  return async (type: "provider" | "client" | null, companyToDeleteId: string) => {
    const mainCompanyId = sessionStorage.getItem("companyId");
    if (!type || !mainCompanyId) return;

    let data;
    if (type === "provider") {
      data = await CompaniesService.getInstance().removeCompanyAsProvider(mainCompanyId, companyToDeleteId);
    } else if (type === "client") {
      data = await CompaniesService.getInstance().removeCompanyAsClient(mainCompanyId, companyToDeleteId);
    }
    return data;
  };
}