import React, { useState } from 'react';
import { Box, Chip, makeStyles, MenuItem, TextField } from '@material-ui/core';
import CancelIcon from "@material-ui/icons/Cancel";
import { useReduxFormStyles } from './useReduxFormStyles';

interface Props {
  input: {
    value: string[],
    onChange: (value: unknown) => void,
    name: string,
  };
  options: {
    value: string,
    label: string
  }[];
  label: string;
  externLabel?: boolean;
  required?: boolean;
}

const RenderMultipleSelect = ({ options, input, label, externLabel, required }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const formClasses = useReduxFormStyles();

  const handleDelete = (e: React.MouseEvent, chipValue: string) => {
    const newValue = input.value.filter((option: string) => option !== chipValue);
    input.onChange(newValue);
    //onChange(newValue);
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    input.onChange(e.target.value);
    setOpen(false);
  }

  const Option = (selected: unknown) => (
    <div className={classes.chips}>
      {(selected as string[]).map((optionValue) => {
        const label = options.find((option) => option.value === optionValue)?.label ?? '';
        return (
          <Chip
            className={classes.chip}
            color="primary"
            deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
            key={optionValue}
            label={label}
            onDelete={(e) => handleDelete(e, optionValue)}
            size="small"
          />
        );
      })}
    </div>
  );

  const RootComponent = externLabel ? Box : React.Fragment;
  const rootComponentProps = externLabel ? {display: 'inline-flex', width:'100%', flexDirection:'column' } : null;

  return (
    <RootComponent {...rootComponentProps}>
      {!!label && externLabel && <label className={formClasses.fieldLabel}>{label}</label>}
      <TextField
        name={input.name}
        id="outlined-select-currency"
        size="small"
        margin="dense"
        select
        fullWidth
        SelectProps={{
          multiple: true,
          renderValue: Option,
          open,
          onOpen: () => setOpen(true),
          onClose: () => setOpen(false)
        }}
        label={!!label && !externLabel ? label : undefined}
        placeholder={label}
        required={required}
        value={input.value}
        onChange={handleChange}
        variant="outlined"
      >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
    </RootComponent>
  )
}

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

export default RenderMultipleSelect;
