/* eslint-disable no-useless-escape */
import React from "react";

import {  Grid, IconButton, Typography, makeStyles } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { FormEntityType } from "../../../../types/global";

type Props = {
  contact: DashboardUser;
  onCancel: (args?: any) => any;
  formType?: FormEntityType;
  onSubmit?: (args?: any) => any;
};

function UserShowContact({ contact, onCancel, formType, onSubmit }: Props) {
  const classes = useStyles();

  console.log(contact);
  

  return (
    <div className={classes.main}>
      <Grid container>
        <Grid item md={12}>
          <Grid container xs={12} justifyContent="space-between" alignItems="center" className="mb-4">
            <div style={{ height: "48px", width: "48px" }}></div>
            <div style={{ height: "48px", width: "48px" }}></div>
            <IconButton aria-label="close" onClick={() => onCancel()}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Grid>

          <Typography className={classes.title}>{contact.user.name}</Typography>

          <Typography className={classes.subtitle}>
              {contact?.user?.email || "Email no registrado"}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    textAlign: "center",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
    textTransform:'capitalize'
  },
  subtitle: {
    width: "60%",
    margin: "3rem auto",
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
}));

export default UserShowContact;
