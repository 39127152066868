import React from "react";
import { Box, Button } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ColoredButton from "../../general/ColoredButton";
import { capitalizeFirstLetter } from "../../../core/utils/utils";

interface StageNavigationProps {
  stages: {
    label: string;
    disabled?: boolean;
  }[];
  value: number;
  onChange?: (newValue: number) => void;
}

const COLORS = [
  "#239ED0",
  "#44B5AE",
  "#3A69A7",
  "#239ED0",
  "#44B5AE",
  "#3A69A7",
  "#239ED0",
  "#44B5AE",
  "#3A69A7",
  "#239ED0",
  "#44B5AE",
  "#3A69A7",
  "#239ED0",
  "#44B5AE",
  "#3A69A7",
];

const StageNavigation = ({ stages = [], value, onChange }: StageNavigationProps) => {
  return (
    <Box display="flex" gridGap={20} margin="20px 0">
      <Button
        disabled={value === 0 || stages[value - 1].disabled}
        aria-label="left"
        size="large"
        variant="contained"
        color="primary"
        onClick={() => onChange?.(value - 1)}
      >
        <ChevronLeftIcon fontSize="large" />
      </Button>
      {stages?.map((stage, i) => (
        <ColoredButton
          key={i}
          fullWidth
          variant={value === i ? "contained" : "outlined"}
          size="large"
          color={COLORS[i]}
          onClick={() => onChange?.(i)}
          disabled={stage.disabled}
        >
          {capitalizeFirstLetter(stage.label)}
        </ColoredButton>
      ))}
      <Button
        disabled={value === stages?.length - 1 || stages[value + 1]?.disabled}
        aria-label="right"
        size="large"
        variant="contained"
        color="primary"
        onClick={() => onChange?.(value + 1)}
      >
        <ChevronRightIcon fontSize="large" />
      </Button>
    </Box>
  );
};

export default StageNavigation;
