import React, { useEffect, useState } from 'react';
import { ListItem, ListItemText, ListItemSecondaryAction, Divider } from '@material-ui/core';
import { SwitchField } from './switch-field';

const PermissionItem = props => {
  const {classes, permission, permissionsSelected, key, onClickSwitch} = props;
  const [checkedPermission, setCheckedPermission] = useState(false)

  useEffect(() => {
    const checkedPermission = permissionsSelected 
      ? (permissionsSelected.find(permissionData => permissionData.id === permission.id) !== undefined)
      : false    
    setCheckedPermission(checkedPermission)
  }, [permissionsSelected, permission.id])
            
  return(
    <React.Fragment key={key}>
      <ListItem  disabled alignItems="flex-end">
        <ListItemText
          primary={permission.name} 
          secondary={permission.description} 
          classes={{root: classes.listItemText}}
        />
        <ListItemSecondaryAction>
          <SwitchField
            id={`${permission.id}`}
            checked={ checkedPermission }
            name={`${permission.id}`}
            onClick={ (value) => {onClickSwitch({id: permission.id, value})}}
            end='end'
          />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider classes={{root: classes.divider}} component="li" />
    </React.Fragment>
  )
}

export default PermissionItem