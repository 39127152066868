//@ts-nocheck
/* eslint-disable no-use-before-define */
import { useEffect } from "react";
import { useHistory } from "react-router";
import openPayConfig from "../../configs/openpay";
import { loadOpenPayScripts } from "../../core/helpers/openpay.helpers";
import useCustomToast from "../../core/hooks/useCustomToast";
import "./payForm.css";

let deviceSessionId;

const PaymentForm = ({ orderId, setIsLoading }) => {
  const history = useHistory();
  const toast = useCustomToast()

  async function init() {
    try {
      await loadOpenPayScripts();
      OpenPay.setId(openPayConfig.id);
      OpenPay.setApiKey(openPayConfig.apyKey);
      OpenPay.setSandboxMode(true);
      deviceSessionId = OpenPay.deviceData.setup("payment-form", "deviceIdHiddenFieldName");
    } catch (error) {
      toast.error("Error al inicializar OpenPay");
    }
  }

  function handlePayClick() {
    setIsLoading(true);
    OpenPay.token.extractFormAndCreate("payment-form", onSuccess, onError);
  }

  function onSuccess(response) {
    var token_id = response?.data?.id;
    fetch("https://payqa.axeleratum.com/api/v1/subscriptions/pay", {
      method: "POST",
      body: JSON.stringify({
        token_id: token_id,
        instanceProcessId: orderId,
        deviceIdHiddenFieldName: deviceSessionId,
      }),
      headers: {
        Authorization: sessionStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "OK") {
          toast.success("Pago exitoso");
          return history.replace("/");
        } else {
          toast.error("Error al procesar el pago");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error al procesar el pago");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function onError(response) {
    console.log("error response", response);
    setIsLoading(false);
    let desc = response.data.description !== undefined ? response.data.description : response.message;
    toast.error("ERROR [" + response.status + "] " + desc);
  }

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="openpayContainer">
      <div className="bkng-tb-cntnt">
        <div className="pymnts">
          <form id="payment-form">
            <input type="hidden" name="token_id" id="token_id" />
            <div className="pymnt-itm card active">
              <h2>Tarjeta de crédito o débito</h2>
              <div className="pymnt-cntnt">
                <div className="card-expl">
                  <div className="credit">
                    <h4>Tarjetas de crédito</h4>
                  </div>
                  <div className="debit">
                    <h4>Tarjetas de débito</h4>
                  </div>
                </div>
                <div className="sctn-row">
                  <div className="sctn-col l">
                    <label>Nombre del titular</label>
                    <input type="text" placeholder="Como aparece en la tarjeta" autoComplete="off" data-openpay-card="holder_name" />
                  </div>
                  <div className="sctn-col">
                    <label>Número de tarjeta</label>
                    <input type="text" autoComplete="off" data-openpay-card="card_number" />
                  </div>
                </div>
                <div className="sctn-row">
                  <div className="sctn-col l">
                    <label>Fecha de expiración</label>
                    <div className="sctn-col half l">
                      <input type="text" placeholder="Mes" data-openpay-card="expiration_month" />
                    </div>
                    <div className="sctn-col half l">
                      <input type="text" placeholder="Año" data-openpay-card="expiration_year" />
                    </div>
                  </div>
                  <div className="sctn-col cvv">
                    <label>Código de seguridad</label>
                    <div className="sctn-col half l">
                      <input type="text" placeholder="3 dígitos" autoComplete="off" data-openpay-card="cvv2" />
                    </div>
                  </div>
                </div>
                <div className="openpay">
                  <div className="logo">Transacciones realizadas vía:</div>
                  <div className="shield">Tus pagos se realizan de forma segura con encriptación de 256 bits</div>
                </div>
                <div className="sctn-row">
                    {/* eslint-disable-next-line  */}
                  <a className="button rght" onClick={handlePayClick}>
                    Pagar
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
