import { Button, Grid, IconButton, Typography, makeStyles, Dialog } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';

type Props = {
  title: string;
  text: string;
  severity?: "success" | "error" | "info" | "warning";
  onCancel: (args?: any) => any;
  onSubmit?: (args?: any) => any;
};

function AlertContinue({ title, text,severity, onCancel, onSubmit }: Props) {
  const classes = useStyles();

  const Icon = () => {
    switch (severity) {
        case "success":
            return <CheckCircleIcon fontSize="inherit" className={classes.checkIcon} />;
        case "error":
            return <ErrorIcon fontSize="inherit" className={classes.errorIcon} />;
            case "info":
            return <InfoIcon fontSize="inherit" className={classes.infoIcon} />;
            case "warning":
            return <InfoIcon fontSize="inherit" className={classes.infoIcon} />;
        default:
            return <InfoIcon fontSize="inherit" className={classes.infoIcon} />;
    }
};

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
    <div className={classes.main}>
      <Grid container>
        <Grid item md={12}>
          <Grid container xs={12} justifyContent="space-between" alignItems="center" className="mb-2">
            <div style={{ height: "48px", width: "48px" }}></div>
            <div style={{fontSize:"3rem"}}><Icon /></div>
            <IconButton aria-label="close" onClick={() => onCancel()}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Grid>

          <Typography className={classes.title}>{title}</Typography>

          <Typography className={classes.subtitle}>{text}</Typography>

          <Button className="mt-2" variant="contained" color="primary" onClick={onSubmit}>
            SIGUIENTE
          </Button>
        </Grid>
      </Grid>
    </div>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    textAlign: "center",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: 700,
    color: theme.palette.black.main,
  },
  subtitle: {
    width: "80%",
    margin: "2rem auto",
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
    errorIcon: {
    color: theme.palette.error.main,
    },
    infoIcon: {
    color: theme.palette.primary.main,
    },
    warningIcon: {
    color: theme.palette.warning.main,
    },
}));

export default AlertContinue;
