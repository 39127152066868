import { SelectOption } from "../../../types/global";

export const servicesOptions = [
  { value: "Agricultura" },
  { value: "Cría y explotación de animales" },
  { value: "Aprovechamiento forestal" },
  {
    value: "Servicios relacionados con las actividades agropecuarias y forestales",
  },
  { value: "Pesca, caza y captura" },
  { value: "Extracción de petróleo y gas" },
  {
    value: "Minería de minerales metálicos y no metálicos, excepto petróleo y gas",
  },
  { value: "Servicios relacionados con la minería" },
  {
    value:
      "Generación, transmisión, distribución y comercialización de energía eléctrica, suministro de agua y de gas natural por ductos al consumidor final",
  },
  { value: "Edificación" },
  { value: "Construcción de obras de ingeniería civil" },
  { value: "Trabajos especializados para la construcción" },
  { value: "Industria alimentaria" },
  { value: "Industria de las bebidas y del tabaco" },
  { value: "Fabricación de insumos textiles y acabado de textiles" },
  { value: "Fabricación de productos textiles, excepto prendas de vestir" },
  { value: "Fabricación de prendas de vestir" },
  {
    value: "Curtido y acabado de cuero y piel, y fabricación de productos de cuero, piel y materiales sucedáneos",
  },
  { value: "Industria de la madera" },
  { value: "Industria del papel" },
  { value: "Impresión e industrias conexas" },
  { value: "Fabricación de productos derivados del petróleo y del carbón" },
  { value: "Industria química" },
  { value: "Industria del plástico y del hule" },
  { value: "Fabricación de productos a base de minerales no metálicos" },
  { value: "Industrias metálicas básicas" },
  { value: "Fabricación de productos metálicos" },
  { value: "Fabricación de maquinaria y equipo" },
  {
    value: "Fabricación de equipo de computación, comunicación, medición y de otros equipos, componentes y accesorios electrónicos",
  },
  {
    value: "Fabricación de accesorios, aparatos eléctricos y equipo de generación de energía eléctrica",
  },
  { value: "Fabricación de equipo de transporte" },
  { value: "Fabricación de muebles, colchones y persianas" },
  { value: "Otras industrias manufactureras" },
  {
    value: "Comercio al por mayor de abarrotes, alimentos, bebidas, hielo y tabaco",
  },
  { value: "Comercio al por mayor de productos textiles y calzado" },
  {
    value:
      "Comercio al por mayor de productos farmacéuticos, de perfumería, artículos para el esparcimiento, electrodomésticos menores y aparatos de línea blanca",
  },
  {
    value: "Comercio al por mayor de materias primas agropecuarias y forestales, para la industria, y materiales de desecho",
  },
  {
    value:
      "Comercio al por mayor de maquinaria, equipo y mobiliario para actividades agropecuarias, industriales, de servicios y comerciales, y de otra maquinaria y equipo de uso general",
  },
  {
    value: "Comercio al por mayor de camiones y de partes y refacciones nuevas para automóviles, camionetas y camiones",
  },
  { value: "Intermediación de comercio al por mayor" },
  {
    value: "Comercio al por menor de abarrotes, alimentos, bebidas, hielo y tabaco",
  },
  {
    value: "Comercio al por menor de artículos de ferretería, tlapalería y vidrios",
  },
  {
    value: "Comercio al por menor de artículos de papelería, para el esparcimiento y otros artículos de uso personal",
  },
  {
    value: "Comercio al por menor de artículos para el cuidado de la salud",
  },
  {
    value: "Comercio al por menor de enseres domésticos, computadoras, artículos para la decoración de interiores y artículos usados",
  },
  {
    value: "Comercio al por menor de productos textiles, bisutería, accesorios de vestir y calzado",
  },
  {
    value: "Comercio al por menor de vehículos de motor, refacciones, combustibles y lubricantes",
  },
  {
    value: "Comercio al por menor en tiendas de autoservicio y departamentales",
  },
  {
    value: "Comercio al por menor exclusivamente a través de internet, y catálogos impresos, televisión y similares",
  },
  { value: "Transporte aéreo" },
  { value: "Transporte por ferrocarril" },
  { value: "Transporte por agua" },
  { value: "Autotransporte de carga" },
  { value: "Transporte terrestre de pasajeros, excepto por ferrocarril" },
  { value: "Transporte por ductos" },
  { value: "Transporte turísticas" },
  { value: "Servicios relacionados con el transporte" },
  { value: "Servicios postales" },
  { value: "Servicios de mensajería y paquetería" },
  { value: "Servicios de almacenamiento" },
  {
    value: "Edición de periódicos, revistas, libros, software y otros materiales, y edición de estas publicaciones integrada con la impresión",
  },
  { value: "Industria fílmica y del video, e industria del sonido" },
  { value: "Radio y televisión" },
  { value: "Telecomunicaciones" },
  {
    value: "Procesamiento electrónico de información, hospedaje y otros servicios relacionados",
  },
  { value: "Otros servicios de información" },
  { value: "Banca central" },
  { value: "Actividades bursátiles, cambiarias y de inversión financiera" },
  {
    value: "Compañías de seguros, fianzas, y administración de fondos para el retiro",
  },
  {
    value: "Instituciones de intermediación crediticia y financiera no bursátil",
  },
  {
    value: "Sociedades de inversión especializadas en fondos para el retiro y fondos de inversión",
  },
  { value: "Servicios inmobiliarios" },
  { value: "Servicios de alquiler de bienes muebles" },
  {
    value: "Servicios de alquiler de marcas registradas, patentes y franquicias",
  },
  { value: "Servicios profesionales, científicos y técnicos" },
  { value: "Corporativos" },
  { value: "Servicios de apoyo a los negocios" },
  { value: "Limpieza" },
  { value: "Servicios de mantenimiento" },
  { value: "Protección y Seguridad" },
  { value: "Manejo de residuos y servicios de remediación" },
  { value: "Servicios educativos" },
  {
    value: "Servicios médicos de consulta externa y servicios relacionados",
  },
  { value: "Hospitales pertenecientes al sector privado" },
  {
    value: "Residencias de asistencia social y para el cuidado de la salud",
  },
  { value: "Otros servicios de asistencia social" },
  {
    value: "Servicios artísticos, culturales y deportivos, y otros servicios relacionados",
  },
  { value: "Museos, sitios históricos, zoológicos y similares" },
  {
    value: "Servicios de entretenimiento en instalaciones recreativas y otros servicios recreativos",
  },
  { value: "Servicios de alojamiento temporal" },
  { value: "Servicios de preparación de alimentos y bebidas" },
  { value: "Servicios de reparación y mantenimiento" },
  { value: "Servicios personales" },
  { value: "Asociaciones y organizaciones" },
  { value: "Hogares con empleados domésticos" },
  { value: "Otro" },
];

export const companyActivities: SelectOption[] = [
  { value: "Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza" },
  { value: "Minería" },
  { value: "Generación, transmisión y distribución de energía eléctrica, suministro de agua y de gas por ductos al consumidor final" },
  { value: "Construcción" },
  { value: "Industrias manufactureras" },
  { value: "Comercio al por mayor" },
  { value: "Comercio al por menor" },
  { value: "Transportes, correos y almacenamiento" },
  { value: "Información en medios masivos" },
  { value: "Servicios financieros y de seguros" },
  { value: "Servicios inmobiliarios y de alquiler de bienes muebles e intangibles y Corporativos" },
  { value: "Servicios profesionales, científicos y técnicos" },
  { value: "Corporativos" },
  { value: "Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación" },
  { value: "Servicios educativos" },
  { value: "Servicios de salud y de asistencia social" },
  { value: "Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos" },
  { value: "Servicios de alojamiento temporal y de preparación de alimentos y bebidas" },
  { value: "Otros servicios excepto actividades gubernamentales" },
  { value: "Otro" },
];
