const CheckIconSm = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00137 0C3.1407 0 0 3.1407 0 7.00137C0 10.862 3.1407 14.0027 7.00137 14.0027C10.862 14.0027 14.0027 10.862 14.0027 7.00137C14.0027 3.1407 10.862 0 7.00137 0ZM10.5499 5.51766L6.75749 9.31007C6.64371 9.42384 6.49435 9.48102 6.34499 9.48102C6.19563 9.48102 6.04626 9.42384 5.93249 9.31007L4.03629 7.41386C3.80816 7.18574 3.80816 6.817 4.03629 6.58887C4.26442 6.36074 4.63315 6.36074 4.86128 6.58887L6.34499 8.07258L9.7249 4.69267C9.95303 4.46454 10.3218 4.46454 10.5499 4.69267C10.778 4.92079 10.778 5.28953 10.5499 5.51766Z"
        fill="#95D97D"
      />
    </svg>
  );
};

export default CheckIconSm;
