import { Grid } from "@material-ui/core";
import { FormikErrors, FormikTouched } from "formik";
import ProfileInput from "./ProfileInput";

type LegalRepresentativeFormProps = {
  rootName: string;
  values: LegalRepresentative;
  errors: FormikErrors<LegalRepresentative>;
  touchedFields: FormikTouched<LegalRepresentative>;
  handleChangeField: (e: React.ChangeEvent<any>) => void;
  isEditing?: boolean;
};

const LegalRepresentativeForm = ({ rootName, isEditing, values, errors, touchedFields, handleChangeField }: LegalRepresentativeFormProps) => {
  return (
    <Grid container alignItems="center" spacing={1}>
      <ProfileInput
        label="Nombre(s)"
        id={`${rootName}.name`}
        error={errors?.name}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields?.name}
        value={values.name}
      />

      <ProfileInput
        label="Apellido Paterno"
        id={`${rootName}.pname`}
        error={errors?.pname}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields?.pname}
        value={values.pname}
      />

      <ProfileInput
        label="Apellido Materno"
        id={`${rootName}.mname`}
        error={errors?.mname}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields?.mname}
        value={values.mname}
      />

      <ProfileInput
        label="CURP"
        id={`${rootName}.curp`}
        error={errors?.curp}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields?.curp}
        value={values.curp}
      />
    </Grid>
  );
};

export default LegalRepresentativeForm;
