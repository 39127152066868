import { Redirect, Route } from "react-router";
import VaultWrapper from "../../components/vault/VaultWrapper";
import DashboardHomeRouting from "./dashboard-home.routes";
import ManagementRouting from "./management.routes";
import ReportesPage from "../../pages/ReportesPage";
import DashboardGroupHomePage from "../../pages/DashboardGroupHomePage";
import useGlobalState from "../../store/globalState";
import RiesgoStatsPage from "../../pages/RiesgoStatsPage";
import Report69StatsPage from "../../pages/Report69StatsPage";
//import PageNotFound from "../../components/onboarding/ValidatePagePath";

const DashboardRouting = (props) => {
  const { match } = props;
  const mainCompany = useGlobalState((s) => s.mainCompany);
  return (
    <>
      <Route exact path={`${match.path}/`}>
        {mainCompany?.group ? <Redirect to={`${match.path}/group-home`} /> : <Redirect to={`${match.path}/home`} />}
      </Route>
      <Route path={`${match.path}/group-home`}>
        <DashboardGroupHomePage />
      </Route>
     {/** <Route path={`${match.path}/group-home/*`} >
        <PageNotFound />
      </Route>*/} 
      <Route path={`${match.path}/home`}>
        <DashboardHomeRouting />
      </Route>
      {/**<Route path={`${match.path}/home/*`} >
        <PageNotFound />
      </Route> */}
      
      {/* <Route path={`${match.path}/ntp/:id/`}>
        <OutsourcingWrapper />
      </Route> */}
      <Route path={`${match.path}/vault`}>
        <VaultWrapper />
      </Route>
      <Route path={`${match.path}/management`}>
        <ManagementRouting />
      </Route>
      <Route path={`${match.path}/reportes/:clientId`}>
        <ReportesPage />
      </Route>
      <Route path={`${match.path}/riesgos`}>
        <RiesgoStatsPage />
      </Route>
      <Route path={`${match.path}/sat69b`}>
        <Report69StatsPage />
      </Route>
     
    </>
  );
};

export default DashboardRouting;
