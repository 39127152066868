import axios from "axios";

export const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/rest`,
});

export const axiosRequestOption = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL_COMPANY}/rest`,
});

export const axiosRequestFiles = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
});

export const axiosIpfsRequest = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_SECURITY_KEYCLOACK}`,
});


export function authHeaderOld() {
  // return authorization header with jwt token
  let token = sessionStorage.getItem("access_token");

  if (token) {
    return { Authorization: "Bearer " + token };
  } else {
    return {};
  }
}

export function authHeader() {
  // return authorization header with jwt token
  let token = sessionStorage.getItem("access_token");

  let headers = {};

  if (token) {
    headers.Authorization = "Bearer " + token;
  }

  // Agregar encabezados CORS
  headers['Access-Control-Allow-Origin'] = '*';
  //headers['Access-Control-Allow-Methods'] = 'GET, OPTIONS';
  headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE, OPTIONS';  // Lista completa de métodos HTTP permitidos
  headers['Access-Control-Allow-Headers'] = 'Content-Type, Authorization';  // Lista de encabezados permitidos

  return headers;
}


export function authIpfsHeader() {
  let token = sessionStorage.access_token_ipfs;

  if (token) {
    return { Authorization: "Bearer " + token };
  } else {
    return {};
  }
}

export function jsonTypeHeader() {
  return {
    "Content-Type": "application/json",
  };
}

export function tempAuthHeader() {
  // return authorization header with jwt token
  let token = sessionStorage.access_token_ipfs;

  if (token) {
    return { Authorization: "Bearer " + token };
  } else {
    return {};
  }
}

export const axiosBusinessRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/rest`,
  headers: authHeader(),
});

export const axiosCalendarRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_CALENDAR_URL}/rest`,
  headers: authHeader(),
});

export const axiosCompanyRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL_COMPANY}/rest`,
  headers: authHeader(),
});

export const axiosProviderRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL_PROVIDER}/rest`,
  headers: authHeader(),
});

export const axiosRequestBlockChain = axios.create({
  baseURL: `${process.env.REACT_APP_API_BLOCKCHAIN_URL}/api`,
});

export const axiosSignatureRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_SIGNATURE_URL}/api`,
  headers: authHeader(),
});

export const axiosRequestIpfsBlockchain = axios.create({
  baseURL: `${process.env.REACT_APP_IPFS_BLOCKCHAIN_URL}/api`,
  headers: authHeader(),
});

export const axiosCompanyRequestV1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL_COMPANY}/api/v1`,
  headers: authHeader()
});

export const axiosNtpRequestV1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL_NTP}/api/v1`,
  headers: authHeader()
});

export const axiosBPMRequestV1 = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_BPM_URL}/api/v1`,
  headers: authHeader()
});

export const axiosPaymentRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_PAYMENT_URL}/api/v1`,
  headers: authHeader()
});

export const axiosPagareRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_PAGARE_URL}/api`,
});

export const axiosGeneradoresRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_GENERADORES_URL}/api/v1`,
});

export const axiosOrkestadocRequest = axios.create({
  baseURL: `${process.env.REACT_APP_API_SCM}/api/v1`,
});
