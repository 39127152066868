
type HandleDialog = [() => void, () => void];

/**
 * Returns a dialog component, a show modal handler and a close modal handler.
 * At least you should use `MaterialityDialog` and `handleShowModal` from the return.
 * @returns {HandleDialog} [MaterialityDialog, handleShowModal, handleCloseModal];
 */
export const useBlurBackdrop = (): HandleDialog  => {
  const container = document.getElementById("root");

  const handleShowBackdrop = () => {
    container!.className="main-content-blurred";
  };
  const handleCloseBackdrop = () => {
    container!.className="";
  };

  return [handleShowBackdrop, handleCloseBackdrop];
}



