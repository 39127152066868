import create from "zustand";
import { FormEntityType, InfoAlert } from "../types/global";

interface State {
  infoAlert: InfoAlert;
  isReadOnlyForm: boolean;
  dialogForm: boolean;
  formType: FormEntityType;
  handleShowInfoAlert: (arg: InfoAlert) => any;
  handleCloseInfoAlert: (arg: InfoAlert) => any;
  showDialogForm: ({ readOnly, formType }: ShowDialogFormArgs) => void;
  closeForm: () => void;
  showError: (e: ErrorMessage) => void;
}

const useDashboardState = create<State>((set) => ({
  infoAlert: {
    show: false,
    title: "",
    type: "success",
  },  
  isReadOnlyForm: false,
  dialogForm: false,
  formType: null,
  handleShowInfoAlert: (arg: InfoAlert) => set({ infoAlert: arg }),
  handleCloseInfoAlert: (arg: InfoAlert) => set({ infoAlert: arg }),
  showDialogForm: ({ readOnly, formType }) => set((state) => ({ formType, isReadOnlyForm: readOnly, dialogForm: true })),
  closeForm: () => set((state) => ({ formType: undefined, isReadOnlyForm: false, dialogForm: false })),
  showError: (e: ErrorMessage) => set({ infoAlert: { show: true, title: e.message, type: "error" } }),
}));

export default useDashboardState;

//FUNCTIONS
// function example(set: NamedSet<State>, get: GetState<State>) {
//   return () => {};
// }
