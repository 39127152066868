import { Box, Button, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { useEffect, useState } from "react";
import WarningIcon from "@material-ui/icons/Warning";
import { currencyFormat, dateFormat, toBase64 } from "../utils";
import { useHandleModal } from "../hooks/useHandleModal";
import { Contract, Service } from "../../../core/models/service.models";
import ColoredButton from "../../general/ColoredButton";
import usePlanState from "../../../store/planState";
import UploadCDFIDialog from "./UploadCDFIDialog";
import { NTPService } from "../../../core/services/NTPService.service";
import ContractFormDialog from "./ContractFormDialog";
import CDFIDialog from "./cfdi/CDFIDialog";
import ShowContractDialog from "./watch-document/ShowContractDialog";
import useUiState from "../../../store/uiState";
import useCustomToast from "../../../core/hooks/useCustomToast";
import EditIcon from "@material-ui/icons/Edit";
import ContractAnnexFormDialog from "./ContractAnnexFormDialog";
import ShowAnnexsDialog from "./ShowAnnexsDialog";
import EditContractDataDialog from "./EditContractDataDialog";
import { Period } from "../../../types/ntp.types";
import { useHistory } from "react-router";
import useGeneralFormCompleteState from "../../../store/generadores/generalFormState";

const StageContractCard = ({ contract, service, refresh }: { contract: Contract; service: Service; refresh: any }) => {
  const theme = useTheme();
  const { setShowGenericLoader } = useUiState();
  const toast = useCustomToast();
  const [showUploadContractDialog, setShowUploadContractDialog] = useState(false);
  const [showUploadAnnexDialog, setShowUploadAnnexDialog] = useState(false);
  const [showContract, handleShowContract, handleCloseContract] = useHandleModal();
  const [showAnnexsDialog, handleShowAnnexsDialog, handleCloseAnnexsDialog] = useHandleModal();
  const [showUploadCDFIDialog, onShowUploadCDFIDialog, onCloseUploadCDFIDialog] = useHandleModal();
  const [showCDFIDialog, onShowCDFIDialog, onCloseCDFIDialog] = useHandleModal();

  const [showEditContractData, handleShowEditContractData, handleCloseEditContractData] = useHandleModal();
  const [uploadedFilesResponse, setUploadedFilesResponse] = useState<string[]>([]);
  const [uploadFinished, setUploadFinished] = useState(false);
  const isLG = useMediaQuery(theme.breakpoints.up("lg"));

  const { canUploadUpdateContract ,invited } = usePlanState();

  const nomina = service?.ntp?.nomina;
  const periodos: Period[] = nomina?.periodos || [];
  const setShowGeneratorsList = useGeneralFormCompleteState((state) => state.setShowGeneratorsList);

  const onShowContract = () => {
    if (contract) {
      handleShowContract();
    }
  };

  const onUploadCFDI = () => {
    onShowUploadCDFIDialog();
  };

  const handleUploadCFDIDialogClose = () => {
    onCloseUploadCDFIDialog();
    setUploadFinished(false);
    setUploadedFilesResponse([]);
  };

  const uploadCFDIFiles = async (files: File[]): Promise<boolean> => {
    try {
      const base64Files: string[] = await Promise.all(files.map(file => toBase64(file) as Promise<string>));
      const res: any = await NTPService.getInstance().uploadPeriod(service?.id, base64Files);
  
      if (res.codigo === 0) {
        const uploadedFilesRes = Object.values(res.respuesta || []);
        setUploadedFilesResponse(prev => prev.concat(uploadedFilesRes as any));
        return true;
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  //upload cfdi files
  // const handleCDFIFilesUpload = async (files: File[]) => {
  //   try {
  //     setShowGenericLoader(true);
  //     const uploaded = await uploadCFDIFiles(files);
  //     if (uploaded) {
  //       toast.success("Se han subido los archivos correctamente");
  //     }
  //     refresh();
  //     if (uploaded) {
  //       handleUploadCFDIDialogClose();
  //     }
  //   } catch (error) {
  //     toast.error("Ocurrió un error al subir documentos");
  //   }
  //   setShowGenericLoader(false);
  // };

  //upload cfdi files by 100 files per request
  const handleCDFIFilesUploadByBatch = async (files: File[]) => {
    const batchSize = 10;
    const totalFiles = files.length;
  
    try {
      setShowGenericLoader(true);
  
      while (files.length > 0) {
        const filesToUploadBatch = files.splice(0, batchSize);
        try {
          await uploadCFDIFiles(filesToUploadBatch);
        } catch (uploadError) {
          // Manejar el error de subida (reintentar o informar al usuario)
          console.error("Error en la subida de archivos:", uploadError);
        }
  
        // Calcular y actualizar el progreso
        const progress = ((1 - files.length / totalFiles) * 100).toFixed(2);
        console.log(`Progreso de subida: ${progress}%`);
      }
  
      setUploadFinished(true);
      console.log("Subida de archivos completada");
    } catch (error) {
      toast.error("Ocurrió un error al subir documentos");
    } finally {
      setShowGenericLoader(false);
    }
  };
  

  const hasContract = Boolean(contract?.fileId);
  const hasAnnex = Boolean(contract?.appendixs?.length);

  useEffect(() => {
    if (uploadFinished) {
      if (uploadedFilesResponse.some((periodMsg: string) => periodMsg.includes("Ya se ha subido"))) {
        toast.warnign("Algunos periodos ya se han subido previamente");
      } else {
        toast.success("Se han subido los archivos correctamente");
        refresh();
        handleUploadCFDIDialogClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFilesResponse, uploadFinished]);
console.log("invited",invited)
  return (
    <>
      <Box style={{ margin: "1rem auto", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem", flexWrap: isLG ? "nowrap" : "wrap" }}>
        <Grid
          item
          xs={12}
          lg={6}
          style={{ backgroundColor: "#fff", padding: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem", height: "100px" }}
        >
          {!hasContract && (
            <Button
              variant="contained"
              color="primary"
              style={{ padding: "1rem 3rem", width: "50%", fontWeight: "bold", height: "60px" }}
              startIcon={<WarningIcon fontSize="inherit" />}
              onClick={() => setShowUploadContractDialog(true)}
              disabled={!hasContract && !canUploadUpdateContract}
            >
              Subir contrato
            </Button>
          )}

          {hasContract && (
            <Box style={{ width: "50%", display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between" }}>
              <div style={{ position: "relative", flexGrow: 1 }}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Contrato
                </Typography>
                <Typography variant="body2">
                  Vigencia: <b>{contract?.expirationDate ? dateFormat(new Date(contract.expirationDate)) : "--"}</b>
                </Typography>
                <Typography variant="body2">
                  Monto: <b>{currencyFormat(contract?.amount, contract?.currency ?? "MXN")}</b>
                </Typography>

                <IconButton
                  color="primary"
                  aria-label="edit"
                  style={{ backgroundColor: "#000", color: "white", width: "1rem", height: "1rem", fontSize: "1rem", position: "absolute", bottom: "4px", right: "1px" }}
                  onClick={handleShowEditContractData}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </div>
              <ColoredButton
                variant={"outlined"}
                size="small"
                style={{ fontWeight: "bold", padding: "2px 2rem", borderWidth: "3px", height: "60px" }}
                color={"#7678ED"}
                onClick={() => setShowUploadAnnexDialog(true)}
                disabled={!hasContract && !canUploadUpdateContract}
              >
                Agregar <br /> anexo
              </ColoredButton>
            </Box>
          )}

{
 
     <Button
            variant="contained"
            color="primary"
            style={{ padding: "1rem 3rem", width: hasAnnex ? "25%" : "50%", fontWeight: "bold", height: "60px" }}
            onClick={onShowContract}
            disabled={!hasContract}
          >
            Consultar contrato
          </Button>
 }
      
          {hasAnnex && (
            <Button
              variant="contained"
              color="primary"
              style={{ padding: "1rem 3rem", width: "25%", fontWeight: "bold", height: "60px" }}
              onClick={() => handleShowAnnexsDialog()}
              disabled={!hasAnnex}
            >
              Consultar anexos
            </Button>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          style={{ backgroundColor: "#fff", padding: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem", height: "100px" }}
        >
        {
        service.serviceTypeId !== "660c48af772b55ac139f24cd" && (
          <ColoredButton
            startIcon={<WarningIcon fontSize="inherit" />}
            variant={"contained"}
            size="large"
            style={{ padding: "1rem 3rem", width: "50%", fontWeight: "bold", overflow: "hidden", whiteSpace: "nowrap", height: "60px" }}
            color={"#0092F6"}
            onClick={onUploadCFDI}
            disabled={!canUploadUpdateContract}
          >
            Subir CFDI’s de nómina
          </ColoredButton>
        )
      }

      {
        service.serviceTypeId !== "660c48af772b55ac139f24cd" && (
          <Button
          variant={"contained"}
          size="large"
          style={{ padding: "1rem 3rem", width: "50%", fontWeight: "bold", overflow: "hidden", whiteSpace: "nowrap", height: "60px" }}
          color={"secondary"}
          onClick={onShowCDFIDialog}
          disabled={!Boolean(periodos?.length)}
        >
          Consultar CFDI’s de nómina
        </Button>
        )
      }
      {/*
        service.serviceTypeId === "660c48af772b55ac139f24cd" && (
          <Button
            variant={"contained"}
            size="large"
            style={{ padding: "1rem 3rem", width: "50%", fontWeight: "bold", overflow: "hidden", whiteSpace: "nowrap", height: "60px" }}
            color={"primary"}
          >
            Cátalogo
          </Button>
        )
      */}
      {
        service.serviceTypeId === "660c48af772b55ac139f24cd" && (
          <Button
          variant={"contained"}
          size="large"
          disabled={!invited} // false ? si es client disabled btn
          style={{ padding: "1rem 3rem", width: "50%", fontWeight: "bold", overflow: "hidden", whiteSpace: "nowrap", height: "60px" }}
          color={"primary"}
          onClick={() => {
            setShowGenericLoader(true);
            setShowGeneratorsList();          
          }}
        >
          Datos de generador
        </Button>
        )
      }
      
        </Grid>
      </Box>

      {/* ----------------------------- MODAL & OTHERS ----------------------------- */}
      {showContract && <ShowContractDialog onClose={handleCloseContract} contract={contract} />}

      {showUploadCDFIDialog && (
        <UploadCDFIDialog
          open={true}
          onClose={handleUploadCFDIDialogClose}
          onUploadFile={handleCDFIFilesUploadByBatch}
          employeesCount={nomina?.employees}
          uploadFilesResponse={uploadedFilesResponse}
          uploadFinished={uploadFinished}
        />
      )}

      {showUploadAnnexDialog && <ContractAnnexFormDialog refresh={refresh} open={true} onClose={() => setShowUploadAnnexDialog(false)} serviceId={service?.id} />}

      {showUploadContractDialog && <ContractFormDialog refresh={refresh} open={true} onClose={() => setShowUploadContractDialog(false)} serviceId={service?.id} />}

      {showAnnexsDialog && <ShowAnnexsDialog onClose={handleCloseAnnexsDialog} contract={contract} />}

      {showEditContractData && <EditContractDataDialog currentContract={contract} onClose={handleCloseEditContractData} refresh={refresh} serviceId={service?.id} />}

      {showCDFIDialog && periodos?.length > 0 && <CDFIDialog open={true} onClose={onCloseCDFIDialog} periodos={periodos} serviceCompId={service?.companyId} serviceId={service?.id} />}
    </>
  );
};

export default StageContractCard;
