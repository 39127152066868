import * as React from "react";

const SatIcon = (props) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx={19.926} cy={20} rx={19.926} ry={20} fill="#2347AD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 7a2 2 0 0 0-2 2v22a2 2 0 0 0 2 2h13.16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2H13Zm8.66 14.3h3.64v.99h-1.215v1.87h-1.2v-1.87H21.66v-.99Zm.26 2.857L20.594 21.3h-1.72L17.5 24.157s1.445.007 1.46 0l.418-1.063s.79.223 1.082 1.063h1.46Zm-2.495-1.192.352-.9.268.9h-.62Zm-2.264-.68v-.856a1.22 1.22 0 0 0-.206-.043 6.85 6.85 0 0 0-.682-.074c-1.285-.074-2.067.195-2.303.612-.293.514-.015.873 1.001 1.087.16.033.329.06.479.085.218.035.393.064.432.094.114.085.016.186-.211.206-.31.03-1.066-.074-1.473-.238l-.035-.013c-.056-.02-.123-.046-.257-.069v.893c.127.012.184.033.233.051.03.01.056.02.093.026 1.634.281 2.829.009 3.13-.459.366-.546-.008-.915-.991-1.134-.163-.037-.322-.068-.459-.095-.212-.042-.373-.073-.417-.1-.113-.063-.082-.16.073-.207.171-.054.892-.023 1.461.172.057.019.114.039.132.062ZM21.27 20a1.43 1.43 0 1 0 0-2.86 1.43 1.43 0 0 0 0 2.86Zm1.43-4.81a1.43 1.43 0 1 1-2.86 0 1.43 1.43 0 0 1 2.86 0ZM17.89 20a1.43 1.43 0 1 0 0-2.86 1.43 1.43 0 0 0 0 2.86Zm1.43-4.81a1.43 1.43 0 1 1-2.86 0 1.43 1.43 0 0 1 2.86 0Z"
      fill="#fff"
    />
  </svg>
);

export default SatIcon;
