import { Button, Typography } from "@material-ui/core";
import GoBackButton from "../components/general/GoBackButton";
import { useEffect, useState } from "react";
import { ReportesService} from "../core/services/reportes.service";
import { GeneradoresServices } from "../core/services/generadores.service";
import { TableroRiesgoService } from "../core/services/tableroRiesgo.service";
import useUiState from "../store/uiState";
import DownloadIcon from "@material-ui/icons/GetApp";
import useCustomToast from "../core/hooks/useCustomToast";
import useGlobalState from "../store/globalState";

const RiesgoStatsPage = () => {
  const [powerbiUrl, setPowerbiUrl] = useState<string>(null);
  const [notPowerBiUrl, setNotPowerBiUrl] = useState<boolean>(false);
  const { setShowGenericLoader } = useUiState();
  const mainCompanyId = useGlobalState((s) => s.mainCompany?.id);

  const toast = useCustomToast();

  function handleDownload() {
    setShowGenericLoader(true);
    TableroRiesgoService.getInstance()
      .downloadPowerBiReport()
      .then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "PowerBi_Data.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.log(error);
        toast.error("No se pudo descargar el reporte");
      })
      .finally(() => {
        setShowGenericLoader(false);
      });
  }

  async function handleDownloadReportDaily() {
    setShowGenericLoader(true);
    try {
      const reportService = ReportesService.getInstance();
      const data = await reportService.getIdealExcelReport(mainCompanyId);// revisar si id debe cambiar
  
      if (!data || data.codigo !== 0 || !data.respuesta?.length) {
        throw new Error("No se pudo obtener la información del reporte.");
      }
  
      const response = data.respuesta[0];
      const fileId = response.fileId;

      if (!fileId) {
        throw new Error("ID de archivo no encontrado en la respuesta.");
      }
  
      const documentService = GeneradoresServices.getInstance();
      const documentData = await documentService.getVaultDocument(fileId);
  
      if (!documentData) {
        throw new Error("No se pudo obtener el documento.");
      }

      function base64ToBlob(base64, contentType = '') {
          const byteCharacters = atob(base64); 
          const byteArrays = [];
      
          for (let i = 0; i < byteCharacters.length; i += 512) {
              const slice = byteCharacters.slice(i, i + 512);
              const byteNumbers = new Array(slice.length);
      
              for (let j = 0; j < slice.length; j++) {
                  byteNumbers[j] = slice.charCodeAt(j);
              }
      
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
          }
      
          return new Blob(byteArrays, { type: contentType });
      }
      
      const blob = base64ToBlob(documentData.file, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      
      const today = new Date();
      const formattedDate = today.toLocaleDateString("es-ES", {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
      });
      
      link.href = url;
      link.setAttribute("download", `Reporte ${formattedDate}.xlsx`);
      
      document.body.appendChild(link);
      link.click();
      link.remove();
      
    } catch (error) {
      console.error(error);
      toast.error(error.message || "No se pudo descargar el reporte.");
    } finally {
      setShowGenericLoader(false);
    }
  }
  
  

  useEffect(() => {
    setShowGenericLoader(true);
    TableroRiesgoService.getInstance()
      .getPowerBiUrl()
      .then((url) => {
        console.log(url);
        if (Boolean(url)) {
          setPowerbiUrl(url);
        } else {
          setNotPowerBiUrl(true);
        }
      })
      .finally(() => {
        setShowGenericLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
<>
  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
    <GoBackButton />

    <div style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
      <Typography variant="h4" style={{ textAlign: "center",paddingLeft:'6rem' }}>
        Tablero de riesgos
      </Typography>
    </div>

    <div style={{ display: "flex", alignItems: "center", gap: ".6rem" }}>
      <Button color="primary" variant="contained" size="small" onClick={handleDownloadReportDaily} endIcon={<DownloadIcon />}>
        Descargar Reporte Diario
      </Button>
         {/** <Button color="primary" variant="contained" size="small" onClick={handleDownload} endIcon={<DownloadIcon />}>
        Descargar Tablero de Riesgos
      </Button> 
  */}
    </div>
  </div>

  <div style={{ marginTop: "1rem", height: "84vmin" }}>
    {Boolean(powerbiUrl) && (
      <iframe
        title="test - Página 1"
        width="100%"
        height="97%"
        src={powerbiUrl}
        style={{ border: "none" }} // Quita el borde con CSS
        allowFullScreen={true}
      ></iframe>
    )}

    {notPowerBiUrl && (
      <Typography variant="body1" style={{ textAlign: "center", marginTop: "3rem" }}>
        No se ha configurado el tablero de riesgos
      </Typography>
    )}
  </div>
</>

  );
};

export default RiesgoStatsPage;
