import React from 'react';
import { Button, Grid, Typography } from "@material-ui/core";

import { FormType } from '../../../pages/ManagementPage';
import DropzoneFiles from '../../inputs/DropzoneFiles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import RenderTextField from '../../inputs/RenderTextField';
import RenderMultipleSelect from '../../inputs/RenderMultipleSelect';

interface FormUsersProps {
  companies: any[];
  onCancel: () => void;
  roles: Role[];
 // projects: any[];
  onSubmit: (formData: FormUserFields) => void;
  typeForm: FormType;
  userSelected: User;
  formType: FormType;
  initialValues?:  Partial<FormUserFields>;
}

const FormUsers = (props: FormUsersProps) => {
  const {
    companies,
    onCancel,
    roles,
   // projects,
    formType
  } = props;

  const formik = useFormik<FormUserFields>({
    initialValues: {
      companies: props.initialValues?.companies ?? [],
      email: props.initialValues?.email ?? '',
      roles: props.initialValues?.roles ?? [],
      name: props.initialValues?.name ?? '',
      lastName: props.initialValues?.lastName ?? '',
      imageFile: null,
    },
    onSubmit: props.onSubmit,
    validationSchema: Yup.object({
      name: Yup.string().required('Campo requerido'),
      lastName: Yup.string().required('Campo requerido'),
      email: Yup.string().required('Campo requerido').email('Dirección de correo no válida'),
      companies: Yup.array().min(1, 'Selecciona la menos una empresa'),
      roles: Yup.array().min(1, 'Selecciona la menos un rol')
    })
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item md={12}>
        <Typography variant="h6" color="primary">{`${formType === 'create' ? 'Agregar' : 'Editar'} Usuario`}</Typography>
          <RenderTextField
            id="name"
            name="name"
            error={formik.errors.name}
            label="Nombre(s)"
            onChange={formik.handleChange}
            touched={formik.touched.name}
            value={formik.values.name}
          />

          <RenderTextField
            id="lastName"
            name="lastName"
            error={formik.errors.lastName}
            label="Apellidos"
            onChange={formik.handleChange}
            touched={formik.touched.lastName}
            value={formik.values.lastName}
          />

          <RenderTextField
            id="email"
            name="email"
            error={formik.errors.email}
            label="Email"
            onChange={formik.handleChange}
            touched={formik.touched.email}
            value={formik.values.email}
            disabled={formType === 'edit'}
          />

         {/** <RenderMultipleSelect
            externLabel
            label="Proyectos"
            name="projects"
            value={formik.values.projects}
            onChange={(value) => {
              formik.setFieldValue('projects', value);
            }}
            options={
              projects              
            }
            touched={formik.touched.projects}

          />*/} 

          <RenderMultipleSelect
            externLabel
            label="Rol de usuario"
            name="roles"
            value={formik.values.roles}
            onChange={(value) => {
              formik.setFieldValue('roles', value);
            }}
            options={roles}
            touched={formik.touched.roles}
            error={formik.errors.roles as string}
          />

          <RenderMultipleSelect
            externLabel
            label="Seleccionar Empresa"
            name="companies"
            value={formik.values.companies}
            onChange= {(value) => {
              formik.setFieldValue('companies', value);
            }}
            options={companies}
            touched={formik.touched.companies}
            error={formik.errors.companies as string}
          />

          <DropzoneFiles
            accept="image/*"
            labelBox="Arrastra la imagen aquí para subirla"
            label="Adjuntar foto de perfil"
            file={formik.values.imageFile}
            onChange={(file) => {
              formik.setFieldValue('imageFile', file);
            }}
          />

          {/* <RenderMultipleSelect value={selectedCompanies} onChange={handleChange} options={currencies}  /> */}

          <div className="text-right" style={{ marginTop: 40 }}>
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={() => onCancel()}
            >
              Cancelar
            </Button>

            <Button
              className="ml-2"
              type="submit"
              variant="contained"
              color="primary"
            >
              Guardar
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

export default FormUsers;