/* eslint-disable no-useless-escape */
import React from "react";

import { Button, Grid, IconButton, Typography, makeStyles } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { FormEntityType } from "../../../../types/global";


type Props = {
  onCancel: (args?: any) => any;
  formType?: FormEntityType;
  onSubmit?: (args?: any) => any;
};

function UserInviteAlert({ onCancel, formType, onSubmit }: Props) {
  const classes = useStyles();


  return (
    <div className={classes.main}>
      <Grid container>
        <Grid item md={12}>

          <Grid container xs={12} justifyContent="space-between" alignItems="center" className="mb-4">
            <div style={{ height: "48px", width: "48px" }}></div>
            <CheckCircleIcon fontSize="large" className={classes.checkIcon} />
            <IconButton aria-label="close" onClick={() => onCancel()}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Grid>

          <Typography className={classes.title}>Invitación enviada con éxito</Typography>

          <Typography className={classes.subtitle}>
            {`La persona de contacto en la empresa ${formType === 'provider' ? 'proveedora' : 'cliente'} recibió un correo con los pasos para crear su cuenta en la plataforma.
            Es importante recordarle que siga los pasos para poder continuar.`}
          </Typography>

          <Button className="mt-2"  variant="contained" color="primary" onClick={onSubmit}>
            SIGUIENTE
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    textAlign: "center",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  subtitle: {
    width: "60%",
    margin: "3rem auto",
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  checkIcon:{
    color:theme.palette.success.main
  }
}));

export default UserInviteAlert;
