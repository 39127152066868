import { Button, withStyles } from '@material-ui/core';

const MatFisButton = withStyles({
  root: {
    textTransform: 'none',
    borderRadius: 10,
    padding: ({ size }: { size?: 'small' | 'medium' | 'large' }) => (size === 'small' ? '0 25px' : '6px 40px')
  },
  contained: {
    color: 'white'
  },
  outlined: {
    border: '2px solid'
  }
})(Button);

export default MatFisButton;
