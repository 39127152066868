import { SvgIcon, SvgIconProps } from '@material-ui/core';

const EditCompanyIcom = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="28" height="26" viewBox="0 0 28 26">
      <path d="M18.2 15.5277H9.8C9.65198 15.5567 9.50002 15.5567 9.352 15.5277L0 10.9922V21.6666C0 22.8159 0.442499 23.9181 1.23015 24.7308C2.0178 25.5434 3.08609 26 4.2 26H23.8C24.9139 26 25.9822 25.5434 26.7698 24.7308C27.5575 23.9181 28 22.8159 28 21.6666V10.9922L18.648 15.4555C18.5023 15.4995 18.3517 15.5238 18.2 15.5277Z"/>
      <path d="M17.9763 12.6389L28.0003 7.95889C27.8363 6.93616 27.3224 6.00797 26.5518 5.34277C25.7812 4.67757 24.805 4.31943 23.8003 4.33333H19.6003V2.88889C19.6003 2.12271 19.3053 1.38791 18.7802 0.846136C18.2551 0.304364 17.5429 0 16.8003 0H11.2003C10.4577 0 9.74552 0.304364 9.22041 0.846136C8.69531 1.38791 8.40031 2.12271 8.40031 2.88889V4.33333H4.20031C3.20767 4.33665 2.24824 4.70261 1.49222 5.36629C0.736193 6.02997 0.23244 6.94848 0.0703125 7.95889L10.0243 12.6389H17.9763ZM11.2003 2.88889H16.8003V4.33333H11.2003V2.88889Z"/>
    </SvgIcon>
  )
}


export default EditCompanyIcom;
