async function loadScript(scriptId, scriptSrc) {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById(scriptId);
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = scriptSrc;
      script.id = scriptId;
      document.body.appendChild(script);
      script.onload = () => {
        console.log(`${scriptId} loaded`);
        resolve();
      };
      script.onerror = () => {
        reject();
      };
    }
    if (existingScript) resolve();
  });
}

export async function loadOpenPayScripts() {
  return Promise.all([
    loadScript("openpay-js-1", "https://openpay.s3.amazonaws.com/openpay.v1.min.js"),
    loadScript("openpay-js-2", "https://openpay.s3.amazonaws.com/openpay-data.v1.min.js"),
  ]);
}
