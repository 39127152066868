import { IconButton, Typography, Button, Box } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Alert } from "@material-ui/lab";
import { useFormik } from "formik";
import * as Yup from "yup";

import RenderTextFieldV2 from "../inputs/inputs-v2/RenderTextFieldV2";
import useGlobalState from "../../store/globalState";

interface Props {
  handleSubmit: (form: FormFields) => void;
  onReturnSignIn: () => void;
}

interface FormFields {
  email: string;
}

const RestorePassword = (props: Props) => {
  const alertInfo = useGlobalState((s) => s.alertInfo);
  const formik = useFormik<FormFields>({
    initialValues: {
      email: "",
    },
    onSubmit: props.handleSubmit,
    validationSchema: Yup.object({
      email: Yup.string().required("El correo electrónico es requerido").email("Debe ser un email válido"),
    }),
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} display="flex" gridGap={15} flexDirection="column" flex="1">
      <Box display="flex" alignItems="center">
        <IconButton color="primary" edge="start" onClick={props.onReturnSignIn}>
          <ArrowBackIcon fontSize="large" />
        </IconButton>
        <Typography variant="h3" color="primary" style={{ fontWeight: "normal" }}>
          Restablecer contraseña
        </Typography>
      </Box>
      <p>Ingresa tu correo para restablecer tu contraseña</p>
      <RenderTextFieldV2
        error={formik.errors.email}
        onChange={formik.handleChange}
        touched={formik.touched.email}
        value={formik.values.email}
        placeholder="Correo"
        name="email"
      />
      {Boolean(alertInfo?.message) && (
        <div style={{ maxWidth: "470px" }}>
          <Alert variant="filled" severity={alertInfo?.type}>
            {alertInfo?.message}
          </Alert>
        </div>
      )}
      <Button type="submit" variant="contained" color="primary" fullWidth={true} size="large">
        Enviar
      </Button>
    </Box>
  );
};

export default RestorePassword;
