import { Menu, MenuItem } from "@material-ui/core";
import React, { FC, useState } from "react";

type ContextMenuCoor = {
  mouseX: null | number;
  mouseY: null | number;
};

const initialState = {
  mouseX: null,
  mouseY: null,
};

export type ContextualMenuItem = {
  text: string;
  action?: () => void;
  disabled?: boolean;
  textColor?: string;
};

type ContextualMenu = [FC, (event: React.MouseEvent<HTMLElement>) => void, () => void];

/**
 * Returns a Menu component, an open menu and a close menu handlers.
 * @returns [CustomMenu, handleopenMenu, handleCloseMenu];
 */
export const useContextualMenu = (options: ContextualMenuItem[]): ContextualMenu => {
  const [anchorItemCoor, setAnchorItemCoor] = useState<ContextMenuCoor>(initialState);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorItemCoor({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleCloseMenu = () => {
    setAnchorItemCoor(initialState);
  };

  const CustomMenu: FC = ({ children }) => (
    <Menu
      open={anchorItemCoor.mouseY !== null}
      onClose={handleCloseMenu}
      anchorReference="anchorPosition"
      anchorPosition={
        anchorItemCoor.mouseY !== null && anchorItemCoor.mouseX !== null
          ? { top: anchorItemCoor.mouseY, left: anchorItemCoor.mouseX }
          : undefined
      }
    >
      {options.map((option, i) => {
        return (
          <MenuItem
            key={i}
            style={{ fontWeight: "bold", color: option.textColor, fontSize: ".8rem" }}
            onClick={() => {
              handleCloseMenu();
              !!option.action && option.action();
            }}
            disabled={option.disabled}
          >
            {option.text}
          </MenuItem>
        );
      })}
      {children}
    </Menu>
  );

  return [CustomMenu, handleOpenMenu, handleCloseMenu];
};
