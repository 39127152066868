import { axiosNtpRequestV1, authHeader,axiosGeneradoresRequest } from '../../configs/axios.config';
import { AddContractAnnexReqbody, AddContractReqBody, AddDocReqBody, AddDocumentDashboardRes, Appendix, CFDI, Contract, Data, GetStageInfoResponse, Payroll, ServiceDashboardRes, UpdateContractReqBody, UpdateDocStatusReqBody, UpdatePayrollReqBody, ValidationStatus } from '../../types/ntp.types';
import { Period } from 'materialidad-outsourcing/dist/types';

export class NTPService {
  private static instance: NTPService;

  public static getInstance(): NTPService {
    if (!NTPService.instance) {
      NTPService.instance = new NTPService();
    }
    return NTPService.instance;
  }

  async addServiceRequirementDoc(serviceId: string, requirementUid: string, body: AddDocReqBody) {
    try {
      const { data } = await axiosNtpRequestV1.post<AddDocumentDashboardRes>(
        `/services/${serviceId}/requirements/${requirementUid}`,
        body,
        { headers: { ...authHeader(), 'Content-Type': 'application/json' }}
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteServiceRequirementDoc(serviceId: string, requirementUid: string, documentUid: string) {
    try {
      const { data } = await axiosNtpRequestV1.delete<ServiceDashboardRes<Data>>(
        `/services/${serviceId}/requirements/${requirementUid}`,
        {
          headers: { ...authHeader(), 'Content-Type': 'application/json' },
          data: { uid: documentUid }
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  
  async updateServiceRequirementDocStatus(serviceId: string, requirementUid: string, body: UpdateDocStatusReqBody) {
    try {
      const { data } = await axiosNtpRequestV1.put<ServiceDashboardRes<Data>>(
        `/services/${serviceId}/requirements/${requirementUid}`,
        body,
        {
          headers: { ...authHeader(), 'Content-Type': 'application/json' },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getNTPProcess(serviceId: string) {
    try {
      const { data } = await axiosNtpRequestV1.get<ServiceDashboardRes<Data>>(`/services/${serviceId}`, {headers: authHeader()});
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getStageInfo(serviceId: string, stageName: string) {
    try {
      const { data } = await axiosNtpRequestV1.get<ServiceDashboardRes<GetStageInfoResponse>>(`/services/${serviceId}/stage/${stageName}`, {headers: authHeader()});
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateSocialObject(serviceId: string, socialObject: string) {
    try {
      const { data } = await axiosNtpRequestV1.put<ServiceDashboardRes<Data>>(
        `/services/${serviceId}/osp/add`,
        { objetoSocialPreponderante: socialObject },
        { headers: authHeader() }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addContract(serviceId: string, body: AddContractReqBody) {
    try {
      const { data } = await axiosNtpRequestV1.post<ServiceDashboardRes<Contract>>(`/services/${serviceId}/contracts/add`, body, {headers: authHeader()});
      return data.data;
    } catch (error) {
      throw error;
    }
  }


  async updateContract(serviceId: string, body: UpdateContractReqBody) {
    try {
      const { data } = await axiosNtpRequestV1.put<ServiceDashboardRes<Contract>>(`/services/${serviceId}/contracts/update`, body, {headers: authHeader()});
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async addContractAnnex(serviceId: string, body: AddContractAnnexReqbody) {
    try {
      const { data } = await axiosNtpRequestV1.post<ServiceDashboardRes<Appendix>>(`/services/${serviceId}/appendixs/add`, body, {headers: authHeader()});
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async validateContract(serviceId: string, body: ValidationStatus) {
    try {
      const { data } = await axiosNtpRequestV1.put<ServiceDashboardRes<Contract>>(`/services/${serviceId}/contracts/validate`, body, {headers: authHeader()});
      return data.data.validationStatus;
    } catch (error) {
      throw error;
    }
  }

  async validateAnnex(serviceId: string, uid: string, body: ValidationStatus) {
    try {
      const { data } = await axiosNtpRequestV1.put<ServiceDashboardRes<Contract>>(`/services/${serviceId}/appendixs/${uid}`, body, {headers: authHeader()});
      return data.data.validationStatus;
    } catch (error) {
      throw error;
    }
  }

  async updatePayrollInfo(serviceId: string, body: UpdatePayrollReqBody) {
    try {
      const { data } = await axiosNtpRequestV1.put<ServiceDashboardRes<Payroll>>(`/services/${serviceId}/nominas/update`, body, {headers: authHeader()});
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async authorizeStage(serviceId: string, stageIndex: number) {
    try {
      const { data } = await axiosNtpRequestV1.patch<ServiceDashboardRes<Payroll>>(`/services/${serviceId}/stages/${stageIndex}/authorize`, null, {headers: authHeader()});
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async uploadPeriod(serviceId: string, cfdis: string[]) {
    try {
      const { data } = await axiosNtpRequestV1.post<ServiceDashboardRes<Period>>(`/services/${serviceId}/periods`, { cfdis }, {headers: authHeader()});
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPeriodCFDI(serviceId: string, uuidPeriod: string) {
    try {
      const { data } = await axiosNtpRequestV1.get<ServiceDashboardRes<CFDI[]>>(`/services/${serviceId}/periods/${uuidPeriod}/cfdis`, {headers: authHeader()});
      return data.data;
    } catch (error) {
      throw error;
    }
  }



  /// GENERADORES API

  async addGeneradorContract(serviceId: string, body: AddContractReqBody) {
    try {
      const { data } = await axiosGeneradoresRequest.post<ServiceDashboardRes<Contract>>(`/services/${serviceId}/contracts/add`, body, {headers: authHeader()});
      return data.data;
    } catch (error) {
      throw error;
    }
  }

async updateGeneradorContract(serviceId: string, body: UpdateContractReqBody) {
  try {
    const { data } = await axiosGeneradoresRequest.put<ServiceDashboardRes<Contract>>(`/services/${serviceId}/contracts/update`, body, {headers: authHeader()});
    return data.data;
  } catch (error) {
    throw error;
  }
}

async addGeneradorContractAnnex(serviceId: string, body: AddContractAnnexReqbody) {
  try {
    const { data } = await axiosGeneradoresRequest.post<ServiceDashboardRes<Appendix>>(`/services/${serviceId}/appendixs/add`, body, {headers: authHeader()});
    return data.data;
  } catch (error) {
    throw error;
  }
}


}

