import { Button, Grid, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Typography } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';

import MenuComponent from "../../general/MenuComponent";

interface ManagementRowProps<S, T> {
  /** Item or rows info that will be shown by the table. */
  tableItem: T;
  itemValue: S;
  /** Table header data. */
  header: {
    key: keyof T;
    title: string;
  }[];
  menuItems?: ManagementMenuItem<S>[];
}

type ManagementRowType = <S, T>(props: ManagementRowProps<S, T>) => JSX.Element;
/**Row component that show each item defined in `ManagementTable` component */
const ManagementRow: ManagementRowType = (props) => {
  const classes = useStyles();

  return (
    <ListItem classes={{ container: classes.root }}>
      <ListItemText disableTypography>
        <Grid container>
          {props.header.filter((item) => !!props.tableItem?.[item.key]).map((item) => {
            const cell = props.tableItem?.[item.key];
            let renderCell: JSX.Element | NonNullable<any>[any];
            if (typeof cell === 'string')
              renderCell = <Typography variant="subtitle1" color="primary">{cell}</Typography>;
            else if(Array.isArray(cell) && cell.every((el) => typeof el === 'string'))
              renderCell = <Typography variant="subtitle1" color="primary">{cell.join(', ')}</Typography>;
            else renderCell = cell;
            return <Grid key={String(item.key)} className={classes.cell} item xs>{renderCell}</Grid>;
          })}
        </Grid>
      </ListItemText>
      {props.menuItems &&(
        <ListItemSecondaryAction>
          {props.menuItems.length === 1
          ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => props.menuItems?.[0].action?.(props.itemValue)}>
                {props.menuItems[0].label}
              </Button>
          )
          : (
            <MenuComponent
              buttonText={ <MoreVertIcon /> }
              buttonType="icon"
              items={props.menuItems.map<ManagementMenuItem>(({action, ...item}) => {
                return {
                  ...item,
                  action: () => action?.(props.itemValue)
                };
              })}
            />
          )}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    marginTop: 8,
    marginBottom: 8,
    padding: 12,
    borderRadius: 50,
  },
  cell: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export default ManagementRow;