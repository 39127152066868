import create, { GetState } from "zustand";
import { devtools, NamedSet } from "zustand/middleware";
import { PolicyData, User } from "../core/models/settings.models";
import { SettingsService } from "../core/services/settings.service";
import useGlobalState from "./globalState";

interface State {
    companyUsers: { loading: boolean; error: boolean; data: User[] };
    getCompanyUsers: (withLoading?: boolean) => Promise<User[]>;
    getPoliciesByCompanyList: (withLoading?: boolean) => Promise<any>;
    getPoliciesByUserList: (userId: string) => Promise<any>;
    updatePoliciesList: (body: PolicyData, withLoading?: boolean) => Promise<any>;
    saveNotApplicableUsers: (body: string[], withLoading?: boolean) => Promise<any>;
    getNotApplicableUsers: (withLoading?: boolean) => Promise<any>;
    validateOtpCode: (id: string, code: string, withLoading?: boolean) => Promise<any>;
    reset: () => void;
}

const initialState = {
    companyUsers: { loading: false, error: false, data: null },
};

const useSettingsState = create<State>(
    devtools(
        // ------------
        (set, get) => ({
            ...initialState,
            getCompanyUsers: getCompanyUsers(set, get),
            getPoliciesByCompanyList: getPoliciesByCompanyList(set, get),
            getPoliciesByUserList: getPoliciesByUserList(set, get),
            updatePoliciesList: updatePoliciesList(set, get),
            saveNotApplicableUsers: saveNotApplicableUsers(set, get),
            getNotApplicableUsers: getNotApplicableUsers(set, get),
            validateOtpCode: validateOtpCode(set, get),
            reset: () => set((state) => ({ ...state, ...initialState })),
        }),
        // ------------
        { name: "SettingsState" }
    )
);
export default useSettingsState;

//FUNCTIONS

const msgPermission = "No cuenta con el permiso para esta acción.";
function hasPermissionForAction(reponse: any, msgPermission: string, redirect: boolean) {
    /* if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: "AVISO",
                text2: msgPermission,
                text3: "Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.",
                redirectHome: redirect,
                onClose: async () => {
                    return { show: false };
                },
            },
        });
    } */
}

function getPoliciesByCompanyList(set: NamedSet<State>, get: GetState<State>) {
    /** */
    return async () => {
        /** */
        const companyId = sessionStorage.getItem("companyId");

        const res = await SettingsService.getInstance().getPoliciesByCompanyList(companyId);

        //hasPermissionForAction( res, "Recuperar políticas de contraseña", false );
        console.log(res);
        if (res?.codigo === 0) {
            return res;
        } else {
            console.log("Error associating company");
            return null;
        }
    }
}

function getPoliciesByUserList(set: NamedSet<State>, get: GetState<State>) {
    /** */
    return async (userId) => {
        /** */

        const res = await SettingsService.getInstance().getPoliciesByUserList(userId);

        //hasPermissionForAction( res, "Recuperar políticas de contraseña", false );
        console.log(res);
        if (res?.codigo === 0) {
            return res;
        } else {
            console.log("Error associating company");
            return null;
        }
    }
}

function updatePoliciesList(set: NamedSet<State>, get: GetState<State>) {
    /** */
    return async (body: PolicyData, withLoading = true) => {
        /** */

        const res = await SettingsService.getInstance().updatePoliciesList(body);

        //hasPermissionForAction( res, "Actualizar políticas", false );

        if (res?.codigo === 0) {
            return res;
        } else {
            console.log("Error updating policies");
            return null;
        }

    }
}

function getCompanyUsers(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
        const idCompany = sessionStorage.getItem("companyId");
        set((state) => ({ companyUsers: { loading: true, error: false, data: state.companyUsers.data } }));

        const res = await SettingsService.getInstance().getCompanyUsers(idCompany);

        hasPermissionForAction(res, "Listar compañias", false);
        console.log(res);
        if (res?.codigo === 0) {
            set({ companyUsers: { loading: false, error: false, data: res.respuesta } });
            return res.respuesta;
        } else {
            console.log("Error fetching company users");
            set((state) => ({ companyUsers: { loading: false, error: true, data: state.companyUsers.data } }));
            //clean error
            set((state) => ({ companyUsers: { ...state.companyUsers, error: false } }));
            return null;
        }
    };
}

function getNotApplicableUsers(set: NamedSet<State>, get: GetState<State>) {
    /** */
    return async () => {
        /** */
        const companyId: string = sessionStorage.getItem("companyId");

        const res = await SettingsService.getInstance().getNotApplicableUsers(companyId);

        hasPermissionForAction(res, "Recuperar usuario sin otp", false);

        if (res?.codigo === 0) {
            return res;
        } else {
            console.log("Error associating company");
            return null;
        }
    }
}

function saveNotApplicableUsers(set: NamedSet<State>, get: GetState<State>) {
    /** */
    return async (body: string[]) => {
        /** */
        const companyId: string = sessionStorage.getItem("companyId");

        const res = await SettingsService.getInstance().saveNotApplicableUsers(body, companyId);

        hasPermissionForAction(res, "Guardar usuarios sin otp", false);

        if (res?.codigo === 0) {
            return res;
        } else {
            console.log("Error associating company");
            return null;
        }
    }
}

function validateOtpCode(set: NamedSet<State>, get: GetState<State>) {
    /** */
    return async (id: string, code: string) => {

        const res = await SettingsService.getInstance().validateOtpCode(id, code);
        if (res?.data.codigo === 0) {
            return res.data;
        } else {
            /* useGlobalState().setAlertInfo({
                type: "error",
                message: 'Código OTP incorrecto',
            });
            console.log("Error otp code valitation"); */

            return res.data;
        }
    }
}