import { Dialog, DialogTitle, IconButton, DialogContent, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import mime from 'mime-types';
import * as Yup from 'yup';
import RenderFileField from '../../stages/comps/inputs/RenderFileField';
import MatFisButton from '../../stages/comps/MatFisButton';


type Props = {
  open: boolean;
  onClose?: () => void;
  onUploadFile: (files: File) => void;
}

const UploadPictureDialog = (props: Props) => {
  const classes = useStyles();

  const formik = useFormik<{ profilePicture?: File }>({
    initialValues: {
      profilePicture: null
    },
    validationSchema: Yup.object({
      profilePicture: Yup.mixed()
        .required('Campo requerido')
        .test({
          name: 'file-type',
          message: `El archivo no es una imagen`,
          test: (file: File) => {
            const mimeType = mime.lookup(file?.name);
            return !!mimeType ? mimeType.startsWith('image') : false;
          }
        })
    }),
    onSubmit: (values) => props.onUploadFile(values.profilePicture)
  });

  const handleClose = () => {
    formik.setFieldValue('profilePicture', []);
    if (props.onClose) props.onClose();
  };

  const handleChange = (files?: File) => {
    formik.setFieldTouched('profilePicture', true);
    formik.setFieldValue('profilePicture', files);
  };

  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <span className={classes.dialogTitleText}>Subir foto</span>
        <IconButton edge="end" onClick={handleClose}>
          <CloseIcon style={{ color: '#7A92C5' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} className={classes.dialogContentRoot}>
          <RenderFileField
            accept="image/*"
            value={formik.values.profilePicture}
            onChange={handleChange}
            selectFileButtonLabel="Seleccionar archivo"
            dropzoneLabel="Arrastra aquí el documento para subirlo"
            touched={formik.touched.profilePicture as any}
            error={formik.errors.profilePicture as string}
          />
          <MatFisButton variant="contained" color="primary" disabled={!formik.isValid} type="submit">
            Subir
          </MatFisButton>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dialogContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20
  },
  dialogTitleText: {
    fontWeight: 600,
    fontSize: '1.125rem',
    lineHeight: '21.33px'
  },
  dialogContentText: {
    fontSize: '0.875rem',
    lineHeight: '17px',
    color: '#7A92C5',
    marginBottom: 40
  }
});

export default UploadPictureDialog