import { Dialog, ThemeProvider } from "@material-ui/core";
import { useEffect } from "react";
import { themeV2 } from "../../styles/common";
import CleanTopBar from "../../components/onboarding/CleanTopBar";
import GeneratorFormNewUserData from "../../components/generators-form-initial/new-user-form/GeneratorFormNewUserData";
import useGlobalState from "../../store/globalState";
import FormNewUserData from "../../components/onboarding/new-user-form/FormNewUserData";
export default function NewUserResgitered(props: any) {

  const { typeService } = useGlobalState();

  //viene de  this.props.history.replace("/pre_instructions", { isAccessible: true });
  const isAccessible = props.history?.location?.state?.isAccessible

  useEffect(() => {
    if (!isAccessible) {
      props.history.replace('/')
    }
    // eslint-disable-next-line
  }, [])
console.log("typeService ppp",typeService)
  return (
    <div style={{ minHeight: "100vh" }}>
      <CleanTopBar />
      <ThemeProvider theme={themeV2}>
        <Dialog open={true} fullWidth maxWidth="md">
          {typeService === 'generators' ?
            <GeneratorFormNewUserData />
            :
            <FormNewUserData />
          }

        </Dialog>
      </ThemeProvider>
    </div>
  );
}
