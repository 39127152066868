import { Grid, Typography } from "@material-ui/core";
import { Group } from "../../../core/models/stage.models";
import RequirementCard from "./RequirementCard";

const shouldHideRequirement = (reqName: string, idCompanyService?: string, idServiceStage?: string) => {
  const namesToHide = [
    "Determinación de prima de riesgo vigente",
    "CFDI de servicios",
    "Comprobante de pago de CFDI",
    "Declaración de impuesto sobre nómina",
    "Comprobante de pago de impuesto sobre nómina"
  ];

  //ID COMPANY ELIM && ID GENERIC SERVICE
  const isSpecificService = idCompanyService === '66ad0245fe8e5f135025ebea' && idServiceStage === '61aa76f60ed44558ba2e0743';

  return isSpecificService && namesToHide.includes(reqName);
};

const shouldHideGroupName = (groupName: string, idCompanyService?: string, idServiceStage?: string) => {
  const isSpecificService = idCompanyService === '66ad0245fe8e5f135025ebea' && idServiceStage === '61aa76f60ed44558ba2e0743';

  return isSpecificService && groupName === "IMPUESTO LOCAL";
};

const StageGroupCard = ({ group, refresh, idCompanyService, idServiceStage }: { group: Group; refresh: any, idCompanyService?: any, idServiceStage?: any }) => {

  const filteredRequirements = group.requirements?.filter((req) => {
    return !shouldHideRequirement(req.name, idCompanyService, idServiceStage);
  });

  return (
    <Grid
      container
      style={{
        backgroundColor: "#fff",
        padding: "1rem",
        marginBottom: "2rem",
      }}
    >
      {!shouldHideGroupName(group.name, idCompanyService, idServiceStage) && (
        <Grid item xs={12} style={{ borderBottom: "1px solid #000", paddingBottom: ".5rem" }}>
          <Typography variant="h6">{group.name}</Typography>
        </Grid>
      )}

      <Grid
        container
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill,minmax(360px,1fr))",
          gridGap: "1rem",
          marginTop: "1rem",
        }}
      >
        {filteredRequirements?.map((req) => (
          <RequirementCard key={req.uid} requirement={{ ...req, documents: req.documents?.reverse() }} refresh={refresh} />
        ))}
      </Grid>
    </Grid>
  );
};

export default StageGroupCard;
