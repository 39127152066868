import { Typography } from '@material-ui/core';
import React from 'react';
import PolicyList from './PolicyList';
import PolicyListItem from './PolicyListItem';
import PolicyParagraph from './PolicyParagraph';

const PrivacyDataProtection = () => {
  const line = (
    <span>
      <hr style={{height:'1px', backgroundColor:'gray', width: '30%', display: 'inline-block', margin: 0}} />
    </span>
  );

  return (
    <>
      <PolicyParagraph>
        Se da a conocer el presente <b>Aviso de Privacidad</b> en estricto cumplimiento de los artículos
        15, 16 y 17 de la Ley Federal de Protección de <b>Datos Personales</b> en Posesión de los
        Particulares (“<b><u>Ley de Datos Personales</u></b>”), publicada en el Diario Oficial de la Federación
        (“<b><u>DOF</u></b>”) el día 5 de julio del año 2010, ordenamiento legal que tiene por objeto la protección
        de los datos personales, con la finalidad de regular su tratamiento legítimo, controlado e
        informado, a efecto de garantizar la privacidad y el derecho a la autodeterminación informativa
        de las personas, así como de lo establecido en su Reglamento, publicado en el DOF el 21 de
        diciembre de 2011 y los Lineamientos del <b>Aviso de Privacidad</b>, publicados en el mismo medio
        informativo el 17 de enero de 2013.
      </PolicyParagraph>
      <PolicyParagraph>
        Derivado de lo antes expuesto, se le informa que Usted tendrá pleno control y decisión sobre
        sus <b>Datos Personales</b>, al hacer uso de la plataforma denominada <b>KIVIT</b>, a la cual
        podrá acceder en la siguiente dirección electrónica: <b>www.kivit.mx</b> (en lo sucesivo
        kivit). Por ello, le recomendamos que lea atentamente la siguiente información:
      </PolicyParagraph>

      <Typography variant="subtitle1">1. DEFINICIONES.</Typography>
      <PolicyParagraph>
        De acuerdo con la <b>Ley de Datos Personales</b>, su Reglamento, los Lineamientos del <b>Aviso de Privacidad</b> y
        el presente Aviso, se entenderá por:
        <PolicyList type="I">
          <PolicyListItem>
            <b>Datos Personales</b>: Cualquier información concerniente a una persona física identificada o
            identificable.
          </PolicyListItem>
          <PolicyListItem>
            <b>Datos Personales Sensibles</b>: Aquellos datos personales que afecten a la esfera más íntima de su
            titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave
            para éste. En particular, se consideran sensibles aquellos que puedan revelar aspectos como origen
            racial, étnico, estado de salud presente y futura, información genética, creencias religiosas,
            filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual.
          </PolicyListItem>
          <PolicyListItem>
            <b>Derechos ARCO</b>: Derechos de Acceso, Rectificación, Cancelación y Oposición previstos en la Ley de
            Protección de Datos Personales.
          </PolicyListItem>
          <PolicyListItem>
            <b>Encargado</b>: La persona física o moral que sola o conjuntamente con otras, trate <b>Datos Personales</b> por
            cuenta e instrucciones del <b>Responsable</b>.
          </PolicyListItem>
          <PolicyListItem>
            <b>Ley de Datos Personales</b>: Ley Federal de Protección de <b>Datos Personales</b> en Posesión de los
            Particulares.
          </PolicyListItem>
          <PolicyListItem>Lineamientos: Lineamientos del <b>Aviso de Privacidad</b>.</PolicyListItem>
          <PolicyListItem>
            <b>Reglamento</b>: Reglamento de la Ley Federal de Protección
            de Datos Personales en Posesión de los Particulares.
          </PolicyListItem>
          <PolicyListItem>
            <b>Remisión</b>: La comunicación de datos personales entre el <b>Responsable</b> y el <b>Encargado</b>, dentro o fuera del territorio mexicano.
          </PolicyListItem>
          <PolicyListItem>
            <b>Titular</b>: La persona física a quien corresponden los <b>Datos Personales</b>.
          </PolicyListItem>
          <PolicyListItem>
            <b>Tratamiento</b>: La obtención, uso, divulgación o almacenamiento de datos personales, por cualquier
            medio. El uso abarca cualquier acción de acceso, manejo, aprovechamiento, transferencia o disposición
            de Datos Personales.
          </PolicyListItem>
        </PolicyList>
      </PolicyParagraph>

      <Typography variant="subtitle1">2. DATOS DEL RESPONSABLE.</Typography>
      <PolicyParagraph>
        {line} (<b>insertar nombre o denominación</b>), con domicilio para oír y recibir
        notificaciones en términos del presente Aviso: {line} (<b>insertar domicilio
        completo</b>).
      </PolicyParagraph>
      <Typography variant="subtitle1">3. DATOS PERSONALES QUE SERÁN TRATADOS</Typography>
      <PolicyParagraph>
        El <b>Titular</b> proporcionará al <b>Responsable</b> los siguientes Datos Personales a través de la <b>Plataforma de Kivit</b>:
        <PolicyList type="A">
          <PolicyListItem>
          <b>Datos de Identificación</b>. (i) Nombre completo / (ii) País de nacimiento / (iii) Fecha de nacimiento
            / (iv) Entidad federativa de nacimiento / (v) Registro Federal de Contribuyente / (vi) Género /
            (vii) Clave Única del Registro de Población / (viii) Estado Civil / (ix) Nacionalidad / (x)
            Referencias personales.
          </PolicyListItem>
          <PolicyListItem>
          <b>Datos de Contacto</b>. (i) Domicilio / (ii) Correo electrónico / (iii) Teléfono de contacto.
          </PolicyListItem>
          <PolicyListItem>
          <b>Datos de Laborales</b>. (i) Ocupación / (ii) Actividad económica / (iii) Giro de la actividad
            económica / (iv) Ingresos / (v) Salario.
          </PolicyListItem>
          <PolicyListItem>
            <b>Datos Patrimoniales</b>. (i) Régimen patrimonial / (ii) Ingresos / (iii) Salario, mismo que será
            tratado bajo medidas de seguridad previstas por la Ley u otras Leyes especiales, garantizando
            su confidencialidad en todo momento.
          </PolicyListItem>
        </PolicyList>
      </PolicyParagraph>

      <Typography variant="subtitle1">4. INFORMACIÓN DE TERCEROS</Typography>
      <PolicyParagraph>
        La información y/o datos de terceros que libremente sean proporcionados por el <b>Titular</b> al hacer uso
        de la <b>Plataforma de Kivit</b> en la integración de los formularios de registro y
        seguimiento de las operaciones fiscales, será tratada con estricta confidencialidad, y exclusivamente
        para el cumplimiento de las Finalidades establecidas en el presente Aviso.
      </PolicyParagraph>
      <PolicyParagraph>
        Por su parte, el <b>Titular</b> asume directamente plena responsabilidad respecto de la veracidad de la
        información y datos propios y de terceros que proporcione.
      </PolicyParagraph>

      <Typography variant="subtitle1">5. FINALIDAD DEL TRATAMIENTO DE DATOS.</Typography>
      <PolicyParagraph>
        Los <b>Datos Personales</b> que el <b>Titular</b> proporcione al <b>Responsable</b> tienen como finalidad:
      </PolicyParagraph>
      <PolicyParagraph>
        <b>PRINCIPALES:</b>
        <PolicyList type="A">
          <PolicyListItem>Envío de información comercial y publicitaria.</PolicyListItem>
          <PolicyListItem>
            Encuestas de calidad y satisfacción, análisis de uso de los servicios operados
            por el <b>Responsable</b>.
          </PolicyListItem>
        </PolicyList>
        El <b>Tratamiento de los Datos Personales</b> proporcionados por el <b>Titular</b> al <b>Responsable</b> se limitará al
        cumplimiento de las <b>Finalidades</b> previstas en el presente Aviso de Privacidad.
      </PolicyParagraph>

      <Typography variant="subtitle1">6. LIMITACIÓN DEL USO Y DIVULGACIÓN DE LOS DATOS.</Typography>
      <PolicyParagraph>
        El Titular podrá limitar el uso y divulgación de sus Datos Personales a través del envío de un
        correo electrónico a la dirección: info@axeleratum.com, en donde podrá explicar qué datos y su
        deseo de que los mismos no sean utilizados para recibir comunicados por parte del <b>Responsable</b>.
      </PolicyParagraph>

      <Typography variant="subtitle1">7. MEDIOS PARA EJERCER LOS DERECHOS ARCO.</Typography>
      <PolicyParagraph>
        El <b>Titular</b>, por sí o mediante representante legal debidamente acreditado, tiene reconocidos y podrá
        ejercitar, en todo momento, durante el tiempo que dure el tratamiento de sus <b>Datos Personales</b>, los
        Derechos de Acceso, Rectificación, Cancelación Y Oposición (<b>Derechos ARCO</b>) frente al <b>Responsable</b>.
      </PolicyParagraph>
      <PolicyParagraph>
        Para ello el <b>Titular</b> o su representante legal podrán solicitar un <b>formato</b> de ejercicio de Derechos
        ARCO, a través del envío de un correo electrónico a la dirección: info@axeleratum.com; dicho <b>formato</b> se
        deberá llenar, firmar y enviar por el mismo medio al <b>Responsable</b>, acompañado de la siguiente
        documentación digitalizada, a fin de que pueda llevarse a cabo la autenticación del <b>Titular</b> que
        requiera ejercer sus <b>Derechos ARCO</b>:
        <PolicyList>
          <PolicyListItem>
            Identificación oficial vigente del <b>Titular</b> (Credencial para Votar, Pasaporte, Cartilla del
            Servicio Militar Nacional o Cédula Profesional)
          </PolicyListItem>
          <PolicyListItem>
            En los casos en que el ejercicio de los <b>Derechos ARCO</b> se realice a través del representante legal
            del <b>Titular</b>, además de la acreditación de la identidad de ambos, se deberá entregar el poder
            notarial correspondiente, carta poder firmada ante dos testigos o declaración en comparecencia del <b>Titular</b>.
          </PolicyListItem>
          <PolicyListItem>
            Cuando se quiera ejercer el derecho de rectificación, se tendrá que entregar la documentación que
            acredite el cambio solicitado de acuerdo a los datos personales a rectificar. La respuesta a dicho <b>formato</b> se
            llevará a cabo por el <b>Responsable</b> dentro de los 20 días hábiles contados a partir de la
            fecha en que fue recibido el mismo. El <b>Responsable</b> podrá ampliar este plazo hasta por 20 días
            hábiles más, cuando el caso lo amerite, previa notificación de esto al <b>Titular</b>.
          </PolicyListItem>
        </PolicyList>
      </PolicyParagraph>
      <PolicyParagraph>
        La resolución adoptada por el <b>Responsable</b> será comunicada al <b>Titular</b> a través del correo electrónico
        indicado en el <b>formato</b> de ejercicio de <b>Derechos ARCO</b>.
      </PolicyParagraph>
      <PolicyParagraph>
        En los casos en que el <b>Titular</b> desee revocar su consentimiento otorgado al <b>Responsable</b> respecto del
        tratamiento de los <b>Datos Personales</b> proporcionados, deberá realizar la solicitud correspondiente en
        los mismos términos establecidos en el presente <b>Aviso de Privacidad</b> para ejercer los <b>Derechos ARCO</b>;
        es decir, a través del <b>formato</b> respectivo.
      </PolicyParagraph>
      <PolicyParagraph>
        La entrega de los <b>Datos Personales</b> será gratuita, debiendo cubrir el <b>Titular</b> únicamente los gastos
        justificados de envío o con el costo de reproducción en copias u otros formatos, en los casos que
        lo amerite.
      </PolicyParagraph>
      <PolicyParagraph>
        Para cualquier duda o aclaración respecto del presente proceso favor de enviar un correo a la
        dirección: info@axeleratum.com.
      </PolicyParagraph>

      <Typography variant="subtitle1">8. TRANSFERENCIA Y REMISIÓN DE DATOS.</Typography>
      <PolicyParagraph>
        En cumplimiento de la <b>Ley de Datos Personales</b>, el <b>Responsable</b> le informa que podrá compartir sus <b>Datos Personales</b> a
        terceros, únicamente cuando se trate de los supuestos establecidos en las
        fracciones del artículo 37 de la <b>Ley de Datos Personales</b>.
      </PolicyParagraph>
      <PolicyParagraph>
        En este sentido, el <b>Responsable</b> se compromete a no transferir su información a terceros, salvo por
        las excepciones previstas en el artículo 37 de la <b>Ley de Datos Personales</b>.
      </PolicyParagraph>

      <Typography variant="subtitle1"> 9. CAMBIOS AL AVISO DE PRIVACIDAD.</Typography>
      <PolicyParagraph>
        El <b>Responsable</b> se reserva su derecho a realizar cambios en el presente <b>Aviso de Privacidad</b>, los
        cuales serán dados a conocer a través de la página: www.kivit.com  o aquella que la
        llegara a sustituir.
      </PolicyParagraph>
      <PolicyParagraph>
        Asimismo, se informa al <b>Titular</b> que cuenta con el derecho a oponerse al tratamiento de sus Datos
        Personales en caso de no estar de acuerdo con las modificaciones que pudiera presentar este Aviso
        de Privacidad, para ello, deberá enviar una solicitud utilizando los medios a que se refiere el <b>numeral 7</b> del
        presente <b>Aviso de Privacidad</b>.
      </PolicyParagraph>

      <Typography variant="subtitle1">10. CONSENTIMIENTO.</Typography>
      <PolicyParagraph>
        Previamente a recabar sus <b>Datos Personales</b>, el <b>Responsable</b> pondrá a disposición del <b>Titular</b> el <b>Aviso de Privacidad</b>,
        para que pueda manifestar su aceptación o negativa al tratamiento de los Datos
        para el cumplimiento de las Finalidades mencionadas en este Instrumento. Para los anteriores efectos,
        el consentimiento se obtendrá de las siguientes maneras:
        <PolicyList type="A">
          <PolicyListItem>
            <b><u>Tácitamente</u></b>. - En términos del artículo 13 y 14 del Reglamento de la Ley, es válido como regla
            general el consentimiento tácito para el tratamiento de los Datos, en el momento que el <b>Titular</b>,
            voluntaria y libremente, los proporcionen al <b>Responsable</b> para las Finalidades de este Aviso.
          </PolicyListItem>
          <PolicyListItem>
          <b><u>Expresamente</u></b>. - En términos del artículo 15 y 16 del Reglamento de la Ley, y únicamente en lo que
            respecta al tratamiento de Datos Sensibles, se otorgará a través de los mecanismos digitales y/o
            físicos que para tal efecto establezca el <b>Responsable</b>, para la formalización del presente Aviso y
            en cumplimiento de la Ley.
          </PolicyListItem>
        </PolicyList>
        <Typography style={{ textAlign: "end" }}><b>Version:</b> Marzo, 2022</Typography>
      </PolicyParagraph>
    </>
  )
}



export default PrivacyDataProtection;
