import { Box } from '@material-ui/core'
import React, { FC } from 'react'


const PolicyParagraph: FC = ({ children }) => {
  return (
    <Box fontSize={16} textAlign="justify" marginTop='16px' marginBottom='16px'>
      {children}
    </Box>
  )
}

export default PolicyParagraph
