import React, { Component } from 'react';
import { Switch } from '@material-ui/core';

export class SwitchField extends Component {

  state = {
    checked: this.props.checked
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked})
    }

    if (prevProps.id !== this.props.id && 
      this.props.checked !== this.state.checked ) {
        this.setState({checked: this.props.checked})
    }
  }

  render () {
    const { name, ref, onClick, end } = this.props;
    const { checked } = this.state;

    return (
      <React.Fragment>
        <Switch
          edge={end ? end : false}
          name={ name }
          onChange={ (evt) => {this.setState( {checked: evt.target.checked }); onClick(evt.target.checked)} }
          checked={checked}
          inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
          inputRef={ref}
        />
      </React.Fragment>
    );
  }
}