import React from 'react';
import clsx from 'clsx';
import { TextField, Divider, makeStyles, Grid, Button, InputLabel, Typography } from '@material-ui/core';
import * as Yup from 'yup';

//@ts-ignore
import { useFormik } from 'formik';
import DropzoneFiles from '../../inputs/DropzoneFiles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


interface FormTemplateProps {
  onCancel: () => void;
  onSubmit: (formData: FormTemplateFields) => void;
  title: string;
}

const FormTemplate = (props: FormTemplateProps) => {

  const classes = useStyles();

  const formik = useFormik<FormTemplateFields>({
    initialValues: {
      name: '',
      numberOfStages: 3,
      setFile: null,
    },
    onSubmit: props.onSubmit,
    validationSchema: Yup.object({
      name: Yup.string().required('Campo requerido'),
      numberOfStages: Yup.number().min(1, 'Debe haber al menos una etapa').required('Campo requerido'),
    })
  });
  const {
    onCancel,
    title,
  } = props;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item md={12}>
          <Typography variant="h6" color="primary">{title}</Typography>
          <div style={{ color: '#A8A8A8' }}>
            <p>INSTRUCCIONES </p>
            <dl><dt>Para poder dar de alta un nuevo Set de Documentos siga estos pasos:</dt>
              <dd> 1.- Descargar la plantilla en formato Excel</dd>
              <dd> 2.- Llene el documento con los datos necesarios de acuerdo al formato de la plantilla.</dd>
              <dd> 3.- Asigne un nombre al Set de Documentos e indique el número de etapas necesarias.</dd>
              <dd> 4.- Suba el archivo excel, el sistema validará que el documento no contenga errores.</dd>
            </dl>

            <Divider />
            {/* <div className="text-center" style={{ marginTop: 10, marginBottom: 10 }}>
              <Button
                className="ml-2"
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                href="https://drive.google.com/uc?export=download&id=1jznnX-g-FZxdNSV6-vwY-gPKAb_oJKOz"
              >
                Descargar Plantilla
              </Button>
            </div> */}
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <InputLabel htmlFor="standard-name">Nombre</InputLabel>
                  <TextField
                    className={clsx(classes.margin, classes.textField)}
                    id="name"
                    variant="outlined"
                    label="Nombre"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={!!formik.errors.name && formik.touched.name}
                    helperText={formik.errors.name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="standard-name"># de Etapas</InputLabel>
                  <TextField
                    className={clsx(classes.margin, classes.textField)}
                    id="numberOfStages"
                    variant="outlined"
                    label="# de Etapas"
                    value={formik.values.numberOfStages}
                    onChange={formik.handleChange}
                    error={!!formik.errors.numberOfStages && formik.touched.numberOfStages}
                    helperText={formik.errors.numberOfStages}
                  />
                </Grid>
              </Grid>
            </div>
            <Divider />
            <DropzoneFiles
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              labelBox=" Arrastra el documento aquí para subirlo"
              label="Set de Documentos"
              file={formik.values.setFile}
              onChange={(file) => {
                formik.setFieldValue('setFile', file);
              }}
            />
            <Divider />
            <div style={{ marginTop: 40 }}>
              {/* {flagProgress === 1 ? <LinearProgress variant="determinate" value={progress} /> : null} */}
            </div>
            <div className="text-right" style={{ marginTop: 40 }}>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => onCancel()}
              >
                Cancelar
              </Button>

              <Button
                className="ml-2"
                type="submit"
                variant="contained"
                color="primary"
              >
                Guardar
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}

export default FormTemplate;
