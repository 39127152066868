import create from "zustand";
import { GeneralFormInfoReqBody, GeneralNextFormInfoReqBody } from "../../types/companies-service";
import { defaultGeneralFormBody, defaultGeneralNextBody } from "./defaultForms";

interface GeneralFormState {
    providerLogoId: string;
    idForm: string;
    attachedFileId: string;
    showGeneratorsList: boolean;
    formGeneratedCode: string;
    generalFormBody: GeneralFormInfoReqBody;
    generalNextBody: GeneralNextFormInfoReqBody;
    updateGeneralForm: (form: GeneralFormInfoReqBody) => void;
    updateGeneralNextForm: (form: GeneralNextFormInfoReqBody) => void;
    setIdForm: (id: string) => void;
    setProviderLogoId: (id: string) => void;
    setAttachedFileId: (id: string) => void;
    setformGeneratedCode: (formCode: string) => void;
    cleanForms: () => void;
    setShowGeneratorsList: () => void;
}

const useGeneralFormCompleteState = create<GeneralFormState>((set) => ({
    showGeneratorsList: false,
    providerLogoId: '',
    idForm: '',
    formGeneratedCode: '',
    attachedFileId: '',
    generalFormBody: defaultGeneralFormBody,
    generalNextBody: defaultGeneralNextBody,
    updateGeneralForm: (form: GeneralFormInfoReqBody) =>
        set((state) => ({
            ...state,
            generalFormBody: form,
        })),
    updateGeneralNextForm: (form: GeneralNextFormInfoReqBody) =>
        set((state) => ({
            ...state,
            generalNextBody: form,
        })),
    setIdForm: (id: string) =>
        set((state) => ({
            ...state,
            idForm: id,
        })),
    setProviderLogoId: (id: string) =>
        set((state) => ({
            ...state,
            providerLogoId: id,
        })),
    setAttachedFileId: (id: string) =>
        set((state) => ({
            ...state,
            attachedFileId: id,
        })),
    setformGeneratedCode: (formCode: string) =>
        set((state) => ({
            ...state,
            formGeneratedCode: formCode,
        })),
    cleanForms: () => 
        set((state) => ({
            ...state,
            generalFormBody: defaultGeneralFormBody,
            generalNextBody: defaultGeneralNextBody,
            attachedFileId: '',
        })),
    setShowGeneratorsList: () =>
        set((state) => ({
            ...state,
            showGeneratorsList: !state.showGeneratorsList,
        })), 
}));


export default useGeneralFormCompleteState;