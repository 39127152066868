import { Box, Button, Tooltip } from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import { useHistory } from "react-router";
import { useGetProfileURL } from "../../../core/hooks/useGetProfileURL";
import useGlobalState from "../../../store/globalState";
import ColoredAvatar from "../../general/ColoredAvatar";

interface CompanyLogoProps {
  companyName: string;
  onChangeCompany?: React.MouseEventHandler<HTMLButtonElement>;
  onEditProfile?: React.MouseEventHandler<HTMLButtonElement>;
  profilePictureId?: string;
}

const CompanyLogo = (props: CompanyLogoProps) => {
  const pictureURL = useGetProfileURL(props.profilePictureId);
  const mainGroupCompany = useGlobalState((s) => s.mainGroupCompany);
  const history = useHistory();

  return (
    <Box display="flex" flexDirection="column" bgcolor="white" padding={1} gridGap={10}>
      <Box display="flex" alignItems="center" justifyContent={mainGroupCompany ? "space-between" : "end"}>
        {mainGroupCompany && (
          <Tooltip title="Seleccionar compañía">
            <Button size="small" variant="contained" color="default" onClick={() => history.replace("/dashboard/group-home")}>
              Seleccionar
            </Button>
          </Tooltip>
        )}
        <Button variant="contained" color="primary" size="small" onClick={props.onEditProfile}>
          Editar perfil
        </Button>
      </Box>
      <Box display="flex" justifyContent="center">
        <ColoredAvatar src={pictureURL} backgroundColor={!!pictureURL ? "white" : undefined} size={160}>
          <BusinessIcon style={{ fontSize: 100 }} />
        </ColoredAvatar>
      </Box>
      <Box fontWeight={600} fontSize="1.25rem" color="rgba(57, 61, 63, 0.85)" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        {props.companyName}
      </Box>
    </Box>
  );
};

export default CompanyLogo;
