
import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import AlertInfo from "@material-ui/lab/Alert";

import { BusinessHttp } from '../../../core/http/business.http';

import FormUsers from './FormUsers'
import { CardUser } from './CardUser';
import { useHandleDialog } from '../../../core/hooks/useHandleDialog';
import { FormType } from '../../../pages/ManagementPage';
import ManagementTable from '../management-table/ManagementTable';
import { toBase64 } from '../../../core/utils/utils';
import { RolesService } from '../../../core/services/roles.service';
import Blocker from '../../general/BlockerLoadPage';
import useManagementState from '../../../store/managementState';


interface UserTable {
  user: JSX.Element;
 // projects: string[];
  roles: string[];
}

const Users = () => {
  const { listUsers, createUser, editUser, deleteUser, users, loadingUsers, setAlert  } = useManagementState()
  const [MaterialityDialog, handleShowModal,  handleCloseModal] = useHandleDialog();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [formType, setFormType] = useState<FormType>('create');
  const [rolesSelect, setRolesSelect] = useState<Role[]>([]);
  const [blockerLoad, setBlockerLoad] = useState(false);
  const [companiesSelect, setCompaniesSelect] = useState<any[]>([]);

  const rolesService = RolesService.getInstance();
  const businessHttp = new BusinessHttp();
 // const providerHttp = new ProviderHttp();

  useEffect(() => {
    listUsers();
    getRoles();
    getCompanies();
    //getProjects();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 /**const getProjects = () => {
    //add companyID sessionStorage
    providerHttp.findAllProjects(
      (resp: any[]) => {
        let providers = resp;
        const providerSelectMapped = providers.map((role) => ({ label: role.companySelf.name, value: role.companySelf.id }))

        setProjectsSelect(providerSelectMapped);
      },
      () => { }
    );
  } */ 

  const getRoles = () => {
    rolesService.listRoles().then((res) => {
      //todo-roles
      const dataSelectMapped = res.data.map((role) => ({ label: role.name, value: role.name }))
      
      let names = 'Pagare-Admin Pagare-Representante-Legal  Pagare-Consultor  Pagare-Cliente Pagare-Gestor Pagare-Super-Admin robot registration rep-legal Editor signer Reviewer';
      //let names = 'registration Reader Revisor rep-legal Admin Editor Reviewer signer'
      let result = dataSelectMapped.filter(u => names.includes(u.label));
      let roleAdminAdd = dataSelectMapped.filter(u => u.label === "Admin");

      let array = [];
      for (let i = 0; i < dataSelectMapped.length; i++) {
          let igual=false;
          for (let j = 0; j < result.length && !igual; j++) {
              if(dataSelectMapped[i]['value'] === result[j]['value'] && 
              dataSelectMapped[i]['label'] === result[j]['label']) 
                      igual=true;
          }
          if(!igual)array.push(dataSelectMapped[i]);
      }

      array.push(roleAdminAdd[0]);

      const rolesFinalNames = array.map((role) => ({ 

        label: role.label === "Reader" ? "Lector" :
            role.label === "Admin" ? "Administrador": 
              role.label , 
               value: role.value }));

      setRolesSelect(rolesFinalNames);

    }, (error) => {
      console.error(error);
    });
  }

  const getCompanies = () => {
    businessHttp.findAllBusiness((data: any) => {
      const dataSelectMapped = data.map((business: any) => ({ label: business.name, value: business.id }))
      setCompaniesSelect(dataSelectMapped);
    }, (error: any) => {
      console.error(error)
    })
  }

  const handleSubmit = async ({imageFile, email, ...data}: FormUserFields) => {

    setBlockerLoad(true);

    handleCloseModal();
    
      try {
        if(formType === 'create') {
          const body: CreateUserRequestBody = {
            ...data,
            password: formType === 'create' ? '1234567890' : undefined,
            email,
            username: email,
            image: imageFile?.type,
            image64: imageFile ? await toBase64(imageFile) : undefined,
            status: true,
            rfc: "" //TODO: temporal
          };
          await createUser(body);
        }
        else if(formType === 'edit') {
          const body: EditUserRequestBody = {
            ...data,
            id: selectedUser!.id
          }
          await editUser(body);
        }
      } catch{

      }finally{
        setBlockerLoad(false);
      }
  }

  const handleOpenEditUserForm = (user: User) => {
    setSelectedUser(user);
    setFormType('edit');
    handleShowModal();
  }

  const handleOpenCreateUserForm = () => {
    setSelectedUser(undefined);
    setFormType('create');
    handleShowModal();
  }

  const handleWarningDeleteUser = (user: User) => {
    setSelectedUser(user);
    setAlert({
      open: true,
      title: '¿Seguro que desea borrar el usuario seleccionado?',
      onConfirm:() => handleDeleteUser(user),
      addCancelButton: true,
      holdOnConfirm: true
    })
  }

  const handleDeleteUser = (user: User) => {
    deleteUser(user.id);
  }

  const mapUsetsToTable = (users: User[]) => {
    return users.map<UserTable>((user) => ({
      user: <CardUser user={user}/>,
      roles: user.roles ? user.roles : []
    }));
  }

  return (
    <>
      <Grid container>
      
        <Grid item xs={12}>
          <ManagementTable
            addButtonOnTop
            addButtonProps={true ? {
              label: "Nuevo Usuario",
              onAdd: handleOpenCreateUserForm
            } : null}
            tableItemsValues={users}
            tableItems={mapUsetsToTable}
            header={[
              {
                key: 'user',
                title: 'Usuario'
              },
              {
                key: 'roles',
                title: 'Roles',
              },
            ]}
            menuItems={[
              {
                label: 'Editar',
                action: handleOpenEditUserForm
              },
              {
                label: 'Borrar',
                action: handleWarningDeleteUser
              }
            ]}
          />
        </Grid>
        {((!loadingUsers && (users?.length === 0))) && (
          <Grid container style={{ justifyContent: 'center', width: '100%' }}>
            <AlertInfo severity="info" >No hay información!</AlertInfo>
          </Grid>
        )}

        {loadingUsers && (
          <Grid container style={{ justifyContent: 'center', width: '100%' }}>
            <AlertInfo severity="info" >Cargando usuarios...</AlertInfo>
          </Grid>
        )}
      </Grid>

      { blockerLoad && (
          <Blocker/>
      )}
        
      <MaterialityDialog>
        <FormUsers
          formType={formType}
          companies={companiesSelect}
          onCancel={handleCloseModal}
          roles={rolesSelect}
          onSubmit={handleSubmit}
          typeForm={formType}
          userSelected={selectedUser!}
         // projects={projectsSelect}
          initialValues={selectedUser ? {
            companies: selectedUser.companies,
            email: selectedUser.email,
            name: selectedUser.name,
            lastName: selectedUser.lastName,
            roles: selectedUser.roles
          } : undefined }
        />
      </MaterialityDialog>
    </>
  );
}

export default Users;