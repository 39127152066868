import React, { FC, VFC } from "react";
import { saveAs } from "file-saver";
import { makeStyles } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

import MatFisButton from "../MatFisButton";
//import { useGetDocument } from '../../hooks/useGetDocument';
import WatchDocumentDrawer from "./WatchDocumentDrawer";
import { DocumentValidationStatus } from "materialidad-outsourcing/dist/types";
import { useGetDocument } from "../../hooks/useGetDocument";
import { ExpedienteDocument } from "../../../../types/expediente.types";

interface WatchDocumentProps {
  name: string;
  document: ExpedienteDocument;
  documentStatus: string;
  onValidateStatus: (status: DocumentValidationStatus) => void;
  onReturn: () => void;
}

const WatchDocument: FC<WatchDocumentProps> = (props) => {
  const classes = useStyles();
  const { fileName, fileBlob, fileMIME, fileStatus } = useGetDocument(props.document?.fileId, true);

  return (
    <div className={classes.root}>
      <WatchDocumentDrawer {...props} />

      <main className={classes.content}>
        {fileBlob ? <DocumentRender mimeType={fileMIME} fileBlob={fileBlob} fileName={fileName} /> : fileStatus}
      </main>
    </div>
  );
};

interface DocumentRenderProps {
  mimeType: string | false;
  fileBlob: Blob;
  fileName: string;
}

const DocumentRender: VFC<DocumentRenderProps> = (props) => {
  const fileurl = URL.createObjectURL(props.fileBlob);

  const saveFile = () => saveAs(props.fileBlob, props.fileName);

  if (props.mimeType === "application/pdf") {
    return (
      <div style={{ position: "fixed", height: "100vh", right: 0, top: 0, width: "71vw" }}>
        <embed src={`${fileurl}#&scrollbar=0&view=Fit`} type="application/pdf" width="100%" height="100%" />
      </div>
    );
  } else if (typeof props.mimeType === "string" && props.mimeType.includes("image")) {
    return <img src={fileurl} alt="" width="90%" />;
  } else {
    return (
      <MatFisButton startIcon={<GetAppIcon />} variant="contained" color="primary" onClick={saveFile}>
        Guardar {props.fileName !== "" ? props.fileName : "Documento"}
      </MatFisButton>
    );
  }
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    minHeight: "100%",
  },
  // necessary for content to be below app bar
  content: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default WatchDocument;
