import React, { useEffect, useState } from "react";
import { Grid, IconButton, InputAdornment, InputLabel, Menu, MenuItem, Select, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import SortIcon from "@material-ui/icons/Sort";

interface UserControlsProps {
  /** Event executed when the user types on the search input. */
  onSearch: (query: string, sortValue: string) => void;
  /** What the search input wull search: users, teams, roles or companies.
   */
  searchTitle: "Equipos" | "Usuarios" | "Roles" | "Empresas" | "Servicios" | "Configuración";
}

/** Component that shows new user button, search user input and filter user button.  */
const UserControls = (props: UserControlsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); //Used to set where the menu will be rendered
  const [querySearch, setQuerySearch] = useState("");
  const [selectValues, setSelectValues] = useState({
    valueSelectSort: "a-z",
  });

  /** Function that handles when the user change the value on any select box of the filter/sort menu. */
  const handleChangeSelect = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    event.target.name && setSelectValues({ ...selectValues, [event.target.name]: event.target.value });
  };

  /** Function that opens the filter menu when the user clicks filter button. */
  const handleSort = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  useEffect(() => {
    props.onSearch(querySearch, selectValues.valueSelectSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValues.valueSelectSort, querySearch]);

  return (
    <>
      {/* Search input and filter icon */}
      {props.searchTitle !== 'Configuración' &&
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs>
            <TextField
              margin="dense"
              placeholder={`Buscar ${props.searchTitle.toLocaleLowerCase()}`}
              variant="outlined"
              fullWidth
              onChange={(e) => setQuerySearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item>
            <IconButton onClick={handleSort} style={{ backgroundColor: "#2347a5" }}>
              <SortIcon htmlColor="white" />
            </IconButton>
          </Grid>
        </Grid>
      }

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <Grid container direction="column" className="menuFilterContent" style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Grid item>
            <InputLabel>Ordenar</InputLabel>
            <Select onChange={handleChangeSelect} value={selectValues.valueSelectSort} name="valueSelectSort" style={{ width: "100%" }}>
              <MenuItem value="a-z">A a Z</MenuItem>
              <MenuItem value="z-a">Z a A</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};

export default UserControls;
