import {
  Button,
  Switch,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  TextField,
  InputAdornment,
  CircularProgress
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import usePagination from "../../../core/hooks/usePagination";
import useGlobalState from "../../../store/globalState";
import useSettingsState from "../../../store/settingsState";
import { NotApplicableUser, User } from "../../../core/models/settings.models";
import Search from "@material-ui/icons/Search";

const TableRowComponent = ({ user, onChange }: { user: User, onChange: (userId: string) => any }) => {
  return (
    <TableRow hover>
      <TableCell>
        {`${user.name} ${user.lastName}`}
      </TableCell>
      <TableCell>
        {user.email}
      </TableCell>
      <TableCell>
        {user.external ? 'Externo' : 'Interno'}
      </TableCell>
      <TableCell align="center">
        <Switch
          id={'sw-policy-otp' + user.id}
          size="medium"
          checked={user.isActive}
          onChange={() => onChange(user.id)}
        />
      </TableCell>
    </TableRow>
  );
};

const UsersOtpTable = ({
  users,
  onChange,
  currentPage,
  maxPages,
  setPage,
}: {
  users: User[];
  onChange: (userId: string) => any;
  currentPage: number;
  maxPages: number;
  setPage: (page: number) => any;
}) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <label className={classes.labelCol} htmlFor="name">Nombre</label>
            </TableCell>
            <TableCell>
              <label className={classes.labelCol} htmlFor="email">E-mail</label>
            </TableCell>
            <TableCell>
              <label className={classes.labelCol} htmlFor="userType">Tipo de usuario</label>
            </TableCell>
            <TableCell align="center">
              <label className={classes.labelCol} htmlFor="id">Aplica OTP</label>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((user, index) => (
            <TableRowComponent key={index} user={user} onChange={() => onChange(user.id)} />
          ))}
        </TableBody>
      </Table>
      {users?.length ? (
        <Box py={3} display="flex" justifyContent="center">
          <Pagination
            count={maxPages}
            page={currentPage}
            onChange={(event, page) => setPage(page)}
            color="primary"
          />
        </Box>
      ) : (
        <Box paddingY={3}>
          <Typography align="center">No se encontraron elementos</Typography>
        </Box>
      )}
    </TableContainer>
  );
};

const UsersOtpSettings = () => {
  const classes = useStyles();
  const { alertInfo, setAlertInfo } = useGlobalState();
  const [loading, setLoading] = useState(false);
  const { getCompanyUsers, getNotApplicableUsers, saveNotApplicableUsers } = useSettingsState();
  const [users, setUsers] = useState<User[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const filteredResults = useMemo(() => {
    return users.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (item.external ? 'Externo' : 'Interno').toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, users]);
  const { currentPage, currentResutls, maxPages, setPage } = usePagination({ data: filteredResults, itemsPerPage: 6 });
  const [keyComponent, setKeyComponent] = useState(Math.random());

  useEffect(() => {
    setLoading(true)
    getCompanyUsers().then((result) => {
      const usersData: User[] = result;
      getNotApplicableUsers().then((data) => {
        setLoading(false)
        const notApplicableUsers: NotApplicableUser[] = data.respuesta;
        usersData?.forEach((item) => {
          item.isActive = !notApplicableUsers.some((usr) => item.id === usr.userId);
        });
        setUsers(usersData);
      });
    });
  }, []);

  const handleUsersOtp = (userId: string) => {
    const updatedUsers = users.map((usr) => {
      if (usr.id === userId) {
        usr.isActive = !usr.isActive;
      }
      return usr;
    });
    setUsers(updatedUsers);
    setKeyComponent(Math.random());
  };

  const handleSaveUsers = () => {
    const ids = users.filter((usr) => !usr.isActive).map((usr) => usr.id);
    saveNotApplicableUsers(ids).then((result) => {
      result?.codigo === 0 ? setAlertInfo({
        message: result.mensaje,
        type: "success",
      }) : setAlertInfo({
        message: result.mensaje,
        type: "error",
      })
    });
  };

  return (
    <Container maxWidth="lg">
      <Box mb={3}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder='Buscar'
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {
        loading ? (
          <div className={classes.loadingProgress}>
            <CircularProgress color="primary" />
          </div>
        ) : <UsersOtpTable
          key={keyComponent}
          users={currentResutls}
          currentPage={currentPage}
          maxPages={maxPages}
          onChange={handleUsersOtp}
          setPage={setPage}
        />
      }

      <Box display="flex" justifyContent="center" mt={2} mb={2}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSaveUsers}
          style={{ fontWeight: "medium", padding: "0.5rem 3rem" }}
          disabled={false}
        >
          Guardar
        </Button>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  labelCol: {
    color: theme.palette.primary.main,
    display: 'block',
  },
  loadingProgress: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default UsersOtpSettings;
