import { useState, useEffect, FC, useRef } from "react";
import { useHistory } from "react-router";

import SignIn from "../components/onboarding/SignIn";
import RestorePassword from "../components/onboarding/RestorePassword";
import UpdateAccount from "../components/onboarding/UpdateAccount";
import { UserService } from "../core/services/user.service";
import OnboardingTemplate from "../components/onboarding/OnboardingTemplate";
import useGlobalState from "../store/globalState";
import useSessionState from "../store/sessionState";
import useDashboardUsersState from "../store/dashboardUsersState";
import usePlanState from "../store/planState";
import Otp from "../components/onboarding/Otp";
import useCustomToast from "../core/hooks/useCustomToast";
import { AuthService } from "../core/services";
const authService = new AuthService();

type FormType = "sign-in" | "update-account" | "restore-password" | "otp";

interface OnboardingProps {
  initialSelectedForm: FormType;
}

const Onboarding: FC<OnboardingProps> = ({ children, initialSelectedForm }) => {
  const {
    setAuthPolicyData,
    activeSession,
    sendEmailPassword,
    closeAlert,
    setUpdatedPassword,
    loggedIn,
    updatedPassword,
    mustUpdateCompany,
    newUserInvited,
    onLogin
  } = useSessionState();
  const { invitedAs, plan } = usePlanState();
  const hasMounted = useRef(false);
  const toast = useCustomToast();
  const { alertInfo, userInfo } = useGlobalState();
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [selectedForm, setSelectedForm] = useState<FormType>(
    initialSelectedForm ?? "sign-in"
  );
  const history = useHistory();
  const { showRepseService, showGeneratorService } = useDashboardUsersState();

  function handleRouting(ignoreUpdatedPassword = false) {
    if (!ignoreUpdatedPassword && !updatedPassword) {
      return history.replace("/update_account", { isAccessible: true });
    } else if (mustUpdateCompany) {
      return history.replace("/pre_instructions", { isAccessible: true });
    } else if ( showRepseService || showGeneratorService ) {
      return history.replace( "/service-selection", { isAccesible: true } );
    } else {
      return history.replace("/dashboard");
    }
  }

  useEffect(() => {
    if (
      loggedIn && ( !showRepseService || !showGeneratorService ) &&
      (selectedForm !== "update-account" ||
        (updatedPassword && !newUserInvited))
    ) {
      history.replace("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasMounted.current && loggedIn) {
      closeAlert();
      handleRouting();
    }
    hasMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  useEffect(() => {
    if (!!alertInfo?.message) closeAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedForm]);

  const handleUpdateAccount = async (
    password: string,
    createType: "invited" | "new"
  ) => {
    //edit user me
    const userBody = {
      ...userInfo,
      updatedPassword:true,
      password,
    };

    const res = await UserService.getInstance().acceptAlta(userBody);
    if ( (res as any)?.codigo === 0 ) {
      setUpdatedPassword(true);
      handleRouting(true);
    }
    // if (res?.status === "ok") {
    //   setUpdatedPassword(true);
    //   handleRouting(true);
    // }
  };

  console.log(" ## KIVIT-18.09 ##")
  const handleLogin = async (email: string, password:string) => {
    try {
      setLoadingButton(true);
      await authService.ipfsToken(email, password).then((response) => {
        if(response?.passwordPolicyList) {
          setAuthPolicyData( response );
          setSelectedForm('otp')
        } else {
          onLogin(response);
        }
      })
    } catch (error) {
      setLoadingButton(false);
      toast.error("Correo y/o contraseña incorrectos.");
    }
  }

  const renderForm = () => {
    switch (selectedForm) {
      case "restore-password":
        return (
          <RestorePassword
            handleSubmit={(form) => sendEmailPassword(form.email)}
            onReturnSignIn={() => setSelectedForm("sign-in")}
          />
        );
      case "update-account":
        return (
          <UpdateAccount alert={alertInfo} onSubmit={handleUpdateAccount} />
        );
      case "sign-in":
        return (
          <SignIn
            loadingButton={loadingButton}
            handleSubmit={(form) => {
              handleLogin(form.email, form.password);
            }}
            alert={alertInfo}
            handleSession={(ev) => {
              activeSession(ev.target.checked);
            }}
            onRecoverPassword={() => setSelectedForm("restore-password")}
          />
        );
      case "otp": 
      return <Otp onReturn={() => setSelectedForm("sign-in")} />
      default:
        return <></>
    }
  };

  return (
    <OnboardingTemplate hasWelcomeText={selectedForm === "sign-in"}>
      {children ? children : renderForm()}
    </OnboardingTemplate>
  );
};

export default Onboarding;