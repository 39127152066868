import { authHeader, axiosGeneradoresRequest } from "../../configs/axios.config";

export class GeneradorClientServices {
  private static instance: GeneradorClientServices;

  private constructor() {}

  public static getInstance(): GeneradorClientServices {
    if (!GeneradorClientServices.instance) {
        GeneradorClientServices.instance = new GeneradorClientServices();
    }
    return GeneradorClientServices.instance;
  }

  async getAllGeneradores(idCompany: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get<any>(`/generador/idCompany/${idCompany}/all`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }
  
  async getAllLiteGeneradores(idCompany: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get<any>(`/generador/idCompany/${idCompany}/allLite`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

}