import { Container, makeStyles } from "@material-ui/core";

//import "axeleratum-sgc-frontend-library/styles/index.css";

import HomeIcon from "@material-ui/icons/Home";
import DashboardRouting from "../../core/routes/dashboard-routing.routes";

import VaultIcon from "../icons/VaultIcon";
import AdminIcon from "../icons/AdminIcon";
import TopBar from "../main-menu/TopBar";
import { useHistory } from "react-router";
import Alert from "../general/Alert";
import useGlobalState from "../../store/globalState";
import useSessionState from "../../store/sessionState";
import usePlanState from "../../store/planState";
import Footer from "../main-menu/Footer";

const Home = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { userInfo, resetState, alertInfo } = useGlobalState();
  const { logout, closeAlert } = useSessionState();
  const { canAccessVault, canAccesAdministation } = usePlanState();

  const handleLogout = () => {
    resetState();
    logout();
    history.push("/");
  };

  const menuItems = [
    {
      text: "Inicio",
      name: "dashboard",
      icon: HomeIcon,
      link: "/home",
      show: true,
    },
    {
      text: "Bóveda",
      name: "boveda",
      icon: VaultIcon,
      link: "/vault",
      show: canAccessVault,
    },
    {
      text: "Administración",
      name: "admin",
      icon: AdminIcon,
      link: "/management",
      show: canAccesAdministation,
    },
    // {
    //   text: "Tablero de riesgos",
    //   name: "tableroRiesgos",
    //   icon: StatsIcon,
    //   link: "/riesgos",
    //   show: canAccessRiesgos,
    // },
  ];

  const filteredMenuItems = userInfo?.isProvider ? [menuItems[0]] : menuItems;
  return (
    <div className={classes.home}>
      <TopBar menuItems={filteredMenuItems} onLogout={handleLogout} username={userInfo?.name || userInfo?.username} />
      <Container className={classes.mainContainer} classes={{ maxWidthLg: classes.homeLg }} maxWidth="lg">
        <Alert open={!!alertInfo?.message} title={alertInfo?.message} type={alertInfo?.type} onConfirm={closeAlert} />
        <DashboardRouting {...props} />
      </Container>
      <Footer />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginTop: "20px",
    paddingLeft: "50px",
    paddingRight: "50px",
    paddingBottom: 140,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  home: {
    backgroundColor: "#F4F4F4",
    display: "flex",
    height: "fit-content",
    minHeight: "100vh",
    paddingTop: 65,
    position: "relative",
  },
  homeLg: {
    maxWidth: "none !important",
  },
}));

export default Home;
