import { Route, useRouteMatch } from "react-router";
import Clients from "../../pages/ClientsPage";
import DashboardHomePage from "../../pages/DashboardHomePage";
import DashboardHomeClient from "../../pages/generadores/DashboardHomeClient";
import FormCreateNewUser from "../../components/dashboard-home/dashboard-users/users-dialog-contents/FormCreateNewUser";
import { CompaniesService } from "../services/companies.service";
import UserInviteAlert from "../../components/dashboard-home/dashboard-users/users-dialog-contents/UserInviteAlert";
import { Dialog } from "@material-ui/core";
import FormNewUser from "../../components/dashboard-home/dashboard-users/users-dialog-contents/FormNewUser";
import { FormEntityType } from "../../types/global";
import Providers from "../../pages/ProvidersPage";
import useGlobalState from "../../store/globalState";
import useDashboardUsersState from "../../store/dashboardUsersState";
import useDashboardState from "../../store/dashboardState";
import StagePage from "../../pages/StagePage";
import MultiStagePage from "../../pages/MultiStagePage";
import Generators from "../../components/dashboard/FormsGenerator/Generators";
import usePlanState from "../../store/planState";
import DashboardHomeProvider from "../../pages/generadores/DashboardHomeProvider";
import useSessionState from "../../store/sessionState";
//import PageNotFound from "../../components/onboarding/ValidatePagePath";

const DashboardHomeRouting = () => {
  const match = useRouteMatch();
  const { invited } = usePlanState();
  const { formType, dialogForm } = useDashboardState();
  const {
    handleOpenUserDialog,
    handleCloseUserDialog,
    isNewUser,
    isSubmited,
    clients,
    providers,
    setClients,
    setProviders,
    setIsLoadingCollaborator,
    activatedGenerator,
  } = useDashboardUsersState();
  const { mainCompany, setAlertInfo, getMainCompany, typeService } = useGlobalState();

  const findAllProviders = async () => {
    setIsLoadingCollaborator(true);
    if (!mainCompany?.id) return;
    const data: any = await CompaniesService.getInstance().getCompanyProviders(mainCompany?.id);
    let cleanedCompanies;
    if (typeService === "generators") {
      console.log(typeService);
      cleanedCompanies = data?.providers?.filter((comp: any) => comp.companySelf.generadores);
    } else {
      cleanedCompanies = data?.providers?.filter((comp: any) => !comp.companySelf.generadores);
    }

    setProviders([...cleanedCompanies, ...data?.pendingCollaborations]);
    setIsLoadingCollaborator(false);
  };

  const findAllClients = async () => {
    setIsLoadingCollaborator(true);
    if (!mainCompany?.id) return;
    const data = await CompaniesService.getInstance().getCompanyClients(mainCompany?.id);

    setClients([...data?.clients, ...data?.pendingCollaborations]);
    setIsLoadingCollaborator(false);
  };

  const handleNewUserInvited = async (type: "provider" | "user") => {
    if (type === "provider") {
      findAllProviders();
    } else {
      findAllClients();
    }
    handleOpenUserDialog({
      formType: type,
      isNewUser: false,
      isSubmited: true,
    });
  };

  const onNewCollaborator = async (selectedCompanyId: string, type: FormEntityType) => {
    const providerId = type === "user" ? mainCompany?.id : selectedCompanyId;
    const clientId = type === "provider" ? mainCompany?.id : selectedCompanyId;

    if (!mainCompany?.id) return;
    handleCloseUserDialog();

    setIsLoadingCollaborator(true);
    try {
      await CompaniesService.getInstance().addCollaboratorV2(clientId, providerId, activatedGenerator);
      if (type === "provider") {
        await findAllProviders();
      } else {
        await findAllClients();
      }
      getMainCompany();
    } catch (error) {
      setAlertInfo({
        message: "Hubo un problema al enviar la invitación",
        type: "error",
      });
    } finally {
      setIsLoadingCollaborator(false);
    }
  };

  return (
    <>
      <Route exact path={`${match.path}/`}>
        {/* <DashboardHomePage /> */}
        {typeService === 'generators' ? (invited ? <DashboardHomeProvider /> : <DashboardHomeClient />) : <DashboardHomePage />}
      </Route>
      <Route path={`${match.path}/clients`} exact>
        <Clients />
      </Route>
      <Route path={`${match.path}/providers`} exact>
        <Providers />
      </Route>
      <Route path={`${match.path}/stage/:stageId`} exact>
        <StagePage />
      </Route>
      {/* <Route path={`${match.path}/stages/:serviceId`}>
        <MultiStagePage />
      </Route> */}
      <Route path={`${match.path}/clients/stages/:serviceId`} exact>
        <MultiStagePage />
      </Route>
      <Route path={`${match.path}/providers/stages/:serviceId`} exact>
        <MultiStagePage />
      </Route>
      <Route path={`${match.path}/clients/stages/generators/:serviceId`} exact>
        <Generators />
      </Route>
      <Route path={`${match.path}/providers/stages/generators/:serviceId`} exact>
        <Generators />
      </Route>
      {/**<Route path={`${match.path}/*`} >
        <PageNotFound />
      </Route>
       */}


      {/* MODALES */}
      <Dialog open={dialogForm} fullWidth maxWidth="md">
        {/* -------------------------------------------------------------------------- */
        /*                                  PROVIDER                                  */
        /* -------------------------------------------------------------------------- */}
        {formType === "provider" && !isNewUser && !isSubmited && (
          <FormNewUser
            //initialValues={userToUpdate ? userToUpdate.formValues : null}
            //@ts-ignore
            users={providers}
            //submitActions={userToUpdate ? editProvider : saveProvider}
            onCancel={handleCloseUserDialog}
            formType={formType}
            onSubmit={onNewCollaborator}
            onAddNewUser={() => handleOpenUserDialog({ formType: "provider", isNewUser: true })}
          />
        )}

        {formType === "provider" && isNewUser && !isSubmited && (
          <FormCreateNewUser
            //@ts-ignore
            users={providers}
            //submitActions={saveProvider}
            onCancel={handleCloseUserDialog}
            formType={formType}
            onSubmit={() => handleNewUserInvited("provider")} //TODO: handle error
          />
        )}

        {formType === "provider" && isSubmited && (
          <UserInviteAlert onCancel={handleCloseUserDialog} formType={formType} onSubmit={handleCloseUserDialog} />
        )}

        {/* -------------------------------------------------------------------------- */
        /*                                    CLIENT                                    */
        /* -------------------------------------------------------------------------- */}

        {formType === "user" && !isNewUser && !isSubmited && (
          <FormNewUser
            //initialValues={userToUpdate ? userToUpdate.formValues : null}
            //@ts-ignore
            users={clients}
            //submitActions={userToUpdate ? editProvider : saveProvider}
            onCancel={handleCloseUserDialog}
            formType={formType}
            onSubmit={onNewCollaborator}
            onAddNewUser={() => handleOpenUserDialog({ formType: "user", isNewUser: true })}
          />
        )}

        {formType === "user" && isNewUser && !isSubmited && (
          <FormCreateNewUser
            //@ts-ignore
            users={clients}
            //submitActions={saveProvider}
            onCancel={handleCloseUserDialog}
            formType={formType}
            onSubmit={() => handleNewUserInvited("user")}
          />
        )}

        {formType === "user" && isSubmited && (
          <UserInviteAlert onCancel={handleCloseUserDialog} formType={formType} onSubmit={handleCloseUserDialog} />
        )}
      </Dialog>
    </>
  );
};

export default DashboardHomeRouting;