import React, { useState, useEffect } from 'react';
import { ListItem, ListItemText, ListItemSecondaryAction, Divider, Collapse, List } from '@material-ui/core';
import { SwitchField } from './switch-field';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PermissionItem from './permission-item';

const PermissionGroupItem = props => {
  const { classes, roleSelected, permissionGroup, permissionsSelected } = props;
  const [collapsePermssions, setCollapsePermssions] = useState(false)
  const [checkedSwitch, setCheckedSwitch] = useState(false)
  const [itemsChecked, setItemsChecked] = useState([])

  const currentPermissionGroup = permissionsSelected.find(permissionselect => permissionselect.id === props.permissionGroup.value)

  useEffect(() => {
    const checkItems = currentPermissionGroup ? currentPermissionGroup.permissions.filter(item => permissionGroup.permissions.includes(item) !== undefined) : []
    const checkedDefault = currentPermissionGroup ? checkItems.length > 0 : false
    setCheckedSwitch(checkedDefault)

    if (checkedDefault) {
      setCollapsePermssions(true)
    } else {
      setCollapsePermssions(false)
    }

    setItemsChecked(checkItems.map(item => ({id: item.id}) ))
  }, [currentPermissionGroup, permissionGroup.permissions])

  useEffect(() => {
    if (itemsChecked.length === 0) {
      setCheckedSwitch(false)
    } else {
      setCheckedSwitch(true)
      setCollapsePermssions(true)
    }
  }, [itemsChecked])
  
  function handleCheckMain(item) {
    if (!item.value) {
      const items = itemsChecked.filter(element => element.id !== item.id)
      setItemsChecked(items)
    } else {
      setItemsChecked(itemsChecked => [...itemsChecked, {id: item.id}])
    }
  }

  function handleClickMainSwitch(value) {
    if (!value) {
      setItemsChecked([])
    } else {
      const permissions = permissionGroup.permissions.map((permission) => {
        return {id: permission.id}
      });

      setItemsChecked([...permissions])
    }
  }
  
  return (
    <React.Fragment>
      <ListItem classes={{gutters: classes.gutters}} disabled alignItems="flex-start" >
        <ListItemText primary={permissionGroup.label} 
          secondary={permissionGroup.description} 
          classes={{root: classes.listItemText}}
        />
        <ListItemSecondaryAction>
          <SwitchField
            id={`${permissionGroup.value}-${roleSelected ? roleSelected.value : ''}`}
            checked={ checkedSwitch }
            name={`permission-group`}
            onClick={ (value) => handleClickMainSwitch(value) }
            end={false}
          />
          {collapsePermssions 
            ? <ExpandLess onClick={() => setCollapsePermssions(false)} style={{cursor: 'pointer'}} /> 
            : <ExpandMore onClick={() => setCollapsePermssions(true)} style={{cursor: 'pointer'}} />
          }
        </ListItemSecondaryAction>
      </ListItem>
      <Divider classes={{root: classes.divider}} component="li" />
      <Collapse in={collapsePermssions} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {permissionGroup.permissions && permissionGroup.permissions.map((permission, key) => {            
            return(
              <PermissionItem
                permissionGroup={currentPermissionGroup}
                permission={permission}
                key={key}
                classes={classes}
                permissionsSelected={itemsChecked}
                onClickSwitch={(value) => handleCheckMain(value)}
              />
            )})
          }
        </List>
      </Collapse>
    </React.Fragment>
  )
}

export default PermissionGroupItem