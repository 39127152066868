import React from "react";
import { Route, Switch } from "react-router";

import Onboarding from "../../pages/OnboardingPage";
import Home from "../../components/dashboard/Home";
import { PrivateRoute } from "../../components/PrivateRoute";
import Instructions from "../../components/onboarding/Instructions";
//import NewUserResgitered from "../../pages/FinishCollaboratorRegisterPage";
import NewUserResgitered from "../../pages/generadores/FinishCollaboratorRegisterPage";
import HomeGenerator from "../../components/dashboard/FormsGenerator/HomeGenerator";
import FormSelector from "../../components/dashboard/FormsGenerator/FormSelector";
import PlansPage from "../../pages/PlansPage";
import NewPassword from "../../components/onboarding/NewPassword";
import AdditionalUserDataForm from "../../components/onboarding/AdditionalUserDataForm";
import CompanyServiceSelection from "../../components/onboarding/CompanyServiceSelection";
//import PageNotFound from "../../components/onboarding/ValidatePagePath";
const Routing = () => {
  return (
    <Switch>
      {/* <Route path="/" exact component={(routerProps) => <Onboarding selectedForm="sign-in" {...routerProps}/>} /> */}
      <Route path="/" exact component={Onboarding} />
      <Route path="/additional-data" exact component={AdditionalUserDataForm} />
      <Route path="/service-selection" exact component={CompanyServiceSelection} />
      <PrivateRoute path="/update_account" exact >
        <Onboarding initialSelectedForm='update-account' />
      </PrivateRoute>
      <PrivateRoute path="/pre_instructions" exact component={NewUserResgitered} />
      <PrivateRoute path="/dashboard" component={Home} />
      <PrivateRoute path="/instructions" component={Instructions} />
      <PrivateRoute path="/payment" component={PlansPage} />
      <Route path="/resetPassword" component={NewPassword} />
      <Route path="/data/generators" component={HomeGenerator} />
      <Route path="/generators/forms" component={FormSelector} />
      {/**      <Route path="/*" component={PageNotFound} />
 */}
    </Switch>
  );
};

export default Routing;
