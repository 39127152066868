import React, { useState, VFC } from "react";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import mime from "mime-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import RenderTextField from "./inputs/RenderTextField";
import RenderFileField from "./inputs/RenderFileField";
import RenderSelect from "./inputs/RenderSelect";
import MatFisButton from "./MatFisButton";
import { SelectOption } from "vault-axeleratum/dist/types";
import { ContractForm } from "materialidad-outsourcing/dist/types/forms";
import { toBase64 } from "../utils";
import { DocumentsService } from "../../../core/services/documets.service";
import { NTPService } from "../../../core/services/NTPService.service";
import { ColumnCardDocument } from "materialidad-outsourcing/dist/types";
import axios from "axios";
import useToast from "../../../core/hooks/useToast";
import useUiState from "../../../store/uiState";
import { AddContractReqBody } from "../../../types/ntp.types";
import useGlobalState from "../../../store/globalState";

interface ContractFormDialogProps {
  open: boolean;
  onClose?: () => void;
  //onUploadFile: (contractForm: ContractForm) => void;
  serviceId: string;
  refresh: () => any;
}

const ContractFormDialog: VFC<ContractFormDialogProps> = (props) => {
  const [nowDate] = useState(new Date().toISOString().split("T")[0])
  const classes = useStyles();
  const { Toast, showToast } = useToast();
  const setShowGenericLoader = useUiState((s) => s.setShowGenericLoader);
  const { typeService } = useGlobalState(); 

  const formik = useFormik<ContractForm>({
    validateOnMount: true,
    initialValues: {
      title: "",
      currency: "MXN",
      expiration: "",
      sendCDFIDate: nowDate,
      CDFIFreq: "Quincenal",
      employeesCount: 1
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Campo requerido"),
      contract: Yup.mixed()
        .required("Campo requerido")
        .test({
          name: "file-type",
          message: `El documento no es de tipo PDF`,
          test: (file) => file && mime.lookup(file?.name) === mime.lookup("pdf"),
        }),
      amount: Yup.number().min(0, "Debe ser un número positivo").required("Campo requerido"),
      //expiration: Yup.date().required("Campo requerido"),
      employeesCount: Yup.number().min(0, "Debe ser un número positivo").integer("Debe ser un entero").required("Campo requerido"),
      //sendCDFIDate: Yup.date().required("Campo requerido"),
    }),
    onSubmit: handleUpload,
  });

  const handleClose = () => {
    formik.setFieldValue("contract", undefined);
    if (props.onClose) props.onClose();
  };

  const handleChangeFile = (file?: File) => {
    formik.setFieldTouched("contract", true);
    formik.setFieldValue("contract", file);
  };

  async function handleUpload(contractForm: ContractForm) {
    try {
      const companyId = sessionStorage.getItem("companyId");
      setShowGenericLoader(true);
  
      let response;
      if (typeService === 'generators') {
        response = await uploadGeneradorContract(contractForm, companyId, props.serviceId);
      } else {
        response = await uploadContract(contractForm, companyId, props.serviceId);
      }
  
      if (response.success) {
    
        showToast("Contrato subido con éxito", "success");
        setTimeout(() => {
          props.onClose();
          props.refresh();
        }, 2000);

      } else {
        showToast(response.message, "error");
      }
    } catch (error) {
      showToast("Ocurrió un error al subir el contrato", "error");
    } finally {
      setShowGenericLoader(false);
    }
  }
  

  async function uploadContract(contractForm: ContractForm, companyId: string, serviceId: string, folderId?: string) {
    if (!contractForm.contract) {
      return { success: false, message: "Debe seleccionar un documento." };
    }
  
    const convertedDoc = (await toBase64(contractForm.contract)) as string;
    const filename = contractForm.contract.name.split(".") ?? ["", ""];
    const extension = `.${filename.pop()}` ?? "";
    const docBody: CreateDocumentReqBody = {
      companyId,
      description: "",
      extension,
      name: contractForm.title ?? filename.join(),
      fileBase64: convertedDoc,
      status: true,
      folderId: folderId,
    };
  
    try {
      const vaultDoc = await DocumentsService.getInstance().createDocument(docBody);
  
      if (!vaultDoc.id) {
        return { success: false, message: "Hubo un error al intentar subir el documento del contrato." };
      }
  
      const body: AddContractReqBody = {
        amount: contractForm.amount as number,
        currency: contractForm.currency,
        expirationDate: new Date(`${contractForm.expiration}:`).toISOString(),
        name: contractForm.title,
        fileId: vaultDoc.id,
        paymentStartDay: new Date(`${contractForm.sendCDFIDate}:`).toISOString(),
        cfdiFrequency: contractForm.CDFIFreq,
        employees: contractForm.employeesCount as number,
      };
  
      try {
        const contract = await NTPService.getInstance().addContract(serviceId, body);
  
        return {
          success: true,
          data: {
            amount: contract.amount,
            currency: contract.currency ?? "MXN",
            annexes: contract.appendixs
              ? contract.appendixs.map<ColumnCardDocument>(({ fileId, createdAt, ...appendix }) => ({
                  ...appendix,
                  id: fileId,
                  createdAt: new Date(createdAt),
                }))
              : [],
            fileId: contract.fileId,
            expirationDate: new Date(contract.expirationDate),
            paymentStartDay: new Date(`${contractForm.sendCDFIDate}:`),
            cfdiFrequency: contractForm.CDFIFreq,
            employeesNumber: contractForm.employeesCount,
            name: contract.name,
            validationStatus: {
              status: "Bajo revisión",
            },
            folderId: contract.folderId,
          },
        };
      } catch (error) {
        return { success: false, message: "Hubo un error al intentar subir la información del contrato y de CFDI de nóminas." };
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data.message === "File exist") {
      return { success: false, message: "El documento que estás intentando cargar parece tener el mismo nombre de uno previamente cargado. Por favor, intenta cargarlo utilizando un nombre diferente." };
      }
  
      return { success: false, message: "Hubo un error al intentar subir el documento del contrato." };
    }
  }
  


  async function uploadGeneradorContract(contractForm: ContractForm, companyId: string, serviceId: string, folderId?: string) {
    if (!contractForm.contract) {
      return { success: false, message: "Debe seleccionar un documento." };
    }
  
    const convertedDoc = (await toBase64(contractForm.contract)) as string;
    const filename = contractForm.contract.name.split(".") ?? ["", ""];
    const extension = `.${filename.pop()}` ?? "";
    const docBody: CreateDocumentReqBody = {
      companyId,
      description: "",
      extension,
      name: contractForm.title ?? filename.join(),
      fileBase64: convertedDoc,
      status: true,
      folderId: folderId,
    };
  
    try {
      const vaultDoc = await DocumentsService.getInstance().createDocument(docBody);
  
      if (!vaultDoc.id) {
        return { success: false, message: "Hubo un error al intentar subir el documento del contrato." };
      }
  
      const body: AddContractReqBody = {
        amount: contractForm.amount as number,
        currency: contractForm.currency,
        expirationDate: new Date(`${contractForm.expiration}:`).toISOString(),
        name: contractForm.title,
        fileId: vaultDoc.id,
        paymentStartDay: new Date(`${contractForm.sendCDFIDate}:`).toISOString(),
        cfdiFrequency: contractForm.CDFIFreq,
        employees: contractForm.employeesCount as number,
      };
  
      try {
        const contract = await NTPService.getInstance().addGeneradorContract(serviceId, body);
  
        return {
          success: true,
          data: {
            amount: contract.amount,
            currency: contract.currency ?? "MXN",
            annexes: contract.appendixs
              ? contract.appendixs.map<ColumnCardDocument>(({ fileId, createdAt, ...appendix }) => ({
                  ...appendix,
                  id: fileId,
                  createdAt: new Date(createdAt),
                }))
              : [],
            fileId: contract.fileId,
            expirationDate: new Date(contract.expirationDate),
            paymentStartDay: new Date(`${contractForm.sendCDFIDate}:`),
            cfdiFrequency: contractForm.CDFIFreq,
            employeesNumber: contractForm.employeesCount,
            name: contract.name,
            validationStatus: {
              status: "Bajo revisión",
            },
            folderId: contract.folderId,
          },
        };
      } catch (error) {
        return { success: false, message: "Hubo un error al intentar subir la información del contrato y de CFDI de nóminas." };
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data.message === "File exist") {
        return { success: false, message: "El documento que estás intentando cargar parece tener el mismo nombre de uno previamente cargado. Por favor, intenta cargarlo utilizando un nombre diferente." };
      }
  
      return { success: false, message: "Hubo un error al intentar subir el documento del contrato." };
    }
  }
  
  return (
    <>
    <Dialog open={props.open} maxWidth="sm">
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <span className={classes.dialogTitleText}>Subir contrato</span>
        <IconButton edge="end" onClick={handleClose}>
          <CloseIcon style={{ color: "#7A92C5" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} className={classes.dialogContentRoot}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <RenderTextField
                id="title"
                label="Título del contrato"
                value={formik.values.title}
                onChange={formik.handleChange}
                touched={formik.touched.title}
                error={formik.errors.title}
                placeholder="Nombre del contrato"
              />
            </Grid>
            <Grid item xs={12}>
              <RenderFileField
                label="Contrato"
                value={formik.values.contract}
                onChange={handleChangeFile}
                error={formik.errors.contract as any}
                touched={formik.touched.contract as any}
                accept="application/pdf"
              />
            </Grid>
            <Grid item>
              <RenderSelect
                externLabel
                id="currency"
                name="currency"
                label="Divisa"
                value={formik.values.currency}
                onChange={formik.handleChange}
                touched={formik.touched.currency}
                error={formik.errors.currency}
                options={selectCurrencyOptions}
              />
            </Grid>
            <Grid item xs>
              <RenderTextField
                id="amount"
                name="amount"
                label="Monto del contrato"
                value={formik.values.amount}
                onChange={formik.handleChange}
                touched={formik.touched.amount}
                error={formik.errors.amount}
                placeholder="0,000,000.00"
                currencyFormat
              />
            </Grid>
            <Grid item xs={12}>
              <RenderTextField
                id="expiration"
                type="date"
                label="Vigencia"
                value={formik.values.expiration}
                onChange={formik.handleChange}
                touched={formik.touched.expiration}
                error={formik.errors.expiration}
                placeholder="dd-mm-aaaa"
              />
            </Grid>
            {typeService != "generators" &&   
            <Grid item xs={12}>
              <RenderTextField
                type="number"
                id="employeesCount"
                label="Cantidad de empleados"
                value={formik.values.employeesCount}
                onChange={formik.handleChange}
                touched={formik.touched.employeesCount}
                error={formik.errors.employeesCount}
                placeholder="1"
              />
            </Grid>
            }
          
            {/* <Grid item xs={6}>
              <RenderTextField
                type="date"
                id="sendCDFIDate"
                label="Fecha de entrega CFDI"
                value={formik.values.sendCDFIDate}
                onChange={formik.handleChange}
                touched={formik.touched.sendCDFIDate}
                error={formik.errors.sendCDFIDate}
                placeholder="0"
              />
            </Grid>
            <Grid item xs={6}>
              <RenderSelect
                externLabel
                id="CDFIFreq"
                name="CDFIFreq"
                label="Frecuencia CFDI"
                value={formik.values.CDFIFreq}
                onChange={formik.handleChange}
                touched={formik.touched.CDFIFreq}
                error={formik.errors.CDFIFreq}
                options={selectOptions}
              />
            </Grid> */}
          </Grid>
          <MatFisButton variant="contained" color="primary" disabled={!formik.isValid} type="submit">
            Subir
          </MatFisButton>
        </form>
      </DialogContent>
    </Dialog>
    <Toast/>
    </>
  );
};

// const selectOptions: SelectOption[] = [
//   { label: "Semanal", value: "Semanal" },
//   { label: "Quincenal", value: "Quincenal" },
//   { label: "Mensual", value: "Mensual" },
// ];

const selectCurrencyOptions: SelectOption[] = [
  { label: "MX$", value: "MXN" },
  { label: "$", value: "USD" },
];

const useStyles = makeStyles({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContentRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 20,
  },
  dialogTitleText: {
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "21.33px",
  },
  dialogContentText: {
    fontSize: "0.875rem",
    lineHeight: "17px",
    color: "#7A92C5",
    marginBottom: 40,
  },
});
export default ContractFormDialog;
