import { axiosRequest, authHeader } from '../../configs/axios.config';

export class RolesHttp {

  async findRolById({id}, success, error) {
    axiosRequest.get(`/roles/${id}`, {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  async findAllRoles(success, error) {
    await axiosRequest.get('/roles', {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }

  editRol({
    name,
    color,
    permission,
    allCompanies
  }, id, success, error) {
  axiosRequest.put(`/roles/${id}`,
  {
    name,
    color,
    permission,
    allCompanies
  }, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp));
  }

  createRol({
    name,
    color,
    permission,
    allCompanies
  }, success, error) {
  axiosRequest.post(`/roles/`,
  {
    name,
    color,
    permission,
    allCompanies
  }, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp));
  }

  deleteRol({id}, success, error) {
    axiosRequest.put(`/roles/delete/${id}`, {headers: authHeader()})
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp));
  }


}