import { GeneralFormInfoReqBody, GeneralNextFormInfoReqBody, GeolocationFormInfoReqBody, PhotographicFormInfoReqBody} from "../../types/companies-service";

export const defaultGeneralFormBody: GeneralFormInfoReqBody = {
    clientName: "", 
    csp: "",
    idPlace: "",
    cot: "",
    cuenta: "",
    serviceOfferedIdList: [],
    startDate: "",
    endDate: "",
    idCode: "",
    name: "",
    idSupplier: "",
    idSap: "",
    district: "",
    cluster: "",
    city: "",
    state: "",
    address: "",
    projectName: "",
    area: "",
    assignment: "",
    servicesList: [],
    businessUnit: ""
};

export const defaultGeneralNextBody: GeneralNextFormInfoReqBody = {
    name: "",
    lastName: "",
    motherLastName: "",
    phone: null,
    email: "",
    listRegions: [""],
    listResponsible: [""],
    phoneResponsible: null,
    emailResponsible: "",
    emailManager: ""
};

export const defaultGeolocationBody: GeolocationFormInfoReqBody = {
    date: "",
    address: "",
    code: "",
    coordinates: "",
    folioTracker: "",
    finalImageId: ""
};

export const defaultPhotographicBody: PhotographicFormInfoReqBody = {
    ont: "",
    beforeList: [],
    duringList: [],
    endList: []
};
