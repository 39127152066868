import React, { VFC } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import mime from "mime-types";
import { useFormik } from "formik";
import * as Yup from "yup";

import MatFisButton from "./MatFisButton";
import RenderSelect from "./inputs/RenderSelect";
import RenderTextField from "./inputs/RenderTextField";
import RenderFileField from "./inputs/RenderFileField";

import { RequirementDocumentForm } from "materialidad-outsourcing/dist/types/forms";
import { monthSelectOptions } from "materialidad-outsourcing/dist/utils";
import { getYearsRange } from "../utils";
import useCustomToast from "../../../core/hooks/useCustomToast";

interface UploadFileDialogProps {
  open: boolean;
  onClose?: () => void;
  description?: string;
  onUploadFile: (file: File, description: string, month: any, year: string) => void;
  fileType: string;
  cardTitle: string;
}

const UploadFileDialog: VFC<UploadFileDialogProps> = (props) => {
  const classes = useStyles();
  const toast = useCustomToast();
  let accept: "application/pdf" | "application/xml" | undefined;
  switch (props.fileType) {
    case "PDF":
      accept = "application/pdf";
      break;
    case "XML":
      accept = "application/xml";
      break;
    default:
      break;
  }

  const currentYear = new Date().getFullYear();

  const formik = useFormik<RequirementDocumentForm & { year: string }>({
    validateOnMount: true,
    initialValues: {
      title: "",
      description: "",
      month: "Enero",
      year: currentYear.toString(),
    },
    validationSchema: Yup.object({
      document: Yup.mixed()
        .required("Campo requerido")
        .test({
          name: "file-type",
          message: `El documento no es de tipo ${mime.extension(accept ?? "")}`,
          test: (file) => (file && accept ? mime.lookup(file?.name) === accept : true),
        }),
    }),
    onSubmit: (values) => props.onUploadFile(values.document as File, values.description, values.month, values.year),
  });

  /*const handleChangeFile = (file?: File) => {
    console.log("archvio malo",file)

    formik.setFieldTouched("document", true);
    formik.setFieldValue("document", file);
  };*/
  const handleChangeFile = (file?: File) => {
    if (file && file.size === 0) {
      console.error("El archivo está corrupto o vacío.");
      toast.error("El archivo está corrupto o vacío. Por favor, inténtalo de nuevo.");
      return;
    }
    console.log("archivo", file);
    formik.setFieldTouched("document", true);
    formik.setFieldValue("document", file);
  };
  console.log("upload doc")

  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <span className={classes.dialogTitleText}>Subir {props.cardTitle}</span>
        <IconButton edge="end" onClick={props.onClose}>
          <CloseIcon style={{ color: "#7A92C5" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} className={classes.dialogContentRoot}>
          <RenderFileField
            label={`Formato requerido: ${props.fileType}`}
            value={formik.values.document}
            onChange={handleChangeFile}
            touched={formik.touched.document as any}
            error={formik.errors.document as any}
            accept={accept}
          />
          <RenderSelect
            externLabel
            label="Mes correspondiente"
            name="month"
            id="month"
            onChange={formik.handleChange}
            options={monthSelectOptions}
            required
            value={formik.values.month}
          />
          <RenderSelect
            externLabel
            label="Año correspondiente"
            name="year"
            id="year"
            onChange={formik.handleChange}
            options={getYearsRange(1990)}
            required
            value={formik.values.year}
          />

          <RenderTextField
            id="description"
            name="description"
            multiline
            label="Descripción"
            placeholder="Agregar descripción"
            onChange={formik.handleChange}
            value={formik.values.description}
          />

          {formik.isValid && (
            <MatFisButton variant="contained" color="primary" type="submit">
              Subir
            </MatFisButton>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContentRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 20,
  },
  dialogTitleText: {
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "21.33px",
  },
  dialogContentText: {
    fontSize: "0.875rem",
    lineHeight: "17px",
    color: "#7A92C5",
    marginBottom: 40,
  },
});
export default UploadFileDialog;
