import { Dialog } from "@material-ui/core";
import { ExpedienteDocument } from "../../../../types/expediente.types";
import WatchDocument from "./WatchDocument";

const ShowDocumentDialog = ({
  onClose,
  doc,
  handleValidateStatus,
}: {
  onClose: any;
  doc: ExpedienteDocument;
  handleValidateStatus: any;
}) => {
  return (
    <Dialog fullScreen open={true} onClose={onClose}>
      <WatchDocument
        name={doc.name}
        document={doc}
        documentStatus={doc.validationStatus?.status ?? ""}
        onReturn={onClose}
        onValidateStatus={handleValidateStatus}
      />
    </Dialog>
  );
};

export default ShowDocumentDialog;
