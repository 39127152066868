import { Button, withStyles } from "@material-ui/core";

const DashedButton = withStyles(theme =>({
  root: {
    backgroundColor: "#00709e1a",
    color: theme.palette.primary.main,
    border: `2px dashed ${theme.palette.primary.main}`
  },
  sizeLarge: {
    padding: 16
  }
}))(Button);

export default DashedButton;