import React, { useRef } from 'react';
import { Box, FormHelperText, Typography } from '@material-ui/core';
import MatFisButton from '../MatFisButton';

import FileInfo from '../FileInfo';
import DropzoneFiles from './DropzoneFiles';
import { useInputFormStyles } from '../../hooks/useInputFormStyles';

const isFileArray = (props: RenderSingleFileFieldProps | RenderMultipleFileFieldProps): props is RenderMultipleFileFieldProps => {
  return props.value ? 'length' in props.value : false;
};

interface RenderFileFieldProps<T> {
  label?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value?: T) => void;
  value?: T;
  accept?: string;
  error?: string;
  touched?: boolean;
  selectFileButtonLabel?: string;
  dropzoneLabel?: string;
}

type RenderSingleFileFieldProps = RenderFileFieldProps<File>;
type RenderMultipleFileFieldProps = RenderFileFieldProps<File[]>;

type RenderFileFieldComponent = (props: RenderSingleFileFieldProps | RenderMultipleFileFieldProps) => JSX.Element | null;
const RenderFileField: RenderFileFieldComponent = (props) => {
  const classes = useInputFormStyles();
  const fileRef = useRef<HTMLInputElement>(null);
  const rootComponentProps = { display: 'inline-flex', width: '100%', flexDirection: 'column', marginBottom: 2 };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isFileArray(props)) props.onChange?.(e.target.files && props.value ? [...props.value, ...e.target.files] : undefined);
    else props.onChange?.(e.target.files?.[0]);
    (fileRef.current as HTMLInputElement).value = '';
  };

  const handleDropzoneInput = (files: File[]) => {
    if (isFileArray(props)) props.onChange?.(files && props.value ? [...props.value, ...files] : undefined);
  };

  const handleButtonFile = () => {
    fileRef.current?.click();
  };

  const handleQuitFile = (index: number) => {
    if (isFileArray(props)) props.onChange?.((props.value as File[]).filter((_, i) => i !== index));
  };

  return isFileArray(props) ? (
    <Box width="100%">
      <Box {...rootComponentProps}>
        {props.label && (
          <label className={classes.fieldLabel}>{`${props.label}, Archivos subidos: ${props.value?.length} ${props.required ? '*' : ''}`}</label>
        )}
        <Box display="flex" alignItems="center" gridGap={6}>
          <input type="file" hidden ref={fileRef} onChange={handleFileInput} accept={props.accept} />
          <MatFisButton style={{ flex: 1 }} size="large" variant="contained" onClick={handleButtonFile} color="primary">
            {props.selectFileButtonLabel ?? 'Seleccionar archivo'}
          </MatFisButton>

          <Typography variant="caption">O</Typography>
          <DropzoneFiles file={props.value} onChange={handleDropzoneInput} accept={props.accept} dropzoneLabel={props.dropzoneLabel} />
        </Box>
      </Box>
      {props.touched && props.error && <FormHelperText error>{props.error}</FormHelperText>}
      {props.value?.map((item, i) => {
        return <FileInfo key={i} filename={item.name} size={item.size} onQuitFile={() => handleQuitFile(i)} />;
      })}
    </Box>
  ) : (
    <Box width="100%">
      {props.value ? (
        <FileInfo filename={props.value.name} size={props.value.size} onQuitFile={() => props.onChange?.(undefined)} />
      ) : (
        <Box {...rootComponentProps}>
          {props.label && <label className={classes.fieldLabel}>{`${props.label} ${props.required ? '*' : ''}`}</label>}
          <Box display="flex" alignItems="center" gridGap={6}>
            <input type="file" hidden ref={fileRef} onChange={handleFileInput} accept={props.accept} />
            <MatFisButton style={{ flex: 1 }} size="large" variant="contained" onClick={handleButtonFile} color="primary">
              {props.selectFileButtonLabel ?? 'Seleccionar archivo'}
            </MatFisButton>
            <Typography variant="caption">O</Typography>
            <DropzoneFiles
              file={props.value as any}
              onChange={(files) => props.onChange?.(files[0])}
              accept={props.accept}
              dropzoneLabel={props.dropzoneLabel}
            />
          </Box>
        </Box>
      )}
      {props.touched && props.error && <FormHelperText error>{props.error}</FormHelperText>}
    </Box>
  );
};

export default RenderFileField;
