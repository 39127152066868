import { Box, Typography, makeStyles, withWidth } from "@material-ui/core"


function TabPanel({ children, value, index, ...other }) {
  const classes = useStyles();
  return (
    <div
      className={classes.widthTabPanel}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box paddingX={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const useStyles = makeStyles(({
  widthTabPanel: {
    width: "100%",
  },
}));

export default TabPanel