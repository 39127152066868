const CFDIWarningIcon = (props) => (
  <svg
    width={17}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#F5FBFF" d="M6 3h4v10H6z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.616.734a1.5 1.5 0 0 0-2.58 0L.214 12.215a1.5 1.5 0 0 0 1.29 2.267H15.15a1.5 1.5 0 0 0 1.29-2.267L9.616.734Zm-1.924 9.432H9.03l.28-5.36H7.412l.28 5.36Zm-.055 1.18c-.165.157-.247.39-.247.697 0 .296.084.526.252.69.168.162.406.242.713.242.3 0 .534-.082.703-.247.168-.168.252-.396.252-.685 0-.3-.084-.53-.252-.692-.165-.16-.399-.241-.703-.241-.314 0-.554.079-.718.236Z"
      fill="#EA9920"
    />
  </svg>
)

export default CFDIWarningIcon