import { Box, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { SelectOption } from '../../types/global';
import { useReduxFormStyles } from './useReduxFormStyles';

interface Props {
  id?: string;
  value: unknown;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  name?: string;
  label?: string;
  touched?: boolean;
  error?: string;
  required?: boolean;
  options: readonly SelectOption[];
  placeholder?: string;
  externLabel?: boolean;
  variant?: "filled" | "standard" | "outlined";
}

const RenderSelect = ({ label, value, onChange, name, required, options, externLabel, error, touched, id, placeholder, variant = "outlined" }: Props) => {
  const formClasses = useReduxFormStyles();
  const RootComponent = externLabel ? Box : React.Fragment;
  const rootComponentProps = externLabel ? { display: 'inline-flex', width: '100%', flexDirection: 'column' } : null;

  return (
    <RootComponent {...rootComponentProps}>
      {!!label && externLabel && <label className={formClasses.fieldLabel}>{`${label} ${required ? '*' : ''}`}</label>}
      <TextField
        value={value}
        onChange={onChange}
        id={id}
        name={name}
        size="small"
        margin="none"
        select
        fullWidth
        label={!!label && !externLabel ? label : undefined}
        placeholder={label}
        required={required}
        variant={variant}
        error={touched && !!error}
        helperText={touched && error}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label ?? option.value}
          </MenuItem>
        ))}
      </TextField>
    </RootComponent>
  );
};

export default RenderSelect;
