import React, { VFC } from 'react';
import { OutlinedInput, FormHelperText, FormControl, Box } from '@material-ui/core';
import CurrencyNumberFormat from './CurrencyNumberFormat';
import { useInputFormStyles } from '../../hooks/useInputFormStyles';


interface RenderTextFieldProps {
  id?: string;
  name?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  error?: string;
  touched?: boolean;
  multiline?: boolean;
  value: unknown;
  type?: 'number' | 'date';
  currencyFormat?: boolean;
}

const RenderTextField: VFC<RenderTextFieldProps> = (props) => {
  const { label, required, multiline, disabled, onChange, error, touched, value, type, placeholder, currencyFormat } = props;
  const classes = useInputFormStyles();
  const rootComponentProps = { display: 'inline-flex', width: '100%', flexDirection: 'column' };

  return (
    <Box {...rootComponentProps}>
      {label && <label className={classes.fieldLabel}>{`${label} ${required ? '*' : ''}`}</label>}
      <FormControl variant="outlined" color="primary" fullWidth margin="dense" error={touched && !!error}>
        <OutlinedInput
          id={props.id}
          name={props.name}
          multiline={multiline}
          rows={3}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          value={value}
          type={!currencyFormat ? type : undefined}
          inputComponent={currencyFormat ? (CurrencyNumberFormat as any) : undefined}
        />
        <FormHelperText>{touched && error}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default RenderTextField;
