import React from "react";
import NumberFormat from "react-number-format";

interface CurrencyNumberFormatProps {
  inputRef: (instance: NumberFormat<any> | null) => void;
  onChange: (event: { target: { name: string; value?: number } }) => void;
  name: string;
}

const CurrencyNumberFormat = (props: CurrencyNumberFormatProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
      fixedDecimalScale
    />
  );
};

export default CurrencyNumberFormat;
