import React, { useState } from "react";
import { Button, Grid, makeStyles, ThemeProvider, Typography } from "@material-ui/core";
import { themeV2 } from "../../styles/common";
import HelpIcon from "../icons/icons-v2/HelpIcon";
import necesidad from "../../assets/img/necesidad.svg";
import provecho from "../../assets/img/provecho.svg";
import trazabilidad from "../../assets/img/trazabilidad.svg";

interface Props {
  end: () => void;
}

const SlideBody1 = () => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.mainText}>
        Gracias por llenar los campos requeridos. Ahora te explicaremos como funciona nuestra plataforma para que puedas
        utilizarla de forma correcta.
      </Typography>
      <Typography className={classes.mainText}>
        La plataforma tiene como objetivo gestionar las operaciones de contratación y prestación de servicios especializados a
        través de la recopilación y validación de documentos. Contamos con 3 etapas, la primera es:
      </Typography>

      <Grid container justifyContent="center" xs={12}>
        <div className={classes.stageBanner} style={{ backgroundColor: "#239ED0" }}>
          <Typography>ETAPA 1: EXPEDIENTE</Typography>
        </div>
      </Grid>

      <Typography className={classes.mainText}>
        Esta etapa se enfoca en el proceso de decisión que llevó a la adquisición del bien o servicio; así como la diligencia
        observada en la contratación.
      </Typography>
    </>
  );
};

const SlideBody2 = () => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.mainText}>La siguiente etapa es</Typography>

      <Grid container justifyContent="center" xs={12}>
        <div className={classes.stageBanner} style={{ backgroundColor: "#44B5AE" }}>
          <Typography>ETAPA 2: CUMPLIMIENTO</Typography>
        </div>
      </Grid>

      <Typography className={classes.mainText}>La cual se centra en los derechos y obligaciones acordados.</Typography>
    </>
  );
};

const SlideBody3 = () => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.mainText}>Y la última etapa es</Typography>

      <Grid container justifyContent="center" xs={12}>
        <div className={classes.stageBanner} style={{ backgroundColor: "#3A69A7" }}>
          <Typography>ETAPA 3: REPORTEO</Typography>
        </div>
      </Grid>

      <Typography className={classes.mainText}>Que busca analizar la consequencia o efecto de las transacciones.</Typography>

      <Typography className={classes.mainText}>
        Ahora puedes comenzar a utilizar la plataforma y trabajar con tus colaboradores con apego a la ley. Te recordamos que
        puedes consultar la sección de preguntas frecuentes localizado en la parte superior izquierda de la pantalla
      </Typography>

      <HelpIcon fontSize="large" />

      <Typography className={classes.mainText}>
        Aquí resolveremos cualquier duda de la plataforma, su uso y como Materialidad Fiscal te ayuda a tener apego a la ley.
      </Typography>
    </>
  );
};

const images = [necesidad, trazabilidad, provecho];

const CarouselInstructions = ({ end }: Props) => {
  const classes = useStyles();
  const [indexSelected, setIndexSelected] = useState(0);

  if (indexSelected > 2) end();

  return (
    <ThemeProvider theme={themeV2}>
      <div className={classes.instructionsCarousel}>
        <Grid container justifyContent="center" xs={12} md={5} lg={4}>
          {indexSelected === 0 && <SlideBody1 />}
          {indexSelected === 1 && <SlideBody2 />}
          {indexSelected === 2 && <SlideBody3 />}

          <Grid container justifyContent="center" xs={12}>
            <Button
              className={classes.nextBtn}
              variant="contained"
              onClick={() => {
                setIndexSelected(indexSelected + 1);
              }}
            >
              Siguiente
            </Button>
          </Grid>
        </Grid>

        <Grid key={indexSelected} container justifyContent="center" xs={12} md={5} className={classes.imgContainer}>
          <img
            style={{ width: "100%" }}
            alt="carousel"
            src={images[indexSelected]}
            className="animate__animated animate__fadeIn"
          />
        </Grid>
      </div>
    </ThemeProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  instructionsCarousel: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
    justifyContent: "space-around",
    alignItems: "center",
    paddingTop: "65px", //altura topbar
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.primary.light,
    minHeight: "inherit",
  },
  imgContainer: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  centralSection: {
    maxWidth: 600,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    "& img": {
      width: 250,
      height: 200,
    },
    "& h3": {
      color: "white",
      textAlign: "center",
    },
    "& p": {
      color: "white",
      textAlign: "center",
      maxWidth: 400,
    },
  },
  mainText: {
    width: "100%",
    fontSize: "18px",
    textAlign: "left",
    color: "#fff",
    fontWeight: 600,
    lineHeight: "27px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stageBanner: {
    width: "75%",
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: "10px",
    boxShadow: theme.shadows[1],
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 800,
    "& p": {
      fontWeight: 800,
      fontSize: "20px",
      color: "#fff",
    },
  },
  nextBtn: {
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    marginTop: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default CarouselInstructions;