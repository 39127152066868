import { makeStyles } from '@material-ui/core';

export const useInputFormStyles = makeStyles((theme) => ({
  fieldLabel: {
    ...theme.typography.body1,
    color: '#A8A8A8',
    fontSize: '0.875rem',
    fontWeight: 'bold'
  }
}));
