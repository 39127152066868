import { useEffect, useState } from "react";
import { Box, Button, Container, FormControl, FormControlLabel, Switch, TextField, Typography } from "@material-ui/core";
import { PasswordPolicyList, PolicyData } from "../core/models/settings.models";
import useSettingsState from "../store/settingsState";
import useGlobalState from "../store/globalState";
import AddExceptionsModal from "../components/management/settings/AddExceptionsModal";


const SettingsPage = () => {
  /** */
  const { getPoliciesByCompanyList, updatePoliciesList } = useSettingsState();
  const [policies, setPolicies] = useState<PasswordPolicyList[]>([]);
  const [policiesOtp, setPoliciesOtp] = useState<PasswordPolicyList[]>([]);
  const [policyData, setPolicyData] = useState<PolicyData>();
  const { alertInfo, setAlertInfo } = useGlobalState();
  const [showModal, setShowModal] = useState<boolean>();
  /* const { setShowUsersOtpModal } = useModalState();
  const toast = useCustomToast(); */

  useEffect(() => {
    getPoliciesByCompanyList().then((result) => {
      /** */
      setPolicyData(result.respuesta);
      makeAndSetPolicies(result.respuesta.passwordPolicyList);
    });
  }, []);

  function makeAndSetPolicies(data: PasswordPolicyList[]) {
    /** Reescribir valor del value para evitar errores */
    data.map(item => !item.value && (item.value = ''));
    setPolicies(data.filter(item => !item.policyType.includes('OTP')));

    /** Políticas OTP */
    const filteredOtpPolicies = data.filter(item => item.policyType.includes('OTP'));
    const principalOtpPolicy = filteredOtpPolicies.find(item => item.policyType === 'OTP_REQUIRED');

    if (principalOtpPolicy) {
      !principalOtpPolicy.active && filteredOtpPolicies.map(item => item.active = false);
    }

    setPoliciesOtp(filteredOtpPolicies);
  }

  const handlePolicy = (index: number, type: string, value?: string) => {
    /** */
    const updatedPolicies = policies.map((policy, idx) => {

      if (type === 'status' && (index === idx)) {

        const input = document.getElementById(`input-policy${index}`);

        policy.active = !policy.active;

        if (policy.active && input) {
          input.removeAttribute('disabled');
          input.focus();
        }

        (!policy.active && input) && input.setAttribute('disabled', 'true');
      }

      if (type === 'value') {
        index === idx && (policy.value = value);
      }

      return policy;
    });

    setPolicies(updatedPolicies);
  }

  const handlePolicyOtp = (index: number, type: string, value?: string) => {
    /** */
    const updatedPoliciesOtp = policiesOtp.map((policy, idx) => {

      if (type === 'status' && (index === idx)) {

        const input = document.getElementById(`input-policy-otp${index}`);

        policy.active = !policy.active;

        if (policy.active && input) {
          input.removeAttribute('disabled');
          input.focus();
        }

        if (policy.active && policy.policyType === 'OTP_REQUIRED') {
          handleUsersOtpModal();
        }

        (!policy.active && input) && input.setAttribute('disabled', 'true');
      }

      if (type === 'value') {
        index === idx && (policy.value = value);
      }

      return policy;
    });

    setPoliciesOtp(updatedPoliciesOtp);
  }

  const handleSavePolicies = () => {
    /** */
    let policy: PolicyData = policyData;

    policy.passwordPolicyList = policies.concat(policiesOtp);

    setPolicyData(policy);

    updatePoliciesList(policyData).then(result => {
      /** */
      result?.codigo === 0
        ?
        setAlertInfo({
          message: result.mensaje,
          type: "success",
        })
        : setAlertInfo({
          message: result.mensaje,
          type: "error",
        })
    });
  }

  const handleUsersOtpModal = () => {
    setShowModal(true);
  }

  return (
    <Container maxWidth="xl" style={{ paddingTop: "30px" }}>
      <Box display="flex" justifyContent="space-between" gridGap={6}>
        <Box flex={1} border={2} borderRadius={2} p={2} pr={1} borderColor="grey.300">
          <Typography variant="h6" color="primary" align="center" gutterBottom>
            Políticas
          </Typography>
          <Box maxHeight={300} overflow="auto" pr='10px'>
            {policies.map((policy, index) => (
              <Box key={index} display="flex" justifyContent="space-between" mt={2}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Switch
                        id={'sw-policy' + index}
                        checked={policy.active}
                        onChange={() => handlePolicy(index, 'status')}
                      />
                    }
                    label={policy.description}
                  />
                </FormControl>
                {policy.valueType !== 'BOOLEAN' && (
                  <TextField
                  style={{width: '80px'}}
                    variant="outlined"
                    id={'input-policy' + index}
                    type="number"
                    value={policy.value}
                    onChange={e => handlePolicy(index, 'value', e.target.value)}
                    disabled={!policy.active}
                    inputProps={{ min: policy.min, max: policy.max }}
                    size="small"
                  />
                )}
              </Box>
            ))}
            {policies.length === 0 && (
              <Box display="flex" justifyContent="center" mt={2}>
                No hay políticas definidas para la autenticación a 2 pasos
              </Box>
            )}
          </Box>
        </Box>
        <Box flex={1} border={2} borderRadius={2} p={2} borderColor="grey.300">
          <Typography variant="h6" color="primary" align="center" gutterBottom>
            Autenticación de 2 pasos
          </Typography>
          <Box maxHeight={300} overflow="auto">
            {policiesOtp.map((policy, index) => (
              <Box key={index} display="flex" justifyContent="space-between" mt={2}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Switch
                        id={'sw-policy-otp' + index}
                        checked={policy.active}
                        onChange={() => handlePolicyOtp(index, 'status')}
                      />
                    }
                    label={policy.description}
                  />
                </FormControl>
                {policy.valueType !== 'BOOLEAN' && (
                  <TextField
                  style={{width: '80px'}}
                    variant="outlined"
                    id={'input-policy-otp' + index}
                    type="number"
                    value={policy.value}
                    onChange={e => handlePolicyOtp(index, 'value', e.target.value)}
                    disabled={!policy.active}
                    inputProps={{ min: policy.min, max: policy.max }}
                    size="small"
                  />
                )}
                {policy.policyType === 'OTP_REQUIRED' && (
                  <Button variant="text" onClick={() => setShowModal(true)}>
                    Agregar excepciones
                  </Button>
                )}
              </Box>
            ))}
            {policiesOtp.length === 0 && (
              <Box display="flex" justifyContent="center" mt={2}>
                No hay políticas definidas para la autenticación a 2 pasos
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" mt={4}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSavePolicies}
          style={{ fontWeight: "medium", padding: "0.5rem 3rem" }}
          disabled={false}>
          Guardar
        </Button>
      </Box>
      <AddExceptionsModal open={showModal} onClose={() => setShowModal(false)} />
    </Container>
  );
};

export default SettingsPage;
