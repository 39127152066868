import { axiosIpfsRequest, authHeader } from "../../configs/axios.config";
import { GenericResponse } from "../../types/global";
import { GetRolesResponse } from "../../types/roles-service";

export class RolesService {
  private static instance: RolesService;

  private constructor() { };

  public static getInstance(): RolesService {
    if (!RolesService.instance) {
      RolesService.instance = new RolesService();
    }
    return RolesService.instance;
  }

  async listRoles() {
    try {
      const { data } = await axiosIpfsRequest.get<GenericResponse<GetRolesResponse[]>>(`api/v1/roles`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }
}