import { axiosNtpRequestV1, authHeader } from "../../configs/axios.config";

export class ReportesService {
  private static instance: ReportesService;

  public static getInstance(): ReportesService {
    if (!ReportesService.instance) {
      ReportesService.instance = new ReportesService();
    }
    return ReportesService.instance;
  }

  
  async getReportes(clientId: string, providerId: string) {
    try {
      const { data } = await axiosNtpRequestV1.get(
        `/reportes/${clientId}/provider/${providerId}`,
        {
          headers: authHeader(),
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async uploadDocument(
    reportId: string,
    requirementId: string,
    body: { name: string; extension: string; month: string; year: string; comments: string; b64File: string }
  ) {
    try {
      const { data } = await axiosNtpRequestV1.post(`/reportes/${reportId}/requirements/${requirementId}`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
  
  async getIdealReporte(idCompany: string) {
    try {
      const { data } = await axiosNtpRequestV1.get(
        `/reporter/idCompany/${idCompany}/`,
        {
          headers: authHeader(),
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getIdealExcelReport(idCompany: string) {
    try {
      const { data } = await axiosNtpRequestV1.get(
        `/reporter/ReporteIdealExcel/companyId/${idCompany}/`,
        {
          headers: authHeader(),
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  //http://localhost:8077/api/v1/reportes/628ed39a51bfa978b42345e0/requirements/d0f78cc8-497f-40d3-88b1-27d70038210d/documents/1a8d4c10-b0e6-4786-91b7-c6bfb5d824fd

  // async deleteDocument(serviceId: string, requirementId: string, docId: string) {
  //   try {
  //     const { data } = await axiosNtpRequestV1.delete(`/services/${serviceId}/requirements/${requirementId}`, {
  //       data: { uid: docId },
  //       headers: authHeader(),
  //     });
  //     return data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  // async downloadDocuments(
  //   serviceId: string,
  //   body: {
  //     month: string;
  //     year: string;
  //     requirements: string[];
  //   }
  // ) {
  //   try {
  //     const { data } = await axiosNtpRequestV1.post(`/services/${serviceId}/cumplimiento/download`, body, {
  //       headers: authHeader(),
  //       responseType: "arraybuffer",
  //     });
  //     return data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
}
