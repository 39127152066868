import React, { useRef, useState} from "react";
import { Box, FormHelperText, Typography } from "@material-ui/core";
import MatFisButton from "../MatFisButton";

import FileInfo from "../FileInfo";
import DropzoneFiles from "./DropzoneFiles";
import { useInputFormStyles } from "../../hooks/useInputFormStyles";
import useUiState from "../../../../store/uiState";
import LinearProgress from '@material-ui/core/LinearProgress';

const isFileArray = (props: RenderSingleFileFieldProps | RenderMultipleFileFieldProps): props is RenderMultipleFileFieldProps => {
  return props.value ? "length" in props.value : false;
};

interface RenderFileFieldProps<T> {
  label?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value?: T) => void;
  value?: T;
  accept?: string;
  error?: string;
  touched?: boolean;
  selectFileButtonLabel?: string;
  dropzoneLabel?: string;
  multiple?: boolean;
  failedFilesRes?: string[]; //according to the server response and index of the file
  fullDisabled?: boolean;
}

type RenderSingleFileFieldProps = RenderFileFieldProps<File>;
type RenderMultipleFileFieldProps = RenderFileFieldProps<File[]>;

type RenderFileFieldComponent = (props: RenderSingleFileFieldProps | RenderMultipleFileFieldProps) => JSX.Element | null;
const RenderFileField: RenderFileFieldComponent = (props) => {
  const classes = useInputFormStyles();
  const fileRef = useRef<HTMLInputElement>(null);
  const rootComponentProps = { display: "inline-flex", width: "100%", flexDirection: "column", marginBottom: 2 };
  const { setShowGenericLoader } = useUiState();
  const [uploading, setUploading] = useState(false);


  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      // Set loading to true at the beginning
      setUploading(true);
  
      if (isFileArray(props)) props.onChange?.(e.target.files && props.value ? [...props.value, ...e.target.files] : undefined);
      else props.onChange?.(e.target.files?.[0]);  
    } catch (error) {
      console.error("Error en la manipulación de archivos:", error);
    } finally {
      // Set loading to false at the end, whether there's an error or not
      setUploading(false);
    }
  };
  
  const handleDropzoneInput = (files: File[]) => {
    if (isFileArray(props)) props.onChange?.(files && props.value ? [...props.value, ...files] : undefined);
  };

  const handleButtonFile = () => {
    fileRef.current?.click();
  };

  const handleQuitFile = (index: number) => {
    if (isFileArray(props)) props.onChange?.((props.value as File[]).filter((_, i) => i !== index));
  };

  //retunr message if the file is not valid
  const isFailedFile = (index): string | undefined => {
    if(!props.failedFilesRes) return undefined
    try {
      return props.failedFilesRes[index];
    } catch (error) {
      return undefined;
    }
  };

  return isFileArray(props) ? (
    <Box width="100%">
      <Box {...rootComponentProps}>
        {props.label && (
          <label className={classes.fieldLabel}>{`${props.label}, Archivos seleccionados: ${props.value?.length} ${
            props.required ? "*" : ""
          }`}</label>
        )}
        {!props.fullDisabled && (
          <>
            {uploading && (
              <LinearProgress style={{ marginBottom: '2rem' }} />
            )}
  
            <Box display="flex" alignItems="center" gridGap={6}>
              <input type="file" hidden ref={fileRef} onChange={handleFileInput} accept={props.accept} multiple={props.multiple} />
              <MatFisButton style={{ flex: 1 }} size="large" variant="contained" onClick={handleButtonFile} color="primary">
                {props.selectFileButtonLabel ?? "Seleccionar archivo"}
              </MatFisButton>
  
              <Typography variant="caption">O</Typography>
              <DropzoneFiles file={props.value} onChange={handleDropzoneInput} accept={props.accept} dropzoneLabel={props.dropzoneLabel} />
            </Box>
          </>
        )}
      </Box>
      {props.touched && props.error && <FormHelperText error>{props.error}</FormHelperText>}
      {props.value?.map((item, i) => {
        return (
          <FileInfo
            key={i}
            filename={item.name}
            size={item.size}
            onQuitFile={() => handleQuitFile(i)}
            failed={isFailedFile(i)}
            showMode={props.fullDisabled}
          />
        );
      })}
    </Box>
  ) : (
    <Box width="100%">
      {props.value ? (
        <FileInfo
          filename={props.value.name}
          size={props.value.size}
          onQuitFile={() => props.onChange?.(undefined)}
          failed={isFailedFile(0)}
          showMode={props.fullDisabled}
        />
      ) : (
        <Box {...rootComponentProps}>
          {props.label && <label className={classes.fieldLabel}>{`${props.label} ${props.required ? "*" : ""}`}</label>}
          <Box display="flex" alignItems="center" gridGap={6}>
            <input type="file" hidden ref={fileRef} onChange={handleFileInput} accept={props.accept} multiple={props.multiple} />
            <MatFisButton style={{ flex: 1 }} size="large" variant="contained" onClick={handleButtonFile} color="primary">
              {props.selectFileButtonLabel ?? "Seleccionar archivo"}
            </MatFisButton>
            <Typography variant="caption">O</Typography>
            <DropzoneFiles
              file={props.value as any}
              onChange={(files) => props.onChange?.(files[0])}
              accept={props.accept}
              dropzoneLabel={props.dropzoneLabel}
            />
          </Box>
        </Box>
      )}
      {props.touched && props.error && <FormHelperText error>{props.error}</FormHelperText>}
    </Box>
  );
  
};

export default RenderFileField;
