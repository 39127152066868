import { Dialog, DialogTitle, IconButton, DialogContent, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import UsersOtpSettings from './UsersOtpSettings';


type Props = {
  open: boolean;
  onClose?: () => void;
}

const AddExceptionsModal = (props: Props) => {
  const classes = useStyles();

  const handleClose = () => {
    if (props.onClose) props.onClose();
  };

  return (
    <Dialog open={props.open} maxWidth="md" fullWidth>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <span className={classes.dialogTitleText}>Excepciones de usuarios</span>
        <IconButton edge="end" onClick={handleClose}>
          <CloseIcon style={{ color: '#7A92C5' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <UsersOtpSettings />
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dialogContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20
  },
  dialogTitleText: {
    fontWeight: 600,
    fontSize: '1.125rem',
    lineHeight: '21.33px'
  },
  dialogContentText: {
    fontSize: '0.875rem',
    lineHeight: '17px',
    color: '#7A92C5',
    marginBottom: 40
  }
});

export default AddExceptionsModal