import { Avatar, AvatarProps, withStyles } from "@material-ui/core";

interface ColoredAvatarProps {
  size: number;
  backgroundColor?: string;
}

const ColoredAvatar = withStyles<"root", Record<string, never>, AvatarProps & ColoredAvatarProps>(theme =>({
  root: {
    width: ({ size }) => size,
    height: ({ size }) => size,
    backgroundColor: ({ backgroundColor }) => backgroundColor ?? theme.palette.primary.main
  },
}))(Avatar);

export default ColoredAvatar;