import React from "react";
import { FormHelperText, FormControl, Box, Select, Typography } from "@material-ui/core";

interface RenderTextFieldProps {
  id?: string;
  name?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onChange: any;
  error?: string;
  touched?: boolean;
  value?: unknown;
  children: JSX.Element;
  defaultValue?: any;
  margin?: "dense" | "none" | "normal";
  size?: "small" | "medium";
}

const RenderSelectV2 = ({
  label,
  onChange,
  error,
  touched,
  children,
  defaultValue = "",
  disabled = false,
  margin = "dense",
  size = "medium",
}: RenderTextFieldProps) => {
  //const classes = useReduxFormStyles();
  const rootComponentProps = { display: "inline-flex", width: "100%", flexDirection: "column" };

  return (
    <Box {...rootComponentProps}>
      {label && <Typography variant="body2">{label}</Typography>}
      <FormControl size={size} variant="outlined" color="primary" fullWidth margin={margin} error={touched && !!error}>
        <Select onChange={onChange} native defaultValue={defaultValue} id="grouped-native-select" disabled={disabled}>
          {children}
        </Select>
        <FormHelperText>{touched && error}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default RenderSelectV2;
