import create from "zustand";

interface State {
  showGenericLoader: boolean;
  setShowGenericLoader: (show: boolean) => void;
}

const useUiState = create<State>((set, get) => ({
  showGenericLoader: false,
  setShowGenericLoader: (show: boolean) => set({ showGenericLoader: show }),
}));

export default useUiState;
