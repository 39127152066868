/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  makeStyles,
  FormControlLabel,
  FormGroup,
  Radio,
  Backdrop,
  CircularProgress,
  Select as MatSelect,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Box,
  IconButton,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import TextField from "../inputs/inputs-v2/RenderTextFieldV2";
import Select from "../inputs/inputs-v2/RenderSelectV2";
import { FormikErrors, useFormik } from "formik";
import { CompaniesService } from "../../core/services/companies.service";
import LogoutOnLeave from "../LogoutOnLeave";
import { EditCompanyInfoReqBody } from "../../types/companies-service";
import useGlobalState from "../../store/globalState";
import { ACTIVITIES_LIST, SERVICES_LIST } from "../../configs/constants";
import usePlanState from "../../store/planState";
import useCustomToast from "../../core/hooks/useCustomToast";
import CleanTopBar from "./CleanTopBar";

// const curpReg = new RegExp(
//   /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
// );
const rfcReg = new RegExp(/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i);

type personType = "moral" | "fisica";

type FormFields = {
  personType: personType | "";
  name?: string;
  rfc: string;
  street: string;
  exteriorNumber: string;
  interiorNumber: string;
  colony: string;
  city: string;
  postalCode: string;
  country: string;
  webSite?: string;
  sector: string;
  employesNumber?: number;
  services: string[];
  legalRepresentatives: LegalRepresentative[];
  REPSEActivities: string[];
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AdditionalUserDataForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const toast = useCustomToast()
  const [loading, setLoading] = useState(false);
  const { userInfo, mainCompany } = useGlobalState();
  const { invitedAs, plan } = usePlanState();
  const mainCompanyId = sessionStorage.getItem("companyId");
  const theme = useTheme();

  
console.log("info user v1",userInfo)
console.log("info mainCompanyId V2",mainCompanyId);
  const handleSubmit = async (values: FormFields) => {
    const body: EditCompanyInfoReqBody = {
    //  id: userInfo?.companies[0],
      id: mainCompanyId,
      name: values.name,
      type: values.personType,
      rfc: values.rfc,
      taxResidence: {
        street: values.street,
        outdoorNum: values.exteriorNumber,
        colonia: values.colony,
        cp: values.postalCode,
        interiorNumber: values.interiorNumber,
        city: values.city,
        country: values.country,
      },
      sector: values.sector,
      employeesSize: values.employesNumber,
      web: values.webSite,
      legalRepresentatives: values.legalRepresentatives.map((value) => ({
        amaterno: value.mname,
        apaterno: value.pname,
        curp: value.curp,
        name: value.name,
      })),
      repses: values.REPSEActivities.map((value) => ({
        name: value,
      })),
      servicesOffered: values.services?.join("|"),
      status: mainCompany?.status || "FREE",
      updatedData: true,
    };

    setLoading(true);
    try {
      const res: any = await CompaniesService.getInstance()
        .updateCompany(body)
        .finally(() => setLoading(false));
      if (res?.code === 409) {
        toast.warnign("Ya existe una empresa registrada con el mismo Nombre o RFC")
        return
      }
      if (res?.status) {
        setLoading(false);
        if (plan === "PREMIUM" || plan === "CORPORATIVO" || invitedAs === "client") {
          return history.push("/dashboard/home");
        }
        
        /* if (invitedAs === "client") {
          return history.push("/dashboard/home");
        } */
        return history.push("/payment");
        //return history.push("/dashboard/home");
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
      toast.error("Ocurrió un error inesperado");
      setLoading(false);
      //return history.push("/instructions"); //TODO: temporal hasta que arreglen la api
    }
  };

  const formik = useFormik<FormFields>({
    initialValues: {
      personType: (mainCompany?.type as any) || "",
      rfc: mainCompany?.rfc || "",
      name: mainCompany?.name || "",
      street: mainCompany?.taxResidence?.street || "",
      exteriorNumber: mainCompany?.taxResidence?.outdoorNum || "",
      interiorNumber: mainCompany?.taxResidence?.interiorNumber || "",
      colony: mainCompany?.taxResidence?.colonia || "",
      city: mainCompany?.taxResidence?.city || "",
      postalCode: mainCompany?.taxResidence?.cp || "",
      country: mainCompany?.taxResidence?.country || "",
      webSite: mainCompany?.web || "",
      employesNumber: mainCompany?.employeesSize,
      sector: "",
      services: [" "],
      legalRepresentatives: [
        {
          name: "",
          curp: "",
          pname: "",
          mname: "",
        },
      ],
      REPSEActivities: [""],
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      name: Yup.string().required("Campo requerido"),
      rfc: Yup.string().matches(rfcReg, { message: "Formato inválido" }).required("Campo requerido"),
      street: Yup.string().required("Campo requerido"),
      exteriorNumber: Yup.string(),
      interiorNumber: Yup.string(),
      colony: Yup.string().required("Campo requerido"),
      city: Yup.string().required("Campo requerido"),
      postalCode: Yup.string().required("Campo requerido"),
      country: Yup.string().required("Campo requerido"),
      webSite: Yup.string(),
      sector: Yup.string().required("Campo requerido"),
      employesNumber: Yup.number().required("Campo requerido").min(0),
      services: Yup.array(Yup.string().min(2, "Seleccione almenos 1 servicio")).required("Campo requerido"),
      legalRepresentatives: Yup.array(
        Yup.object({
          name: Yup.string().required("Campo requerido"),
          curp: Yup.string().required("Campo requerido"),
          pname: Yup.string().required("Campo requerido"),
        })
      ).min(1, "Debe tener al menos un representante legal"),
      REPSEActivities: Yup.array().min(1, "Debe tener al menos una actividad REPSE"),
    }),
  });

  const onPersonTypeChange = (e: any) => {
    formik.setFieldValue("personType", e.target.value);
  };

  const [servicesSelected, setServices] = React.useState([" "]);

  const handleChange = (event) => {
    const values: string[] = event.target.value;
    if (values.length === 0) {
      setServices([" "]);
    } else {
      setServices(values.filter((e) => e !== " "));
    }
  };

  const handleAddREPSE = () => {
    const aux = [...formik.values.REPSEActivities];
    aux.push("");
    formik.setFieldValue("REPSEActivities", aux);
  };

  const handleAddLegalRep = () => {
    const aux = [...formik.values.legalRepresentatives];
    aux.push({
      name: "",
      pname: "",
      mname: "",
      curp: "",
    });
    formik.setFieldValue("legalRepresentatives", aux);
  };

  const handleDeleteREPSE = (index: number) => {
    const aux = [...formik.values.REPSEActivities];
    aux.splice(index, 1);
    formik.setFieldValue("REPSEActivities", aux);
  };

  const handleDeleteLegalRep = (index: number) => {
    const aux = [...formik.values.legalRepresentatives];
    aux.splice(index, 1);
    formik.setFieldValue("legalRepresentatives", aux);
  };

  useEffect(() => {
    formik.setFieldValue("services", servicesSelected);
    // eslint-disable-next-line
  }, [servicesSelected]);

  return (
    <div className={classes.wrapper}>
        <CleanTopBar />
        <form className={classes.form} onSubmit={formik.handleSubmit}>
            <Typography variant="h4" color="primary"
                style={{ fontWeight: "normal", textAlign: "center", marginBottom: "1rem" }}>
                Para poder iniciar necesitamos algunos datos
            </Typography>
            {/* <LogoutOnLeave /> */}
            <Grid container>
                <Grid item md={12}>
                <Box marginTop={1} marginBottom={1} fontSize="body2.fontSize" fontWeight={600}>
                    Datos relevantes:
                </Box>

                <div className={classes.subContainer}>
                    <TextField
                        size="small"
                        error={formik.errors.name}
                        onChange={formik.handleChange}
                        touched={formik.touched.name}
                        value={formik.values.name}
                        placeholder="Nombre de tu empresa (razón social)*"
                        name="name"
                    />

                    <FormGroup row className={classes.personSelect}>
                    <FormControlLabel
                        className="mr-5"
                        control={
                            <Radio
                                className="mr-3"
                                checked={formik.values.personType === "moral"}
                                onChange={onPersonTypeChange}
                                value="moral"
                                name="moral"
                            />
                        }
                        label="Persona Moral*"
                    />

                    <FormControlLabel
                        control={
                        <Radio
                            className="mr-3"
                            checked={formik.values.personType === "fisica"}
                            onChange={onPersonTypeChange}
                            value="fisica"
                            name="fisica"
                        />
                        }
                        label="Persona Física*"
                    />
                    </FormGroup>

                    <TextField
                        size="small"
                        error={formik.errors.rfc}
                        onChange={formik.handleChange}
                        touched={formik.touched.rfc}
                        value={formik.values.rfc}
                        placeholder="RFC *"
                        name="rfc"
                    />

                    <TextField
                        size="small"
                        error={formik.errors.webSite}
                        onChange={formik.handleChange}
                        touched={formik.touched.webSite}
                        value={formik.values.webSite}
                        placeholder="Sitio web"
                        name="webSite"
                    />

                    <Select
                        name="sector"
                        touched={formik.touched.sector}
                        error={formik.errors.sector}
                        placeholder="Sector al que pertenece *"
                        onChange={(e: any) => {
                            formik.setFieldValue("sector", e.target.value);
                        }}>
                        <>
                            <option value="" selected disabled>
                                Sector al que pertenece *
                            </option>
                            {ACTIVITIES_LIST.map((sector, i) => (
                                <option key={i} value={sector}>
                                    {sector}
                                </option>
                            ))}
                        </>
                    </Select>

                    <TextField
                        size="small"
                        type="number"
                        error={formik.errors.employesNumber}
                        onChange={formik.handleChange}
                        touched={formik.touched.employesNumber}
                        value={formik.values.employesNumber}
                        placeholder="N° de empleados *"
                        name="employesNumber"
                    />

                    <FormControl variant="outlined" fullWidth margin="dense" error={!!formik.touched.services && !!formik.errors.services}>
                    <MatSelect
                        name="services"
                        value={formik.values.services}
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        multiple
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" />}
                        renderValue={(selected: any) => {
                        return (
                            <div>
                            {selected[0] === " " && <span>Servicios ofrecidos (uno o varios) *</span>}
                            {selected.map(
                                (value: string, idx: number) =>
                                !!value &&
                                value !== " " && (
                                    <span key={value}>
                                    {idx > 0 && ", "}
                                    {value.substr(0, 24)} {value.length > 24 && "..."}
                                    </span>
                                )
                            )}
                            </div>
                        );
                        }}
                        MenuProps={MenuProps}>
                        {SERVICES_LIST.map((name) => (
                        <option
                            key={name}
                            value={name}
                            style={{
                            backgroundColor: `${servicesSelected.includes(name) ? "#3297FD" : "unset"}`,
                            color: `${servicesSelected.includes(name) ? "#fff" : "unset"}`,
                            cursor: "pointer",
                            }}>
                            {name}
                        </option>
                        ))}
                    </MatSelect>
                    <FormHelperText>{!!formik.touched.services && formik.errors.services}</FormHelperText>
                    </FormControl>

                    <Box marginTop={1} marginBottom={1} fontSize="body2.fontSize" fontWeight={600}>
                        Domicilio Fiscal:
                    </Box>

                    <TextField
                        size="small"
                        error={formik.errors.street}
                        onChange={formik.handleChange}
                        touched={formik.touched.street}
                        value={formik.values.street}
                        placeholder="Calle *"
                        name="street"
                    />

                    <div className={classes.inputDoubleRow}>
                    <TextField
                        size="small"
                        error={formik.errors.exteriorNumber}
                        onChange={formik.handleChange}
                        touched={formik.touched.exteriorNumber}
                        value={formik.values.exteriorNumber}
                        placeholder="Número exterior *"
                        name="exteriorNumber"
                        type="text"
                    />

                    <TextField
                        size="small"
                        error={formik.errors.interiorNumber}
                        onChange={formik.handleChange}
                        touched={formik.touched.interiorNumber}
                        value={formik.values.interiorNumber}
                        placeholder="Número interior"
                        name="interiorNumber"
                        type="text"
                    />
                    </div>

                    <div className={classes.inputDoubleRow}>
                    <TextField
                        size="small"
                        error={formik.errors.colony}
                        onChange={formik.handleChange}
                        touched={formik.touched.colony}
                        value={formik.values.colony}
                        placeholder="Colonia *"
                        name="colony"
                    />

                    <TextField
                        size="small"
                        error={formik.errors.city}
                        onChange={formik.handleChange}
                        touched={formik.touched.city}
                        value={formik.values.city}
                        placeholder="Ciudad *"
                        name="city"
                    />
                    </div>

                    <div className={`${classes.inputDoubleRow} mb-3`}>
                    <TextField
                        size="small"
                        error={formik.errors.postalCode}
                        onChange={formik.handleChange}
                        touched={formik.touched.postalCode}
                        value={formik.values.postalCode}
                        placeholder="Código postal *"
                        name="postalCode"
                        type="number"
                    />

                    <TextField
                        size="small"
                        error={formik.errors.country}
                        onChange={formik.handleChange}
                        touched={formik.touched.country}
                        value={formik.values.country}
                        placeholder="País *"
                        name="country"
                    />
                    </div>

                    <Box marginTop={1}
                        marginBottom={1}
                        fontSize="body2.fontSize"
                        fontWeight={600}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between">
                        Representante(s) legal(es):
                        <Button startIcon={<AddIcon />} variant="contained" color="primary" size="small" onClick={handleAddLegalRep}>
                            Agregar
                        </Button>
                    </Box>

                    {formik.values.legalRepresentatives.map((legalRep, i) => (
                        <Box display="flex" marginBottom={2}>
                            <Box marginRight={1} marginTop={1}>
                                {i + 1}.
                            </Box>
                                <Box>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <TextField
                                                size="small"
                                                error={(formik.errors.legalRepresentatives?.[i] as FormikErrors<LegalRepresentative>)?.name}
                                                onChange={formik.handleChange}
                                                touched={formik.touched.legalRepresentatives?.[i].name}
                                                value={legalRep.name}
                                                placeholder="Nombre (s)"
                                                name={`legalRepresentatives[${i}].name`}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField
                                                size="small"
                                                error={(formik.errors.legalRepresentatives?.[i] as FormikErrors<LegalRepresentative>)?.pname}
                                                onChange={formik.handleChange}
                                                touched={formik.touched.legalRepresentatives?.[i].pname}
                                                value={legalRep.pname}
                                                placeholder="Apellido Paterno"
                                                name={`legalRepresentatives[${i}].pname`}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <TextField
                                                size="small"
                                                error={(formik.errors.legalRepresentatives?.[i] as FormikErrors<LegalRepresentative>)?.mname}
                                                onChange={formik.handleChange}
                                                touched={formik.touched.legalRepresentatives?.[i].mname}
                                                value={legalRep.mname}
                                                placeholder="Apellido Materno"
                                                name={`legalRepresentatives[${i}].mname`}
                                            />
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <TextField
                                                size="small"
                                                error={(formik.errors.legalRepresentatives?.[i] as FormikErrors<LegalRepresentative>)?.curp}
                                                onChange={formik.handleChange}
                                                touched={formik.touched.legalRepresentatives?.[i].curp}
                                                value={legalRep.curp}
                                                placeholder="CURP"
                                                name={`legalRepresentatives[${i}].curp`}
                                            />
                                        </div>
                                    </div>
                                </Box>
                            {formik.values.legalRepresentatives.length > 1 && (
                                <Box marginTop={0.5}>
                                    <IconButton size="small" onClick={() => handleDeleteLegalRep(i)}>
                                        <CloseIcon color="primary" />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>
                    ))}

                    <Box
                    marginTop={1}
                    marginBottom={1}
                    fontSize="body2.fontSize"
                    fontWeight={600}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    >
                    Actividad(es) registrada(s) en el REPSE:
                    <Button startIcon={<AddIcon />} variant="contained" color="primary" size="small" onClick={handleAddREPSE}>
                        Agregar
                    </Button>
                    </Box>

                    {formik.values.REPSEActivities.map((activity, i) => (
                    <Box display="flex" key={i}>
                        <TextField
                        size="small"
                        error={formik.errors.REPSEActivities?.[i] as string}
                        onChange={formik.handleChange}
                        touched={formik.touched.REPSEActivities}
                        value={activity}
                        placeholder="Escribir tal cual aparecen en el formato de registro REPSE*"
                        name={`REPSEActivities[${i}]`}
                        />
                        {formik.values.REPSEActivities.length > 1 && (
                        <IconButton size="small" onClick={() => handleDeleteREPSE(i)}>
                            <CloseIcon color="primary" />
                        </IconButton>
                        )}
                    </Box>
                    ))}
                </div>

                <Grid container item xs={12} justifyContent="center">
                    <div className="text-center">
                    <Typography style={{ display: "block" }} variant="caption">
                        * Campos obligatorios
                    </Typography>
                    <Button type="submit" className="mt-2" variant="contained" color="primary" disabled={loading}>
                        SIGUIENTE
                    </Button>
                    </div>
                </Grid>
                </Grid>
            </Grid>
            <Backdrop style={{ zIndex: 2000 }} open={loading} onClick={() => {}}>
                <CircularProgress color="secondary" />
            </Backdrop>
        </form>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
   wrapper: {
    display: "flex",
    justifyContent: "center",
    background: "linear-gradient(107.74deg, rgba(35, 71, 173, 0.9) 0%, rgba(82, 198, 186, 0.9) 48.55%, rgba(118, 120, 237, 0.9) 99.17%)"
  },
  form: {
    width: "100%",
    maxWidth: "50rem",
    minWidth: "30rem",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "0px 12px 15px -12px rgba(0, 0, 0, 0.12)",
    marginTop: theme.spacing(14),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  subtitle: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  subContainer: {
    marginBottom: theme.spacing(2),
  },
  personSelect: {
    padding: theme.spacing(1),
  },
  inputDoubleRow: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "1rem",
  }
}));

export default AdditionalUserDataForm;
