import React, { FC } from 'react'


const PolicyListItem: FC = ({ children }) => {
  return (
    <li style={{marginBottom:10}}>
      {children}
    </li>
  )
}


export default PolicyListItem
