import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export default function HelpIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 36 36">
      <rect x="36" width="36" height="36" rx="18" transform="rotate(90 36 0)" fill="white" fill-opacity="0.3" />
      <path
        d="M15.4146 20.5454V19.3696C15.4146 18.4764 15.5969 17.7244 15.9615 17.1138C16.3261 16.494 16.9732 15.8924 17.9029 15.3091C18.6412 14.8442 19.1699 14.425 19.4889 14.0513C19.817 13.6776 19.9811 13.2492 19.9811 12.7661C19.9811 12.3833 19.8079 12.0825 19.4615 11.8638C19.1152 11.6359 18.664 11.522 18.108 11.522C16.7317 11.522 15.123 12.0096 13.2818 12.9849L11.3814 9.26611C13.651 7.97184 16.0208 7.32471 18.4908 7.32471C20.5234 7.32471 22.1184 7.77132 23.276 8.66455C24.4335 9.55778 25.0123 10.7746 25.0123 12.3149C25.0123 13.4178 24.7525 14.3748 24.233 15.186C23.7226 15.9881 22.9023 16.7492 21.7721 17.4692C20.815 18.089 20.2135 18.5448 19.9674 18.8364C19.7304 19.119 19.6119 19.4562 19.6119 19.8481V20.5454H15.4146ZM14.8268 25.3306C14.8268 24.492 15.0683 23.8449 15.5514 23.3892C16.0436 22.9334 16.7636 22.7056 17.7115 22.7056C18.623 22.7056 19.3202 22.938 19.8033 23.4028C20.2955 23.8586 20.5416 24.5011 20.5416 25.3306C20.5416 26.16 20.2864 26.8026 19.776 27.2583C19.2747 27.714 18.5865 27.9419 17.7115 27.9419C16.8092 27.9419 16.1028 27.7186 15.5924 27.272C15.082 26.8162 14.8268 26.1691 14.8268 25.3306Z"
        fill="white"
      />
    </SvgIcon>
  );
}
