const WarnignIconSmYell = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.80865 0.473649C7.44925 -0.157884 6.55075 -0.157883 6.19135 0.473651L0.126491 11.1308C-0.232908 11.7623 0.216342 12.5517 0.93514 12.5517H13.0649C13.7837 12.5517 14.2329 11.7623 13.8735 11.1308L7.80865 0.473649ZM6.52601 8.45445H7.63577L7.86772 3.86207H6.29405L6.52601 8.45445ZM6.48053 9.46506C6.34408 9.59981 6.27586 9.79879 6.27586 10.062C6.27586 10.3158 6.3456 10.5133 6.48508 10.6543C6.62456 10.7922 6.82164 10.8611 7.07634 10.8611C7.32497 10.8611 7.51903 10.7906 7.65851 10.6496C7.79798 10.5054 7.86772 10.3096 7.86772 10.062C7.86772 9.80506 7.79798 9.60764 7.65851 9.46976C7.52206 9.33188 7.32801 9.26294 7.07634 9.26294C6.81558 9.26294 6.61698 9.33031 6.48053 9.46506Z"
        fill="#F4CD69"
      />
    </svg>
  );
};

export default WarnignIconSmYell;
