import { makeStyles } from "@material-ui/core";
import DocExample from "../../../../assets/img/documents/exampleDoc.png";
import InfonavitPositivo32D from "../../../../assets/img/documents/32D INFONAVIT POSITIVO.png";
import ImssPositivo32D from "../../../../assets/img/documents/32 IMSS positivo.png";
import SatPositivo32D from "../../../../assets/img/documents/32D SAT POSITIVO.png";
import ActaConstitutiva from "../../../../assets/img/documents/acta-constitutiva.png";
import AcuseIcsoeImss from "../../../../assets/img/documents/Acuse icsoe “imss”.png";
import AcuseSisubInfonavit from "../../../../assets/img/documents/Acuse sisub “infonavit”.png";
import CFDINomina from "../../../../assets/img/documents/CFDI de nómina.png";
import CFDIServicios from "../../../../assets/img/documents/CFDI de servicios (subir en formato XML).png";
import CFDImss from "../../../../assets/img/documents/CFDI IMSS (subir en fomato XML).png";
import CFDInfonavit from "../../../../assets/img/documents/CFDI INFONAVIT (subir en formato XML).png";
import ComprobantePagoCFDI from "../../../../assets/img/documents/COMPROBANTE DE PAGO DE CFDI.png"
import ComprobantePagoIsrRetenido from "../../../../assets/img/documents/Comprobante de pago de ISR retenido por salarios.png";
import ComprobantePagoIva from "../../../../assets/img/documents/comprobante de pago de iva.png";
import ComprobantePagoIsn from "../../../../assets/img/documents/Comprobante de pago ISN.png";
import ComprobantePagoSipare from "../../../../assets/img/documents/Comprobante de pago SIPARE.png";
import ComprobantesDomiciliarios from "../../../../assets/img/documents/Comprobantes domiciliarios .png";
import ConstanciaSituacionFiscal from "../../../../assets/img/documents/Constancia de situación fiscal.png";
import DeclaracionImpuestosIsr from "../../../../assets/img/documents/Declaración de impuestos ISR retenido por salarios.png";
import DeclaracionImpuestosIva from "../../../../assets/img/documents/Declaración de impuestos IVA.png";
import DeterminacionPrimaRiesgo from "../../../../assets/img/documents/Determinación de prima de riesgo vigente.png";
import IdentificacionOficial from "../../../../assets/img/documents/Identificación oficial.png";
import LiquidacionSuaBimestral from "../../../../assets/img/documents/Liquidación SUA bimestral.png";
import LiquidacionSuaMensual from "../../../../assets/img/documents/Liquidación SUA mensuaL.png";
import PagoImpuestosNominsaIsn from "../../../../assets/img/documents/Pago de impuestos sobre nomina ISN_.png";
import RegistroRepse from "../../../../assets/img/documents/registro repse.png";
import Sipare from "../../../../assets/img/documents/Sipare.png";
import TarjetaPatronal from "../../../../assets/img/documents/tarjeta patronal vigente.png";
import ComprobantePagoImss from "../../../../assets/img/documents/comprobante pago imss.jpg";
import ListaNomina from "../../../../assets/img/documents/listaNom.png"
import { expediente, cumplimiento, reporteo } from "./stages-documents";

export const ImageByName = (document) => {

  const classes = useStyles();
  const nombres = [...expediente, ...cumplimiento, ...reporteo];
  let imagen;
  let matchFound = false;
  nombres.forEach(nom => {
    if (nom.replace(/"/g, '').toLowerCase() === document.replace(/"/g, '').toLowerCase()) {
      matchFound = true;
      switch (document.toLowerCase()) {
        case 'acta constitutiva':
          imagen = <img src={ActaConstitutiva} className={classes.imgStyle} alt="Acta constitutiva" />;
          break;
        case 'poder notarial':
          imagen = <img src="ruta-de-la-imagen-poder-notarial.jpg" className={classes.imgStyle} alt="Poder notarial" />;
          break;
        case 'identificación oficial del representante legal vigente':
          imagen = <img src={IdentificacionOficial} className={classes.imgStyle} alt="Identificación oficial" />;
          break;
          case 'comprobante de pago en imss (transferencia sipare)':
            imagen = <img src={ComprobantePagoImss} className={classes.imgStyle} alt="Identificación oficial" />;
            break;  
        case 'comprobante de domicilio (antigüedad no mayor a 3 meses)':
          imagen = <img src={ComprobantesDomiciliarios} className={classes.imgStyle} alt="Comprobante de domicilio" />;
          break;
        case 'comprobante domiciliario (3 meses antigüedad)':
          imagen = <img src={ComprobantesDomiciliarios} className={classes.imgStyle} alt="Comprobante de domicilio" />;
          break;
          case 'comprobante de domicilio vigente':
            imagen = <img src={ComprobantesDomiciliarios} className={classes.imgStyle} alt="CComprobante de domicilio" />;
            break;
        case 'tarjeta patronal vigente':
          imagen = <img src={TarjetaPatronal} className={classes.imgStyle} alt="Tarjeta patronal" />;
          break;
          case 'tarjeta patronal':
            imagen = <img src={TarjetaPatronal} className={classes.imgStyle} alt="Tarjeta patronal" />;
            break;
        case 'constancia de situación fiscal':
          imagen = <img src={ConstanciaSituacionFiscal} className={classes.imgStyle} alt="Constancia de situación fiscal" />;
          break;
        case 'registro repse':
          imagen = <img src={RegistroRepse} className={classes.imgStyle} alt="Registro REPSE" />;
          break;
        case '32d imss positivo':
          imagen = <img src={ImssPositivo32D} className={classes.imgStyle} alt="32D IMSS positivo" />;
          break;
        case '32d infonavit positivo':
          imagen = <img src={InfonavitPositivo32D} className={classes.imgStyle} alt="32D infonavit positivo" />;
          break;
        case '32d sat positivo':
          imagen = <img src={SatPositivo32D} className={classes.imgStyle} alt="32D infonavit positivo" />;
        break
        case 'determinación de prima de riesgo vigente':
          imagen = <img src={DeterminacionPrimaRiesgo} className={classes.imgStyle} alt="Determinación de prima de riesgo vigente" />;
          break;
        case 'sipare':
          imagen = <img src={Sipare} className={classes.imgStyle} alt="Sipare" />;
          break;
        case 'pago sipare':
          imagen = <img src={Sipare} className={classes.imgStyle} alt="Sipare" />;
          break;
        case 'pago de impuestos sobre nómina isn':
          imagen = <img src={PagoImpuestosNominsaIsn} className={classes.imgStyle} alt="comprobante de pago isn" />;
          break; 
        case 'comprobante de pago isn':
          imagen = <img src={ComprobantePagoIsn} className={classes.imgStyle} alt="comprobante de pago isn" />;
        break;
        case 'constancia de situación fiscal cfs (rfc)':
          imagen = <img src="ruta-de-la-imagen-acuse-icsoe-imss.jpg" className={classes.imgStyle} alt="constancia de situación" />;
        break;
        case 'comprobante de pago sipare':
          imagen = <img src={ComprobantePagoSipare} className={classes.imgStyle} alt="pago sipare" />;
        break;

        case 'declaración del isr retenido por salarios': 
          imagen = <img src={ComprobantePagoIsrRetenido} className={classes.imgStyle} alt="ISR" />;
        break;
        case 'declaración de impuestos isr retenido por salarios':
          imagen = <img src={DeclaracionImpuestosIsr} className={classes.imgStyle} alt="ISR Retenidos" />;
        break;
        case 'acuse declaración isr retenido por salarios':
          imagen = <img src={DeclaracionImpuestosIsr} className={classes.imgStyle} alt="ISR Salarios" />;
        break;
        case 'pago isr retenido por salarios':
          imagen = <img src="ruta-de-la-imagen-acuse-icsoe-imss.jpg" className={classes.imgStyle} alt="Pago ISR" />;
        break;
        case 'comprobante de pago de isr retenido por salarios':
          imagen = <img src={ComprobantePagoIsrRetenido} className={classes.imgStyle} alt="Pago Salarios" />;
        break;
        case 'comprobante de pago de isr retenido por salarios ':
          imagen = <img src={ComprobantePagoIsrRetenido} className={classes.imgStyle} alt="ISR Salarios" />;
          break;
        case 'declaracion del iva':
          imagen = <img src={DeclaracionImpuestosIva} className={classes.imgStyle} alt="Declaracion IVA" />;
        break;
        case 'declaración de impuestos iva por salarios':
          imagen = <img src={DeclaracionImpuestosIva} className={classes.imgStyle} alt="Declaración de impuestos" />;
        break;
        case 'acuse declaración iva retenido por salarios':
          imagen = <img src={DeclaracionImpuestosIva} className={classes.imgStyle} alt="Iva Retenido" />;
        break;
        case 'pago iva':
          imagen = <img src="ruta-de-la-imagen-acuse-sisub-infonavit.jpg" className={classes.imgStyle} alt="Pago IVA" />;
        break;
        case 'comprobante de pago de iva':
          imagen = <img src={ComprobantePagoIva} className={classes.imgStyle} alt="Comprobante Iva" />;
        break;
        case 'sua mensual (ema)':
          imagen = <img src={LiquidacionSuaMensual} className={classes.imgStyle} alt="SUA Mensual" />;
        break;
        case 'cédula de determinación de cuotas "sua mensual"':
          imagen = <img src={LiquidacionSuaMensual} className={classes.imgStyle} alt="liquidación sua" />;
          break;
        case 'liquidación sua mensual': 
          imagen = <img src={LiquidacionSuaMensual} className={classes.imgStyle} alt="liquidación sua" />;
        break;
        case 'sua bimestral (eba)':
          imagen = <img src="ruta-de-la-imagen-acuse-sisub-infonavit.jpg" className={classes.imgStyle} alt="sua bimestral" />;
        break;
        case 'liquidación sua bimestral':
          imagen = <img src={LiquidacionSuaBimestral} className={classes.imgStyle} alt="liquidación sua bimestral" />;
        break;
        case 'cédula de determinación de cuotas "sua bimestral"':
          imagen = <img src={LiquidacionSuaBimestral} className={classes.imgStyle} alt="liquidación sua bimestral" />;
        break;
        case 'infonavit cfdi':
          imagen = <img src={CFDInfonavit} className={classes.imgStyle} alt="infonavit cfdi" />;
        break;
        case 'cfdi infonavit (subir en formato xml)':
          imagen = <img src={CFDInfonavit} className={classes.imgStyle} alt="cfdi infonavit" />;
        break;
        case 'cfdi infonavit':
          imagen = <img src={CFDInfonavit} className={classes.imgStyle} alt="cfdi infonavit" />;
          break;
        case 'cfdi nónima (subir en formato xml)':
          imagen = <img src={CFDINomina} className={classes.imgStyle} alt="cfdi nónima" />;
        break;
        case 'imss cfdi':
          imagen = <img src={CFDImss} className={classes.imgStyle} alt="cfdi imss" />;
        break;
        case 'cfdi imss (subir en formato xml)':
          imagen = <img src={CFDImss} className={classes.imgStyle} alt="cfdi imss" />;
        break;
        case 'cfdi imss':
        imagen = <img src={CFDImss} className={classes.imgStyle} alt="cfdi imss" />;
        break;
        case 'cfdi de servicios (subir en formato xml)':
          imagen = <img src={CFDIServicios} className={classes.imgStyle} alt="cfdi de servicios" />;
        break;
        case 'cfdi de servicios':
          imagen = <img src={CFDIServicios} className={classes.imgStyle} alt="cfdi de servicios" />;
        break;
        case 'cfdi de factura de servicios':
          imagen = <img src={CFDIServicios} className={classes.imgStyle} alt="cfdi de servicios" />;
        break;
        case 'comprobante de pago de cfdi':
          imagen = <img src={ComprobantePagoCFDI} className={classes.imgStyle} alt="comprobante de pago de cfdi" />;
        break;
        case 'cfdi por comprobante de pago de factura de servicios':
          imagen = <img src={ComprobantePagoCFDI} className={classes.imgStyle} alt="comprobante de pago de cfdi" />;
        break;
        case 'lista de nómina pagada':
          imagen = <img src={ListaNomina} className={classes.imgStyle} alt="lista de nómina pagada" />;
        break;
        case 'acuse icsoe "imss"':
          imagen = <img src={AcuseIcsoeImss} className={classes.imgStyle} alt="acuse icsoe imss" />;
        break;
        case 'acuse iscoe "imss"':
          imagen = <img src={AcuseIcsoeImss} className={classes.imgStyle} alt="acuse icsoe imss" />;
        break;
        case 'acuse sisub "infonavit"':
          imagen = <img src={AcuseSisubInfonavit} className={classes.imgStyle} alt="acuse sisub infonavit" />;
        break;
        case 'acuse declaración iva por salarios':
          imagen = <img src={DeclaracionImpuestosIva} className={classes.imgStyle} alt="iva salarios" />;
        break;
        case 'acuse de isr retenido por salarios':
          imagen = <img src={DeclaracionImpuestosIsr} className={classes.imgStyle} alt="salarios" />;
        break;
        case 'declaración de impuesto sobre nómina':
          imagen = <img src={PagoImpuestosNominsaIsn} className={classes.imgStyle} alt="impuestos" />;
          break;
          case 'comprobante de pago de impuesto sobre nómina':
            imagen = <img src={ComprobantePagoIsn} className={classes.imgStyle} alt="impuestos" />;
          break;
          case 'acuse de iva':
            imagen = <img src={DeclaracionImpuestosIva} className={classes.imgStyle} alt="impuestos" />;
            break;
        default:
          imagen = <img src={DocExample} className={classes.imgStyle} alt="Imagen por defecto" />;
        break;
      }
    }
  });
  if (!matchFound) {
    imagen = <img src={DocExample} className={classes.imgStyle} alt="Imagen por defectoe" />;
  }
  return (
    <center>
      <div style={{width:"100%",alignItems:'center'}}>
      {imagen}
      </div>
    </center>
  );
};

const useStyles = makeStyles({
  imgStyle: {
    maxHeight: '80%',
    maxWidth: '80%',
    width: 'auto',
    height: 'auto',
  }
});


