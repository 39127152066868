import { Backdrop, Button, CircularProgress, Typography } from "@material-ui/core";
import { Sat69Service } from "../core/services/sat69.service";
import FileSaver from "file-saver";
import { dateFormat } from "../components/stages/utils";
import { useState } from "react";

const Report69StatsPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  async function handleDownload() {
    setIsLoading(true);
    const res = await Sat69Service.getInstance().downloadExcel();
    setIsLoading(false);
    FileSaver.saveAs(res?.data, `Reporte-${dateFormat(new Date())}.xlsx`);
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" style={{ marginLeft: "-8px" }}>
          Análisis de facturación diaria
        </Typography>

        <Button color="primary" variant="contained" onClick={handleDownload}>
          Descargar excel
        </Button>
      </div>

      <div style={{ marginTop: "1rem" }}>
        <iframe
          title="test - Página 1"
          width={"100%"}
          height="600px"
          src="https://app.powerbi.com/view?r=eyJrIjoiMzU0YjhjZWMtZDhiZC00MmYwLTg0NzAtZDE1ODg5OGI4ZDYwIiwidCI6ImQyMmM0MWI2LTY2ODAtNDhiYS1iZGQzLWEzNDNiODJjNTdiOCJ9"
          frameBorder={0}
          allowFullScreen={true}
        ></iframe>
      </div>
      <Backdrop open={isLoading} style={{ zIndex: 1400 }}>
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
};

export default Report69StatsPage;
