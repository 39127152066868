import { AppBar, makeStyles } from "@material-ui/core";
import React from "react";
import KivitIcon from "../icons/KivitIcon";

export default function CleanTopBar() {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar}>
      <div className={classes.logoWrapper}>
        <KivitIcon htmlColor="white" fontSize="small" />
      </div>
    </AppBar>
  );
} 

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      alignItems: "center",
      borderBottomLeftRadius: 25,
      borderBottomRightRadius: 25,
      boxShadow: "0 0 black",
      flexDirection: "row",
      height: 72,
      padding: 20,
      //zIndex: 1400
    },
    logoWrapper: {
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        marginRight: theme.spacing(1),
      },
    },
  };
});
