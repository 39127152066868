import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import { AlertProps } from "../components/general/Alert";
import { BusinessHttp } from "../core/http/business.http";
import { RolesService } from "../core/services/roles.service";
import { UserService } from "../core/services/user.service";

const httpCompanies = new BusinessHttp();
const rolesService = RolesService.getInstance();
const userService = UserService.getInstance();

interface State {
  auxCompanies: Company[];
  companies: Company[];
  auxRoles: Role[];
  roles: Role[];
  auxUsers: User[];
  users: User[];
  loadingCompanies: boolean;
  loadingRoles: boolean;
  loadingUsers: boolean;
  infoAlert: AlertProps;
  createUser: (formData: CreateUserRequestBody) => any;
  filterCompanies: (query: string) => any;
  filterRoles: (query: string) => any;
  filterUsers: (query: string, sort: "a-z" | "z-a") => any;
  getCompanies: () => any;
  getRoles: () => any;
  listUsers: () => any;
  editUser: (formData: EditUserRequestBody) => any;
  deleteUser: (userId: string) => any;
  setAlert: (
    dialogInfo: Omit<AlertProps, "onCancel"> & {
      addCancelButton?: boolean;
      holdOnConfirm?: boolean;
    }
  ) => any; //TODO: revisar
}

const useManagementState = create<State>((set, get) => ({
  auxCompanies: [],
  companies: [],
  auxRoles: [],
  roles: [],
  auxUsers: [],
  users: [],
  loadingCompanies: false,
  loadingRoles: false,
  loadingUsers: false,
  infoAlert: { open: false, type: "error", title: "" },
  createUser: createUser(set, get),
  filterCompanies: filterCompanies(set, get),
  filterRoles: filterRoles(set, get),
  filterUsers: filterUsers(set, get),
  getCompanies: getCompanies(set, get),
  getRoles: getRoles(set, get),
  listUsers: listUsers(set, get),
  editUser: editUser(set, get),
  deleteUser: deleteUser(set, get),
  setAlert: setAlert(set, get),
}));

export default useManagementState;

//FUNCTIONS
function closeInfoAlert(set: NamedSet<State>) {
  set({
    infoAlert: {
      open: false,
      title: "",
    },
  });
}

function setAlert(set: NamedSet<State>, get: GetState<State>) {
  return (
    dialogInfo: Omit<AlertProps, "onCancel"> & {
      addCancelButton?: boolean;
      holdOnConfirm?: boolean;
    }
  ) => {
    set({
      infoAlert: {
        ...dialogInfo,
        onCancel: dialogInfo.addCancelButton ? () => closeInfoAlert(set) : undefined,
        onConfirm:
          (() => {
            !!dialogInfo.onConfirm && dialogInfo.onConfirm();
            !dialogInfo.holdOnConfirm && closeInfoAlert(set);
          }) ?? (() => closeInfoAlert(set)),
      },
    });
  };
}
/* -------------------------------------------------------------------------- */
/*                                  companies                                 */
/* -------------------------------------------------------------------------- */

function getCompanies(set: NamedSet<State>, get: GetState<State>) {
  return () => {
    if (get().companies?.length === 0) {
      set({ loadingCompanies: true });
      httpCompanies.findAllBusiness((resp: any) => {
        set({ companies: resp, auxCompanies: resp, loadingCompanies: false });
      }, console.log);
    }
  };
}

function filterCompanies(set: NamedSet<State>, get: GetState<State>) {
  return (query: string) => {
    const currentCompanies = get().auxCompanies;
    const result = query !== "" ? currentCompanies?.filter((company) => company.name.includes(query)) : currentCompanies;
    set({ companies: result });
  };
}

/* -------------------------------------------------------------------------- */
/*                                    roles                                   */
/* -------------------------------------------------------------------------- */
function getRoles(set: NamedSet<State>, get: GetState<State>) {
  return async () => {
    set({ loadingRoles: true });
    try {
      const roles = (await rolesService.listRoles()).data;
      const dataMapped = roles.map<Role>((role) => {
        return {
          label: role.name,
          value: role.id,
        };
      });
      set({ roles: dataMapped, auxRoles: dataMapped, loadingRoles: false });
    } catch (error) {
      set({ loadingRoles: false });
      console.log(error);
    }
  };
}

function filterRoles(set: NamedSet<State>, get: GetState<State>) {
  return (query: string) => {
    const currentRoles = get().auxRoles;
    const result = query !== "" ? currentRoles?.filter((role) => role.label.includes(query)) : currentRoles;
    set({ roles: result });
  };
}

/* -------------------------------------------------------------------------- */
/*                                    users                                   */
/* -------------------------------------------------------------------------- */
function listUsers(set: NamedSet<State>, get: GetState<State>) {
  return () => {
    set({ loadingUsers: true });
    userService
      .listUsersNew()
      .then((res) => {
        set({ users: res.users, auxUsers: res.users });
      })
      .finally(() => set({ loadingUsers: false }));
  };
}

function filterUsers(set: NamedSet<State>, get: GetState<State>) {
  return (query: string, sort: "a-z" | "z-a") => {
    query = query.toLowerCase();
    const currentUsers = get().auxUsers ?? [];
    const result =
      query !== ""
        ? currentUsers?.filter((user) => {
            const fullName = `${user.name} ${user.lastName}`.toLowerCase();
            const email = user.email.toLowerCase();
            const roles = Array.isArray(user.roles) ? user.roles : []; //

            return (
              fullName.includes(query) ||
              email.includes(query) ||
              roles.some((role) => role.toLowerCase().includes(query))
            );
          })
        : currentUsers;

    result.sort((a, b) => {
      if (sort === "a-z") {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });

    set({ users: result });
  };
}


function createUser(set: NamedSet<State>, get: GetState<State>) {
  return async (formData: CreateUserRequestBody) => {
    return new Promise<boolean>((resolve, reject) => {
      userService
        .createUser(formData)
        .then((user) => {
          get().listUsers();
          set({
            infoAlert: {
              open: true,
              title: "Usuario creado correctamente",
              type: "success",
              onConfirm: () => set({ infoAlert: { open: false, title: "" } }),
            },
          });
          resolve(true);
        })
        .catch((error) => {
          get().setAlert({
            open: true,
            title: error.response?.data?.message?.includes("Exception: 409")
              ? "El usuario ya existe"
              : "Ocurrió un error al crear el usuario",
            type: "error",
          });

          reject(false);
        });
    });
  };
}

function editUser(set: NamedSet<State>, get: GetState<State>) {
  return (formData: EditUserRequestBody) => {
    return new Promise<boolean>((resolve, reject) => {
      userService
        .editUser(formData)
        .then((response) => {
          const currentUsers = get().users;
          const newUsers = [...currentUsers];
          const newUserI = newUsers.findIndex((user) => user.id === formData.id);
          newUsers[newUserI] = {
            ...newUsers[newUserI],
            ...formData,
          };
          set({
            users: newUsers,
            auxUsers: newUsers,
          });
          get().setAlert({
            open: true,
            title: "Usuario actualizado correctamente",
            type: "success",
          });
          resolve(true);
        })
        .catch((error) => {
          get().setAlert({
            open: true,
            title: "Ocurrió un error al actualizar el usuario",
            type: "error",
          });
          reject(false);
        });
    });
  };
}

function deleteUser(set: NamedSet<State>, get: GetState<State>) {
  return (userId: string) => {
    userService
      .deleteUser(userId)
      .then(() => {
        const currentUsers = get().users;
        const newUsers = currentUsers.filter((user) => user.id !== userId);
        set({
          users: newUsers,
          auxUsers: newUsers,
        });
        get().setAlert({
          open: true,
          title: "Usuario borrado correctamente",
          type: "success",
        });
      })
      .catch((error) => {
        get().setAlert({
          open: true,
          title: "El usuario ya no se pudo eliminar",
          type: "error",
        });
      });
  };
}

/*function filterUsers(set: NamedSet<State>, get: GetState<State>) {
  return (query: string, sort: "a-z" | "z-a") => {
    query = query.toLowerCase();
    const currentUsers = get().auxUsers;
    const result =
      query !== ""
        ? currentUsers?.filter(
            (user) =>
              `${user.name} ${user.lastName}`.toLowerCase().includes(query) ||
              user.email.toLowerCase().includes(query) ||
              user.roles.some((role) => role.toLowerCase().includes(query))
          )
        : currentUsers;
    result.sort((a, b) => {
      if (sort === "a-z") {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
    set({ users: result });
  };
}*/