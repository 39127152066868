import React from 'react';
import { Backdrop, Box, Fade, IconButton, makeStyles, Modal, Typography } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    backgroundColor: 'white', // Fondo blanco
    border: '0px solid #e2e8f0',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    width: 'auto', // Ajustar el tamaño al contenido
    maxHeight: '80vh',
    overflow: 'auto',
  },
  closeButton: {
    position: 'absolute',
    left: 8,
    top: 8,
    color: theme.palette.grey[500],
  },
}));

const DocumentGeneratorInfoModal = ({ open, handleClose, data }) => {
  const classes = useStyles();

  // Función para formatear la fecha a formato CDMX (UTC-6 o UTC-5 en horario de verano)
  const formatFecha = (fecha: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: 'America/Mexico_City',
    };
    return new Date(fecha).toLocaleString('es-MX', options);
  };

  return (
    <Modal
      aria-labelledby="pdf-modal-title"
      aria-describedby="pdf-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className={classes.paper}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" color='primary' style={{ textAlign: 'center', marginTop:'10px' }}>
            Generador
          </Typography>
          <Typography><strong>Información del generador:</strong></Typography>
          <Typography>Comentario: {data.comment}</Typography>
          <Typography>Estatus: {data.status === 'REJECTED' ? 'Rechazado' : 'Aceptado'}</Typography>
          <Typography>Fecha de actualización: {formatFecha(data.lastModifiedDate)}</Typography>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DocumentGeneratorInfoModal;
