import { authHeader, axiosGeneradoresRequest } from "../../configs/axios.config";

export class GeneradorCatalogue {
  private static instance: GeneradorCatalogue;

  private constructor() {}

  public static getInstance(): GeneradorCatalogue {
    if (!GeneradorCatalogue.instance) {
        GeneradorCatalogue.instance = new GeneradorCatalogue();
    }
    return GeneradorCatalogue.instance;
  }

  async createCatalogue(documentId: string, body : any) {
    try {
      const { data } = await axiosGeneradoresRequest.post<any>(`/catalogueGenerador/${documentId}`,body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async enableCatalogue( documentId : string) {
    try {
    const { data } = await axiosGeneradoresRequest.post(`/catalogueGenerador/id/${documentId}/enable`, {}, {headers: authHeader()});
    return data;
    } catch (error) {
      throw error;
    }
  }

  async disabledCatalogue( documentId : string) {
    try {
    const { data } = await axiosGeneradoresRequest.post(`/catalogueGenerador/${documentId}/disable`, {}, {headers: authHeader()});
    return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllCatalogue( documentId : string) {
    try {
    const { data } = await axiosGeneradoresRequest.get(`/catalogueGenerador/idCompany/${documentId}/all`, {headers: authHeader()});
    return data;
    } catch (error) {
      throw error;
    }
  }

  async getCatalogueJson( documentId : string) {
    try {
     const { data } = await axiosGeneradoresRequest.get(`/catalogueGenerador/fileToJson/${documentId}`, {headers: authHeader()});
     return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteCatalogue( documentId : string) {
    try {
    const { data } = await axiosGeneradoresRequest.delete(`/catalogueGenerador/idFile/${documentId}`, {headers: authHeader()});
    return data;
    } catch (error) {
      throw error;
    }
  }

  async getExternalTreeCatalogue( companyId : string) {
    try {
     const { data } = await axiosGeneradoresRequest.get(`/catalogueGenerador/externalTreeCatalogue/idCompany/${companyId}`, {headers: authHeader()});
     return data;
    } catch (error) {
      throw error;
    }
  }


 /* async createFormGenerador(idCompany: string, externalId: string, idProvider: string) {
    try {
      const { data } = await axiosGeneradoresRequest.post<any>(`/generador/init/idCompany/${idCompany}/externalId/${externalId}/idProvider/${idProvider}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }*/
 /* async patchGeneralForm(id: string, body: any) {
    try {
      const { data } = await axiosGeneradoresRequest.patch<any>(`/generador/general/id/${id}`, body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }*/

}