import React, { useState, VFC } from 'react';
import {
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  FormControl,
  IconButton,
  Box,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useReduxFormStyles } from './useReduxFormStyles';

interface RenderTextFieldProps {
  defaultValue?:string;
  input: any;
  label?: string;
  externLabel?: boolean;
  required?: boolean;
  disabled?: boolean;
  type?: string,
  icon?: JSX.Element,
  passwordAction?: boolean,
  placeholder?: string;
  onKeyUp: (value: any) => void,
  meta: {
    error?: string;
    touched: boolean;
  }
}

const RenderTextField: VFC<RenderTextFieldProps> = (props) => {
  const {
    defaultValue,
    input,
    label,
    externLabel,
    required,
    disabled,
    type,
    icon,
    passwordAction,
    onKeyUp,
    meta: {
      error,
      touched
    }
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const classes = useReduxFormStyles();
  
  const RootComponent = externLabel ? Box : React.Fragment;
  const rootComponentProps = externLabel ? {display: 'inline-flex', width:'100%', flexDirection:'column' } : null;

  return (
    <RootComponent {...rootComponentProps}>
      {label && externLabel && <label className={classes.fieldLabel}>{label}</label>}
      <FormControl
        variant="outlined"
        color="primary"
        fullWidth
        margin="dense"
        error={touched && error !== undefined && error.length > 0}
      >
        {label && !externLabel && <InputLabel>{label}</InputLabel>}
        <OutlinedInput
          {...input}
          value={defaultValue}
          disabled={!!defaultValue || disabled}
          type={passwordAction ? (showPassword ? 'text' : 'password') : type}
          onChange={event => input.onChange(event)}
          onKeyUp={() => onKeyUp && onKeyUp(input.value)}
          placeholder={label}
          required={required}
          label={label && !externLabel ? label : undefined}
          endAdornment={
            <>
              <InputAdornment position="end">
                {
                  passwordAction ?
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((oldShowPassword) => !oldShowPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    :
                    <>
                      {icon}
                    </>
                }
              </InputAdornment>
            </>
          }
        />

        <FormHelperText>{(touched && error) && error}</FormHelperText>
      </FormControl>
    </RootComponent>
  );
};

export default RenderTextField;
