/* eslint-disable no-useless-escape */
import React from "react";

import { Button, Grid, IconButton, Typography, makeStyles } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

type Props = {
  user: DashboardUser;
  onCancel: (args?: any) => any;
  userType: string;
  onSubmit?: (args?: any) => any;
  disabled?: boolean;
};


function UserDeleteConfirm({ user, onCancel, userType, onSubmit, disabled }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Grid container>
        <Grid item md={12}>
          <Grid container xs={12} justifyContent="space-between" alignItems="center" className="mb-4">
            <div style={{ height: "48px", width: "48px" }}></div>
            <div style={{ height: "48px", width: "48px" }}></div>
            <IconButton aria-label="close" onClick={onCancel}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Grid>

          <Typography className={classes.title}>{`¿Estas seguro que quieres eliminar este ${userType}?`}</Typography>

          <Grid style={{ marginTop: "100px" }}>
            <Button className={classes.dangerBtn} variant="contained" color="inherit" onClick={onSubmit} disabled={disabled}>
              ELIMINAR
            </Button>
          </Grid>
          <Grid className="mt-4 mb-2">
            <Button variant="contained" color="primary" onClick={onCancel}>
              CANCELAR
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    textAlign: "center",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  subtitle: {
    width: "60%",
    margin: "3rem auto",
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
  dangerBtn: {
    color: "#fff",
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export default UserDeleteConfirm;
