import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core';

/*const options = [
  'Editar',
  'Archivar'
];*/

const options = [
  'Eliminar',
];

const DotsMenuCard = ({disabled,handleDelete,item}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option: string) => {
    console.log(option);
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.menu}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.menu }}
      >
        {options.map((option) => (
        //  <MenuItem key={option} onClick={() => handleOptionSelect(option)}>
          <MenuItem key={option} onClick={() => handleDelete(item)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  menu: {
    padding: 0,
  }
}))

export default DotsMenuCard;