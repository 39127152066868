import { Avatar, Box, IconButton, Menu, MenuItem, Paper, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router";
import useCustomToast from "../../../core/hooks/useCustomToast";
import useGlobalState from "../../../store/globalState";
import { GroupCompanyChildren } from "../../../types/global";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useState } from "react";
import { CompaniesService } from "../../../core/services/companies.service";

const MenuCard = ({ company, onLoading }: { company: GroupCompanyChildren; onLoading: (loading: boolean) => any }) => {
  const { getCompanyData, setMainCompany, getCompaniesByGroup } = useGlobalState();
  const history = useHistory();
  const toast = useCustomToast();
  const isArchived: boolean = company.archived === "true" ? true : false;
  const {typeService,setTypeService} = useGlobalState();

  const handleClick = async () => {
    try {
      onLoading(true);
      const tempTypeService = typeService;
      const comp = await getCompanyData(company.id);
      setMainCompany(comp as any);
      setTypeService(tempTypeService);
      history.push("/dashboard/home");
    } catch (error) {
      console.log(error);
      toast.error("Error al cargar datos");
    } finally {
      onLoading(false);
    }
  };

  const handleArchive = (event, company: GroupCompanyChildren) => {
    event?.stopPropagation();
    onLoading(true);
    CompaniesService.getInstance()
      .archiveCompany(company?.id, !isArchived)
      .then((res) => {
        toast.success("Estado actualizado");
        getCompaniesByGroup();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error al actualizar estado");
      })
      .finally(() => {
        onLoading(false);
      });
  };

  return (
    <Box style={{ width: "30%", cursor: isArchived ? "not-allowed" : "pointer" }} onClick={isArchived ? null : handleClick}>
      <Paper
        elevation={isArchived ? 0 : 2}
        style={{ padding: "0 1rem", position: "relative", backgroundColor: isArchived ? "#E0E0E0" : "#fff" }}
      >
        <div style={{ position: "absolute", right: 0, top: 0 }}>
          <OptionsMenu onArchive={(e) => handleArchive(e, company)} isArchived={isArchived} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Avatar
            aria-label="recipe"
            style={{
              backgroundColor: "#2347AD80",
              width: 87,
              height: 87,
              fontSize: "3rem",
            }}
          >
            {company.name[0]?.toUpperCase()}
          </Avatar>
          <div
            style={{
              width: "100%",
              fontSize: "1.2rem",
              fontWeight: "bold",
              marginTop: "2rem",
              paddingBottom: ".3rem",
              borderBottom: "1px dashed #000",
              minHeight: "64px",
            }}
          >
            {company.name}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0 1rem 1rem 1rem",
          }}
        >
          <div
            style={{
              fontSize: "0.75rem",
              color: "#979797",
            }}
          >
            RFC. {company.rfc}
          </div>
          <div style={{ fontSize: ".75rem" }}>Email: {company.email}</div>
          <div style={{ fontSize: ".75rem" }}>
            Lista negra del SAT:{" "}
            <small>
              <b>{company.blackList ? "PERTENECE" : "NO PERTENECE"}</b>
            </small>
          </div>
        </div>
      </Paper>
    </Box>
  );
};

export default MenuCard;

const OptionsMenu = ({ onArchive, isArchived }: { onArchive: (event) => any; isArchived: boolean }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event?.stopPropagation();
    setAnchorEl(null);
  };

  const handleArchive = (event) => {
    event.stopPropagation();
    handleClose(event);
    onArchive(event);
  };

  return (
    <div>
      <Tooltip title="Opciones" placement="top">
        <IconButton aria-controls="options-menu" aria-label="options" onClick={handleClick} style={{ backgroundColor: "unset" }}>
          <MoreVertIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} disableScrollLock={true}>
        <MenuItem onClick={handleArchive}>{isArchived ? "Desarchivar" : "Archivar"}</MenuItem>
      </Menu>
    </div>
  );
};
