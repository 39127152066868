import { Avatar, Box, Button, Card, CardHeader, makeStyles, Typography } from "@material-ui/core";

interface MenuCardProps {
  title: string;
  counter?: number;
  Icon: JSX.Element;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  primaryButtonAction?: () => void;
  secondaryButtonAction?: () => void;
  gap?: string;
}
const MenuCard = (props: MenuCardProps) => {
  const { gap = "10%" } = props;
  const classes = useStyles();
  return (
    <Card>
      <CardHeader
        classes={{ action: classes.cardActions, avatar: classes.avatarContainer }}
        avatar={<Avatar className={classes.avatar}>{props.Icon}</Avatar>}
        disableTypography
        title={
          <Box display="flex" gridGap={gap} alignItems="center">
            <Typography variant="h6" noWrap>
              {props.title}{props.counter > -1 && ":"}
            </Typography>
            <Typography variant="h3">{props.counter}</Typography>
          </Box>
        }
        action={
          <Box display="flex" gridGap={5} flexDirection="column">
            {props.primaryButtonAction ? (
              <Button fullWidth onClick={props.primaryButtonAction} variant="contained" color="primary" size="small">
                {props.primaryButtonText}
              </Button>
            ) : (
              //@ts-ignore
              <Button disabled size="small" style={{ visibility: "hidden", order: "2" }}>
                ---
              </Button>
            )}
            {props.secondaryButtonAction ? (
              <Button fullWidth onClick={props.secondaryButtonAction} variant="contained" color="primary" size="small">
                {props.secondaryButtonText}
              </Button>
            ) : (
              //@ts-ignore
              <Button disabled size="small" style={{ visibility: "hidden" }}>
                ---
              </Button>
            )}
          </Box>
        }
      />
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  avatarContainer: {
    marginRight: "5%",
  },
  cardActions: {
    width: 165,
  },
}));

export default MenuCard;
