import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export default function UserShowIcon(props:SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 15 15">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 15C11.6421 15 15 11.6422 15 7.5C15 3.35785 11.6421 0 7.5 0C3.35791 0 0 3.35785 0 7.5C0 11.6422 3.35791 15 7.5 15ZM3.07043 7.76114C4.22107 6.05215 5.7804 5 7.5 5C9.2196 5 10.7789 6.05215 11.9296 7.76321C12.0234 7.90323 12.0234 8.09885 11.9296 8.23886C10.7789 9.94785 9.2196 11 7.5 11C5.7804 11 4.22107 9.94785 3.07043 8.23679C2.97656 8.09677 2.97656 7.90115 3.07043 7.76114ZM5.61108 7.86206C5.53931 9.14069 6.48193 10.1929 7.62329 10.1125C8.56409 10.0446 9.32812 9.19012 9.38892 8.13797C9.46069 6.85931 8.51807 5.80713 7.37671 5.88745C6.43408 5.95334 5.67004 6.80783 5.61108 7.86206ZM6.48376 7.92587C6.44324 8.61359 6.95142 9.17981 7.56628 9.1366C8.07251 9.10159 8.48499 8.64243 8.51807 8.07205C8.55859 7.38434 8.05042 6.81812 7.43555 6.86136C6.92749 6.89841 6.51501 7.35757 6.48376 7.92587Z"/>
    </SvgIcon>
  );
}
