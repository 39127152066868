import { Grid, Link, Typography } from '@material-ui/core';
import React from 'react';
import RenderCheckbox from '../../core/redux-form-fields/RenderCheckbox';

interface Props {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickLink: () => void;
  text: string;
  linkText: string;
  name: string;
}

const LinkCheckbox = ({ checked, onChange, onClickLink, text, linkText, name }: Props) => {
  return (
    <Grid item md={ 12 } xs={ 12 }>
      <RenderCheckbox
        color="primary"
        name={name}
        checked={checked}
        onChange={onChange}
        label={(
          <Typography variant="caption" style={{color: "#A8A8A8"}}>
            {text} &nbsp;
          </Typography>
        )}
      />

      <Typography variant="caption" style={{fontWeight: 'bold'}}>
        <Link
          className="pointer terms-text"
          onClick={ onClickLink }
          color="primary"
        >
          {linkText}
        </Link>
      </Typography>
    </Grid>
  )
}

export default LinkCheckbox;
