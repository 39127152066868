import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Grid, Link, List, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import BusinessIcon from "@material-ui/icons/Business";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import SettingsIcon from '@material-ui/icons/Settings';

import UsersAdminIcon from "../components/icons/UsersAdminIcon";
import ManagementItem from "../components/management/ManagementItem";
import UserControls from "../components/management/UserControls";
import IndexedSection from "../components/general/IndexedSection";
import Users from "../components/management/users/Users";
import Roles from "../components/management/roles/Roles";
import Companies from "../components/management/companies/Companies";
import Templates from "../components/management/templates/Templates";
import { useSearchParams } from "../core/hooks/useSearchParams";
import { createBrowserHistory } from "history";
import Alert from "../components/general/Alert";
import EditCompanyIcom from "../components/icons/EditCompanyIcon";
import { useHistory } from "react-router";
import ColoredAvatar from "../components/general/ColoredAvatar";
import { useGetProfileURL } from "../core/hooks/useGetProfileURL";
import useGlobalState from "../store/globalState";
import useManagementState from "../store/managementState";
import usePlanState from "../store/planState";
import GoBackButton from "../components/general/GoBackButton";
import SettingsPage from "./SettingsPage";

export type FormType = "edit" | "create";

type SectionOptionsId = "Servicios" | "Equipos" | "Usuarios" | "Roles" | "Empresas" | "Principal" | "Configuración";

type SectionOptions = {
  id: SectionOptionsId;
  label?: string;
};

/**Management main page.*/
const Management = () => {
  const { filterRoles, filterCompanies, filterUsers, infoAlert } = useManagementState();
  const { mainCompany } = useGlobalState();
  const userType = mainCompany.invitedAs;
  const [sectionSelected, setSectionSelected] = useState<SectionOptions>({
    id: "Principal",
  });
  let [searchParams, setSearchParams] = useSearchParams({ tab: "" });
  const pictureURL = useGetProfileURL(mainCompany.profilePictureId);
  const history = useHistory();
  const plan = usePlanState((s) => s.plan);

  /**
   * Handler executed when the user selects a section on the main menu or when the user clicks 'Administracion'
   * laben on the breadcrumb.
   */
  const handleSectionSelected = (option: SectionOptions) => {
    setSectionSelected(option);
    setSearchParams({ tab: option.id });
  };

  /**
   * Function that gets tab query param from url and sets sectionSelected state variable based on query param
   * value. if 'tab' doesn't match SectionOptions type, the function will set SectionSelected value to 'Principal'
   * */
  const updateSectionFromURL = () => {
    const section = searchParams.get("tab");
    switch (section) {
      case "Equipos":
      case "Usuarios":
      case "Roles":
      case "Empresas":
      case "Servicios":
        setSectionSelected({ id: section });
        break;
      default:
        setSectionSelected({ id: "Principal" });
        break;
    }
  };

  /**
   * Effect executed when Management component renders at firts time or
   * when the user clicks backward/forward browser button.
   * */
  useEffect(() => {
    updateSectionFromURL();
    //Event subscription to backward/forward browser buttons.
    const backListener = createBrowserHistory().listen((location) => {
      if (location.action === "POP") {
        updateSectionFromURL();
      }
    });
    return () => {
      /** Unsubscribe event listener */
      backListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**Handler executed when the user types on search user input at the top of the page. */
  const handleSearch = (query: string, sortValue: "a-z" | "z-a") => {
    switch (sectionSelected.id) {
      case "Usuarios":
        filterUsers(query, sortValue);
        break;
      case "Roles":
        filterRoles(query);
        break;
      case "Empresas":
        filterCompanies(query);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {/* header with breadcrumbs, search and filter users controls. */}
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" gridGap={20} marginTop="20px" marginBottom="25px">
            <ColoredAvatar src={pictureURL} backgroundColor={!!pictureURL ? "white" : undefined} size={57}>
              <BusinessIcon style={{ fontSize: 35 }} />
            </ColoredAvatar>
            <Typography variant="h6">{mainCompany?.name}</Typography>
            <div style={{ marginRight: "1rem", textAlign: "end", flexGrow: 1 }}>
              <GoBackButton />
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="large" />} aria-label="breadcrumb">
            <Typography
              variant="h5"
              color="primary"
              component={Link}
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                e.preventDefault();
                handleSectionSelected({ id: "Principal" });
              }}
            >
              Administración
            </Typography>
            {sectionSelected.id !== "Principal" && (
              <Typography variant="h5" color="primary">
                {sectionSelected.label ?? sectionSelected.id}
              </Typography>
            )}
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sm md={4}>
          {sectionSelected.id !== "Principal" && <UserControls onSearch={handleSearch} searchTitle={sectionSelected.id} />}
        </Grid>
      </Grid>
      {/* Main menu of management section */}
      <IndexedSection value={sectionSelected.id} index="Principal">
        <List>
          <ManagementItem
            text="Usuarios"
            Icon={<UsersAdminIcon color="primary" style={{ fontSize: 26 }} />}
            onClick={() => handleSectionSelected({ id: "Usuarios" })}
          />
          <ManagementItem
            text="Editar datos empresa"
            Icon={<EditCompanyIcom color="primary" style={{ fontSize: 26 }} />}
            onClick={() => history.push("/dashboard/management/edit-profile")}
          />
          {userType === "provider" ? null : (
            <ManagementItem
              text="Configuración"
              Icon={<SettingsIcon color="primary" style={{ fontSize: 26 }} />}
              onClick={() => handleSectionSelected({ id: "Configuración" })}
            />
          )}
          {/* <ManagementItem
            text="Configuración"
            Icon={<SettingsIcon color="primary" style={{ fontSize: 26 }} />}
            onClick={() => history.push("/dashboard/management/settings")}
          /> */}
          {/* <ManagementItem text="Gestión de pago o plan" Icon={<CardIcon color="primary" style={{ fontSize: 26 }} />} />
          <ManagementItem text="Facturación" Icon={<BillingIcon color="primary" style={{ fontSize: 26 }} />} /> */}
          {/* <ManagementItem text="Roles" Icon={<FlagIcon />} onClick={() => handleSectionSelected('Roles')} />
          <ManagementItem text="Empresas" Icon={<WorkIcon />} onClick={() => handleSectionSelected('Empresas')} /> */}
          {/*plan === "CORPORATIVO" && (
            <ManagementItem
              text="Set de documentos"
              Icon={<LibraryBooksIcon color="primary" style={{ fontSize: 30 }} />}
              onClick={() =>
                handleSectionSelected({
                  id: "Servicios",
                  label: "Parametrización de Set de Documentos",
                })
              }
            />
            )*/}
          {/* <ManagementItem text="Equipos" Icon={<GroupIcon />} onClick={() => handleSectionSelected('Equipos')} /> */}
        </List>
      </IndexedSection>
      {/* Children sections. */}
      <IndexedSection value={sectionSelected.id} index="Usuarios">
        <Users />
      </IndexedSection>
      <IndexedSection value={sectionSelected.id} index="Roles">
        <Roles />
      </IndexedSection>
      <IndexedSection value={sectionSelected.id} index="Empresas">
        <Companies />
      </IndexedSection>
      <IndexedSection value={sectionSelected.id} index="Servicios">
        <Templates />
      </IndexedSection>
      <IndexedSection value={sectionSelected.id} index="Configuración">
        <SettingsPage />
      </IndexedSection>
      {/* <IndexedSection value={sectionSelected} index="Equipos">Equipos</IndexedSection> */}
      {infoAlert?.open && <Alert {...infoAlert} />}
    </>
  );
};

export default Management;
