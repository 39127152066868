import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormGeneral from './FormGeneral';
import FormGeolocation from './FormGeolocation';
import FormPhotographic from './FormPhotographic';
import FormGeneralNext from './FormGeneralNext';
import FormAnexos from './FormAnexos';
import { useStyles } from './styles.js';
import TextField from "../../inputs/inputs-v2/RenderTextFieldV2";
import { Box, Button, IconButton } from '@material-ui/core';
import LogoTP from '../../../assets/img/LogoTP.png'
import { useHistory } from 'react-router';
import { GeneradoresServices } from '../../../core/services/generadores.service';
import useGeneralFormCompleteState from '../../../store/generadores/generalFormState';
import useCustomToast from '../../../core/hooks/useCustomToast';
import useUiState from '../../../store/uiState';
import { Delete } from '@material-ui/icons';

function base64ToObjectURL(base64) {
  // Decodificar el string base64 y convertirlo en binario
  let byteString = atob(base64);
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // Crear un Blob a partir de los datos binarios
  let blob = new Blob([ab], { type: 'image/png' });

  // Crear un ObjectURL a partir del Blob
  let url = URL.createObjectURL(blob);

  return url;
}

const FormSelector = ({closeFormGenerator}) => {
  const classes = useStyles();
  const toast = useCustomToast();
  const [selectedForm, setSelectedForm] = useState('FormGeneral');
  const history = useHistory();
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);
  const generadorId = useGeneralFormCompleteState((state) => state.idForm);
  const providerLogoId = useGeneralFormCompleteState((state) => state.providerLogoId);
  const setProviderLogoId = useGeneralFormCompleteState((state) => state.setProviderLogoId);
  const formGeneratedCode = useGeneralFormCompleteState((state) => state.formGeneratedCode);
  const { setShowGenericLoader } = useUiState();
  
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (providerLogoId !== '' && providerLogoId !== null) {
      setShowGenericLoader(true);
      try {
        const getImage = async () => {
          try {
            await GeneradoresServices.getInstance().getImage(providerLogoId).then((data) => {
                const file = base64ToObjectURL(data.file);
                setImagePreview(file);
                setShowGenericLoader(false);
            });
          } catch (error) {
            setShowGenericLoader(false);
          }
        };
        getImage();
      } catch (error) {
        toast.error('Un error ocurrio al cargar la imagén, si persiste suba de nuevo el logo del proveedor.');
      }
    }
  }, []);
  
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    const formData = new FormData();
    formData.append('file', file);
    await GeneradoresServices.getInstance().postImage(formData).then(async (data) => {
      await GeneradoresServices.getInstance().patchProviderLogo(generadorId, data.respuesta).then((data) => {
        console.log(data);
        setProviderLogoId(data.respuesta.providerLogoId);
      });
      console.log(data);
    }).catch((error) => {
      console.log(error);
    });
  };
    
  const goToNextForm = (nextForm) => {
    setSelectedForm(nextForm);
  };

  const goToPreviousForm = (prevForm) => {
    setSelectedForm(prevForm);
  };





  const renderForm = useCallback(() => {
    switch (selectedForm) {
      case 'FormGeneral':
        return <FormGeneral goToNextForm={goToNextForm} />;
      case 'FormGeneralNext':
        return <FormGeneralNext goToPreviousForm={goToPreviousForm} />;
      case 'FormGeolocation':
        return <FormGeolocation />;
      case 'FormPhotographic':
        return <FormPhotographic onClose={closeFormGenerator}/>;
      case 'FormAnexos':
        return <FormAnexos />;
      default:
        return <p>Por favor, selecciona un formulario.</p>;
    }
  }, [selectedForm, goToNextForm, goToPreviousForm]);

  return (
    <Box className={classes.banner}>
      <Box
        bgcolor="white"
        borderRadius="20px"
        className="animate__animated animate__backInUp"
        color="text.primary"
        display="flex"
        flexDirection="column"
        margin="0 0 auto 0"
        style={{ width: '70%'}}
        padding="40px"
      >
        <div className={classes.container}>
          <div className={classes.header}>
            <TextField
              size="small"
              value={formGeneratedCode}
              disabled={true}
              name="idForm" onChange={()=>{}}
              style={{width: '30%'}}
            />
            <IconButton
              aria-label="close"
              onClick={()=>{
                closeFormGenerator();
              }}
              style={{color: 'black', justifyContent: 'flex-end', marginLeft: 'auto'}}
            >
              X
            </IconButton>
          </div>
          <div>
            <div style={{ paddingRight: '4.5%', marginTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 'auto'}}>
              <div style={{ maxWidth: "200px"}}>
                <img src={LogoTP} alt="logo" style={{ maxWidth: '200px', maxHeight: '100px' }} />
              </div>
              <div style={{ maxWidth: "200px"}}>
                {imagePreview && (
                  <div style={{ position: 'relative', display: 'inline-block', marginTop: '20px', marginRight: '20%' }}>
                  <img src={imagePreview} alt="Vista previa" style={{ maxWidth: '200px', maxHeight: '100px' }} />
                  <Delete 
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      cursor: 'pointer',
                      color: 'white',
                      backgroundColor: '#2347AD',
                      borderRadius: '50%',
                      padding: 5,
                    }}
                    onClick={() => {
                      setImagePreview(null);
                    }}
                  />
                </div>                
                )}
              </div>
            </div>
            <div className={classes.options} style={{marginBottom: '10px'}}>
                {!imagePreview ? (
                  <div>
                  <Button
                    type="button"
                    className="mt-2 mr-5"
                    variant="contained"
                    color="primary"
                    style={{ width: '150px', display: 'flex' }}
                    onClick={handleButtonClick}
                  >
                    {'Subir logo'}
                  </Button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                </div>
                ) : null}
            </div>
          </div>
          <div className={classes.options}>
            <button
              onClick={() => setSelectedForm('FormGeneral')}
              className={`${classes.optionButton} ${(selectedForm === 'FormGeneral' || selectedForm === 'FormGeneralNext') && classes.active}`}
            >
              General
            </button>
            <button
              onClick={() => setSelectedForm('FormGeolocation')}
              className={`${classes.optionButton} ${selectedForm === 'FormGeolocation' && classes.active}`}
            >
              Geolocalización
            </button>
            <button
              onClick={() => setSelectedForm('FormPhotographic')}
              className={`${classes.optionButton} ${selectedForm === 'FormPhotographic' && classes.active}`}
            >
              Fotográfico
            </button>
            <button
              onClick={() => setSelectedForm('FormAnexos')}
              className={`${classes.optionButton} ${selectedForm === 'FormAnexos' && classes.active}`}
            >
              Anexos
            </button>
          </div>
          <div className={classes.separator}></div>
          <div className={classes.formContainer}>
            {renderForm()}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default FormSelector;
