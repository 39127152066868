import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export default function LogoutIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 20">
      <path
        d="M17.9432 8.96333C17.905 8.87181 17.8502 8.78855 17.7805 8.7188L15.5311 6.46939C15.2378 6.17687 14.7638 6.17687 14.4705 6.46939C14.1773 6.76265 14.1773 7.23743 14.4705 7.52995L15.4403 8.49976H11.2505C10.8358 8.49976 10.5005 8.83577 10.5005 9.2498C10.5005 9.66383 10.8358 9.99984 11.2505 9.99984H15.4403L14.4705 10.9697C14.1772 11.2629 14.1772 11.7377 14.4705 12.0302C14.6167 12.1772 14.8087 12.25 15.0008 12.25C15.1928 12.25 15.3848 12.1772 15.5311 12.0302L17.7805 9.7808C17.8502 9.71178 17.905 9.62853 17.9432 9.53627C18.019 9.35334 18.019 9.14633 17.9432 8.96333Z"
        fill="white"
      />
      <path
        d="M12.7508 11.6667C12.336 11.6667 12.0008 12.04 12.0008 12.5V16.6666H9.00059V3.33331C9.00059 2.96581 8.78306 2.64082 8.46579 2.535L5.86086 1.66668H12.0008V5.83335C12.0008 6.29335 12.336 6.66667 12.7508 6.66667C13.1656 6.66667 13.5008 6.29335 13.5008 5.83335V0.833358C13.5008 0.37332 13.1656 0 12.7508 0H0.750037C0.723036 0 0.699022 0.0125 0.672794 0.0158203C0.63753 0.02 0.60529 0.0266406 0.571537 0.0358202C0.492782 0.0583202 0.421516 0.0924998 0.354785 0.13914C0.338296 0.15082 0.318044 0.15164 0.302293 0.164961C0.296246 0.17 0.293996 0.179179 0.287984 0.184179C0.20624 0.25582 0.137997 0.34332 0.0884941 0.447499C0.0779817 0.469999 0.0757315 0.494179 0.0675044 0.517499C0.0434911 0.580819 0.0172629 0.642499 0.00826226 0.712499C0.0045003 0.737499 0.0112507 0.760819 0.0105124 0.784999C0.00977408 0.801678 0 0.816678 0 0.833319V17.5C0 17.8975 0.252755 18.2391 0.60304 18.3166L8.10352 19.9833C8.15228 19.995 8.20179 20 8.25052 20C8.42227 20 8.59103 19.9342 8.72604 19.8108C8.8993 19.6525 9.00056 19.4166 9.00056 19.1666V18.3333H12.7508C13.1656 18.3333 13.5008 17.96 13.5008 17.5V12.5C13.5008 12.04 13.1656 11.6667 12.7508 11.6667Z"
        fill="white"
      />
    </SvgIcon>
  );
}
