import { Box, Dialog, Grid, makeStyles } from "@material-ui/core"
import ButtonAddCatalog from "./ButtonAddCatalog"
import AddCatalogModal from "./catalogs/AddCatalogModal";
import { useState } from "react";
import CatalogCard from "./catalogs/CatalogCard";

interface PropsData {
  file?: File;
  fechaInicial?: string;
  fechaFinal?: string;
  nombreCatalogo?: string;
}

function CatalogsView({listCatalogue}) {
  const [showModal, setShowModal] = useState(false);
  const [dataCatalog, setDataCatalog] = useState<PropsData[]>([])
  const classes = useStyles();

  return (
    <>
      <ButtonAddCatalog onclick={() => setShowModal(!showModal)} />
      {listCatalogue.length === 0 ? <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
        color={'primary'}
        className={classes.middleText}
      >
        <p>No se ha cargado ningún catálogo</p>
      </Box> :
        (<Grid container xs={12} spacing={4}>
          {
            listCatalogue.map(data => (
              <CatalogCard key={data.fileName} dataCard={data} />
            ))
          }
        </Grid>)
      }
      <AddCatalogModal open={showModal} handleClose={() => setShowModal(false)} addNewCatalog={setDataCatalog} dataCatalog={dataCatalog} />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  middleText: {
    color: theme.palette.primary.main
  }
}));

export default CatalogsView