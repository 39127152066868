import { SvgIcon, SvgIconProps } from "@material-ui/core";

function AxeleratumIconLarge(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="249" height="48" viewBox="0 0 249 48" style={{ ...props.style,  width: '5.2em', height: '1em' }}>
      <path d="M126.169 15.1738H121.644C121.341 15.1738 121.096 15.419 121.096 15.7215V42.6293C121.096 42.9318 121.341 43.177 121.644 43.177H126.169C126.472 43.177 126.718 42.9318 126.718 42.6293V15.7215C126.718 15.419 126.472 15.1738 126.169 15.1738Z" />
      <path d="M91.9428 32.7112L97.7801 23.4261C97.8284 23.3429 97.8543 23.2485 97.8553 23.1523C97.8563 23.0561 97.8325 22.9613 97.7861 22.877C97.7397 22.7927 97.6722 22.7218 97.5903 22.6711C97.5084 22.6205 97.4149 22.5918 97.3186 22.5879H92.34C92.2456 22.5889 92.153 22.6139 92.0708 22.6603C91.9887 22.7067 91.9196 22.7731 91.8701 22.8534L88.5808 28.2852L85.2887 22.8534C85.2394 22.7735 85.1708 22.7073 85.0892 22.6609C85.0076 22.6145 84.9155 22.5894 84.8216 22.5879H79.8346C79.7381 22.5913 79.6441 22.6197 79.5618 22.6702C79.4795 22.7206 79.4118 22.7915 79.365 22.8759C79.3183 22.9603 79.2942 23.0552 79.2951 23.1516C79.296 23.2481 79.3219 23.3427 79.3702 23.4261L85.2076 32.7112L79.085 42.3398C79.0367 42.4231 79.0108 42.5175 79.0098 42.6137C79.0088 42.7099 79.0326 42.8048 79.079 42.8891C79.1254 42.9734 79.1929 43.0444 79.2747 43.095C79.3566 43.1457 79.4502 43.1742 79.5465 43.1781H84.5811C84.6747 43.1769 84.7665 43.1522 84.8481 43.1063C84.9297 43.0604 84.9985 42.9948 85.0481 42.9155L88.5696 37.1316L92.0883 42.9155C92.1381 42.9952 92.2074 43.0611 92.2895 43.107C92.3716 43.1529 92.464 43.1773 92.5581 43.1781H97.5927C97.689 43.1742 97.7825 43.1457 97.8644 43.095C97.9463 43.0444 98.0138 42.9734 98.0602 42.8891C98.1066 42.8048 98.1304 42.7099 98.1294 42.6137C98.1284 42.5175 98.1025 42.4231 98.0542 42.3398L91.9428 32.7112Z" />
      <path d="M108.687 22.0898C102.889 22.0898 98.9902 26.4208 98.9902 32.8642C98.9902 39.2321 103.043 43.6775 108.841 43.6775C113.535 43.6775 117.213 40.9448 117.99 36.8737C118.003 36.795 117.999 36.7146 117.978 36.6377C117.957 36.5608 117.92 36.4892 117.869 36.4278C117.818 36.3664 117.755 36.3166 117.683 36.2817C117.611 36.2468 117.533 36.2277 117.453 36.2255H113.213C113.092 36.2272 112.975 36.2682 112.879 36.3423C112.783 36.4164 112.714 36.5196 112.682 36.6363C112.427 37.6394 111.305 38.8101 108.995 38.8101C106.685 38.8101 105.029 37.2035 104.732 34.5993H117.683C117.829 34.5994 117.969 34.5423 118.073 34.4405C118.178 34.3387 118.238 34.2002 118.242 34.0544V32.6099C118.231 26.214 114.486 22.0898 108.687 22.0898ZM104.772 30.2292C105.174 28.1755 106.618 26.9573 108.665 26.9573C110.713 26.9573 112.125 28.2062 112.48 30.2292H104.772Z" />
      <path d="M68.8661 16.1718C68.8288 16.0653 68.7591 15.973 68.6669 15.9079C68.5746 15.8428 68.4643 15.8081 68.3514 15.8086H62.928C62.8151 15.8081 62.7047 15.8428 62.6125 15.9079C62.5202 15.973 62.4506 16.0653 62.4134 16.1718L53.2112 42.0095C53.1843 42.092 53.1769 42.1797 53.1897 42.2656C53.2026 42.3515 53.2352 42.433 53.2852 42.5041C53.3351 42.5751 53.4009 42.6337 53.4774 42.6749C53.5539 42.7161 53.639 42.7388 53.7258 42.7415H60.0191C60.1368 42.7398 60.251 42.7012 60.3454 42.631C60.4399 42.5607 60.5097 42.4625 60.545 42.3503L61.8484 38.0138H69.4422L70.7484 42.3503C70.7836 42.4621 70.853 42.56 70.9468 42.6302C71.0407 42.7004 71.1542 42.7393 71.2714 42.7415H77.5815C77.6686 42.7393 77.754 42.7168 77.8308 42.6757C77.9077 42.6347 77.9739 42.5763 78.0241 42.5052C78.0744 42.4341 78.1074 42.3522 78.1203 42.2662C78.1333 42.1801 78.126 42.0923 78.099 42.0095L68.8661 16.1718ZM63.0203 34.1299L65.6467 25.4066L68.2703 34.1299H63.0203Z" />
      <path d="M196.57 38.496C195.972 38.6955 195.346 38.7947 194.716 38.7895C193.566 38.7895 192.942 38.4766 192.942 36.8336V27.4228H196.327C196.475 27.4228 196.617 27.3639 196.722 27.2591C196.827 27.1543 196.886 27.0122 196.886 26.864V23.1281C196.886 22.9799 196.827 22.8378 196.722 22.733C196.617 22.6282 196.475 22.5693 196.327 22.5693H192.942V17.7717C192.942 17.6259 192.885 17.4859 192.783 17.3815C192.682 17.2771 192.543 17.2165 192.397 17.2129H187.869C187.72 17.2129 187.578 17.2718 187.473 17.3766C187.368 17.4814 187.309 17.6235 187.309 17.7717V22.5861H184.792C184.643 22.5861 184.501 22.645 184.396 22.7498C184.291 22.8546 184.232 22.9967 184.232 23.1449V26.8807C184.232 27.029 184.291 27.1711 184.396 27.2759C184.501 27.3807 184.643 27.4396 184.792 27.4396H187.309V37.2331C187.309 41.6451 189.326 43.6988 193.65 43.6988C194.771 43.7147 195.886 43.539 196.948 43.1791C197.047 43.1365 197.132 43.066 197.192 42.9763C197.252 42.8865 197.285 42.7813 197.286 42.6734V39.041C197.287 38.9535 197.268 38.867 197.23 38.7883C197.192 38.7096 197.135 38.641 197.066 38.588C196.996 38.535 196.915 38.499 196.829 38.4831C196.743 38.4671 196.654 38.4715 196.57 38.496Z" />
      <path d="M217.109 22.5879H212.609C212.46 22.5879 212.318 22.6468 212.213 22.7516C212.108 22.8564 212.049 22.9985 212.049 23.1467V34.505C212.049 37.1846 210.835 38.7829 208.802 38.7829C206.307 38.7829 205.781 36.5811 205.781 34.7342V23.1467C205.781 22.9985 205.722 22.8564 205.617 22.7516C205.512 22.6468 205.37 22.5879 205.222 22.5879H200.696C200.548 22.5879 200.406 22.6468 200.301 22.7516C200.196 22.8564 200.137 22.9985 200.137 23.1467V35.7205C200.137 40.641 202.875 43.6979 207.283 43.6979C208.198 43.71 209.105 43.5326 209.948 43.1773C210.791 42.8219 211.551 42.2961 212.181 41.633V42.6528C212.181 42.801 212.24 42.9431 212.345 43.0479C212.449 43.1527 212.592 43.2117 212.74 43.2117H217.095C217.243 43.2117 217.386 43.1527 217.491 43.0479C217.595 42.9431 217.654 42.801 217.654 42.6528V23.1552C217.657 23.0079 217.601 22.8656 217.498 22.7594C217.396 22.6532 217.256 22.5916 217.109 22.5879Z" />
      <path d="M241.977 22.0881C240.854 22.0787 239.746 22.336 238.742 22.8388C237.739 23.3416 236.87 24.0756 236.206 24.9801C235.023 23.0885 233.054 22.0881 230.489 22.0881C229.604 22.095 228.731 22.2858 227.923 22.6485C227.116 23.0112 226.394 23.5379 225.802 24.195V23.1331C225.802 22.9849 225.743 22.8428 225.638 22.738C225.533 22.6332 225.391 22.5743 225.242 22.5743H220.887C220.741 22.5779 220.603 22.6385 220.501 22.7429C220.399 22.8473 220.342 22.9873 220.342 23.1331V42.6309C220.342 42.7767 220.399 42.9167 220.501 43.0211C220.603 43.1255 220.741 43.1861 220.887 43.1897H225.416C225.564 43.1897 225.706 43.1308 225.811 43.026C225.916 42.9212 225.975 42.7791 225.975 42.6309V31.2921C225.975 28.6013 227.125 26.9947 229.052 26.9947C230.171 26.9947 230.942 27.4306 231.387 28.3275C231.81 29.1658 231.86 30.2834 231.86 31.0434V42.6309C231.86 42.7791 231.919 42.9212 232.024 43.026C232.129 43.1308 232.271 43.1897 232.419 43.1897H236.928C237.076 43.1897 237.219 43.1308 237.324 43.026C237.429 42.9212 237.487 42.7791 237.487 42.6309V30.9847C237.597 28.4867 238.741 26.9947 240.545 26.9947C243.062 26.9947 243.372 29.1071 243.372 30.9484V42.6309C243.372 42.7791 243.431 42.9212 243.536 43.026C243.641 43.1308 243.783 43.1897 243.932 43.1897H248.441C248.589 43.1897 248.731 43.1308 248.836 43.026C248.941 42.9212 249 42.7791 249 42.6309V29.5122C248.938 24.9326 246.267 22.0881 241.977 22.0881Z" />
      <path d="M139.101 22.0898C133.301 22.0898 129.404 26.4208 129.404 32.8642C129.404 39.2321 133.454 43.6775 139.253 43.6775C143.949 43.6775 147.624 40.9448 148.404 36.8737C148.417 36.7948 148.413 36.7142 148.392 36.6371C148.371 36.56 148.333 36.4883 148.282 36.4269C148.231 36.3654 148.167 36.3156 148.095 36.2809C148.023 36.2462 147.945 36.2273 147.865 36.2255H143.627C143.506 36.2272 143.389 36.2682 143.293 36.3423C143.197 36.4164 143.128 36.5196 143.096 36.6363C142.838 37.6394 141.72 38.8101 139.406 38.8101C137.093 38.8101 135.443 37.2035 135.147 34.5993H148.097C148.243 34.5994 148.383 34.5423 148.487 34.4405C148.592 34.3387 148.652 34.2002 148.656 34.0544V32.6099C148.642 26.214 144.897 22.0898 139.101 22.0898ZM135.186 30.2292C135.591 28.1755 137.032 26.9573 139.082 26.9573C141.132 26.9573 142.539 28.2062 142.894 30.2292H135.186Z" />
      <path d="M163.15 22.3634C162.534 22.2064 161.9 22.134 161.265 22.1482C160.433 22.1307 159.609 22.3077 158.858 22.6653C158.107 23.0229 157.45 23.5511 156.94 24.2076V23.1345C156.94 22.9863 156.882 22.8442 156.777 22.7394C156.672 22.6346 156.529 22.5757 156.381 22.5757H152.046C151.897 22.5757 151.755 22.6346 151.65 22.7394C151.545 22.8442 151.486 22.9863 151.486 23.1345V42.6323C151.486 42.7805 151.545 42.9226 151.65 43.0274C151.755 43.1322 151.897 43.1911 152.046 43.1911H156.574C156.722 43.1911 156.865 43.1322 156.97 43.0274C157.075 42.9226 157.133 42.7805 157.133 42.6323V31.4556C157.133 28.857 158.619 27.3678 161.206 27.3678C161.771 27.3743 162.333 27.4408 162.884 27.5662C162.964 27.5816 163.046 27.5795 163.125 27.56C163.204 27.5405 163.277 27.5041 163.341 27.4533C163.404 27.4025 163.456 27.3386 163.492 27.2659C163.528 27.1932 163.548 27.1135 163.55 27.0324V22.8859C163.547 22.7678 163.507 22.6535 163.435 22.5596C163.363 22.4657 163.263 22.397 163.15 22.3634Z" />
      <path d="M183.211 38.5558C182.978 38.6245 182.736 38.6585 182.493 38.6565C181.95 38.6565 181.813 38.5558 181.813 37.7874V28.8628C181.813 24.7498 178.633 22.0898 173.701 22.0898C168.589 22.0898 165.101 25.007 165.031 29.3547C165.031 29.5029 165.09 29.645 165.195 29.7498C165.3 29.8546 165.442 29.9135 165.59 29.9135H170.001C170.144 29.9138 170.281 29.8595 170.385 29.7618C170.489 29.6641 170.552 29.5306 170.56 29.3882C170.619 27.8459 171.749 26.9629 173.657 26.9629C175.802 26.9629 176.252 27.9994 176.252 28.8684C176.252 30.2068 175.282 30.4527 172.471 30.9641L171.911 31.0675C168.424 31.699 164.424 33.0234 164.424 37.6394C164.424 41.3109 167.031 43.6831 171.07 43.6831C172.228 43.7037 173.378 43.4808 174.445 43.0288C175.512 42.5768 176.472 41.9059 177.262 41.0595C177.998 42.5935 179.438 43.4345 181.349 43.4345C182.132 43.4444 182.911 43.3049 183.642 43.0237C183.738 42.9794 183.819 42.9089 183.877 42.8204C183.934 42.7319 183.965 42.629 183.967 42.5236V39.0756C183.966 38.9857 183.944 38.8972 183.901 38.8177C183.859 38.7383 183.799 38.6701 183.724 38.6191C183.65 38.5681 183.565 38.5357 183.476 38.5247C183.386 38.5137 183.296 38.5243 183.211 38.5558ZM176.219 36.5999C175.38 37.997 173.757 38.8352 171.973 38.8352C170.015 38.8352 170.015 37.7176 170.015 37.2957C170.015 36.1613 170.717 35.6192 172.91 35.1078C174.423 34.753 175.402 34.4623 176.224 33.951L176.219 36.5999Z" />
      <path d="M35.4209 27.9961C35.4209 27.9961 31.1135 33.4865 23.584 37.8203L26.6803 44.4705C27.0625 45.2927 27.6722 45.9883 28.4374 46.4755C29.2027 46.9627 30.0914 47.2211 30.9988 47.2199H42.6595C42.8657 47.2202 43.0686 47.1683 43.2493 47.069C43.4299 46.9697 43.5824 46.8262 43.6924 46.6521C43.8025 46.4779 43.8666 46.2787 43.8787 46.0731C43.8907 45.8674 43.8505 45.6621 43.7616 45.4763L35.4209 27.9961Z" />
      <path d="M34.0615 25.006L22.9042 1.47902C22.8055 1.26923 22.6491 1.09193 22.4532 0.967669C22.2573 0.843412 22.03 0.777344 21.798 0.777344C21.566 0.777344 21.3387 0.843412 21.1429 0.967669C20.947 1.09193 20.7905 1.26923 20.6918 1.47902L0.407939 45.4789C0.322365 45.6655 0.285255 45.8706 0.300047 46.0753C0.314839 46.2801 0.381032 46.4777 0.492546 46.6501C0.60406 46.8225 0.757288 46.964 0.938045 47.0616C1.1188 47.1592 1.32128 47.2097 1.52674 47.2084H10.0883C10.3229 47.2113 10.5534 47.1464 10.7519 47.0215C10.9504 46.8966 11.1085 46.7169 11.2071 46.5043L13.3189 41.9721C10.439 42.675 7.48098 43.0074 4.51672 42.9612C4.47334 42.9593 4.43218 42.9414 4.40118 42.911C4.37019 42.8806 4.35151 42.84 4.34874 42.7967C4.34597 42.7534 4.35929 42.7106 4.38616 42.6765C4.41303 42.6424 4.45155 42.6194 4.49433 42.612C7.84962 42.08 11.1338 41.1698 14.2838 39.8988L19.4304 28.8535L22.5742 35.6014C26.9678 32.734 30.8504 29.1528 34.0615 25.006Z" />
    </SvgIcon>
  )
}

export default AxeleratumIconLarge;
