
export default function SatWarningIcon({size}: {size?: number}) {
  return (
    <svg
    width={size || 29}
    height={size * 0.8965 || 26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.175.981a1.92 1.92 0 0 0-3.35 0L.262 23.057C-.482 24.365.448 26 1.937 26h25.126c1.489 0 2.42-1.635 1.675-2.943L16.175.98Zm-2.657 16.532h2.299L16.297 8h-3.26l.481 9.513Zm-.094 2.093c-.283.28-.424.691-.424 1.237 0 .526.145.934.433 1.227.29.285.697.428 1.225.428.515 0 .917-.146 1.206-.438.289-.299.433-.704.433-1.217 0-.532-.144-.942-.433-1.227-.283-.286-.685-.428-1.206-.428-.54 0-.951.14-1.234.418Z"
      fill="#F45F5F"
    />
  </svg>
  );
}
