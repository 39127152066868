import { Button, Grid, Menu, MenuItem, Paper, Typography } from "@material-ui/core";
import moment from "moment";
import kivitLogo from "../../../src/assets/img/kivit-white-logo.svg";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useEffect, useState } from "react";
import { monthSelectOptions } from "../stages/utils";

const PymePlanCard = ({ onSelect }) => {
  const oneMonthLater = moment().add(1, "month");

  const [anchorElGigas, setAnchorElGigas] = useState(null);
  const [anchorElEmployees, setAnchorElEmployees] = useState(null);
  const [selectedPrice, setPrice] = useState(1000);

  const [gigasSelected, setGigas] = useState(20);
  const [employeesSelected, setEmployees] = useState(100);

  const handleClose = () => {
    setAnchorElGigas(null);
    setAnchorElEmployees(null);
  };

  const handleEmployeeChange = (qty: number) => {
    setEmployees(qty);
    handleClose();
  };

  const handleGigasChange = (qty: number) => {
    setGigas(qty);
    handleClose();
  };

  const handleSelect = () => {
    onSelect({ gigas: gigasSelected, employees: employeesSelected });
  };

  useEffect(() => {
    setPrice(getPrice(employeesSelected));
  }, [employeesSelected]);

  useEffect(() => {
    setPrice(getPrice(employeesSelected) + (gigasSelected / 20 - 1) * 200);
  }, [gigasSelected, employeesSelected]);

  const menuGigas = (
    <Menu id="simple-menu" anchorEl={anchorElGigas} keepMounted open={Boolean(anchorElGigas)} onClose={handleClose}>
      <MenuItem onClick={() => handleGigasChange(20)}>20 gb</MenuItem>
      <MenuItem onClick={() => handleGigasChange(40)}>40 gb</MenuItem>
      {/* <MenuItem onClick={() => handleGigasChange(60)}>60 gb</MenuItem> */}
    </Menu>
  );

  const menuEmployees = (
    <Menu id="simple-menu" anchorEl={anchorElEmployees} keepMounted open={Boolean(anchorElEmployees)} onClose={handleClose}>
      <MenuItem onClick={() => handleEmployeeChange(100)}>100</MenuItem>
      <MenuItem onClick={() => handleEmployeeChange(150)}>150</MenuItem>
      <MenuItem onClick={() => handleEmployeeChange(200)}>200</MenuItem>
      <MenuItem onClick={() => handleEmployeeChange(300)}>300</MenuItem>
      <MenuItem onClick={() => handleEmployeeChange(400)}>400</MenuItem>
      <MenuItem onClick={() => handleEmployeeChange(500)}>500</MenuItem>
    </Menu>
  );

  return (
    <Paper
      elevation={3}
      style={{
        backgroundColor: "#7678ED",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "30px",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "50%",
          minHeight: "524px",
          padding: "4rem 2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <img src={kivitLogo} width={"200px"} alt="logo"/>
        </div>
        <ul style={{ color: "#fff", marginTop: "20px" }}>
          <ListItemLeft text="Fácil identificación y organización de tus documentos." />
          <ListItemLeft text="Registro de todos los movimientos realizados (Trazabilidad)." />
          <ListItemLeft text="Validación y verificación automatizada." />
          <ListItemLeft text="Notificaciones  periódicas para actualizar." />
          <ListItemLeft text="Atención a clientes: Lunes a Viernes de 09:00 a 17:30 hrs." />
          <ListItemLeft
            startElement={<span style={{ fontSize: "18px" }}>Ilimitada</span>}
            text="la capacidad de crear accesos para tus clientes y equipo."
          />
          <ListItemLeft
            startElement={
              <span style={{ fontSize: "18px", display: "flex", alignItems: "center", cursor: "pointer" }}>
                {gigasSelected} gb <KeyboardArrowDownIcon color="inherit" onClick={(e) => setAnchorElGigas(e.currentTarget)} />
              </span>
            }
            text="Almacenamiento en Blockchain."
          />
          <ListItemLeft
            startElement={
              <span style={{ fontSize: "18px", display: "flex", alignItems: "center", cursor: "pointer" }}>
                {employeesSelected} <KeyboardArrowDownIcon color="inherit" onClick={(e) => setAnchorElEmployees(e.currentTarget)} />
              </span>
            }
            text="trabajadores (CFDIs de nómina) administrados."
          />
        </ul>
        {menuGigas}
        {menuEmployees}
      </div>
      <div
        style={{
          width: "50%",
          minHeight: "524px",
          padding: "3rem 2rem",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Grid xs={12} style={{ padding: "2rem" }}>
          <Typography color="primary" style={{ fontSize: "45px", fontWeight: "lighter" }}>
            Plan PyME
          </Typography>

          <div style={{ marginTop: "2rem" }}>
            <div>Pago mensual</div>
            <div style={{ fontSize: "45px", fontWeight: "normal" }}>$ {selectedPrice} MXP</div>
          </div>

          <ul style={{ marginTop: "2rem" }}>
            <ListItemRight text="Prueba gratis de 30 días, Puedes cancelar cuando quieras" />
            <ListItemRight text="Te enviaremos un recordatorio antes de que termine tu prueba" />
            <ListItemRight text={`Vence el ${oneMonthLater.date()} de ${monthSelectOptions[oneMonthLater.month()].value} de ${oneMonthLater.year()}`} />
            <ListItemRight text="Precio de introducción/promoción válido por tiempo limitado" />
          </ul>

          <div style={{ width: "100%", textAlign: "center", marginTop: "2rem" }}>
            <Button color="primary" variant="contained" style={{ paddingRight: "4rem", paddingLeft: "4rem" }} onClick={handleSelect}>
              Siguiente
            </Button>
          </div>
        </Grid>
      </div>
    </Paper>
  );
};

export default PymePlanCard;

const ListItemLeft = ({ text, startElement }: { text: string; startElement?: JSX.Element }) => {
  return (
    <li style={{ display: "flex", alignItems: "center", fontWeight: "lighter", marginTop: "10px" }}>
      <div style={{ marginRight: "10px" }}>
        {startElement ? (
          startElement
        ) : (
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.84 89.84" style={{ width: "25px", height: "25px" }}>
              <defs>
                <style>{".cls-1{fill:none;stroke:#f5fbff;stroke-miterlimit:10;stroke-width:2px}"}</style>
              </defs>
              <g id="Capa_2" data-name="Capa 2">
                <g id="Capa_1-2" data-name="Capa 1">
                  <circle className="cls-1" cx={44.92} cy={44.92} r={43.92} />
                  <path className="cls-1" d="m24.78 47.31 11.3 14.5 30.86-33.78" />
                </g>
              </g>
            </svg>
          </div>
        )}
      </div>
      <span style={{ fontWeight: "normal", fontSize: "16px" }}>{text}</span>
    </li>
  );
};

const ListItemRight = ({ text }: { text: string }) => {
  return (
    <li style={{ display: "flex", alignItems: "center", fontWeight: "lighter", marginTop: "6px" }}>
      <div style={{ marginRight: "10px" }}>
        <svg width={24} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="m2 8.816 6 5.289L22 1.764" stroke="#2347AD" strokeWidth={3} />
        </svg>
      </div>
      <Typography color="primary" style={{ fontWeight: "normal", fontSize: "15px" }}>
        {text}
      </Typography>
    </li>
  );
};

function getPrice(employees: number) {
  switch (employees) {
    case 100:
      return 1000;
    case 150:
      return 2000;
    case 200:
      return 3000;
    case 300:
      return 5000;
    case 400:
      return 7000;
    case 500:
      return 9000;
    default:
      return 1000;
  }
}
