import { Button, Grid, Typography } from '@material-ui/core';
import ProfileInput from './ProfileInput';
import AddIcon from '@material-ui/icons/Add';

type Props = {
  title: string;
  values: string[];
  errors?: string[];
  touchedFields?: boolean;
  handleChangeField: (e: React.ChangeEvent<any>) => void;
  isEditing?: boolean;
  onAddAvtivity: () => void;
  onDeleteAvtivity: (index: number) => void;
  type?: string;
  visibleTitle?: boolean
}

const REPSEForm = ({ title, isEditing, values, errors, touchedFields, handleChangeField, onAddAvtivity, onDeleteAvtivity, type, visibleTitle }: Props) => {
  return (
    <Grid container alignItems="center" spacing={isEditing ? 0 : 2}>
      {
        ((visibleTitle) || (!visibleTitle && isEditing)) &&
        <Grid container item xs={12} justifyContent="space-between" style={{ marginBottom: "1rem", marginTop: '1rem' }}>
          <Typography variant="h6">{title}</Typography>
          {isEditing && <Button variant="contained" color="primary" size="small" startIcon={<AddIcon />} onClick={onAddAvtivity}>
            Agregar
          </Button>}
        </Grid>
      }
      {values?.map((value, i) => (
        <ProfileInput
          label={type === 'Actividad' ? `Actividad ${i + 1}` : `Objeto ${i + 1}`}
          id={`REPSEActivities[${i}]`}
          error={errors?.[i]}
          isEditing={isEditing}
          onChange={handleChangeField}
          touched={touchedFields}
          value={value}
          onDelete={values.length > 1 ? () => onDeleteAvtivity(i) : undefined}
          key={i}
        />
      ))}

    </Grid>
  );
}

export default REPSEForm