import { useEffect, useState } from "react";
import { Box, Button, Container, Dialog, Grid, IconButton, Typography } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddIcon from "@material-ui/icons/Add";
import BusinessIcon from "@material-ui/icons/Business";
import EditIcon from "@material-ui/icons/Edit";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";

import ProfileForm from "../components/management/profile/ProfileForm";
import TaxResidenceForm from "../components/management/profile/TaxResidenceForm";
import { CompaniesService } from "../core/services/companies.service";
import LegalRepresentativeForm from "../components/management/profile/LegalRepForm";
import ColoredAvatar from "../components/general/ColoredAvatar";
import REPSEForm from "../components/management/profile/REPSEForm";
import UploadPictureDialog from "../components/management/profile/UploadPictureDialog";
import { toBase64 } from "../core/utils/ntpUtils";
import { useGetProfileURL } from "../core/hooks/useGetProfileURL";
import { EditClientGeneratorCompanyInfoReqBod, EditCompanyInfoReqBody } from "../types/companies-service";
import useGlobalState from "../store/globalState";
import { useHandleModal } from "../components/stages/hooks/useHandleModal";
import GeneratorFormNewUserData from "../components/generators-form-initial/new-user-form/GeneratorFormNewUserData";
import FormNewUserData from "../components/onboarding/new-user-form/FormNewUserData";
import ContactsForm from "../components/management/profile/ContactsForm";
import { getFormInitialValues } from "redux-form";
import usePlanState from "../store/planState";


const ProfilePage = () => {
  const { mainCompany, setMainCompany, setAlertInfo, typeService } = useGlobalState()
  const [isEditingMainInfo, setIsEditingMainInfo] = useState(false);
  const [preponderantes, setPreponderantes] = useState([]);
  const [showModal, handleShowModal, handleCloseModal] = useHandleModal();
  const pictureURL = useGetProfileURL(mainCompany.profilePictureId);
  const idProvider = mainCompany?.id;
  const companiesService = CompaniesService.getInstance();
  const [open, setopen] = useState<boolean>(false)
  const { plan } = usePlanState();


  const handleClose = () => {
    setopen(false);
  };

  const handleLoad = () => {
    if (mainCompany?.generadores && plan !== 'CORPORATIVO') {
      companiesService.getAdditionalInfo(idProvider).then(async (data) => {
        const additionalInfoo: EditClientGeneratorCompanyInfoReqBod = data.respuesta
        formik.setFieldValue('legalRepresentatives', additionalInfoo?.legalRepresentativesList?.map((e) => ({
          curp: e.curp,
          mail: e.email,
          mname: e.motherLastName,
          name: e.name,
          phone: e.phone,
          pname: e.lastName,
          rfc: e.rfc
        })))
        formik.setFieldValue('contacts', additionalInfoo?.contactList)
        formik.setFieldValue('REPSEActivities', additionalInfoo.socialObjectPredominantList)
        formik.setFieldValue('services', additionalInfoo.serviceOfferedIdList)
      });
    } else {
      getTnitialFormValues()
      console.log(mainCompany)
    }
  }

  useEffect(() => {
    handleLoad()
  }, []);

  const getTnitialFormValues = (): ProfileFormFields => ({
    taxRegime: mainCompany.type ?? "",
    employeesCount: mainCompany.employeesSize ?? undefined,
    RFC: mainCompany.rfc ?? "",
    website: mainCompany.web ?? "",
    businessActivity: mainCompany.sector ?? "",
    offeredServices: mainCompany.servicesOffered?.split("|") ?? [],
    street: mainCompany.taxResidence?.street ?? "",
    extNumber: mainCompany.taxResidence?.outdoorNum ?? "",
    intNumber: mainCompany.taxResidence?.interiorNumber ?? "",
    suburb: mainCompany.taxResidence?.colonia ?? "",
    city: mainCompany.taxResidence?.city ?? "",
    postalCode: mainCompany.taxResidence?.cp ?? "",
    country: mainCompany.taxResidence?.country ?? "",
    legalRepresentatives: mainCompany.legalRepresentatives ? mainCompany.legalRepresentatives?.map((rep) => ({
      name: rep.name,
      curp: rep.curp,
      pname: rep.amaterno,
      mname: rep.apaterno
    })) : [],
    REPSEActivities: mainCompany?.generadores && plan !== 'CORPORATIVO' ? [] : mainCompany?.repses?.map((repse) => repse.name),
    name: mainCompany.name ?? "n/a",
    contacts: []
  });

  const handleSaveProfileInfo = async (formFields: ProfileFormFields) => {
    const newCompanyInfo: EditCompanyInfoReqBody = {
      id: mainCompany.id,
      employeesSize: formFields.employeesCount,
      legalRepresentatives: formFields.legalRepresentatives.map((value) => ({
        amaterno: value.mname,
        apaterno: value.pname,
        curp: value.curp,
        name: value.name,
      })),
      name: formFields.name,
      rfc: formFields.RFC,
      sector: formFields.businessActivity,
      status: mainCompany?.status,
      web: formFields.website,
      servicesOffered: formFields.offeredServices.join("|"),
      taxResidence: {
        city: formFields.city,
        colonia: formFields.suburb,
        cp: formFields.postalCode,
        interiorNumber: formFields.intNumber,
        outdoorNum: formFields.extNumber,
        street: formFields.street,
        country: formFields.country,
      },
      repses: formFields.REPSEActivities.map((value) => ({
        name: value,
      })),
      type: formFields.taxRegime,
      updatedData: true
    };
    await companiesService
      .updateCompany(newCompanyInfo)
      .then((res) => {
        setMainCompany({
          ...mainCompany,
          ...newCompanyInfo
        })
        setAlertInfo({
          message: "Se actualizó la información correctamente",
          type: "success",
        });
        setIsEditingMainInfo(false);
      })
      .catch(() => {
        setAlertInfo({
          message: "Hubo un problema al guardar la información de la empresa",
          type: "error",
        });
        formik.resetForm();
      });
    if (mainCompany?.generadores) {
      await companiesService.getAdditionalInfo(idProvider).then(async (data) => {
        const body = { ...data.respuesta, socialObjectPredominantList: preponderantes };
        await companiesService.updateAdditionalGeneradoresInfo(body);
      });
      formik.setFieldValue("REPSEActivities", preponderantes);
    }
  };

  const formik = useFormik<ProfileFormFields>({
    initialValues: getTnitialFormValues(),
    onSubmit: handleSaveProfileInfo,
    validationSchema: Yup.object({
      taxRegime: Yup.string().required("Campo requerido"),
      RFC: Yup.string().required("Campo requerido"),
      businessActivity: Yup.string().required("Campo requerido"),
      offeredServices: Yup.array().min(1, "Selecciona al menos servicio"),
      employeesCount: Yup.number().min(0, "Debe ser un número positivo").integer("Debe ser un entero").required("Campo requerido"),
      street: Yup.string().required("Campo requerido"),
      extNumber: Yup.string().required("Campo requerido"),
      suburb: Yup.string().required("Campo requerido"),
      city: Yup.string().required("Campo requerido"),
      postalCode: Yup.string().required("Campo requerido"),
      country: Yup.string().required("Campo requerido"),
      legalRepresentatives: Yup.array(
        Yup.object({
          name: Yup.string().required("Campo requerido"),
          curp: Yup.string().required("Campo requerido"),
          pname: Yup.string().required("Campo requerido"),
        })
      ),//.min(1, "Debe tener al menos un representante legal"),
      REPSEActivities: Yup.array(),//.min(1, "Debe tener al menos una actividad REPSE"),
      name: Yup.string().required("Campo requerido"),
    }),
  });

  useEffect(() => {
    if (!mainCompany.generadores) {
      formik.setValues(getTnitialFormValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCompany])

  const handleEditInfo = () => {
    if (plan === 'CORPORATIVO') {
      setIsEditingMainInfo(true);
    } else {
      setopen(true)
    }
  };

  const handleAddREPSE = () => {
    const aux = [...formik.values.REPSEActivities];
    aux.push("");
    formik.setFieldValue("REPSEActivities", aux);
  };


  const handleAddLegalRep = () => {
    const aux = [...formik.values.legalRepresentatives];
    aux.push({
      curp: "",
      mname: "",
      name: "",
      pname: "",
    });
    formik.setFieldValue("legalRepresentatives", aux);
  };

  const handleDeleteREPSE = (index: number) => {
    const aux = [...formik.values.REPSEActivities];
    aux.splice(index, 1);
    formik.setFieldValue("REPSEActivities", aux);
  };

  const handleDeleteLegalRep = (index: number) => {
    const aux = [...formik.values.legalRepresentatives];
    aux.splice(index, 1);
    formik.setFieldValue("legalRepresentatives", aux);
  };

  const handleUploadFile = async (file: File) => {
    try {
      const base64 = await toBase64(file as File);
      await companiesService.editCompanyProfilePicture(mainCompany.id, {
        extension: /(?:\.([^.]+))?$/.exec(file!.name)![0],
        name: file.name,
        fileBase64: base64
      });
      setAlertInfo({
        message: "Imagen actualizada correctamente",
        type: "success",
      });
      handleCloseModal();
    } catch (error) {
      setAlertInfo({
        message: "Hubo un problema al subir la imagen",
        type: "error",
      });
    }
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item container md={2} direction="column" justifyContent="flex-start" alignItems="center" style={{ gap: 25 }} >
          <ColoredAvatar backgroundColor={!!pictureURL ? "white" : undefined} src={pictureURL} size={160}>
            <BusinessIcon style={{ fontSize: 100 }} />
          </ColoredAvatar>
          <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={handleShowModal}>
            Editar Foto
          </Button>
        </Grid>
        <Grid item md={5}>
          <ProfileForm
            errors={formik.errors}
            handleChangeField={formik.handleChange}
            isEditing={isEditingMainInfo}
            onEditInfo={handleEditInfo}
            onSaveForm={() => {
              formik.submitForm()
            }}
            onCancelEdit={() => setIsEditingMainInfo(false)}
            setFieldValue={formik.setFieldValue}
            title={mainCompany.name}
            touchedFields={formik.touched}
            values={formik.values}
          />
        </Grid>
        <Grid item md={5}>
          <TaxResidenceForm
            title="Domicilio Fiscal"
            isEditing={isEditingMainInfo}
            values={formik.values}
            touchedFields={formik.touched}
            errors={formik.errors}
            handleChangeField={formik.handleChange}
          />
        </Grid>
        {formik?.values?.legalRepresentatives?.length !== 0 && (
          <>
            <Grid container item xs={8} style={{ marginBottom: "1rem", paddingLeft: '0' }}>
              <Typography variant="h6" style={{ marginRight: "35%" }}>
                Representante(s) legal(es)
              </Typography>
              {isEditingMainInfo && (
                <Button variant="contained" color="primary" size="small" startIcon={<AddIcon />} onClick={handleAddLegalRep}>
                  Agregar
                </Button>
              )}
            </Grid>
            <Grid container>
              {formik?.values?.legalRepresentatives?.map((legalRep, i) => (
                <>
                  <Grid item md={5}>
                    <LegalRepresentativeForm
                      rootName={`legalRepresentatives[${i}]`}
                      isEditing={isEditingMainInfo}
                      values={legalRep}
                      touchedFields={formik.touched.legalRepresentatives?.[i]}
                      errors={formik.errors.legalRepresentatives?.[i] as FormikErrors<LegalRepresentative>}
                      handleChangeField={formik.handleChange}
                    />
                  </Grid>
                  <Grid item md={1}>
                    {isEditingMainInfo && formik.values.legalRepresentatives.length > 1 && (
                      <IconButton size="small" onClick={() => handleDeleteLegalRep(i)}>
                        <DeleteOutlineIcon color="primary" />
                      </IconButton>
                    )}
                  </Grid>
                </>
              ))}
            </Grid>
          </>
        )
        }

        {
          mainCompany.generadores && formik?.values?.contacts.length !== 0 &&
          <>
            <Grid container item xs={8} style={{ marginBottom: "1rem", paddingLeft: '0' }}>
              <Typography variant="h6" style={{ marginRight: "35%" }}>
                Contactos
              </Typography>
            </Grid>
            <Grid container>
              {
                formik?.values?.contacts?.map((contact, i) => (
                  <>
                    <Grid item md={5}>
                      <ContactsForm
                        rootName={`contacts[${i}]`}
                        isEditing={isEditingMainInfo}
                        values={contact}
                        touchedFields={formik.touched.contacts?.[i]}
                        errors={formik.errors.contacts?.[i] as FormikErrors<ContactList>}
                        handleChangeField={formik.handleChange}
                      />
                    </Grid>
                  </>
                ))
              }
            </Grid>
          </>
        }
        {mainCompany.generadores ? (
          <Grid container>
            <Grid item>
              <REPSEForm
                values={formik.values.REPSEActivities}
                errors={formik.errors.REPSEActivities as string[]}
                touchedFields={formik.touched.REPSEActivities}
                handleChangeField={formik.handleChange}
                title="Actividad(es) registrada(s) en el REPSE"
                isEditing={isEditingMainInfo}
                onAddAvtivity={handleAddREPSE}
                onDeleteAvtivity={handleDeleteREPSE}
                type="Actividad"
                visibleTitle={formik?.values?.REPSEActivities?.length !== 0}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid className="mt-3" container>
            <Grid item>
              <REPSEForm
                values={formik.values.REPSEActivities}
                errors={formik.errors.REPSEActivities as string[]}
                touchedFields={formik.touched.REPSEActivities}
                handleChangeField={formik.handleChange}
                title="Objeto(s) social(es) preponderante(s)"
                isEditing={isEditingMainInfo}
                onAddAvtivity={handleAddREPSE}
                onDeleteAvtivity={handleDeleteREPSE}
                type="Objeto"
                visibleTitle={formik?.values?.REPSEActivities?.length !== 0}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <UploadPictureDialog open={showModal} onClose={handleCloseModal} onUploadFile={handleUploadFile} />
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
        {mainCompany.generadores ?
          <GeneratorFormNewUserData isEditing setClose={handleClose} onFinish={handleLoad} />
          :
          <FormNewUserData isEditing setClose={handleClose} onFinish={handleLoad} />
        }
      </Dialog>
    </Container>
  );
};

export default ProfilePage;
