import {
  Accordion,
  AccordionSummary,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { mapPeriodDetail } from "../../../../core/utils/ntpUtils";
import { monthSelectOptions } from "../../utils";
import CFDICalendar from "./CFDICalendar";
import CollapsableRow from "./CollapsableRow";
import CFDICheckIcon from "./icons/CFDICheckIcon";
import CFDIWarningIcon from "./icons/CFDIWarningIcon";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useHistory } from "react-router";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CFDI, Period } from "../../../../types/ntp.types";
import useStagesState from "../../../../store/stagesState";

const MONTHS_LABELS = monthSelectOptions.map((m) => m.label);


interface CDFIDialogProps {
  open: boolean;
  onClose?: () => void;
  periodos: Period[];
  serviceCompId: string;
  serviceId: string;
}

const CDFIDialog = (props: CDFIDialogProps) => {
  const [selectedPeriodDetails, setSelectedPeriodDetails] = useState<Period>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  
  function handleSelectPeriod(period: Period, year: number, month: number) {
    setSelectedYear(year);
    setSelectedMonth(month);
    setSelectedPeriodDetails(period);
  }
return (
    <Dialog open={props.open} maxWidth="xl" fullWidth style={{ padding: "1rem" }}>
      <DialogContent style={{ minHeight: "70vh" }}>
        <IconButton
          size="small"
          edge="end"
          onClick={props.onClose}
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
        >
          <CloseIcon style={{ color: "#7A92C5" }} />
        </IconButton>
        <>
          {!Boolean(selectedPeriodDetails) && (
            <InitialScreen
              periodos={props.periodos}
              onShowPeriodDetails={handleSelectPeriod}
              preSelectedYear={selectedYear}
              preSelectedMonth={selectedMonth}
              serviceId={props.serviceId}
            />
          )}
          {Boolean(selectedPeriodDetails) && <DetailScreen period={selectedPeriodDetails} onGoBack={() => setSelectedPeriodDetails(null)} companyId={props.serviceCompId} />}
        </>
      </DialogContent>
    </Dialog>
  );
};

const InitialScreen = (props: {
  periodos: Period[];
  preSelectedYear?: number;
  preSelectedMonth?: number;
  serviceId: string;
  onShowPeriodDetails: (period: Period, year: number, month: number) => void;
}) => {
  const classes = useStyles();
  const [selectedYear, setSelectedYear] = useState<number | null>(props.preSelectedYear);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(props.preSelectedMonth);
  const [selectedCFDI, setSelectedCFDI] = useState<CFDI>(null);
  const [selectedPeriodsCfdis, setSelectedPeriodsCfdis] = useState<Period[]>(null);
  const getCfdisData = useStagesState((s) => s.getPeriodCfdis);

  const existingYears = useMemo(() => {
    const tempYears = new Set<number>();

    props.periodos?.forEach((period) => {
      tempYears.add(moment(period.startDate).utcOffset("+00:00").year());
      tempYears.add(moment(period.endDate).utcOffset("+00:00").year());
    });

    const ordered = Array.from(tempYears).sort((a: number, b: number) => b - a); //max to min

    //contempla el caso de peridos que duren varios años(nada probable)
    const maxY = ordered[0];
    const minY = ordered[ordered.length - 1];
    const allYears = [];
    for (let i = maxY; i >= minY; i--) {
      allYears.push(i);
    }

    return allYears;
  }, [props.periodos]);

  const cfdiPeriodDays = useMemo(() => {
    if (!selectedCFDI || selectedMonth === null) return null;

    const startDate = moment(selectedCFDI.fechaInicialDate).utcOffset("+00:00").startOf("date");
    const endDate = moment(selectedCFDI.fechaFinalDate).utcOffset("+00:00").endOf("date");

    let dateArray = [];

    // Genera un rango de fechas entre la fecha de inicio y la fecha final
    for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, "days")) {
      if (m.month() === selectedMonth) {
        dateArray.push(m.date());
      }
    }

    return dateArray;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCFDI]);

  //existing months and periods by selected year
  const [existingMonths, periodsUidsByMonth] = useMemo(() => {
    if (!selectedYear) return [null, null];

    const monthsSet = new Set<number>();
    const periodsUidsByMonth: Record<number, string[]> = {};

    props.periodos.forEach((period) => {
      const startDate = moment(period.startDate).utcOffset("+00:00").startOf("month");
      const endDate = moment(period.endDate).utcOffset("+00:00").endOf("month");

      //Generates a range of dates between the start date and the end date
      for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, "month")) {
        if (m.year() === selectedYear) {
          const month = m.month();
          monthsSet.add(month);
          if (!periodsUidsByMonth[month]) periodsUidsByMonth[month] = [];
          periodsUidsByMonth[month].push(period.uuid);
        }
      }
    });

    return [Array.from(monthsSet).sort((a: any, b: any) => b - a), periodsUidsByMonth];
  }, [props.periodos, selectedYear]);

  //periods inside selected month and year
  const selectedPeriods = useMemo(() => {
    if (!selectedYear || typeof selectedMonth !== "number") return null;
    return props.periodos?.filter((period) => periodsUidsByMonth[selectedMonth]?.includes(period.uuid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.periodos, selectedYear, selectedMonth]);

  const eployeesCount = useMemo(() => {
    if (!selectedPeriodsCfdis) return null;
    const employeeRfcs = new Set<string>();
    selectedPeriodsCfdis.forEach((period) => {
      period.cfdi?.forEach((cfdi) => {
        employeeRfcs.add(cfdi.employeeData?.rfc);
      });
    });

    return employeeRfcs.size || 0;
  }, [selectedPeriodsCfdis]);

  function handleSelectYear(year: number) {
    setSelectedMonth(null);
    setSelectedCFDI(null);
    setSelectedYear(year);
  }

  function handleSelectMonth(month: number) {
    setSelectedCFDI(null);
    setSelectedMonth(month);
  }

  function handleSelectCFDI(cfdi: any) {
    setSelectedCFDI(cfdi);
  }

  function parsePeriodName(name: string = ""): JSX.Element {
    name = name.replace("Periodo ", "");
    const from = name.split(" - ")[0];
    const to = name.split(" - ")[1];

    return (
      <small>
        <b>{`${from}`}</b> al <b>{`${to}`}</b>
      </small>
    );
  }

  useEffect(() => {
    if (selectedPeriods?.length) {
      Promise.all(selectedPeriods?.map((period) => getCfdisData(props.serviceId, period.uuid))).then((res) => {
        setSelectedPeriodsCfdis(selectedPeriods.map((period, i) => ({ ...period, cfdi: res[i] })));
      });
    }else{
      setSelectedPeriodsCfdis(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriods]);

  const renderPeriods = selectedPeriods && selectedPeriodsCfdis ? selectedPeriodsCfdis : selectedPeriods;

  return (
    <div style={{ width: "100%", display: "flex", margin: "1rem auto", gap: "1rem", justifyContent: "space-around" }}>
      {/* periods col */}
      <div>
        <Typography variant="h6" color="secondary" style={{ fontWeight: 500, overflowY: "auto", maxHeight: "60vh" }}>
          Periodos
        </Typography>
        <div style={{ display: "flex", gap: "1rem", marginTop: ".5rem" }}>
          <div className={classes.btnsCol}>
            {existingYears?.map((y) => (
              <ToggleButton key={y} style={{ width: "100px" }} label={String(y)} active={selectedYear === y} onClick={() => handleSelectYear(y)} />
            ))}
          </div>
          <div className={classes.btnsCol}>
            {MONTHS_LABELS.map((m, idx) => (
              <ToggleButton style={{ width: "200px" }} label={m} disabled={!existingMonths?.includes(idx)} active={selectedMonth === idx} onClick={() => handleSelectMonth(idx)} />
            ))}
          </div>
        </div>
      </div>
      {/* employees col */}
      <div style={{ flexGrow: 1, maxWidth: "450px" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="h6" color="secondary" style={{ fontWeight: 500 }}>
            Empleados ({eployeesCount || 0})
          </Typography>
          {/* <FormControl variant="outlined" color="primary" size="small">
            <OutlinedInput onChange={(e) => setEmployeeSearch(e.target.value)} placeholder={"Buscar"} value={employeeSearch} />
          </FormControl> */}
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem", marginTop: ".5rem", paddingRight: ".5rem", overflowY: "auto", maxHeight: "60vh" }}>
          {!renderPeriods?.length && (Boolean(selectedMonth) ? <div>No hay empleados para mostrar</div> : <div>Selecciona un año y un mes para ver los empleados</div>)}
          {renderPeriods?.map((period, idx) => (
            <Accordion key={period.uuid} elevation={0} className={classes.periodAccordion}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "1rem" }}>
                  <div style={{ fontSize: "14px" }}>
                    <div>PERIODO: {parsePeriodName(period.name)}</div>
                    <div>
                      CFDI(s):{" "}
                      <small>
                        <b>{period.cfdi?.length}</b>
                      </small>
                    </div>
                  </div>
                  <div className={classes.periodDetailLinkButton} role="button" onClick={() => props.onShowPeriodDetails(period, selectedYear, selectedMonth)}>
                    Ver Detalle
                  </div>
                </div>
              </AccordionSummary>
              <div className={classes.btnsCol}>
                {/* <div style={{ borderBottom: "2px solid #737373", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <span>{period.name}</span>
                  <Button variant="text" color="primary" size="small" onClick={() => props.onShowPeriodDetails(period, selectedYear, selectedMonth)}>
                    Ver Detalle
                  </Button>
                </div> */}
                {period.cfdi?.map((cfdi) => (
                  <ToggleButton
                    style={{ width: "100%", minWidth: "300px" }}
                    label={cfdi.employeeData?.fullName}
                    active={selectedCFDI?.uuid === cfdi.uuid}
                    onClick={() => handleSelectCFDI(cfdi)}
                  />
                ))}
              </div>
            </Accordion>
          ))}
        </div>
      </div>
      <div style={{ flexGrow: 1, maxWidth: "450px" }}>
        {/* calendar */}
        <CFDICalendar month={selectedMonth} year={selectedYear} daysRange={cfdiPeriodDays} />
        {/* status */}
        <div style={{ marginTop: "1rem" }}>
          <Typography variant="h6" color="secondary" style={{ fontWeight: 500 }}>
            Estatus
          </Typography>
          <div className={classes.statusBoxRoot}></div>
        </div>
      </div>
    </div>
  );
};

const DetailScreen = (props: { period: Period; companyId: string; onGoBack: () => void }) => {
  const classes = useStyles();
  const history = useHistory();


  const mappedData = useMemo(() => mapPeriodDetail(props.period?.cfdi ?? []), [props.period]);
  console.log("mappedData",mappedData)
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "1rem", marginBottom: "1rem", marginTop: "2rem" }}>
        <Button onClick={props.onGoBack} size="small" disableElevation variant="contained" startIcon={<ChevronLeftIcon />}>
          Regresar
        </Button>
        <Typography variant="h5">{props.period?.name}</Typography>
        <Button
          onClick={() => {
            history.push(`/dashboard/vault/${props.period?.folderId}?company=${props.companyId}`);
          }}
          size="small"
          color="primary"
          disableElevation
          variant="contained"
        >
          Ver en bóveda
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            {/* <TableRow>
              <TableCell align="center" colSpan={5}>
                DETALLE DE EMPLEADOS ({mappedData?.length} empleados)
              </TableCell>
              <TableCell align="center">Validaciòn</TableCell>
              <TableCell />
            </TableRow> */}

            <TableRow>
              <TableCell />
              <TableCell>Nombre Completo</TableCell>
              <TableCell align="center">Identificadores</TableCell>
              <TableCell align="center">Salario diario</TableCell>
              <TableCell align="center" width={"20%"}>
                SBC
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {mappedData.map((cdfiData, i) => {
              return <CollapsableRow key={i} {...cdfiData as any} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CDFIDialog;

const ToggleButton = ({
  label,
  active,
  icon,
  onClick,
  style,
  disabled,
}: {
  label: string;
  active?: boolean;
  icon?: "check" | "warning";
  onClick?: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
}) => {
  const classes = useStyles();
  return (
    <button className={`${classes.toggableButton} ${active ? classes.btnActive : ""} ${disabled ? classes.btnDisabled : ""}`} onClick={onClick} style={style}>
      <span style={{ textTransform: "capitalize" }}>{label?.toLowerCase()}</span>
      {icon === "check" && <CFDICheckIcon />}
      {icon === "warning" && <CFDIWarningIcon />}
    </button>
  );
};

const useStyles = makeStyles((theme) => ({
  statusBoxRoot: {
    marginTop: ".5rem",
    width: "100%",
    minHeight: "100px",
    backgroundColor: "#E7E7E7",
    borderRadius: "3px",
    padding: "1rem",
  },
  btnsCol: {
    display: "flex",
    flexDirection: "column",
    gap: ".6rem",
    width: "100%",
    marginBottom: "1rem",
    padding: "0 1rem",
  },
  toggableButton: {
    width: "100%",
    height: "30px",
    padding: "0 10px",
    borderRadius: "3px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    cursor: "pointer",
    backgroundColor: "#E7E7E7",
    border: "2px solid transparent",
    fontSize: "16px",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
  btnActive: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  btnDisabled: {
    backgroundColor: "#bfbfbf",
    color: "#595959",
    cursor: "not-allowed",
    opacity: ".8",
    "&:hover": {
      borderColor: "transparent",
    },
  },
  table: {
    "&> thead": {
      "& th": {
        border: "1px solid #E0E1F2",
        padding: "0 6px",
        fontWeight: "bold",
      },
      "&> :first-child > th": {
        color: theme.palette.primary.main,
      },
    },
    "&> tbody": {
      "& td": {
        borderBottom: "1px solid #E0E1F2",
      },
    },
  },
  periodAccordion: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
  },
  periodDetailLinkButton: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: "12px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
