import { Backdrop, CircularProgress, Grid, Menu, MenuItem } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ProviderUserIcon from "../../components/icons/ProviderUserIcon";
import CompanyLogo from "../../components/dashboard-home/pyme/CompanyLogo";
import MenuCard from "../../components/dashboard-home/pyme/MenuCard";
import usePlanState from "../../store/planState";
import useGlobalState from "../../store/globalState";
import useDashboardUsersState from "../../store/dashboardUsersState";
import useStagesState from "../../store/stagesState";
import Rfc69Modal from "../../components/modals/Rfc69Modal";
import StatusSat69Modal from "../../components/modals/StatusSat69Modal";
import StatusSat69FullModal from "../../components/modals/StatusSat69FullModal";
import AlertRepse from "../../components/UI/AlertRepseUpdate";
import MaintenanceModal from "../../components/UI/MaintenanceModal";
import BannerGenerator from "../../components/dashboard-home/pyme/BannerGenerator";
import { CompaniesService } from "../../core/services/companies.service";

const DashboardHomeClient = () => {
  const { isLoadingCollaborator, handleOpenUserDialog } = useDashboardUsersState();
  const { mainCompany, setMainCompany, userCompanies, getMainCompany, additionalProducts } = useGlobalState();
  const { setActivatedGenerator } = useDashboardUsersState();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const history = useHistory();
  const { getCompanyStages, companyStages } = useStagesState();
  const [showSat69Modal, setShowSat69Modal] = useState(false);
  const [showSat69ModalResult, setShowSat69ModalResult] = useState(false);
  const [showSat69ModalResultFull, setShowSat69ModalResultFull] = useState(false);

  const [showAlertContinue, setShowAlertContinue] = useState(false);
  const [showAlertMaintenanceContinue, setShowMaintenanceAlertContinue] = useState(false);

  const setHasReadUpdateRepseAlert = useGlobalState((s) => s.setHasReadUpdateRepseAlert);
  const hasReadUpdateRepseAlert = useGlobalState((s) => s.hasReadUpdateRepseAlert);

  const setHasReadUpdatMaintenanceAlert = useGlobalState((s) => s.setHasReadUpdatMaintenanceAlert);
  const hasReadMaintenanceAlert = useGlobalState((s) => s.hasReadMaintenanceAlert);

  const [rfcResult, setRfcResult] = useState<any>({ rfc: null, status: false, data: null });

  const { canCreateClients, canCreateProviders, canShowHomeStages, invitedAs, invited, plan, canAccessRiesgos } = usePlanState();
  const [providers, setProviders] = useState<any>();

  useEffect(() => {
    const getProviders = async () => {
      try {
        await CompaniesService.getInstance().getCompanyProviders(mainCompany?.id).then((data) => {
          const providersWithGeneradores = data.providers.filter(provider => provider.companySelf?.generadores === true);
          setProviders(providersWithGeneradores);
        });
      } catch (error) {
        console.log(error);
      }
    };

    getProviders();
  },[]);

  const handleNavigateProviders = () => {
    history.push("/dashboard/home/providers");
  };
  const handleNavigateClients = () => {
    history.push("/dashboard/home/clients");
  };

  const handleCloseRepseAlert = () => {
    setShowAlertContinue(false);
    setHasReadUpdateRepseAlert(true);
  };


  const handleCloseMaintenanceAlert = () => {
    setShowMaintenanceAlertContinue(false);
    setHasReadUpdatMaintenanceAlert(true);
  };

  // const handleNavigateExpedient = () => {
  //   if (!mainCompany?.id) return;
  //   sessionStorage.setItem("companyId", mainCompany?.id);
  //   history.push("/dashboard/expediente");
  // };
  console.log("home page invitedAs", invitedAs)
  const handleNavigateStage = (stageId: string) => {
    if (!mainCompany?.id) return;
    sessionStorage.setItem("companyId", mainCompany?.id);
    history.push(`/dashboard/home/stage/${stageId}`);
  };

  const handleClickCompany = (comp) => {
    sessionStorage.setItem("companyId", comp.id);
    setMainCompany(comp);
    setAnchorEl(undefined);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(undefined);
  };

  const handleEditProfile = () => history.push("/dashboard/management/edit-profile");

  const handleRfcQuery = (rfc: string, data: any) => {
    setShowSat69Modal(false);
    if (data) {
      setRfcResult({ rfc, data });
      setShowSat69ModalResultFull(true);
    } else {
      setRfcResult({ rfc, status: false });
      setShowSat69ModalResult(true);
    }
  };

  useEffect(() => {
   /* if (mainCompany && canShowHomeStages) {
      getCompanyStages(mainCompany.id);
      if (mainCompany.group) {
        history.push("/dashboard/group-home");
      }
    }
    //eslint-disable-next-line*/
  }, [mainCompany]);

  
  useEffect(() => {
    //getMainCompany();
    //eslint-disable-next-line
    if (invitedAs === "provider" && hasReadUpdateRepseAlert === false && invited) {
     // setShowAlertContinue(true);
    }

    if (hasReadMaintenanceAlert === false) {
      // setShowMaintenanceAlertContinue(true); modal para modal de mantenimiento
    }

  }, []);

  const handleOpenDialogAddProvider = () => {
    handleOpenUserDialog({ formType: "provider", isNewUser: false });
    setActivatedGenerator(true);
  };

console.log("dashboard-client generators")
  return (
    <>
      {/* <GeneratorFormNewUserData /> */}
      <Grid container spacing={2} style={{ marginBottom: 10 }}>
        <Grid item style={{ width: 275 }}>
          <CompanyLogo
            onChangeCompany={handleClickMenu}
            companyName={mainCompany?.name?.toUpperCase() || "no asignada"}
            onEditProfile={handleEditProfile}
            profilePictureId={mainCompany?.profilePictureId}
          />
        </Grid>
        <Grid item xs>
          <BannerGenerator />
        </Grid>
      </Grid>
      <div style={{ width: "100%", display: "flex", gap: "1rem", paddingBottom: "2rem" }}>
        <Grid item xs={12} lg={6} style={{ display: "flex", flexDirection: "column", gap: "1rem", height: "max-content" }}>
          <Grid item xs={12}>
            <MenuCard
              title="Mis Proveedores"
              primaryButtonAction={canCreateProviders ? handleOpenDialogAddProvider : null}
              secondaryButtonAction={handleNavigateProviders}
              counter={
                (providers?.length ?? 0) 
                //(mainCompany?.pendingCollaborations?.filter((value) => value.collaboratorRole === "PROVIDER")?.length ?? 0)
              }
              Icon={<ProviderUserIcon fontSize="small" />}
              primaryButtonText="Agregar proveedor"
              secondaryButtonText="Consultar"
              gap="60px"
            />
          </Grid>
        </Grid>
      </div>

      {/* OTHERS */}
      <Menu id="options-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {userCompanies?.map((company) => (
          <MenuItem key={company.id} onClick={() => handleClickCompany(company)}>
            {company?.name}
          </MenuItem>
        ))}
      </Menu>
      <Backdrop open={isLoadingCollaborator} style={{ zIndex: 1400 }}>
        <CircularProgress color="primary" />
      </Backdrop>

      {showSat69Modal && <Rfc69Modal open={true} onClose={() => setShowSat69Modal(false)} onSubmit={handleRfcQuery} />}
      {showSat69ModalResult && (
        <StatusSat69Modal open={true} onClose={() => setShowSat69ModalResult(false)} status={rfcResult.status} rfc={rfcResult.rfc} />
      )}
      {showSat69ModalResultFull && (
        <StatusSat69FullModal open={true} onClose={() => setShowSat69ModalResultFull(false)} data={rfcResult?.data} />
      )}
      {showAlertContinue && (
        <AlertRepse
          title="IMPORTANTE"
          text="NO OLVIDES..."
          text2="Renovar tu registro REPSE consulta"
          text3="Subir a la plataforma la Determinación de prima de riesgo emitida en febrero 2024."
          onCancel={() => handleCloseRepseAlert()}
          onSubmit={() => handleCloseRepseAlert()}
        />
      )}

      {showAlertMaintenanceContinue && (
        <MaintenanceModal
          title="Estimado usuario:"
          text="Queremos brindarte una mejor experiencia, por lo que Kivit entrará en mantenimiento el próximo viernes 26 de abril a partir de las 12:01 horas reanudándose el servicio el lunes 29 de abril a las 12:01 horas."
          text2="Agradecemos tu comprensión."
          text3=""
          onCancel={() => handleCloseMaintenanceAlert()}
          onSubmit={() => handleCloseMaintenanceAlert()}
        />
      )}
    </>
  );
};

export default DashboardHomeClient;
