import { axiosBusinessRequest, authHeader } from '../../configs/axios.config';

export class TagHttp {

  findAllTags(success, error) {
    axiosBusinessRequest.get('/tags', {headers: authHeader()})
      .then((resp) => success(resp.data))
      .catch(respError => error(respError));
  }

}