import * as React from "react"

const StatsIcon = (props) => (
  <svg
    width={40}
    height={41}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse cx={20} cy={20.074} rx={20} ry={20.074} fill="#2347AD" />
    <path
      d="M29.198 20.794a2 2 0 1 1-3.405 2.1l3.405-2.1Zm-5.308-4.8-1.634-1.153a2 2 0 0 1 3.337.104l-1.703 1.05Zm-3.304 4.68 1.633 1.154a2 2 0 0 1-3.313-.068l1.68-1.086Zm-6.787-6.81a2 2 0 1 1 3.359-2.173l-3.359 2.172Zm11.994 9.03-3.605-5.85 3.405-2.099 3.605 5.85-3.405 2.098Zm-.27-5.746-3.304 4.68-3.267-2.308 3.304-4.68 3.268 2.308Zm-6.617 4.612L13.8 13.863l3.359-2.172 5.107 7.897-3.359 2.172Z"
      fill="#fff"
    />
    <path
      d="M31 27H10V11"
      stroke="#fff"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default StatsIcon
