import React, { useState, VFC } from "react";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
  withStyles,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import WarningIcon from "@material-ui/icons/Warning";
import UserDeleteIcon from "../../icons/icons-v2/UserDeleteIcon";
import UserShowIcon from "../../icons/icons-v2/UserShowIcon";
import UserSendIcon from "../../icons/icons-v2/UserSendIcon";
import { AuthService } from "../../../core/services";
import ColoredButton from "../../general/ColoredButton";
import { sliceText } from "../../../core/utils/utils";
import SatBlacklistIcon from "../../icons/icons-v2/SatBlacklistIcon";
import SatCheckIcon from "../../icons/icons-v2/SatCheckIcon";
import SatPendingIcon from "../../icons/icons-v2/SatPendingIcon";
import SatWarningIcon from "../../icons/icons-v2/SatWarningIcon";
import usePlanState from "../../../store/planState";

interface CardDashboardUserProps {
  mainCompanyName: string;
  user: any;
  userType: "usuario" | "proveedor" | "cliente";
  pendingStatus?: "REQUEST" | "INVITATION";
  invitationId?: string;
  onToggleActivate?: (userId: string, enabled: boolean) => void;
  onAnswerCollaboration?: (invitationId: string, answer: boolean) => void;
  onClick?: () => void;
  onEdit?: (userCompany: any) => void;
  onDelete?: (suserCompany: any) => void;
  onShowContact: (user: DashboardUser) => void;
  blackList?: "PERTENECE" | "NO PERTENECE" | "BAJO REVISIÓN";
}

const authService = new AuthService();

const CardDashboardUser: VFC<CardDashboardUserProps> = ({
  mainCompanyName,
  user,
  userType,
  pendingStatus,
  invitationId,
  blackList,
  onToggleActivate,
  onClick,
  onEdit,
  onAnswerCollaboration,
  onDelete,
  onShowContact,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const userCompany = user?.companySelf;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); //Used to set where the menu will be rendered
  const isTempCompany = !userCompany.updatedData;
  const isAwaiting = isTempCompany && userType !== "cliente";
  const { canDeleteClients, canDeleteProviders } = usePlanState();
  const enableDelete = (userType === "proveedor" && canDeleteProviders) || (userType === "cliente" && canDeleteClients);

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDelete = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    handleMenuClose();
    onDelete(user);
  };

  var handleMenuClose = () => {
    return setAnchorEl(null);
  };

  const handleShowContact = (user: DashboardUser) => {
    handleMenuClose();
    onShowContact(user);
  };

  const handleEmailResend = () => {
    handleMenuClose();
    const userEmail = userCompany?.user?.email;
    authService.resendEmailInvitedUser(userEmail, mainCompanyName);
  };

  const handleClick = (e: any) => {
    if (e.target.parentNode.tagName !== "LI" && e.target.parentNode.tagName !== "UL" && onClick) {
      onClick();
    }
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#fff",
      color: "#ffc24f",
      maxWidth: 170,
      textAlign: "center",
    },
  }))(Tooltip);

  const AwaitingLabel = (
    <HtmlTooltip
      placement="bottom-end"
      title={
        <div>
          <div style={{ margin: "2px auto", fontWeight: "bold", fontSize: "12px" }}>Respuesta pendiente</div>
          {/* <div style={{ margin: "2px auto", fontWeight: "normal", fontSize: "12px", width: "70%" }}>
            Si desea reenviar el correo, oprima en opciones.
          </div> */}
        </div>
      }
    >
      <span className={classes.waitingChip}>
        <WarningIcon />
      </span>
    </HtmlTooltip>
  );

  const Action = (
    <>
      <Tooltip title="Opciones">
        <IconButton aria-controls="options-menu" aria-label="options" onClick={handleClickMenu} className={classes.actionBtn}>
          <MoreVertIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Menu id="options-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {enableDelete && (
          <MenuItem onClick={handleDelete}>
            <UserDeleteIcon fontSize="small" className="mr-1" />
            Eliminar
          </MenuItem>
        )}
        {false && (
          <MenuItem onClick={handleEmailResend}>
            <UserSendIcon fontSize="small" className="mr-1" />
            Reenviar correo
          </MenuItem>
        )}

        <MenuItem onClick={() => handleShowContact(userCompany)}>
          <UserShowIcon fontSize="small" className="mr-1" />
          Ver contacto
        </MenuItem>
      </Menu>
    </>
  );

  const MainContent = (
    <>
      <Box minWidth={"42%"} maxWidth={"42%"}>
        <LightTooltip title={isTempCompany ? userCompany.user?.name : userCompany.name} placement="top">
          <Typography component="span" display="block" variant="body2" className={classes.cardTitle}>
            {isTempCompany ? userCompany.user?.name : userCompany.name}
          </Typography>
        </LightTooltip>
        <Typography component="span" display="block" variant="body2" color="textSecondary" className={classes.cardSubheader}>
          RFC. {isAwaiting || isTempCompany ? "---" : userCompany.rfc}
        </Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box minWidth={"28%"} maxWidth={"28%"}>
        <Typography component="span" display="block" variant="caption">
          Email:
        </Typography>
        <Typography component="span" display="block" variant="caption">
          {sliceText(userCompany.user?.email ?? "Sin email", 24)}
        </Typography>
      </Box>
      {!pendingStatus && (
        <>
          <Divider orientation="vertical" flexItem />
          <LightTooltip
            color={blackList === "NO PERTENECE" ? "#95D97D" : blackList === "BAJO REVISIÓN" ? "#FBB64F" : "#F45F5F"}
            title={blackList}
          >
            <Box style={{ display: "flex", alignItems: "center", gap: ".6rem" }}>
              <SatBlacklistIcon />
              {getBlacklistIcon(blackList)}
            </Box>
          </LightTooltip>
        </>
      )}
      {
        /*(pendingStatus || isAwaiting)*/ false && (
          <>
            <Divider orientation="vertical" flexItem />
            <Box>
              {AwaitingLabel}
              {pendingStatus === "INVITATION" && (
                <Box display="flex" gridColumnGap={10}>
                  <ColoredButton
                    size="small"
                    variant="contained"
                    color={theme.palette.success.main}
                    onClick={() => onAnswerCollaboration?.(invitationId, true)}
                  >
                    Aceptar
                  </ColoredButton>
                  <ColoredButton
                    size="small"
                    variant="contained"
                    color={theme.palette.error.main}
                    onClick={() => onAnswerCollaboration?.(invitationId, false)}
                  >
                    Rechazar
                  </ColoredButton>
                </Box>
              )}
            </Box>
          </>
        )
      }
    </>
  );

  return (
    <>
      <Card className={`${classes.cardRoot} ${isAwaiting && classes.cardRootWaiting}`} onClick={isAwaiting ? () => {} : handleClick}>
        <div>
          <CardHeader
            classes={{
              root: classes.cardHeaderRoot,
              action: classes.moreIcon,
              content: classes.cardHeaderContent,
            }}
            disableTypography
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                {isTempCompany ? userCompany.user?.name[0]?.toUpperCase() : userCompany.name[0].toUpperCase()}
              </Avatar>
            }
            action={Action}
            title={MainContent}
          />
        </div>
      </Card>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    boxShadow: "0px 12px 15px -12px rgba(0, 0, 0, 0.12)",
    cursor: "pointer",
  },
  cardRootWaiting: {
    cursor: "not-allowed",
    backgroundColor: "#BEBEBE1A",
  },
  avatar: {
    backgroundColor: "#2347AD80",
    width: 60,
    height: 60,
    fontSize: "2rem",
  },
  cardHeaderRoot: {
    padding: 12,
  },
  cardHeaderContent: {
    minWidth: 0,
    display: "flex",
    alignItems: "center",
    gap: 15,
  },
  cardSubheader: {
    fontSize: "0.75rem",
    color: "#979797",
  },
  cardTitle: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.black.light,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textTransform: "uppercase",
  },
  moreIcon: {
    alignSelf: "unset",
    marginTop: "unset",
  },
  rfcText: {
    color: theme.palette.lightBlue.main,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  actionBtn: {
    padding: "10px 5px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  waiting: {
    display: "flex",
    justifyContent: "end",
  },
  waitingChip: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.error.main,
    fontSize: "0.75rem",
    padding: "2px 4px",
  },
}));

export default React.memo(CardDashboardUser);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: ({ color }: any) => color || "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: ({ size }: any) => size || 16,
    textTransform: "uppercase",
  },
}))(Tooltip);

function getBlacklistIcon(blacklist: string) {
  switch (blacklist) {
    case "NO PERTENECE":
      return <SatCheckIcon />;
    case "PERTENECE":
      return <SatWarningIcon />;
    case "BAJO REVISIÓN":
      return <SatPendingIcon />;
    default:
      return <SatWarningIcon />;
  }
}
