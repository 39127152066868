import React from "react";
import { alpha, Box, Button, Card, CardHeader, IconButton, makeStyles, Typography, useTheme } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router";
import ColoredButton from "../../general/ColoredButton";
import usePlanState from "../../../store/planState";
import useGlobalState from "../../../store/globalState";

interface Props {
  id: string;
  onClickMore: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onAnswerCollaboration?: (processId: string, answer: boolean) => void;
  name: string;
  initDate:number;
  endDate: number;
  serviceStatus: "accepted" | "rejected" | "pending" | "waiting"; // pending: user must answer, waiting: user must wait for collaborator answer
  answerProcessId?: string;
  showOptions: boolean;
  serviceCompanyId: string;
  serviceTypeId: string;
  userType: "proveedor" | "cliente";
}

const ServiceCard = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { canConsultService } = usePlanState();
  const { typeService } = useGlobalState();

  const handleOpen = () => {
    if (!canConsultService) return;
    const pathPrefix = props.userType === "proveedor" ? "providers" : "clients";
    if (typeService === "generators") {
      return history.push(`${pathPrefix}/stages/generators/${props.id}`);
    }else {
      return history.push(`${pathPrefix}/stages/${props.id}`);
    }

    // if (plan === "CORPORATIVO" && props.serviceTypeId) {
    // } else if (plan === "CORPORATIVO" && props.userType === "proveedor") {
    //   history.push(`/dashboard/client-sec/${props.serviceCompanyId}/${props.id}`);
    // } else if (invitedAs === "provider") {
    //   history.push(`/dashboard/cumplimiento/${props.id}`);
    // } else {
    //   history.push(`/dashboard/client-sec/${props.serviceCompanyId}/${props.id}`);
    // }
  };

  return (
    <Card className={classes.serviceCard}>
      <CardHeader
        disableTypography
        classes={{
          title: [classes.titleService, classes.serviceCardText].join(" "),
          subheader: classes.serviceCardText,
        }}
        action={
          <Box display="flex" alignItems="center">
            {["waiting", "rejected"].includes(props.serviceStatus) && (
              <>
              <span className={classes.serviceStatusChip}>
                <span style={{paddingRight:'6px'}}><WarningIcon /></span>
                {props.serviceStatus === "waiting" ? "Pendiente de Aprobación" : "Servicio rechazado"}
              </span>
              </>
              
            )}
            {props.serviceStatus === "pending" && (
              <>
                <ColoredButton
                  size="large"
                  variant="contained"
                  color={theme.palette.success.main}
                  onClick={() => props.onAnswerCollaboration(props.answerProcessId as string, true)}
                >
                  Aceptar
                </ColoredButton>
                <ColoredButton
                  size="large"
                  variant="contained"
                  color={theme.palette.error.main}
                  onClick={() => props.onAnswerCollaboration(props.answerProcessId as string, false)}
                >
                  Rechazar
                </ColoredButton>
              </>
            )}
            {["accepted"].includes(props.serviceStatus) && (
              <Button size="large" variant="contained" color="primary" onClick={handleOpen}>
                Consultar
              </Button>
            )}
            <IconButton
              disabled={!props.showOptions}
              aria-label="settings"
              onClick={(evt) => {
                evt.stopPropagation();
                props.onClickMore(evt);
              }}
            >
              <MoreVertIcon color="primary" />
            </IconButton>
          </Box>
        }
        title={
          <Typography variant="subtitle2" color="primary">
            {props.name?.slice(0,80)}
          </Typography>
        }
        subheader={
          <Typography variant="caption" color="primary">
            {"Vigencia del : " +  (!!props.initDate ? new Date(props.initDate ? props.initDate : "").toLocaleString("es-MX", options) : "Por confirmar") + " al " +
              (!!props.endDate ? new Date(props.endDate ? props.endDate : "").toLocaleString("es-MX", options) : "Por confirmar")}
          </Typography>
        }
      />
    </Card>
  );
};

const options: Intl.DateTimeFormatOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  timeZone: "UTC",
};

const useStyles = makeStyles((theme) => ({
  serviceCard: {
    border: `1px solid ${theme.palette.primary.main}`,
    marginBottom: 10,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  titleService: {
    color: theme.palette.primary.main,
  },
  serviceCardText: {
    ...theme.typography.body1,
    fontWeight: 600,
  },
  downloadIcon: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    padding: 10,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  serviceStatusChip: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.orange.main,
    fontSize: "0.75rem",
    padding: "2px 6px",
    width: 110,
  },
}));

export default ServiceCard;
