import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useFormik } from "formik";
import * as Yup from "yup";

import TermsAndConditions from "./policy/TermsConditions";
import SafetyIndicator from "./SafetyIndicator";
import LinkCheckbox from "./LinkCheckbox";
import PolicyDialog from "./policy/PolicyDialog";
import LogoutOnLeave from "../LogoutOnLeave";
import { useHistory, useLocation } from "react-router";
import RenderTextFieldV2 from "../inputs/inputs-v2/RenderTextFieldV2";
import PrivacyDataProtection from "./policy/PrivacyDataProtection";
import useGlobalState, { AlertInfo } from "../../store/globalState";

interface FormFields {
  newPassword: string;
  newPasswordConfirm: string;
  tAndC: boolean;
  privacyData: boolean;
}

interface UpdateAccountProps {
  onSubmit: (password: string, createType: "invited" | "new") => void;
  alert: AlertInfo;
}

const UpdateAccount = (props: UpdateAccountProps) => {
  const [dialogTandCOpen, setDialogTandCOpen] = useState(false);
  const [privacyDialogOpen, setPrivacyDialogOpen] = useState(false);
  const [invitedCompany, setInvitedCompany] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const userInfo = useGlobalState(s=>s.userInfo)

  useEffect(() => {
    const isAccessible = location?.state?.isAccessible;
    if (!isAccessible) {
      return history.replace("/");
    }


    if (userInfo?.id) {
      //is invited user
      setInvitedCompany(userInfo?.invited);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: FormFields) => {
    props.onSubmit(values.newPassword, invitedCompany ? "invited" : "new");
  };

  const formik = useFormik<FormFields>({
    initialValues: {
      newPassword: "",
      newPasswordConfirm: "",
      tAndC: false,
      privacyData: false,
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("El campo es requerido")
        .min(10, "La contraseña no puede ser menor a 10 caracteres")
        .max(20, "La contraseña no puede ser mayor a 20 caracteres"),
      newPasswordConfirm: Yup.string()
        .required("El campo es requerido")
        .oneOf([Yup.ref("newPassword")], "Las contraseñas deben coincidir."),
      tAndC: Yup.boolean().isTrue("Si no acepta los términos y condiciones no se puede concluir el registro de usuario."),
      privacyData: Yup.boolean().isTrue("Si no acepta el aviso de privacidad no se puede concluir el registro de usuario."),
    }),
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <>
      <Box component="form" onSubmit={formik.handleSubmit} display="flex" gridGap={15} flexDirection="column" flex="1">
        <Typography variant="h2" color="primary" style={{ fontWeight: "normal" }}>
          Actualizar contraseña
        </Typography>
        <RenderTextFieldV2
          size="small"
          label="Contraseña nueva"
          error={formik.errors.newPassword}
          onChange={formik.handleChange}
          touched={formik.touched.newPassword}
          value={formik.values.newPassword}
          placeholder="Tu nueva contraseña"
          name="newPassword"
          type="password"
          passwordAction
        />
        <RenderTextFieldV2
          size="small"
          label="Confirmar contraseña"
          error={formik.errors.newPasswordConfirm}
          onChange={formik.handleChange}
          touched={formik.touched.newPasswordConfirm}
          value={formik.values.newPasswordConfirm}
          placeholder="Tu nueva contraseña"
          name="newPasswordConfirm"
          type="password"
          passwordAction
        />

        <SafetyIndicator password={formik.values.newPassword} />
        <Box>

          <LinkCheckbox
            name="privacyData"
            checked={formik.values.privacyData}
            onChange={formik.handleChange}
            onClickLink={() => window.open("/docs/aviso-privacidad.pdf", "_blank")}
            text="Acepto el"
            linkText="Aviso de privacidad"
          />
          <LinkCheckbox
            name="tAndC"
            checked={formik.values.tAndC}
            onChange={formik.handleChange}
            onClickLink={() => window.open("/docs/kivit-terms.pdf", "_blank")}
            text="Acepto los"
            linkText="Términos y Condiciones."
          />
        </Box>
        {!!formik.errors.tAndC && (
          <Alert variant="filled" severity="error">
            {formik.errors.tAndC}
          </Alert>
        )}
        {props.alert?.message && (
          <Alert variant="filled" severity={props.alert?.type}>
            {props.alert.message}
          </Alert>
        )}
        <Button type="submit" variant="contained" color="primary" fullWidth={true}>
          Actualizar contraseña
        </Button>
        <LogoutOnLeave />
      </Box>
      <PolicyDialog
        onReturn={() => setDialogTandCOpen(false)}
        open={dialogTandCOpen}
        onAccept={() => {
          formik.setFieldValue("tAndC", true);
          setDialogTandCOpen(false);
        }}
        onReject={() => {
          formik.setFieldValue("tAndC", false);
          setDialogTandCOpen(false);
        }}
        title="Términos y Condiciones"
      >
        <TermsAndConditions />
      </PolicyDialog>
      <PolicyDialog
        onReturn={ () => setPrivacyDialogOpen(false) }
        open={ privacyDialogOpen }
        onAccept={() => {
          formik.setFieldValue("privacyData", true);
          setDialogTandCOpen(false);
        }}
        onReject={() => {
          formik.setFieldValue("privacyData", false);
          setDialogTandCOpen(false);
        }}
        title="Aviso de Privacidad"
      >
        <PrivacyDataProtection />
      </PolicyDialog>
    </>
  );
};

export default UpdateAccount;
