
const CFDICheckIcon = (props) => (
  <svg
    width={14}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={7} cy={7} r={7} fill="#F5FBFF" />
    <path
      d="M7 0C3.14 0 0 3.14 0 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7Zm3.548 5.517L6.756 9.308a.582.582 0 0 1-.825 0L4.035 7.412a.583.583 0 1 1 .825-.824L6.344 8.07l3.379-3.38a.583.583 0 1 1 .825.826Z"
      fill="#85D06A"
    />
  </svg>
)

export default CFDICheckIcon
