import { List, ListItem, ListItemIcon, ListItemText, makeStyles, SvgIconProps } from '@material-ui/core';
import React from 'react'
import { NavLink } from 'react-router-dom';

interface MainMenuProps {
  show: boolean;
  /** Items shown on the top menu */
  menuItems: {
    /**Item tex */
    text: string,
    /** identifier each item used on map function as key prop. */
    name: string,
    /** Icon shown beside the name. */
    icon: (props: SvgIconProps) => JSX.Element,
    /** Link where the item will be redirect when the user clicks on it. */
    link: string,

    show:boolean
  }[];
}

const MainMenu = (props: MainMenuProps) => {
  const classes = useStyles();

  return (
    <List className={`${classes.mainMenu} ${props.show ? classes.mainMenuOpen : classes.mainMenuClosed}`}>
      {props.menuItems.map((item) => {
        const ItemIcon = item.icon;

        if(!item.show) return null
        return (
          <ListItem
            className={classes.listItem}
            activeClassName={classes.listItemActive}
            button
            component={NavLink}
            to={`/dashboard${item.link}`}
            key={item.name}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <ItemIcon htmlColor="white" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        )
      })}
    </List>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    menuItemIcon: { minWidth: 25 },
    listItem: {
      opacity: 0.5,
      width: 'fit-content',
      transition: 'opacity 0.3s, background-color 0.3s',
      '&:hover': {
        opacity: 1,
        color: 'white',
      },
      '&:focus': {
        textDecoration: 'none',
        outline: 'none',
        color: 'white',
      },
      '& span': {
        fontSize: 14,
      },
    },
    listItemActive: {
      opacity: 1,
      '& span': {
        fontWeight: 'bold',
      },
    },
    mainMenu: {
      display: 'flex',
      transition: 'width 0.5s, opacity 0.6s',
    },
    mainMenuOpen: {
      opacity: 100,
      width: '100%',
    },
    mainMenuClosed: {
      opacity: 0,
      width: 0,
    }
  };
});

export default MainMenu
