import create from "zustand";
import { GeolocationFormInfoReqBody } from "../../types/companies-service";
import { defaultGeolocationBody } from "./defaultForms";

interface GeolocationFormState {
    geolocationFormBody: GeolocationFormInfoReqBody;
    updateGeolocationForm: (form: GeolocationFormInfoReqBody) => void;
    updateFinalImageId: (finalImageId: string) => void;
}

const useGeolocationFormState = create<GeolocationFormState>((set) => ({
    geolocationFormBody: defaultGeolocationBody,
    updateGeolocationForm: (form: GeolocationFormInfoReqBody) =>
        set((state) => ({
            ...state,
            geolocationFormBody: form,
        })),
    updateFinalImageId: (finalImageId: string) =>
        set((state) => ({
            geolocationFormBody: {
                ...state.geolocationFormBody,
                finalImageId,
            },
        })),
}));


export default useGeolocationFormState;