import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useState } from "react";
import useCustomToast from "../../../core/hooks/useCustomToast";
import { Stage } from "../../../core/models/stage.models";
import useStagesState from "../../../store/stagesState";
import MatFisButton from "./MatFisButton";

const DownloadDocsDialog = ({ onClose, stage, companyId }: { onClose: any; stage: Stage; companyId: string }) => {
  const classes = useStyles();
  const toast = useCustomToast();
  const [all, setAll] = useState(false);
  const [selectedReqIds, setSelectedReqIds] = useState<string[]>([]);
  const downloadDocuments = useStagesState((s) => s.downloadStageRequirementDocument);

  const handleChange = (event) => {
    const reqId = event.target.value;
    setAll(false);
    setSelectedReqIds((prevState) => {
      if (event.target.checked) {
        return [...new Set([...prevState, reqId])];
      } else {
        return prevState.filter((id) => id !== reqId);
      }
    });
  };

  const handleChangeAll = (event) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedReqIds(stage?.groups?.flatMap((group) => group?.requirements?.map((req) => req.uid)));
    } else {
      setSelectedReqIds([]);
    }
    setAll(event.target.checked);
  };

  const handleDownload = async () => {
    const downloaded = await downloadDocuments(companyId, stage.stageTemplateId, selectedReqIds);
    if (!downloaded) {
      toast.error("No se pudo descargar los documentos");
    }else{
      onClose();
    }
  };

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <DialogTitle
        disableTypography
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span className={classes.dialogTitleText}>Descargar documentos</span>
        <IconButton edge="end" onClick={onClose}>
          <CloseIcon style={{ color: "#7A92C5" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControlLabel value={""} control={<Checkbox checked={all} />} label={"TODOS"} onChange={handleChangeAll} />
        {stage?.groups?.map((group, idx) => (
          <div key={idx}>
            <h4
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                marginTop: "1rem",
              }}
            >
              {group.name}
            </h4>
            <FormControl component="fieldset">
              {group.requirements?.map((req) => {
                return (
                  <FormControlLabel
                    key={req.uid}
                    value={req.uid}
                    control={<Checkbox checked={selectedReqIds?.includes(req.uid)} />}
                    label={req.name}
                    onChange={handleChange}
                  />
                );
              })}
            </FormControl>
          </div>
        ))}

        <Grid container justifyContent="center" style={{ padding: "2rem 0 1rem 0" }}>
          <MatFisButton variant="contained" color="primary" type="button" onClick={handleDownload}>
            Descargar
          </MatFisButton>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContentRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 20,
  },
  dialogTitleText: {
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "21.33px",
  },
  dialogContentText: {
    fontSize: "0.875rem",
    lineHeight: "17px",
    color: "#7A92C5",
    marginBottom: 40,
  },
});

export default DownloadDocsDialog;
