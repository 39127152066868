import { useEffect } from "react";
import { List, ListItem, Box, makeStyles, ListItemIcon, ListItemText } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import DoneIcon from "@material-ui/icons/Done";
import { PasswordPolicyList } from "../../core/models/settings.models";

interface SafetyProps {
  errorPolicies: any
  policies: PasswordPolicyList[]
  setPolicies: (policies: PasswordPolicyList[]) => void
}

const SafetyPolicies = ({ errorPolicies, policies, setPolicies }: SafetyProps) => {
  const classes = useStyles();
  useEffect(() => {
    if (errorPolicies) {
      const updatedPolicies = policies.map(policy => {
        policy.hasError = false;
        for (const key in errorPolicies) {
          if (key === policy.policyType) {
            policy.hasError = true;
          }
        }
        return policy;
      });
      setPolicies(updatedPolicies);
    }
  }, [errorPolicies]);

  return (
    <Box>
      <List>
        {policies.map((policy, index) => (
          <ListItem key={index} style={{ display: 'flex', alignItems: 'center' }}>
            {policy.hasError ? (
              <ListItemIcon>
                <Cancel color="error" style={{ marginRight: 16, marginBottom: 8 }} />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <DoneIcon className={classes.strongPassword} />
              </ListItemIcon>
            )}
            <ListItemText
              className={policy.hasError ? classes.instructionError : classes.instruction}
              primary={policy.valueType === 'NUMERIC' ? `${policy.description} ${policy.value}` : policy.description}
            ></ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  strongPassword: {
    color: theme.palette.success.main
  },
  instruction: {
    fontFamily: '\'Barlow\', sans-serif',
    fontSize: 12,
    lineHeight: '0.75rem',
    color: theme.palette.success.main,
  },
  instructionError: {
    fontFamily: '\'Barlow\', sans-serif',
    fontSize: 12,
    lineHeight: '0.75rem',
    color: theme.palette.error.main,
  }
}));

export default SafetyPolicies;
