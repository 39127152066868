import { Typography } from "@material-ui/core";
import { useMemo } from "react";
import { getCalendarDays } from "../../../../core/utils/utils";
import { monthSelectOptions } from "../../utils";

const DAYS_LABELS = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"];
const MONTHS_LABELS = monthSelectOptions.map((m) => m.label);

const CFDICalendar = ({ month, year, daysRange, severity }: { month: number; year: number; daysRange?: number[]; severity?: "warning" | "success" }) => {
  const composedMonth = Number.isInteger(month) ? month : new Date().getMonth()
  const composedYear = year || new Date().getFullYear()
  const composedDate = `${composedYear}-${String(composedMonth + 1).padStart(2, "0")}-15`; //half month
  const currentMonthLabel = MONTHS_LABELS[composedMonth];
  const currentYearLabel = String(composedYear);
  
  const calendarDays = useMemo(() => getCalendarDays(composedDate), [composedDate]);

  const colors = {
    warning: "#FFC107",
    success: "#afe09d",
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h6" color="secondary" style={{ fontWeight: 500 }}>
        Días
      </Typography>
      <div style={{ display: "flex", flexDirection: "column", marginTop: ".5rem" }}>
        <div
          style={{
            minWidth: "280px",
            minHeight: "250px",
            width: "100%",
            backgroundColor: "#E7E7E7",
            borderRadius: "3px",
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography variant="h6" color="primary" style={{ fontWeight: 400, paddingLeft: "8px"}}>
            {currentMonthLabel} {currentYearLabel}
          </Typography>
          {/* week days labels */}
          <div style={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)" }}>
            {DAYS_LABELS.map((d) => (
              <div style={{ textAlign: "center", padding: "4px" }}>
                <Typography variant="body1" color="primary" style={{ fontWeight: 400 }}>
                  {d}
                </Typography>
              </div>
            ))}
          </div>
          {/* days grid */}
          <div style={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)" }}>
            {calendarDays.map((week) =>
              week.map((day) => (
                <div
                  style={{
                    textAlign: "center",
                    padding: "8px",
                    color: "#000",
                    backgroundColor: daysRange?.includes(day.dayNumber) && day.isCurrentMonthDay ? colors[severity ?? "success"] : "unset",
                  }}
                >
                  <Typography variant="body1" color="inherit" style={{ fontWeight: 400, opacity: day.isCurrentMonthDay ? 1 : 0.3 }}>
                    {day.dayNumber}
                  </Typography>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CFDICalendar;
