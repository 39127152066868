import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

const ReportGenerator = ({ filteredData }) => {
    const isDisabled = !filteredData || filteredData.length === 0;

    const exportToExcel = () => {
        if (isDisabled) return; // No hacer nada si está deshabilitado

        const tagStyle = (tag) => {
            switch (tag) {
                case 'ACCEPTED':
                    return 'Aceptado';
                case 'PAID':
                    return 'Pagado';
                case 'REJECTED':
                case 'DISABLED':
                    return 'Rechazado';
                case 'REVISION':
                    return 'Bajo Revisión';
                default:
                    return 'Pendiente de pago';
            }
        };

        const headers = [
            "Proveedor",
            "ID del Generador",
            "Inicio",
            "Fin",
            "Importe total",
            "Cuidad",
            "Estatus",
            "Estado de Pago"
        ];

        // Crear los datos para exportar
        const dataToExport = filteredData.map(item => ({
            "Proveedor": item.providerName,
            "ID del Generador": item.generatedCode,
            "Inicio": item.startDate,
            "Fin": item.endDate,
            "Importe total": item.total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }),
            "Cuidad": item.city,
            "Estatus": tagStyle(item.status),
            "Estado de Pago": tagStyle(item.statusPago)
        }));

        // Crear la hoja de cálculo
        const worksheet = XLSX.utils.json_to_sheet(dataToExport, { header: headers });

        // Aplicar formato a los encabezados
        const headerCellStyle = {
            fill: {
                fgColor: { rgb: "4F81BD" } // Azul para el fondo
            },
            font: {
                color: { rgb: "FFFFFF" } // Blanco para el texto
            }
        };

        // Ajustar el ancho de las columnas
        const columnWidths = headers.map(() => ({ width: 25 })); // Ajustar el ancho de las columnas a 20
        worksheet['!cols'] = columnWidths;

        // Aplicar formato a los encabezados
        headers.forEach((_, index) => {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index }); // Primera fila
            if (worksheet[cellAddress]) {
                worksheet[cellAddress].s = headerCellStyle;
            }
        });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte");

        // Escribir el archivo Excel
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        // Crear el archivo Blob y descargar
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, `Reporte_${new Date().toLocaleDateString()}.xlsx`);
    };

    return (
        <div>
            <Button
                variant="contained"
                style={{
                    backgroundColor: isDisabled ? 'rgba(200, 200, 200, 1)' : 'rgba(30, 136, 229, 1)', // Color deshabilitado
                    color: 'rgba(255, 255, 255, 1)',
                    marginLeft: '10px',
                    height: '40px',
                    padding: '0 16px',
                }}
                onClick={exportToExcel}
                startIcon={<DescriptionIcon />}
                disabled={isDisabled} // Deshabilitar el botón si no hay datos
            >
                Generar Reporte
            </Button>
        </div>
    );
};

export default ReportGenerator;
