import { Box, Button, makeStyles } from "@material-ui/core";
import React, { VFC } from "react";

interface TagTemplateFilterProps {
  label: string;
  count: number;
  selected?: boolean;
  onClick: (label: string) => void;
}

const TagTemplateFilter: VFC<TagTemplateFilterProps> = ({ count, label, selected, onClick }) => {
  const classes = useStyles();
  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        className={`${classes.buttonFilter} ${selected ? classes.buttonFilterSelected : classes.buttonFilterUnselected}`}
        disableElevation
        onClick={() => {
          onClick(label);
        }}
      >
        {label}
        <Box p={0.5} ml={0.5} className={classes.buttonFilterNumber}>
          {count ?? 0}
        </Box>
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonFilter: {
    backgroundColor: "#2347AD1A",
    color: theme.palette.primary.main,
    borderRadius: 35,
    paddingRight: 6,
    marginRight: 12,
    marginBottom: 8,
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
    "& $buttonFilterNumber": {
      backgroundColor: "rgba(33, 70, 183, 0.05)",
      borderRadius: 26,
      width: 26,
      height: 26,
    },
  },
  buttonFilterSelected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "& $buttonFilterNumber": {
      backgroundColor: "rgba(255,255,255,0.2)",
    },
  },
  buttonFilterUnselected: {
    backgroundColor: "#2347AD1A",
    color: theme.palette.primary.main,
    "& $buttonFilterNumber": {
      backgroundColor: "#2347AD80",
    },
  },
  buttonFilterNumber: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default TagTemplateFilter;
