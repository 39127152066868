/* eslint-disable no-useless-escape */
import React from "react";

import { Button, Grid, IconButton, Typography, makeStyles } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import CloseIcon from "@material-ui/icons/Close";

type Props = {
  success: "success" | "error" | null;
  onCancel: (args?: any) => any;
  userType: string;
  onSubmit?: (args?: any) => any;
};

function UserDeletedAlert({ success, onCancel, userType, onSubmit }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Grid container>
        <Grid item md={12}>
          <Grid container xs={12} justifyContent="space-between" alignItems="center" className="mb-4">
            <div style={{ height: "48px", width: "48px" }}></div>
            
            {success === "success" ? <CheckCircleIcon fontSize="large" className={classes.checkIcon} /> : <WarningRoundedIcon fontSize="large" className={classes.alertIcon} />}
            <IconButton aria-label="close" onClick={onCancel}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Grid>

          <Typography className={classes.title}>
            {success === "success" ? `Operación exitosa.` : `Operación NO exitosa.`}
          </Typography>

          <Typography className={classes.subtitle}>
            {success === "success" ? `El ${userType} se eliminó correctamente.` : `Por favor intente nuevamente.`}
          </Typography>

          <Grid className="mb-2" style={{ marginTop: "100px" }}>
            <Button variant="contained" color="primary" onClick={onSubmit}>
              SIGUIENTE
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  main: {
    textAlign: "center",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  subtitle: {
    width: "60%",
    margin: "3rem auto",
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
  alertIcon: {
    color: theme.palette.warning.main,
  },
  dangerBtn: {
    color: "#fff",
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

export default UserDeletedAlert;
