import { Box, Button, Drawer, Grid, makeStyles, Typography } from "@material-ui/core";
import { DocumentValidationStatus } from "materialidad-outsourcing/dist/types";
import React, { useState, VFC } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import RenderTextField from "../inputs/RenderTextField";
import MatFisButton from "../MatFisButton";
import usePlanState from "../../../../store/planState";

interface Props {
  name: string;
  documentStatus: string;
  onValidateStatus: (status: DocumentValidationStatus) => void;
  onReturn: () => void;
}

const WatchDocumentDrawer: VFC<Props> = ({ name, onReturn, documentStatus, onValidateStatus }) => {
  const classes = useStyles();
  const { canValidateDocuments } = usePlanState();
  const {plan,invitedAs} = usePlanState();

  const [comment, setComment] = useState("");
  //get pathname
  const pathname = window.location.pathname;
  const watchingAsClient = pathname.includes("/providers/stages/");
  const canValidate = watchingAsClient ? canValidateDocuments.whenIsClient : canValidateDocuments.whenIsProvider;
console.log("logsa",watchingAsClient);
console.log("canValidate",canValidate);
console.log("plan",plan)
console.log("invitedAs",invitedAs)
const canValidateWithPlan = canValidate || plan === 'CORPORATIVO';
console.log("canValidateWithPlan",canValidateWithPlan)
  const handleChangeComment: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    setComment(e.target.value);
  };

  const handleAcceptDocument = () => {
    onValidateStatus({
      comments: comment,
      date: new Date().toISOString(),
      status: "Aceptado",
    });
    onReturn();
  };

  const handleRejectDocument = () => {
    onValidateStatus({
      comments: comment,
      date: new Date().toISOString(),
      status: "Rechazado",
    });
    onReturn();
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <Grid style={{ padding: "1rem" }}>
        <Grid item>
          <Button size="small" startIcon={<ChevronLeftIcon />} variant="contained" onClick={onReturn}>
            Regresar
          </Button>
        </Grid>
        <Typography variant="h4" color="primary" style={{ marginTop: "3rem", textTransform: "uppercase" }}>
          {name}
        </Typography>
      </Grid>
      {
        canValidateWithPlan && (
          <>
            <RenderTextField
              value={comment}
              onChange={handleChangeComment}
              multiline
              label="Comentario"
              placeholder="Agregar un comentario"
            />
            <Box display="flex" justifyContent="flex-start" marginTop="auto" marginBottom="24px" gridGap={12}>
              <MatFisButton variant="outlined" color="primary" onClick={handleRejectDocument}>
                Rechazar
              </MatFisButton>
              <MatFisButton variant="contained" color="primary" onClick={handleAcceptDocument}>
                Validar Revision
              </MatFisButton>
            </Box>
          </>
        )
      }
    </Drawer>
  );
};

const drawerWidth = "28vw";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100%",
  },
  drawer: {
    minWidth: "450px",
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    boxSizing: "content-box",
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.12)",
    minWidth: "436px",
    width: drawerWidth,
    paddingLeft: 15,
    paddingRight: 15,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20,
  },
  // necessary for content to be below app bar
  toolbar: {
    display: "flex",
    alignItems: "center",
  },
  content: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  documentTitle: {
    fontSize: "1.25rem",
    color: theme.palette.primary.main,
    lineHeight: "25px",
    marginLeft: 18,
  },
}));

export default WatchDocumentDrawer;
