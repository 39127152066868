import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export default function UserDeleteIcon(props:SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 15 15">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5ZM9.64307 10.2857C9.64307 10.6804 9.32343 11 8.92877 11H6.07162C5.67698 11 5.35734 10.6804 5.35734 10.2857V6H9.64307V10.2857ZM6.60715 4L6.24999 4.35714H5V5.07143H10V4.35714H8.75001L8.39285 4H6.60715Z" />
    </SvgIcon>
  );
}
