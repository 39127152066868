import create from "zustand";

interface GeneradorServicio {
    material: string;
    descripcion: string;
    unidad: string;
    precioUnitario: number;
    cantidad: number;
    total: number;
}

interface CatalogoState {
    summaryData: any;
    adecuacionesList: GeneradorServicio[];
    adecuacionesListSelected: GeneradorServicio[];
    totalAdecuacionesSelected: number;
    fibraOpticaList: GeneradorServicio[];
    fibraOpticaListSelected: GeneradorServicio[];
    totalFibraOpticaSelected: number;
    microondasList: GeneradorServicio[];
    microondasListSelected: GeneradorServicio[];
    totalMicroondasSelected: number;
    radioBaseList: GeneradorServicio[];
    radioBaseListSelected: GeneradorServicio[];
    totalRadioBaseSelected: number;
    setSummaryData: (data: any) => void;
    addAdecuacion: (newAdecuacion: GeneradorServicio) => void;
    removeAdecuacion: (material: string) => void;
    updateAdecuacion: (material: string, updatedFields: Partial<GeneradorServicio>) => void;
    cleanAdecuacionesList: () => void;
    updateAdecuacionesSelected: () => void;
    addFibraOptica: (newAdecuacion: GeneradorServicio) => void;
    removeFibraOptica: (material: string) => void;
    updateFibraOptica: (material: string, updatedFields: Partial<GeneradorServicio>) => void;
    cleanFibraOpticaList: () => void;
    updateFibraOpticaSelected: () => void;
    addMicroondas: (newAdecuacion: GeneradorServicio) => void;
    removeMicroondas: (material: string) => void;
    updateMicroondas: (material: string, updatedFields: Partial<GeneradorServicio>) => void;
    cleanMicroondasList: () => void;
    updateMicroondasSelected: () => void;
    addRadioBase: (newAdecuacion: GeneradorServicio) => void;
    removeRadioBase: (material: string) => void;
    updateRadioBase: (material: string, updatedFields: Partial<GeneradorServicio>) => void;
    cleanRadioBaseList: () => void;
    updateRadioBaseSelected: () => void;
}

const updateSelectedList = (list: GeneradorServicio[]) => {
    const selectedList = list.filter((service) => service.cantidad > 0);
    const totalSelected = selectedList.reduce((sum, service) => sum + service.total, 0);
    return {
      selectedList,
      totalSelected,
    };
  };


const useCatalogoState = create<CatalogoState>((set) => ({
    summaryData: null,
    adecuacionesList: [],
    adecuacionesListSelected: [],
    totalAdecuacionesSelected: 0,
    fibraOpticaList: [],
    fibraOpticaListSelected: [],
    totalFibraOpticaSelected: 0,
    microondasList: [],
    microondasListSelected: [],
    totalMicroondasSelected: 0,
    radioBaseList: [],
    radioBaseListSelected: [],
    totalRadioBaseSelected: 0,

    setSummaryData: (data: any) =>
      set((state) => {
        return {
          summaryData: data,
        };
      }),

    addAdecuacion: (newAdecuacion) =>
      set((state) => {
        const newList = [...state.adecuacionesList, newAdecuacion];
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          adecuacionesList: newList,
          adecuacionesListSelected: selectedList,
          totalAdecuacionesSelected: totalSelected,
        };
      }),
  
    removeAdecuacion: (material) =>
      set((state) => {
        const newList = state.adecuacionesList.filter(
          (adecuacion) => adecuacion.material !== material
        );
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          adecuacionesList: newList,
          adecuacionesListSelected: selectedList,
          totalAdecuacionesSelected: totalSelected,
        };
      }),
  
    updateAdecuacion: (material, updatedFields) =>
      set((state) => {
        const newList = state.adecuacionesList.map((adecuacion) =>
          adecuacion.material === material ? { ...adecuacion, ...updatedFields } : adecuacion
        );
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          adecuacionesList: newList,
          adecuacionesListSelected: selectedList,
          totalAdecuacionesSelected: totalSelected,
        };
      }),
  
    cleanAdecuacionesList: () =>
      set((state) => {
        const newList = [];
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          adecuacionesList: newList,
          adecuacionesListSelected: selectedList,
          totalAdecuacionesSelected: totalSelected,
        };
      }),
  
    updateAdecuacionesSelected: () =>
      set((state) => {
        const { selectedList, totalSelected } = updateSelectedList(state.adecuacionesList);
        return {
          adecuacionesListSelected: selectedList,
          totalAdecuacionesSelected: totalSelected,
        };
      }),
  
    addFibraOptica: (newAdecuacion) =>
      set((state) => {
        const newList = [...state.fibraOpticaList, newAdecuacion];
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          fibraOpticaList: newList,
          fibraOpticaListSelected: selectedList,
          totalFibraOpticaSelected: totalSelected,
        };
      }),
  
    removeFibraOptica: (material) =>
      set((state) => {
        const newList = state.fibraOpticaList.filter(
          (adecuacion) => adecuacion.material !== material
        );
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          fibraOpticaList: newList,
          fibraOpticaListSelected: selectedList,
          totalFibraOpticaSelected: totalSelected,
        };
      }),
  
    updateFibraOptica: (material, updatedFields) =>
      set((state) => {
        const newList = state.fibraOpticaList.map((adecuacion) =>
          adecuacion.material === material ? { ...adecuacion, ...updatedFields } : adecuacion
        );
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          fibraOpticaList: newList,
          fibraOpticaListSelected: selectedList,
          totalFibraOpticaSelected: totalSelected,
        };
      }),
  
    cleanFibraOpticaList: () =>
      set((state) => {
        const newList = [];
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          fibraOpticaList: newList,
          fibraOpticaListSelected: selectedList,
          totalFibraOpticaSelected: totalSelected,
        };
      }),
  
    updateFibraOpticaSelected: () =>
      set((state) => {
        const { selectedList, totalSelected } = updateSelectedList(state.fibraOpticaList);
        return {
          fibraOpticaListSelected: selectedList,
          totalFibraOpticaSelected: totalSelected,
        };
      }),
  
    addMicroondas: (newAdecuacion) =>
      set((state) => {
        const newList = [...state.microondasList, newAdecuacion];
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          microondasList: newList,
          microondasListSelected: selectedList,
          totalMicroondasSelected: totalSelected,
        };
      }),
  
    removeMicroondas: (material) =>
      set((state) => {
        const newList = state.microondasList.filter(
          (adecuacion) => adecuacion.material !== material
        );
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          microondasList: newList,
          microondasListSelected: selectedList,
          totalMicroondasSelected: totalSelected,
        };
      }),
  
    updateMicroondas: (material, updatedFields) =>
      set((state) => {
        const newList = state.microondasList.map((adecuacion) =>
          adecuacion.material === material ? { ...adecuacion, ...updatedFields } : adecuacion
        );
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          microondasList: newList,
          microondasListSelected: selectedList,
          totalMicroondasSelected: totalSelected,
        };
      }),
  
    cleanMicroondasList: () =>
      set((state) => {
        const newList = [];
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          microondasList: newList,
          microondasListSelected: selectedList,
          totalMicroondasSelected: totalSelected,
        };
      }),
  
    updateMicroondasSelected: () =>
      set((state) => {
        const { selectedList, totalSelected } = updateSelectedList(state.microondasList);
        return {
          microondasListSelected: selectedList,
          totalMicroondasSelected: totalSelected,
        };
      }),
  
    addRadioBase: (newAdecuacion) =>
      set((state) => {
        const newList = [...state.radioBaseList, newAdecuacion];
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          radioBaseList: newList,
          radioBaseListSelected: selectedList,
          totalRadioBaseSelected: totalSelected,
        };
      }),
  
    removeRadioBase: (material) =>
      set((state) => {
        const newList = state.radioBaseList.filter(
          (adecuacion) => adecuacion.material !== material
        );
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          radioBaseList: newList,
          radioBaseListSelected: selectedList,
          totalRadioBaseSelected: totalSelected,
        };
      }),
  
    updateRadioBase: (material, updatedFields) =>
      set((state) => {
        const newList = state.radioBaseList.map((adecuacion) =>
          adecuacion.material === material ? { ...adecuacion, ...updatedFields } : adecuacion
        );
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          radioBaseList: newList,
          radioBaseListSelected: selectedList,
          totalRadioBaseSelected: totalSelected,
        };
      }),
  
    cleanRadioBaseList: () =>
      set((state) => {
        const newList = [];
        const { selectedList, totalSelected } = updateSelectedList(newList);
        return {
          radioBaseList: newList,
          radioBaseListSelected: selectedList,
          totalRadioBaseSelected: totalSelected,
        };
      }),
  
    updateRadioBaseSelected: () =>
      set((state) => {
        const { selectedList, totalSelected } = updateSelectedList(state.radioBaseList);
        return {
          radioBaseListSelected: selectedList,
          totalRadioBaseSelected: totalSelected,
        };
      }),
  }));

export default useCatalogoState;