import React, { useState } from 'react';
import { makeStyles, Typography, Box, Collapse } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { sassFalse } from 'sass';

interface AccordionItemProps {
  title: string;
  info: React.ReactNode;
  children: React.ReactNode;
}

const CustomAccordion: React.FC<AccordionItemProps> = ({ title, info, children }) => {

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean | null>(true);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classes.accordionItem}>
      <div className={classes.accordionSummary} onClick={() => handleToggle()}>
        <ExpandMoreIcon className={`${classes.iconStyle} ${isOpen ? classes.rotateIcon : ''}`} />
        <Box
          marginTop={1}
          marginBottom={1}
          fontSize="body2.fontSize"
          fontWeight={600}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >{title}</Box>
      </div>
      <div className={classes.infoDiv}>
        {info}
      </div>
      <Collapse in={isOpen}>
        <div className={classes.accordionDetails}>
          {children}
        </div>
      </Collapse>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  accordionItem: {
    marginBottom: '10px',
  },
  accordionSummary: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center'
  },
  infoDiv: {
  },
  accordionDetails: {
  },
  iconStyle: {
    transition: "0.3s"
  },
  rotateIcon: {
    rotate: "180deg",
  }
}));

export default CustomAccordion;
