import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import { Service } from "../../core/models/service.models";
import { Stage, StageLite } from "../../core/models/stage.models";
import { StagesGeneratorService } from "../../core/services/stages.generators.service";
const stagesService = StagesGeneratorService.getInstance();

interface State {
  companyStages: StageLite[];
  currentStage: Stage;
  currentService: Service;
  generatorCurrentService: Service;
  setCurrentStage: (stage: Stage) => void;
  setCurrentService: (service: Service) => void;
  getCompanyStages: (companyId?: string) => Promise<any>;
  getCompanyStage: (companyId: string, stageTemplateId: string) => Promise<any>;
  uploadRequirementDocument: (companyId: string, stageTemplateId: string, requirementUuid: string, document: any) => Promise<any>;
  uploadServiceRequirementDocument: (serviceId: string, requirementUuid: string, document: any) => Promise<any>;
  deleteRequirementDocument: (companyId: string, stageTemplateId: string, requirementUuid: string, documentUuid: string) => Promise<any>;
  deleteServiceRequirementDocument: (serviceId: string, requirementUuid: string, document: any) => Promise<any>;
  downloadStageRequirementDocument: (companyId: string, stageTemplateId: string, reqIds?: string[]) => Promise<any>;
  downloadServiceStageRequirementDocument: (companyId: string, stageName: string, serviceId: string, reqIds?: string[]) => Promise<any>;
  getService: (serviceId: string) => Promise<Service>;
  getGeneratorService: (serviceId: string) => Promise<Service>;
  getPeriodCfdis: (serviceId: string, periodId: string) => Promise<any>;
}

const useGeneradoresStagesState = create<State>((set, get) => ({
  companyStages: null,
  currentStage: null,
  currentService: null,
  generatorCurrentService: null,
  setGeneratorCurrentService: (service: Service) => set({ generatorCurrentService: service }),
  setCurrentStage: (stage: Stage) => set({ currentStage: stage }),
  setCurrentService: (service: Service) => set({ currentService: service }),
  getCompanyStages: getCompanyStages(set, get),
  getCompanyStage: getCompanyStage(set, get),
  uploadRequirementDocument: uploadRequirementDocument(set, get),
  uploadServiceRequirementDocument: uploadServiceRequirementDocument(set, get),
  deleteRequirementDocument: deleteRequirementDocument(set, get),
  deleteServiceRequirementDocument: deleteServiceRequirementDocument(set, get),
  downloadStageRequirementDocument: downloadStageRequirementDocument(set, get),
  downloadServiceStageRequirementDocument: downloadServiceStageRequirementDocument(set, get),
  getService: getService(set, get),
  getGeneratorService: getGeneratorService(set, get),
  getPeriodCfdis: getPeriodCfdis(set, get),
}));

export default useGeneradoresStagesState;

//FUNCTIONS
function getCompanyStages(set: NamedSet<State>, get?: GetState<State>) {
  return async (companyId) => {
    const res = await stagesService.getCompanyStages(companyId);
    if (res?.codigo === 0) {
      set({ companyStages: res.respuesta });
      return true;
    }

    return false;
  };
}

function getCompanyStage(set: NamedSet<State>, get?: GetState<State>) {
  return async (companyId, stageTemplateId) => {
    const res = await stagesService.getCompanyStage(companyId, stageTemplateId);
    if (res?.codigo === 0) {
      set({ currentStage: res.respuesta });
      return true;
    }

    return false;
  };
}

function uploadRequirementDocument(set: NamedSet<State>, get?: GetState<State>) {
  return async (companyId, stageTemplateId, requirementUuid, document) => {
    const res = await stagesService.uploadStageRequirementDocument(companyId, stageTemplateId, requirementUuid, document);
    if (res?.codigo === 0) {
      return { ok: true };
    } else {
      return { ok: false, error: "No se pudo subir el documento" };
    }
  };
}

function uploadServiceRequirementDocument(set: NamedSet<State>, get?: GetState<State>) {
  return async (serviceId, requirementUuid, document) => {
    const res = await stagesService.uploadServiceRequirementDocument(serviceId, requirementUuid, document);
    if (res?.ok) {
      return { ok: true };
    } else {
      return { ok: false, error: res.message };
    }
  };
}

function deleteRequirementDocument(set: NamedSet<State>, get?: GetState<State>) {
  return async (companyId, stageTemplateId, requirementUuid, documentUuid) => {
    const res = await stagesService.deleteStageRequirementDocument(companyId, stageTemplateId, requirementUuid, documentUuid);
    if (res?.codigo === 0) {
      return true;
    }

    return false;
  };
}

function deleteServiceRequirementDocument(set: NamedSet<State>, get?: GetState<State>) {
  return async (serviceId, requirementUuid, document) => {
    const res = await stagesService.deleteServiceRequirementDocument(serviceId, requirementUuid, document);
    if (res?.ok) {
      return true;
    }

    return false;
  };
}

function downloadStageRequirementDocument(set: NamedSet<State>, get?: GetState<State>) {
  return async (companyId, stageTemplateId, requirementIds) => {
    try {
      const res = await stagesService.downloadCompanyStagesDocuments(companyId, stageTemplateId, requirementIds);
      const blob = new Blob([res], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Documents.zip");
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
}

function downloadServiceStageRequirementDocument(set: NamedSet<State>, get?: GetState<State>) {
  return async (companyId, stageName, serviceId, requirementIds) => {
    try {
      const res = await stagesService.downloadServiceStagesDocuments(companyId, stageName, serviceId, requirementIds);
      const blob = new Blob([res], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Documents.zip");
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
}

// function getCompanyStageProgress(set: NamedSet<State>, get?: GetState<State>) {
//   return async (companyId, stageTemplateId) => {
//     if (!companyId) companyId = sessionStorage.getItem("companyId");
//     const res = await stagesService.getCompanyStageProgress(companyId, stageTemplateId);
//     if (res?.codigo === 0) {
//       set({ currentStageProgress: res.respuesta });
//       return true;
//     }

//     return false;
//   };
// }

function getService(set: NamedSet<State>, get?: GetState<State>) {
  return async (serviceId) => {
    const res = await stagesService.getServiceStages(serviceId);
    if (res?.ok) {
      set({ currentService: res.data });
      return res.data;
    }

    return null;
  };
}

function getGeneratorService(set: NamedSet<State>, get?: GetState<State>) {
  return async (serviceId) => {
    const res = await stagesService.getGeneratorServiceStages(serviceId);
    if (res?.ok) {
      set({ currentService: res.data });
      return res.data;
    }

    return null;
  };
}

function getPeriodCfdis(set: NamedSet<State>, get?: GetState<State>) {
  return async (serviceId, periodId) => {
    const res = await stagesService.getPeriodCfdis(serviceId, periodId);
    if (res?.codigo === 0) {
      return res.respuesta;
    }

    return null;
  };
}
