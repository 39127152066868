import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
//import { Stage, StageLite } from "../core/models/stage.models";
import { GeneradorClientServices } from "../../core/services/generadorClient.service";
import { GeneradorCatalogue } from "../../core/services/generadorCatalogue.service";
import { GeneradoresServices } from "../../core/services/generadores.service";

const generadoresServices = GeneradoresServices.getInstance();

interface State {
  createFinalGeneradorPDFandProcess : (idGenerador: string) => Promise<any>;
}

const useGeneratorGlobalState = create<State>((set, get) => ({
    createFinalGeneradorPDFandProcess : createFinalGeneradorPDFandProcess(set,get)
}));

export default useGeneratorGlobalState;


function createFinalGeneradorPDFandProcess(set: NamedSet<State>, get?: GetState<State>) {
    return async (idGenerador) => {
      const res = await generadoresServices.generadorPDFandProcces(idGenerador);
      if (res?.codigo === 0) {
        return true;
      }
      return false;
    };
  }

