import React, { VFC } from 'react';
import { OutlinedInput, FormHelperText, FormControl, Box } from '@material-ui/core';
import { useReduxFormStyles } from './useReduxFormStyles';

interface RenderTextFieldProps {
  id?: string;
  name?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  error?: string;
  touched?: boolean;
  value: unknown;
}

const RenderTextField: VFC<RenderTextFieldProps> = (props) => {
  const { label, required, disabled, onChange, error, touched, value, placeholder } = props;
  const classes = useReduxFormStyles();
  const rootComponentProps = { display: 'inline-flex', width: '100%', flexDirection: 'column' };

  return (
    <Box {...rootComponentProps}>
      {label && <label className={classes.fieldLabel}>{label}</label>}
      <FormControl variant="outlined" color="primary" fullWidth margin="dense" error={touched && !!error}>
        <OutlinedInput
          id={props.id}
          name={props.name}
          disabled={disabled}
          onChange={onChange}
          placeholder={label ?? placeholder}
          required={required}
          value={value}
        />
        <FormHelperText>{touched && error}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default RenderTextField;
