import { Box, Button } from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import { useGetProfileURL } from "../../../core/hooks/useGetProfileURL";
import ColoredAvatar from "../../general/ColoredAvatar";

interface CompanyLogoProps {
  companyName: string;
  onChangeCompany?: React.MouseEventHandler<HTMLButtonElement>;
  onEditProfile?: React.MouseEventHandler<HTMLButtonElement>;
  profilePictureId?: string;
}

const CompanyLogo = (props: CompanyLogoProps) => {
  const pictureURL = useGetProfileURL(props.profilePictureId);

  return (
    <Box display="flex" flexDirection="column" bgcolor="white" padding={1} gridGap={10}>
      <Box display="flex" alignItems="center" justifyContent={"end"}>
        {/* {!!props.onChangeCompany && (
          <Tooltip title="Seleccionar compañía">
            <IconButton size="small" onClick={props.onChangeCompany}>
              <SwapHorizIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        )} */}
        <Button variant="contained" color="primary" size="small" onClick={props.onEditProfile}>
          Editar perfil
        </Button>
      </Box>
      <Box display="flex" justifyContent="center">
        <ColoredAvatar src={pictureURL} backgroundColor={!!pictureURL ? "#fff" : undefined} size={160}>
          <BusinessIcon style={{ fontSize: 100 }} />
        </ColoredAvatar>
      </Box>
      <Box fontWeight={600} fontSize="1.25rem" color="rgba(57, 61, 63, 0.85)" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        {props.companyName}
      </Box>
    </Box>
  );
};

export default CompanyLogo;
