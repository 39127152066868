import { useEffect, useState, VFC } from "react";
import SearchIcon from "@material-ui/icons/Search";

import RenderTextFieldV2 from "../inputs/inputs-v2/RenderTextFieldV2";
import { makeStyles } from "@material-ui/core";

interface SearchCompanyProps {
  onSearch: (query: string) => void;
}

const SearchCompany: VFC<SearchCompanyProps> = ({ onSearch }) => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const wait = searchQuery ? 500 : 100;
    const timerId = setTimeout(() => {
      onSearch(searchQuery);
    }, wait);
    return () => {
      clearTimeout(timerId);
    }
    // eslint-disable-next-line
  }, [searchQuery]);

  return (
    <div style={{ width: "320px" }}>
      <RenderTextFieldV2
        classes={{ root: classes.searchInput, notchedOutline: classes.notchedOutline }}
        className={classes.searchInput}
        onChange={(e) => setSearchQuery(e.target.value)}
        value={searchQuery}
        placeholder="Buscar"
        name="search"
        startIcon={<SearchIcon />}
        size="small"
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  searchInput: {
    backgroundColor: "#2347AD33",
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    "& $notchedOutline": {
      border: 'unset',
    },
  },
  notchedOutline: {
  },
}))

export default SearchCompany;