import React, { FC } from 'react';
import { Dialog, Button, makeStyles, DialogTitle, DialogActions, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { blue } from '@material-ui/core/colors';
export interface AlertProps {
  open: boolean;
  title: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  type?: 'success' | 'error' | 'info' | 'warning';
}

const Alert: FC<AlertProps> = (props) => {
  const classes = useStyles();
  const { open, title, onConfirm, onCancel, type } = props;

  const renderIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircleIcon className={classes.textSuccess} />;
      case 'error':
        return <ErrorIcon className={classes.textError} />;
      default:
        return <InfoIcon className={classes.textInfo} />;
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle disableTypography>
        <div style={{ textAlign: 'center' }}>{renderIcon()}</div>
        <Typography variant="h4" color="primary" align="center">
          {title}
        </Typography>
      </DialogTitle>
      <DialogActions>
        {!!onCancel && (
          <Button color="primary" variant="outlined" onClick={onCancel}>
            Cancelar
          </Button>
        )}
        <Button color="primary" variant="contained" onClick={onConfirm}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogContainer: { padding: 30 },
  textSuccess: { fontSize: 50, color: '#4caf50' },
  textError: { fontSize: 50, color: '#f44336' },
  textInfo: { fontSize: 50, color: blue[500] }
});

export default Alert;
