import React, { FC } from 'react'

interface Props {
  /** Section index */
  index: unknown;
  /** Current selected section index. */
  value: unknown;
}

/** Component that conditionaly renders a section. If the section index and the current selected section index (prop value)
 * are equal, the section will be rendered.
 */
const IndexedSection: FC<Props> = ({index, value, children }) => {
  return value === index ? (
    <>
      {children}
    </>
  ): null;
}

export default IndexedSection
