import create, { GetState } from "zustand";
import { devtools, NamedSet, persist } from "zustand/middleware";
import { AdditionalProductType } from "../core/models/company.models";
import { CompaniesService } from "../core/services/companies.service";
import { GroupCompanyChildren, Nullable, UserCompany, UserInfo } from "../types/global";
import usePlanState from "./planState";

export interface AlertInfo {
  type: "success" | "info" | "warning" | "error";
  message: string;
}

interface State {
  initialState?: State;
  userInfo: Nullable<UserInfo>;
  isAdmin: boolean;
  mainCompany: Partial<UserCompany>;
  mainGroupCompany: Partial<UserCompany>;
  userCompanies: UserCompany[];
  alertInfo: AlertInfo;
  groupChildrenCompanies: GroupCompanyChildren[];
  additionalProducts: AdditionalProductType[];
  hasNewNotifications: boolean;
  hasReadUpdateRepseAlert: boolean;
  hasReadMaintenanceAlert: boolean;
  typeService?: string;
  setMainCompany: (value: Partial<UserCompany>) => void;
  setUserInfo: (value: string) => void;
  setAlertInfo: (value: AlertInfo) => void;
  resetState: () => void;
  getMainCompany: () => Promise<UserCompany>;
  getCompaniesByGroup: () => Promise<any>;
  getCompanyData: (companyId: string) => Promise<any>;
  getCompanyAdditionalProducts: (companyId: string) => Promise<any>;
  setHasNewNotifications: (value: boolean) => void;
  setHasReadUpdateRepseAlert: (value: boolean) => void;
  setHasReadUpdatMaintenanceAlert: (value: boolean) => void;
  setTypeService: (value: string) => void

  reset: () => void;
}

const useGlobalState = create<State>(
  devtools(
    persist(
      (set, get) => ({
        userInfo: null,
        isAdmin: false,
        mainCompany: null,
        mainGroupCompany: null,
        userCompanies: null,
        typeService: '',
       // name: null,
        alertInfo: {
          message: "",
          type: "error",
        },
        groupChildrenCompanies: null,
        additionalProducts: [],
        hasNewNotifications: false,
        hasReadUpdateRepseAlert: false,
        hasReadMaintenanceAlert: false,
        setMainCompany: setMainCompany(set, get),
        setUserInfo: setUserInfo(set, get),
        resetState: () => set((state) => ({ ...state.initialState })),
        setAlertInfo: (data) => set({ alertInfo: data }),
        getMainCompany: getMainCompany(set, get),
        getCompaniesByGroup: getCompaniesByGroup(set, get),
        getCompanyData: getCompanyData(set, get),
        getCompanyAdditionalProducts: getCompanyAdditionalProducts(set, get),
        setHasNewNotifications: (value) => set({ hasNewNotifications: value }),
        setTypeService: (value) => set({ typeService: value }),
        setHasReadUpdateRepseAlert: (value) => set({ hasReadUpdateRepseAlert: value }),
        setHasReadUpdatMaintenanceAlert : (value) => set({ hasReadMaintenanceAlert: value }),
        reset: () => set({ userInfo: null, isAdmin: false, mainCompany: null, userCompanies: null,hasReadUpdateRepseAlert: false, hasReadMaintenanceAlert: false }),
      }),
      { name: "global", getStorage: () => sessionStorage }
    ),
    { name: "KV-GlobalState" }
  )
);

//set initial state for reset state
useGlobalState.setState((state) => ({ initialState: state }));

export default useGlobalState;

//FUNCTIONS
function setMainCompany(set: NamedSet<State>, get: GetState<State>) { 
  return async (company: UserCompany) => {
    sessionStorage.setItem("companyId", company.id);
    set({typeService: company.generadores ? 'generators' : ''});
    set({ mainCompany: company });
    const userInfo = get().userInfo;
    usePlanState.getState().setPlan(userInfo, company.status as any, company.invitedAs ?? "client", company.invited);
  };
}

function setUserInfo(set: NamedSet<State>, get: GetState<State>) {
  return async (userInfo) => {
    sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    const isAdmin = (userInfo?.roles as string[])?.includes("Admin");
    set({ userInfo, isAdmin });
  };
}

function getMainCompany(set: NamedSet<State>, get: GetState<State>) {
  return async () => {
    const { companies } = await CompaniesService.getInstance().getActiveCompanies(); //TODO: handle error
    set({ userCompanies: companies });
    const storeCompanyId = sessionStorage.getItem("companyId");
    let comp: UserCompany;
    if (storeCompanyId && companies?.length) {
      comp = companies.find((c) => c.id === storeCompanyId);
      !!comp && get().setMainCompany(comp);
    } else {
      comp = companies?.[0];
      get().setMainCompany(comp);
    }

    if (comp) {
      get().getCompanyAdditionalProducts(comp.id);
    }

    if (comp?.group) {
      set({ mainGroupCompany: comp });
      get().getCompaniesByGroup();
    }

    return comp;
  };
}

function getCompanyData(set: NamedSet<State>, get: GetState<State>) {
  return async (companyId) => {
    const company = await CompaniesService.getInstance().getCompany(companyId);
    return company;
  };
}

function getCompanyAdditionalProducts(set: NamedSet<State>, get: GetState<State>) {
  return async (companyId) => {
    const res = await CompaniesService.getInstance().getAdditionalProducts(companyId);
    if (res.codigo === 0) {
      set({ additionalProducts: res.respuesta?.filter((p) => Boolean(p))?.map((p) => p.type) });
    }
    return res.respuesta;
  };
}

function getCompaniesByGroup(set: NamedSet<State>, get: GetState<State>) {
  return async () => {
    const res: unknown = await CompaniesService.getInstance().getCompanyByGroup(get().mainCompany?.id);
    set({ groupChildrenCompanies: res as GroupCompanyChildren[] });
  };
}