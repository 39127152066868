import React from "react";
import { Button, Checkbox, Link, Typography, Box, useMediaQuery, Theme, CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AlertInfo } from "../../store/globalState";
import RenderTextFieldV2 from "../inputs/inputs-v2/RenderTextFieldV2";

interface SignInProps {
  onRecoverPassword?: () => void;
  handleSubmit: (form: FormFields) => void;
  alert: AlertInfo;
  handleSession: (e: React.ChangeEvent<HTMLInputElement>) => void;
  initialData?: Partial<FormFields>;
  loadingButton: boolean;
}

interface FormFields {
  email: string;
  password: string;
}

const SignIn = (props: SignInProps) => {
  const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const formik = useFormik<FormFields>({
    initialValues: {
      email: props.initialData?.email ?? "",
      password: props.initialData?.password ?? "",
    },
    onSubmit: props.handleSubmit,
    validationSchema: Yup.object({
      email: Yup.string().required("El correo electrónico es requerido").email("Debe ser un email válido"),
      password: Yup.string().required("La contraseña es requerida"),
    }),
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} display="flex" flexDirection="column" justifyContent="space-between" flex="1" gridGap={isLg ? 16 : 0}>
      <Typography variant="h2" color="primary" style={{ fontWeight: "normal" }}>
        Iniciar Sesión
      </Typography>
      <RenderTextFieldV2
        size="small"
        label="Correo"
        error={formik.errors.email}
        onChange={formik.handleChange}
        touched={formik.touched.email}
        value={formik.values.email}
        placeholder="hola@correo.com"
        name="email"
      />

      <RenderTextFieldV2
        size="small"
        label="Contraseña"
        error={formik.errors.password}
        onChange={formik.handleChange}
        touched={formik.touched.password}
        value={formik.values.password}
        name="password"
        type="password"
        passwordAction
      />
      <Link style={{ fontStyle: "italic", marginLeft: 10 }} variant="body2" onClick={props.onRecoverPassword} underline="hover">
        ¿Olvidaste tu contraseña?
      </Link>

      <Box display="flex" alignItems="center">
        <Checkbox color="primary" onChange={props.handleSession} icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} />
        <p>Mantener sesión abierta</p>
      </Box>

      {props.alert?.message && (
        <Alert variant="filled" severity={props.alert.type}>
          {props.alert.message}
        </Alert>
      )}

      <Button variant="contained" color="primary" fullWidth type="submit" disabled={props.loadingButton}>
        {props.loadingButton ? <CircularProgress color="inherit" size={24} /> : 'Iniciar sesión'}
      </Button>

    </Box>
  );
};

export default SignIn;
