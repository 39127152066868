import React, { useState } from 'react';
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Button, Grid, Typography } from "@material-ui/core";
import RenderTextField from '../../../core/redux-form-fields/RenderTextField';
import RenderSwitchField from '../../../core/redux-form-fields/RenderSwitchField';
import RenderMultipleSelect from '../../../core/redux-form-fields/RenderMultipleSelect';

interface FormCompaniesProps {
  submitActions: (form: any) => void;
  users: any;
  tags: any;
  onCancel: () => void;
}

const FormCompanies = (props: InjectedFormProps<{}, FormCompaniesProps> & FormCompaniesProps) => {
  const { handleSubmit, submitActions, users, tags, onCancel } = props;
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([])

  const handleChangeMembers = (value: unknown) => setSelectedMembers(value as string[]);
  const handleChangeTags = (value: unknown) => setSelectedTags(value as string[]);

  return (
    <form onSubmit={handleSubmit(submitActions)}>
      <Grid container>
        <Grid item md={12}>
          <Typography variant="h6" color="primary">
            {
              users ?
                'Editar Empresa'
                :
                'Añadir Empresa'
            }
          </Typography>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs>
              <Field
                externLabel
                label="Nombre"
                name="name"
                required
                component={RenderTextField}
              />
            </Grid>
            <Grid item>
              <Field
                label="Habilitar"
                name="enabled"
                component={RenderSwitchField}
              />
            </Grid>
          </Grid>

          <Field
            externLabel
            label="Agregar miembros"
            name="users"
            options={users}
            required
            value={selectedMembers}
            onChange={handleChangeMembers}
            component={RenderMultipleSelect}
          />

          <Field
            externLabel
            label="Etiquetas"
            name="tags"
            options={tags}
            required
            value={selectedTags}
            onChange={handleChangeTags}
            component={RenderMultipleSelect}
          />


          <h4>Servicio de Document Storage</h4>

          <Field
            externLabel
            label="Url del servicio"
            name="urlApiKaleido"
            required
            component={RenderTextField}
          />


          <Field
            externLabel
            label="Usuario Document Storage"
            name="userKaleido"
            required
            component={RenderTextField}
          />


          <Field
            externLabel
            label="Contraseña"
            name="passwordKaleido"
            type="password"
            required
            component={RenderTextField}
          />

          <Grid className="mt-2" container justifyContent="flex-end">
            <>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => onCancel()}
              >
                Cancelar
              </Button>

              <Button
                className="ml-2"
                type="submit"
                variant="contained"
                color="primary"
              >
                Guardar
              </Button>
            </>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

type ValidationFormCompanies = {
  name: string,
  folder: string,
  userKaleido: string,
  passwordKaleido: string,
  urlApiKaleido: string,
}

const validate = (values: ValidationFormCompanies) => {
  const errors = {
    name: '',
    folder: '',
    userKaleido: '',
    passwordKaleido: '',
    urlApiKaleido: '',
  }

  if (!values.name) {
    errors.name = 'El nombre de la empresa es requerido';
  }

  if (values.name) {
    if (values.name.length > 100) {
      errors.name = 'El nombre de la empresa no puede ser mayor a 100 caracteres';
    }
  }

  if (!values.folder) {
    errors.folder = 'El nombre de la empresa es requerido';
  }

  if (!values.userKaleido) {
    errors.userKaleido = 'El usuario de Kaleido es requerido';
  }

  if (!values.passwordKaleido) {
    errors.passwordKaleido = 'La contraseña de Kaleido es requerido';
  }

  if (!values.urlApiKaleido) {
    errors.urlApiKaleido = 'La URL de Kaleido es requerido';
  }

  return errors;
}

export default reduxForm<{}, FormCompaniesProps>({
  form: 'FormBusiness',
  validate
})(FormCompanies);
