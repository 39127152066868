import { Button } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useHistory } from "react-router";

const GoBackButton = () => {
  const history = useHistory();

  return (
    <Button size="small" startIcon={<ChevronLeftIcon />} variant="contained" onClick={() => history.go(-1)}>
      Regresar
    </Button>
  );
};

export default GoBackButton;
