import { makeStyles } from "@material-ui/core";

const Footer = () => {
  const classes = useStyles();

  return (
    <>
      <footer className={classes.footer}>
        <p>Axeleratum 2023. Todos los derechos reservados.</p>
        <div style={{fontSize:"14px", display:"flex",flexDirection:"column", gap:"6px"}}>
          <a style={{ textDecoration: "underline" }} href="/docs/kivit-terms.pdf" target="_blank" rel="noreferrer">
            Terminos y condiciones
          </a>
          <a style={{ textDecoration: "underline" }} href="/docs/contrato-licencia.pdf" target="_blank" rel="noreferrer">
            Contrato de licencia
          </a>
          <a style={{ textDecoration: "underline" }} href="/docs/aviso-privacidad.pdf" target="_blank" rel="noreferrer">
            Aviso de privacidad
          </a>
            v2.0.1
        </div>
      </footer>
    </>
  );
};

export default Footer;

const useStyles = makeStyles((theme) => ({
  footer: {
    position: "absolute",
    bottom: 0,
    backgroundColor: "#F4F4F4",
    height: 100,
    right: 50,
    left: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
    borderTop: "2px solid",
    borderTopColor: theme.palette.primary.main,
  },
}));
