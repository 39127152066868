import { axiosRequestBlockChain, authHeader } from '../../configs/axios.config';

export class DocumentsService {
  private static instance: DocumentsService;

  private constructor() {};

  public static getInstance(): DocumentsService {
    if (!DocumentsService.instance) {
      DocumentsService.instance = new DocumentsService();
    }
    return DocumentsService.instance;
  }

  async editDocument(reqData: unknown) {
    try {
      const { data } = await axiosRequestBlockChain.put(`/document`, reqData, {headers: authHeader()});
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDocumentInfo(documentId: string) {
    try {
      const { data } = await axiosRequestBlockChain.get(`v1/documents/${documentId}`, {headers: authHeader()});
      return data;
    } catch (error) {
      throw error;
    }
  }

  // IPFS NEW APIS
  async downloadDocument(documentId: string) {
    try {
      const { data } = await axiosRequestBlockChain.get<BlobPart>(`v1/documents/download/${documentId}`, {headers: authHeader(), responseType: 'arraybuffer'});
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createDocument(reqData: CreateDocumentReqBody) {
    try {
      const { data } = await axiosRequestBlockChain.post<CreateDocumentResponse>(`/v1/documents`, reqData, {headers: authHeader()});
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteDocument(documentId: string) {
    try {
      const { data } = await axiosRequestBlockChain.delete<{ status: boolean }>(`v1/documents/${documentId}`, {headers: authHeader()});
      return data.status;
    } catch (error) {
      throw error;
    }
  }

  async createFolder(reqData: CreateFolderDataReq) {
    try {
      const { data } = await axiosRequestBlockChain.post(`/v1/folders`, reqData, {headers: authHeader()});
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFolder(documentId: string) {
    try {
      await axiosRequestBlockChain.delete(`/v1/folders/${documentId}`, {headers: authHeader()});
    } catch (error) {
      throw error;
    }
  }
}



