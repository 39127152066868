import { Button, IconButton, makeStyles, Typography, useTheme } from "@material-ui/core";
import { useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WarningIcon from "@material-ui/icons/Warning";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ContextualMenuItem, useContextualMenu } from "../hooks/useContextualMenu";
import UploadFileDialog from "./UploadFileDialog";
import { useHandleModal } from "../hooks/useHandleModal";
import { DocumentValidationStatus, Month } from "materialidad-outsourcing/dist/types";
import { dateFormat, toBase64 } from "../utils";
import ShowDocumentDialog from "./watch-document/ShowDocumentDialog";
import useToast from "../../../core/hooks/useToast";
import usePlanState from "../../../store/planState";
import { ReporteRequirement } from "../reportes.types";
import { ExpedienteService } from "../../../core/services/expediente.service";
import { ReportesService } from "../../../core/services/reportes.service";

const DocumentCard = ({ doc, onShow, onDelete }: { doc: any; onShow: (docID: string) => any; onDelete: (docId: string) => any }) => {
  const theme = useTheme();
  const { canUploadDocuments } = usePlanState();
  const menuOptions: ContextualMenuItem[] = [
    { text: "Abrir", action: () => onShow(doc.uid) },
    // { text: "Firmar", action: () => {} /*props.onSignDocument?.(props.document.id)*/, disabled: true },

    canUploadDocuments.onExpedient
      ? {
          text: "Eliminar",
          action: () => onDelete(doc.uid), //handleConfirmDelete,
          disabled: false /*!props.onDeleteDocument || props.document.validationStatus.status !== 'Bajo revisión'*/,
        }
      : undefined,
  ];
  const [Menu, openMenu] = useContextualMenu(menuOptions.filter((option) => !!option));

  const statusColor = () => {
    switch (doc.validationStatus?.status) {
      case "Aceptado":
        return "success";
      case "Rechazado":
        return "error";
      case "Bajo revisión":
      default:
        return "warning";
    }
  };

  return (
    <div style={{ padding: ".5rem" }}>
      <div
        style={{
          padding: ".8rem",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px dashed #a6a6a6",
        }}
      >
        <div>
          <p style={{ lineHeight: "1.2", minHeight: "2.4rem" }}>
            <b>{doc.name}</b>
          </p>
          <p style={{ fontSize: ".8rem" }}>
            Mes correspondiente: <b>{doc.month}</b>
          </p>
          <p style={{ fontSize: ".8rem" }}>
            Agregado el: <b>{dateFormat(new Date(doc.createdAt)) ?? "---"}</b>
          </p>
        </div>
        <div>
          <div style={{ color: "#0d0d0d", textAlign: "right" }}>
            <IconButton
              color="inherit"
              onClick={() => {
                onShow(doc.uid);
              }}
              size="small"
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton color="inherit" onClick={openMenu} size="small">
              <MoreVertIcon />
            </IconButton>
            <Menu />
          </div>
          <Button
            size="small"
            variant="contained"
            disableElevation
            style={{ color: "white", backgroundColor: theme.palette[statusColor()].main }}
          >
            {/* @ts-ignore */}
            <nobr>{doc.validationStatus?.status}</nobr>
          </Button>
        </div>
      </div>
    </div>
  );
};

const RequirementCard = ({ requirement, getReporte, reportId }: { requirement: ReporteRequirement; getReporte: any; reportId: string }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showAllDocs, setShowAllDocs] = useState(false);

  const { Toast, showToast } = useToast();

  const daysLeft = requirement.deliveryDate
    ? Math.ceil((new Date(requirement.deliveryDate).getTime() - new Date().getTime()) / (1000 * 24 * 3600))
    : undefined;

  const [showModal, handleShowModal, handleCloseModal] = useHandleModal(); // Handles the upload document modal.;
  const [showDocument, handleShowDocument, handleCloseDocument] = useHandleModal();
  const canUploadDocuments = usePlanState((s) => s.canUploadDocuments);

  const onShowDocument = (documentId: string) => {
    setSelectedDocument(requirement.documents?.find((doc) => doc.uid === documentId));
    //const i = props.files.findIndex((file) => file.id === documentId);
    //setDocumentIndex(i);
    handleShowDocument();
  };

  const handleDeleteCardDocument = async (documentId: string) => {
    const companyId = sessionStorage.getItem("companyId");
    try {
      const deleted = await ExpedienteService.getInstance().deleteDocument(companyId, requirement.uid, documentId);
      if (!deleted) {
        throw new Error("Document not deleted");
      } else {
        getReporte();
      }
    } catch (error) {
      throw error;
    }
  };

  const handleValidateStatus = (status: DocumentValidationStatus) => {
    //setDocumentStatus(props.index, props.columnIndex, documentIndex, status);
  };

  const uploadDocument = async (file: File, description: string, month: Month, year: string) => {
    const filename = file.name.split(".") ?? ["", ""];

    const body = {
      name: filename[0],
      extension: "." + filename[1],
      comments: description,
      b64File: await toBase64(file),
      month,
      year,
    };

    try {
      const res = await ReportesService.getInstance().uploadDocument(reportId, requirement.uid, body as any);
      if (res.ok) {
        showToast("Se ha cargado el documento", "success");
        getReporte();
      } else {
        showToast("Error al cargar el documento", "error");
      }
    } catch (error) {
      if (error?.response?.data?.message?.includes("File exist")) {
        return showToast("Ya existe un archivo con el mimso nombre.", "error");
      }
      showToast("Error al cargar el documento", "error");
    }
  };

  const handleFileUpload = async (file: File, description: string, month: Month, year: string) => {
    if (file) {
      uploadDocument(file, description, month, year);
      handleCloseModal();
    } else {
      showToast("No se ha seleccionado algún archivo para subir.", "error");
    }
  };

  return (
    <>
      <div style={{ width: "100%", backgroundColor: "#F3F3F3", display: "flex", flexDirection: "column", height: "max-content" }}>
        <div style={{ backgroundColor: "#52C6BA1A", padding: ".5rem", width: "100%", textAlign: "center" }}>
          <h4
            style={{
              fontSize: "1.2rem",
              lineHeight: "1.3rem",
              minHeight: "2.5rem",
              fontWeight: "bold",
              color: theme.palette.secondary.main,
            }}
          >
            {requirement.name}
          </h4>
          <p style={{ color: theme.palette.secondary.main, fontSize: ".8rem" }}>
            <span>Entrega: {requirement.deliveryDate ? dateFormat(new Date(requirement.deliveryDate)) : "---"}</span>
            <span style={{ padding: "0 5px" }}> | </span>
            <span>Formato requerido: {requirement.fileType}</span>
          </p>
        </div>

        {requirement.documents?.length ? (
          <>
            <p style={{ fontSize: "0.8rem", color: "#8c8c8c", padding: ".2rem .5rem 0 .5rem", fontStyle: "italic" }}>
              *Se muestran los ultimos 3 documentos
            </p>
            {requirement.documents?.slice(0, showAllDocs ? 3 : 1).map((doc) => (
              <DocumentCard key={doc.uid} doc={doc} onShow={onShowDocument} onDelete={handleDeleteCardDocument} />
            ))}
          </>
        ) : (
          <div style={{ flex: 1 }}></div>
        )}

        {canUploadDocuments.onExpedient && (
          <div style={{ padding: ".8rem", width: "100%", display: "flex", justifyContent: "space-between" }}>
            <Button size="small" variant="contained" color="secondary" onClick={handleShowModal}>
              Subir documentos
            </Button>{" "}
            <Button size="small" variant="contained" color="secondary" startIcon={<VisibilityIcon fontSize="inherit" />} disabled>
              Ver documentos anteriores
            </Button>
          </div>
        )}

        <div
          style={{
            backgroundColor: "#BCC5CB80",
            padding: ".5rem",
            width: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {requirement.documents?.length > 0 &&
          requirement.documents[requirement.documents.length - 1]?.validationStatus?.status === "Aceptado" ? (
            <CheckCircleIcon htmlColor={theme.palette.success.main} />
          ) : (
            <WarningIcon htmlColor={theme.palette.error.main} />
          )}

          {requirement.deliveryDate && (
            <>
              <NotificationsActiveIcon
                style={{ marginLeft: 8 }}
                htmlColor={(daysLeft as number) >= 0 ? "#00709E" : "#F44336"}
                fontSize="small"
              />
              <Typography
                variant="caption"
                className={`${classes.notification} ${(daysLeft as number) < 0 ? classes.notificationError : ""}`}
              >
                {(daysLeft as number) >= 0
                  ? `Actualizar en ${daysLeft} días`
                  : `Tu entrega lleva atrasada ${0 - (daysLeft as number)} días.`}
              </Typography>
            </>
          )}
          <IconButton
            aria-expanded={showAllDocs}
            aria-label="show more"
            color="default"
            className={[classes.expand, showAllDocs ? classes.expandOpen : null].join(" ")}
            onClick={() => setShowAllDocs(!showAllDocs)}
            size="small"
            disabled={!(requirement.documents?.length > 1)}
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
      </div>
      <UploadFileDialog
        cardTitle={requirement.name}
        open={showModal}
        onClose={handleCloseModal}
        onUploadFile={handleFileUpload}
        fileType={requirement.fileType}
      />
      {showDocument && (
        <ShowDocumentDialog onClose={handleCloseDocument} doc={selectedDocument} handleValidateStatus={handleValidateStatus} />
      )}
      <Toast />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  infoSection: {
    backgroundColor: "white",
    padding: "1rem",
  },
  actionBtn: {
    padding: "10px 5px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  notification: {
    color: "#00709E",
    fontSize: "0.75rem",
    fontWeight: "bold",
  },
  notificationError: {
    color: theme.palette.error.main,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export default RequirementCard;
