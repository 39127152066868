import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export default function QuestionIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 15 22">
      <path
        d="M4.41465 13.9736V12.7979C4.41465 11.9046 4.59694 11.1527 4.96152 10.542C5.32611 9.9222 5.97324 9.32064 6.90293 8.7373C7.64121 8.27246 8.16986 7.85319 8.48887 7.47949C8.81699 7.10579 8.98106 6.67741 8.98106 6.19434C8.98106 5.81152 8.80788 5.51074 8.46152 5.29199C8.11517 5.06413 7.664 4.9502 7.10801 4.9502C5.73171 4.9502 4.12298 5.43783 2.28184 6.41309L0.381447 2.69434C2.65098 1.40007 5.02077 0.75293 7.49082 0.75293C9.52337 0.75293 11.1184 1.19954 12.276 2.09277C13.4335 2.986 14.0123 4.2028 14.0123 5.74316C14.0123 6.84603 13.7525 7.80306 13.233 8.61426C12.7226 9.41634 11.9023 10.1774 10.7721 10.8975C9.81504 11.5173 9.21348 11.973 8.96738 12.2646C8.73041 12.5472 8.61192 12.8844 8.61192 13.2764V13.9736H4.41465ZM3.82676 18.7588C3.82676 17.9202 4.0683 17.2731 4.55137 16.8174C5.04356 16.3617 5.76361 16.1338 6.71152 16.1338C7.62298 16.1338 8.32025 16.3662 8.80332 16.8311C9.29551 17.2868 9.5416 17.9294 9.5416 18.7588C9.5416 19.5882 9.28639 20.2308 8.77598 20.6865C8.27468 21.1423 7.58652 21.3701 6.71152 21.3701C5.80918 21.3701 5.1028 21.1468 4.59238 20.7002C4.08197 20.2445 3.82676 19.5973 3.82676 18.7588Z"
        fill="white"
      />
    </SvgIcon>
  );
}
