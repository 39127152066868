import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Box } from '@material-ui/core';

interface TabsProps{
  tabChange: (event: any, newValue: any) => void;
  tabValue: number;
}

export default function TabsButtons({tabChange, tabValue}: TabsProps) {
  const classes = useStyles();
  /* const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  }; */

  return (
    <Box className={classes.root}>
      <Tabs
        value={tabValue}
        onChange={tabChange}
        textColor="primary"
        classes={{ flexContainer: classes.tabsContainer, root: classes.tabsHeightContainer, indicator:  classes.indicatorLine}}
      >
        <Tab className={classes.tabStyle} label="Proveedores" aria-controls="tabpanel-0" />
        <Tab className={classes.tabStyle} label="Generadores" aria-controls="tabpanel-1" />
        <Tab className={classes.tabStyle} label="Catálogos" aria-controls="tabpanel-2" />
      </Tabs>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabsContainer: {
    justifyContent: 'end',
    gap: "1px",
    borderBottom: `3px solid ${theme.palette.primary.dark}`,
  },
  tabsHeightContainer: {
    minHeight: 'auto',
  },
  indicatorLine: {
    backgroundColor: theme.palette.primary.dark
  }, 
  tabStyle: {
    color: 'white',
    backgroundColor: theme.palette.primary.dark,
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    textTransform: "capitalize",
    fontWeight: "bold",
    width: "auto",
    minHeight: "auto",
    padding: "3px 20px",
    '&.Mui-selected': {
      color: theme.palette.primary.dark,
      backgroundColor: 'white',
      borderTop: `1px solid ${theme.palette.primary.dark}`,
      borderLeft: `1px solid ${theme.palette.primary.dark}`,
      borderRight: `1px solid ${theme.palette.primary.dark}`,
    },
  }
}));