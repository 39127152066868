import { BaseNTPState, BotRequirementData, ColumnCardDocument, DashboardColumn, DashboardContract, DashboardStage, Owner } from 'materialidad-outsourcing/dist/types';
import { AddDocReqBody, CFDI, Contract, Data, ServiceDashboardRes, Stage } from '../../types/ntp.types';

/**
 * Converts a file to a Base64
 * @param file File obtained from an input
 * @returns Base 64 representation of ´file´.
 */
 export const toBase64 = (file: File) =>
 new Promise<string>((resolve, reject) => {
   const reader = new FileReader();
   reader.readAsDataURL(file);
   reader.onload = () => {
     const result = (reader.result as string).replace(/^.+?;base64,/, '')
     resolve(result);
   };
   reader.onerror = (error) => {
     reject(error);
   };
 });

/** Returns an object with an Authorization header if a jwt exist on sessionStorage */
export function authHeader() {
  // return authorization header with jwt token
  const token = sessionStorage.getItem('access_token');

  if (token) {
    return { Authorization: 'Bearer ' + token };
  } else {
    return {};
  }
}

export const ntpResToState = (response: ServiceDashboardRes<Data>, owner: Owner): BaseNTPState => {
  return {
    companyId: response.data.companyId,
    service: {
      id: response.data.id,
      name: response.data.name
    },
    provider: owner,
    necesityStage: transformStage(response.data.ntp.documentSet.stages[0]),
    traceabilityStage: transformStage(response.data.ntp.documentSet.stages[1]),
    profitStage: transformStage(response.data.ntp.documentSet.stages[2]),
    contract: transformContracts(response.data.ntp.contract),
    preponderantSocialObject: response.data.objetoSocialPreponderante ?? '',
    payroll: {
      cfdiFrequency: response.data.ntp.nomina.cfdiFrequency as "Semanal" | "Quincenal" | "Mensual",
      paymentStartDay: response.data.ntp.nomina.paymentStartDay ? new Date(response.data.ntp.nomina.paymentStartDay) : undefined,
      employeesNumber: response.data.ntp.nomina.employees,
      periods: response.data.ntp.nomina.periodos ? response.data.ntp.nomina.periodos.map(({ uuid, name }) => ({ uuid, name })) : []
    },
  };
};

const transformContracts = (contract: Contract): DashboardContract => {
  return {
    name: contract?.name,
    amount: contract?.amount,
    currency: contract?.currency ?? 'MXN',
    validationStatus: contract?.validationStatus ? {
      status: contract.validationStatus.status,
      comments: contract.validationStatus.comments,
      date: contract.validationStatus.updatedAt
    } : { status: 'Bajo revisión' },
    annexes: contract?.appendixs?.map<ColumnCardDocument>((document) => ({
      uid: document.uid,
      id: document.fileId,
      name: document.name,
      createdAt: document.createdAt ? new Date(document.createdAt) : undefined,
      month: document.month,
      validationStatus: {
        status: document.validationStatus?.status,
        comments: document.validationStatus?.comments,
        date: document.validationStatus?.updatedAt
      }
    })) ?? [],
    expirationDate: new Date(contract?.expirationDate),
    fileId: contract?.fileId,
    folderId: contract?.folderId
  };
};

const transformStage = ({ traceabilityPoints, groups ,...stage}: Stage): DashboardStage => {
  return {
    ...stage,
    percent: Math.round(stage.percent),
    totalPoints: traceabilityPoints,
    documentsColumns: groups.map<DashboardColumn>((col) => {
      return {
        title: col.name,
        cards: col.requirements.map(({ valuePoints, documents, deliveryDate, ...requirement}) => {
          return {
            ...requirement,
            deliveryDate: deliveryDate ? new Date(deliveryDate as string) : undefined,
            points: valuePoints,
            status: {
              id: '',
              name: ''
            },
            files:
              documents?.map<ColumnCardDocument>(
                ({ fileId, createdAt, validationStatus, ...item}) => ({
                  uid: item.uid,
                  month: item.month,
                  name: item.name,
                  id: fileId,
                  createdAt: new Date(createdAt as string),
                  validationStatus: {
                    status: validationStatus?.status === 'Bajo revisión' ? 'Bajo revisión' : validationStatus?.status,
                    comments: validationStatus?.comments,
                    date: validationStatus?.updatedAt
                  },
                })
              ) ?? []
          };
        })
      };
    })
  };
};

export const mapRequirementDoc = ({ id, name, month }: ColumnCardDocument): AddDocReqBody => ({
  name,
  fileId: id,
  month
});



export const mapPeriodDetail = (cfdis: CFDI[]): any[] => cfdis.map((cfdiData, numberId) => ({
  numberId,
  completeName: cfdiData.employeeData.fullName,
  curp: cfdiData.employeeData.curp,
  imms: cfdiData.employeeData.nss,
  rfc: cfdiData.employeeData.rfc,
  dailySalary: cfdiData.salDiario ?? 0,
  sbc: cfdiData.sbc ?? 0,
  monthSalary: cfdiData.salMensual ?? 0,
  declaredSalary: cfdiData.percepciones[0]?.amount, 
  imssDeduction : cfdiData.deducciones.filter(transaction => transaction.tipo ==="001").map(transaction => transaction.amount).toString() ?? 0,
  isrDeduction : cfdiData.deducciones.filter(transaction => transaction.tipo ==="002").map(transaction => transaction.amount).toString() ?? 0,
  infonavitDeduction : cfdiData.deducciones.filter(transaction => transaction.tipo ==="004" ).map(transaction => transaction.amount).toString() ?? 0,
  contributions : cfdiData.deducciones?.filter(transaction => transaction.tipo ==="003").map(transaction => transaction.amount).toString() ?? 0,
  subsidies : cfdiData.otrosPagos?.filter(transaction => transaction.tipo ==="002").map(transaction => transaction.amount).toString() ?? 0,
  calculatedSalary: cfdiData.percepciones[0]?.importeCalculado,
  salaryDifference: cfdiData.percepciones[0]?.diferencia,
  declaredIMSS : cfdiData.deducciones?.filter(transaction => transaction.tipo ==="001").map(transaction => transaction.amount).toString() ?? 0,
  calculatedIMSS : cfdiData.deducciones?.filter(transaction => transaction.tipo ==="001").map(transaction => transaction.importeCalculado).toString() ?? 0,
  differenceIMSS : cfdiData.deducciones?.filter(transaction => transaction.tipo ==="001").map(transaction => transaction.diferencia).toString() ?? 0,
  declaredISR : cfdiData.deducciones.filter(transaction => transaction.tipo ==="002").map(transaction => transaction.amount).toString() ?? 0,
  calculatedISR : cfdiData.deducciones.filter(transaction => transaction.tipo ==="002").map(transaction => transaction.importeCalculado).toString() ?? 0,
  differenceISR : cfdiData.deducciones.filter(transaction => transaction.tipo ==="002").map(transaction => transaction.diferencia).toString() ?? 0,
  periodicidadPagoDescripcion: cfdiData.periodicidadPagoDescripcion,
  numDiasPagados:cfdiData.numDiasPagados,
  totalOtrasDeducciones:cfdiData.totalOtrasDeducciones,
  totalImpuestosRetenidos:cfdiData.totalImpuestosRetenidos,
  descuento:cfdiData.descuento,
  totalOtrosPagos: cfdiData.totalOtrosPagos,
  sueldo: cfdiData.sueldo,
  subTotal: cfdiData.subTotal,
  total: cfdiData.total,
  validation: {
    status: cfdiData.validationStatus.status,
    comments: cfdiData.validationStatus.comments,
    date: cfdiData.validationStatus.updatedAt
  },
  imssConcept: cfdiData.imss!==null ? cfdiData.imss : 0 ,
  isrConcept : cfdiData.isr!==null ? cfdiData.isr : 0,
}));

export const mapLogTask = (tasks: any[]): BotRequirementData[] => tasks.map((task) => ({
  name: task.task,
  status: {
    status: task.status,
    comments: task.coments,
    date: task.end
  },
  documents: task['sub-task'].map((sub) => ({
    name: sub.task,
    status: {
      status: sub.status,
      comments: sub.coments,
      date: sub.end
    },
  }))
}))
