import { useEffect, useState } from "react";
import { DocumentsService } from "../services/documets.service";

export const useGetProfileURL = (pictureId?: string) => {
  const documentsService = DocumentsService.getInstance();
  const [pictureURL, setPictureURL] = useState<string>();

  useEffect(() => {
    if(!!pictureId) handleOpenDocument(pictureId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureId]);


  const handleOpenDocument = async(pictureId: string) => {
    try {
      const docContent = await documentsService.downloadDocument(pictureId);
      const url = URL.createObjectURL(
        new Blob([docContent], {
          type: 'application/pdf'
        })
      );
      setPictureURL(url);

      return docContent;
    } catch (error) {
      throw error;
    }
  }

  return pictureURL;
}