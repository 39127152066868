import React from "react";
import { Typography } from "@material-ui/core";

import PolicyList from "./PolicyList";
import PolicyParagraph from "./PolicyParagraph";

const TermsAndConditions = () => {
  return (
    <>
      <br />
      <PolicyParagraph>
        Bienvenido a KIVIT ("KIVIT", "Compañía", "nosotros", "nuestro" o "nos"). Estos Términos de servicio ("Términos" o "Términos de
        servicio") rigen el uso de la plataforma denominada KIVIT, misma que es propiedad de AXELERATUM, S.A. DE C.V. (AXELERATUM), y la
        cual incluye la plataforma, y todas las herramientas, aplicaciones, datos, software y otros servicios relacionados proporcionados
        por nosotros enfocados en gestionar toda la documentación necesaria para resguardar, dar seguimiento y poder comprobar ante
        cualquier auditoría la materialidad de las operaciones que nuestros clientes realizan a través de la contratación de proveedores
        (los "Servicios"). Todas estas Políticas se incorporan por referencia en estos Términos y constituyen un acuerdo legalmente
        vinculante entre usted y AXELERATUM en relación con su uso del Servicio.
      </PolicyParagraph>

      <PolicyParagraph>
        Se considerará que los Términos de servicio incluyen todas las pautas, términos, reglas, condiciones, políticas y procedimientos
        adicionales a los que se hace referencia en el presente o que KIVIT pueda publicar en la plataforma (colectivamente, las
        "Políticas”), que incluye, entre otros, la AUP (Acceptable Use Policy) o PUA (Política de Uso Aceptable), documentación y política
        de privacidad, entre otros.
      </PolicyParagraph>

      <PolicyParagraph>
        ESTOS TÉRMINOS ESTABLECEN LOS TÉRMINOS Y CONDICIONES LEGALMENTE VINCULANTES ENTRE LAS PARTES QUE RIGEN EL USO DE LA PLATAFORMA Y
        SERVICIOS. AL ACCEDER O UTILIZAR LA PLATAFORMA O SERVICIOS, USTED ACEPTA ESTOS TÉRMINOS (EN NOMBRE DE USTED MISMO O DE LA ENTIDAD
        QUE REPRESENTA), Y DECLARA Y GARANTIZA QUE TIENE EL DERECHO, LA AUTORIDAD Y LA CAPACIDAD PARA ACEPTAR ESTOS TÉRMINOS (EN NOMBRE DE
        DE USTED MISMO O DE LA ENTIDAD QUE REPRESENTA). ADEMÁS, DECLARA Y GARANTIZA QUE DE OTRO MODO ESTÁ LEGALMENTE PERMITIDO PARA UTILIZAR
        LOS SERVICIOS EN SU JURISDICCIÓN Y QUE AXELERATUM NO ES RESPONSABLE DEL CUMPLIMIENTO DE DICHAS LEYES APLICABLES. NO PUEDE ACCEDER O
        UTILIZAR LA PLATAFORMA O SERVICIOS O ACEPTAR LOS TÉRMINOS SI NO TIENE AL MENOS 18 AÑOS. SI NO ESTÁ DE ACUERDO CON TODAS LAS
        DISPOSICIONES DE ESTOS TÉRMINOS, NO ACCEDA NI UTILICE LA PLATAFORMA.
      </PolicyParagraph>

      <Typography variant="subtitle1" style={{ textAlign: "center" }}>
        DEFINICIONES
      </Typography>

      <PolicyParagraph>
        "AUP" hace referencia a la política de uso aceptable de AXELERATUM publicada actualmente en la misma Plataforma KIVIT.
      </PolicyParagraph>

      <PolicyParagraph>
        “Datos del cliente” se refiere a los datos ingresados en forma electrónica o recopilados a través de la Plataforma o Servicios por o
        desde el Cliente, incluidos, entre otros, los Usuarios del Cliente.
      </PolicyParagraph>

      <PolicyParagraph>
        "Documentación" significa el manual estándar de AXELERATUM relacionado con el uso de la Plataforma y Servicios que se encuentran
        actualmente publicados de manera electrónica en la misma Plataforma KIVIT.
      </PolicyParagraph>

      <PolicyParagraph>
        "Política de privacidad" se refiere a la política de privacidad de AXELERATUM, publicada actualmente en la Plataforma KIVIT.
      </PolicyParagraph>

      <PolicyParagraph>"Plazo" se define en la Cláusula 10.1 de estos Términos y Condiciones.</PolicyParagraph>

      <PolicyParagraph>
        “Usuario” significa cualquier individuo que usa la Plataforma y Servicios en nombre del Cliente o mediante la cuenta o contraseñas
        del Cliente, ya sea que esté autorizado o no.
      </PolicyParagraph>

      <PolicyParagraph>
        "Políticas" significa todas las pautas, términos, reglas, condiciones, políticas y procedimientos adicionales a los que se hace
        referencia en estos Términos de servicio o que AXELERATUM puede publicar en la Plataforma de vez en cuando.
      </PolicyParagraph>

      <Typography variant="subtitle1" style={{ textAlign: "center" }}>
        CUENTAS
      </Typography>

      <PolicyParagraph>
        <PolicyParagraph>
          <b>2.1 Creación de cuenta.</b> Para utilizar ciertas funciones de la Plataforma y los Servicios, debe registrarse para obtener una
          cuenta ("Cuenta") y proporcionar cierta información sobre usted según lo solicite el formulario de registro de la cuenta. Puede
          optar por registrarse o no, a su discreción. Si se registra en los Servicios a través del sitio de un tercero, creará la Cuenta al
          ser redirigido a la Plataforma. Usted declara y garantiza que: (a) toda la información de registro requerida que envíe es veraz y
          precisa; (b) mantendrá la exactitud de dicha información. Puede eliminar su Cuenta en cualquier momento, por cualquier motivo,
          siguiendo las instrucciones de la Plataforma. AXELERATUM puede suspender o cancelar su Cuenta de acuerdo con este Acuerdo.
        </PolicyParagraph>

        <PolicyParagraph>
          Su envío de Datos del cliente a través de la Plataforma o los Servicios se rige por la Política de privacidad de AXELERATUM.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>2.2 Responsabilidades de la cuenta.</b> Usted es responsable de mantener la confidencialidad de la información de inicio de
          sesión de su Cuenta y es totalmente responsable de todas las actividades que ocurran en su Cuenta. Usted acepta notificar de
          inmediato a AXELERATUM sobre cualquier uso no autorizado o sospecha de uso no autorizado de su Cuenta o cualquier otra violación
          de seguridad. AXELERATUM no puede y no será responsable por ninguna pérdida o daño que surja de su incumplimiento de los
          requisitos anteriores.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>2.3 Cliente responsable del acceso del usuario.</b> El Cliente es responsable de: (a) el uso de la Plataforma o Servicios por
          parte de los Usuarios, incluida, entre otras, la conducta del Usuario no autorizado y cualquier conducta del Usuario que infrinja
          la PUA o los requisitos de este Acuerdo aplicables al Cliente; y (b) cualquier uso de la Plataforma o Servicios a través de la
          cuenta del Cliente, ya sea autorizado o no.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>2.4 Acceso no autorizado.</b> El Cliente tomará las medidas razonables para evitar el acceso no autorizado a la Plataforma o
          Servicios, lo que incluye, entre otros, la protección de sus contraseñas y otra información de inicio de sesión. El Cliente
          notificará a AXELERATUM inmediatamente de cualquier uso no autorizado conocido o sospechado de la Plataforma de Servicios o
          violación de su seguridad y hará todo lo posible para detener dicha violación.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>2.5 Veracidad y Autenticidad de la documentación. </b> El Cliente manifiesta bajo protesta de decir verdad que toda la
          documentación proporcionada a través de la Plataforma, cargada o adherida a la misma corresponde a una copia fiel de su original y
          la misma no ha sido alterada ni modificada, por lo que será absoluta responsabilidad del Cliente la autenticidad y veracidad de
          dicha documentación, liberado a AXELERATUM de cualquier responsabilidad sea legal o de cualquier otra índole frente a terceros o
          cualquier entidad sea de carácter público o privado.
        </PolicyParagraph>

        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          ACCESO A SITIOS Y SERVICIOS
        </Typography>

        <PolicyParagraph>
          <b>3.1 Licencia.</b> Sujeto a estos Términos, AXELERATUM le otorga una licencia limitada, no transferible, no exclusiva, revocable
          para usar y acceder a la Plataforma y Servicios únicamente para su uso personal o comercial interno.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>3.2 Ciertas restricciones.</b> Los derechos que se le otorgan en estos Términos están sujetos a las siguientes restricciones:
          El Cliente deberá cumplir con la PUA en general. Además, (a) no deberá licenciar, vender, alquilar, arrendar, transferir, asignar,
          distribuir, alojar o explotar comercialmente la Plataforma o Servicios, ya sea en su totalidad o en parte, o cualquier contenido
          que se muestre en la Plataforma o Servicios. ; (b) no podrá modificar, realizar trabajos derivados, desensamblar, realizar una
          compilación inversa o realizar ingeniería inversa en ninguna parte de la Plataforma o Servicios; (c) no deberá acceder a la
          Plataforma o Servicios para crear un sitio web, producto o servicio similar o competitivo o con el propósito de monitorear su
          disponibilidad, desempeño o funcionalidad, o para cualquier otro propósito de evaluación comparativa o competitivo; (d) salvo que
          se indique expresamente en el presente, ninguna parte de la Plataforma o Servicios puede copiarse, reproducirse, distribuirse,
          replicarse, descargarse, mostrarse, publicarse o transmitirse en cualquier forma o por cualquier medio; (e) no deberá utilizar la
          Plataforma o los Servicios para almacenar o transmitir virus informáticos, trabajos, bombas de tiempo, caballos de Troya y otros
          códigos, rutinas, archivos, scripts, agentes o programas dañinos o maliciosos; (f) no utilizará la Plataforma o los Servicios para
          almacenar o distribuir información, material o datos que sean acosadores, amenazantes, infractores, difamatorios, ilegales,
          obscenos o que violen la privacidad o los derechos de propiedad intelectual de terceros; y (g) no interferirá ni interrumpirá la
          integridad o el rendimiento de la Plataforma o los Servicios o los datos de terceros contenidos en ellos. A menos que se indique
          lo contrario, cualquier versión futura, actualización u otra adición a la funcionalidad de la Plataforma o Servicios estará sujeta
          a estos Términos.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>3.3 Cumplimiento de las leyes.</b> Al utilizar la Plataforma o Servicios, el Cliente deberá cumplir con todas las leyes
          aplicables, incluidas, entre otras, las leyes que rigen la protección de la información de identificación personal y otras leyes
          aplicables a la protección de los Datos del Cliente. Esto incluye específicamente, sin limitación, proporcionar avisos apropiados
          a sus Usuarios, obtener los consentimientos necesarios, brindar las oportunidades de exclusión requeridas y cumplir con sus
          responsabilidades al transferir datos personales.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>3.4 Modificación.</b> AXELERATUM se reserva el derecho, en cualquier momento, de modificar, suspender o descontinuar la
          Plataforma, los Servicios o cualquier Service Level Agreement (Acuerdo de Nivel de Servicios) vigente (en su totalidad o en parte)
          con o sin previo aviso. Si dicha revisión reduce materialmente las características o funciones proporcionadas de conformidad con
          un Pedido, el Cliente puede, dentro de los 30 días posteriores a la notificación de la revisión, rescindir dicho Pedido, sin
          causa, o rescindir este Acuerdo sin causa si dicho Pedido es el único pendiente. Si dicha revisión de cualquier SLA en ese momento
          reduce materialmente los niveles de servicio proporcionados de conformidad con una Orden pendiente, las revisiones no entrarán en
          vigencia con respecto a dicha Orden hasta el inicio del Plazo que comience 30 o más días después de que AXELERATUM publique la
          revisión y así informa al Cliente.
        </PolicyParagraph>

        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          PAGO
        </Typography>

        <PolicyParagraph>
          <b>4.1 Tarifas de suscripción.</b> El Cliente deberá pagar a AXELERATUM la tarifa establecida en cada Pedido (la “Tarifa de
          Suscripción”) por cada Plazo. Las facturas de la empresa vencen dentro de los 30 días posteriores a su emisión. En caso de pago
          atrasado, el Cliente deberá pagar los cargos por intereses desde el momento en que venció el pago a la tasa que sea más baja entre
          el 1.5% por mes o la tasa más alta permitida por la ley aplicable. Excepto por el incumplimiento sustancial de este Acuerdo por
          parte de AXELERATUM o según se especifique de otro modo en la Cláusula 3.3, AXELERATUM no estará obligada a reembolsar la Tarifa
          de Suscripción bajo ninguna circunstancia.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>4.2 Impuestos.</b> Los montos adeudados en virtud de este Acuerdo son pagaderos a AXELERATUM sin deducción y son netos de
          cualquier impuesto, tarifa, tasa o tasación impuesta por cualquier autoridad gubernamental (nacional, estatal, municipal o local),
          incluidas, entre otras, las ventas, el uso, los impuestos especiales, impuesto ad valorem, propiedad, retención o impuesto al
          valor agregado retenido en la fuente. Si la ley aplicable requiere la retención o deducción de dichos impuestos o aranceles, el
          Cliente deberá pagar por separado a AXELERATUM el monto retenido o deducido.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>4.3 Procesadores de pago de terceros.</b> Utilizamos procesadores de pago de terceros (los "Procesadores de pago") para
          facturarle a través de una cuenta de pago vinculada a su Cuenta en los Servicios (su "Cuenta de facturación") por el uso de los
          Servicios de pago. El procesamiento de pagos puede estar sujeto a los términos, condiciones y políticas de privacidad de los
          Procesadores de pagos además de este Acuerdo. No somos responsables de los errores de los Procesadores de pagos. Al elegir
          utilizar los Servicios pagados, usted acepta pagarnos, a través de los Procesadores de pago, todos los cargos a los precios
          vigentes en ese momento por cualquier uso de dichos Servicios pagados de acuerdo con los términos de pago aplicables y nos
          autoriza, a través de los Procesadores de pago, para cobrar a su proveedor de pago elegido (su "Método de pago"). Acepta realizar
          el pago utilizando ese método de pago seleccionado.
        </PolicyParagraph>

        <PolicyParagraph>
          DEBE PROPORCIONAR INFORMACIÓN ACTUAL, COMPLETA Y EXACTA PARA SU CUENTA DE FACTURACIÓN. DEBE ACTUALIZAR INMEDIATAMENTE TODA LA
          INFORMACIÓN PARA MANTENER SU CUENTA DE FACTURACIÓN ACTUAL, COMPLETA Y EXACTA (COMO UN CAMBIO EN LA DIRECCIÓN DE FACTURACIÓN,
          NÚMERO DE TARJETA DE CRÉDITO O FECHA DE VENCIMIENTO DE LA TARJETA DE CRÉDITO), Y DEBE NOTIFICARNOS INMEDIATAMENTE SU MÉTODO DE
          PAGO O NUESTRO PROCESO DE PAGO SE CANCELA (POR EJEMPLO, POR PÉRDIDA O ROBO) O SI SE PRESENTA UN POTENCIAL INCUMPLIMIENTO DE LA
          SEGURIDAD, COMO LA DIVULGACIÓN O USO NO AUTORIZADO DE SU NOMBRE DE USUARIO O CONTRASEÑA. LOS CAMBIOS A DICHA INFORMACIÓN SE PUEDEN
          HACER EN EL CORREO <b>info@kivit.com</b>.
        </PolicyParagraph>

        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          PROPIEDAD INTELECTUAL
        </Typography>

        <PolicyParagraph>
          <b>5.1 Propiedad.</b> Usted reconoce que todos los derechos, títulos e intereses y derechos de propiedad intelectual, incluidos
          los derechos de autor, patentes, marcas comerciales y secretos comerciales, en los Servicios, la Plataforma y su contenido son
          propiedad de AXELERATUM o los proveedores de AXELERATUM. Ni estos Términos (ni su acceso a la Plataforma o el uso de los
          Servicios) le transfieren a usted ni a ningún tercero ningún derecho, título o interés sobre dichos derechos de propiedad
          intelectual, excepto los derechos de acceso limitado expresamente establecidos en la Sección 2.1. AXELERATUM y sus proveedores se
          reservan todos los derechos no otorgados en estos Términos. No se otorgan licencias implícitas en virtud de estos Términos.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>5.2 Comentarios.</b> Si le proporciona a AXELERATUM comentarios o sugerencias con respecto a la Plataforma o Servicios
          ("Comentarios"), por la presente cede a AXELERATUM todos los derechos sobre dichos Comentarios y acepta que AXELERATUM tendrá el
          derecho de usar y explotar plenamente dichos Comentarios e información relacionada en cualquier de la manera que considere
          apropiada. AXELERATUM tratará cualquier Comentario que le proporcione a AXELERATUM como no confidencial y sin derechos de
          propiedad. Usted acepta que no enviará a AXELERATUM ninguna información o idea que considere confidencial o de propiedad
          exclusiva.
        </PolicyParagraph>

        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          DATOS DEL CLIENTE, PRIVACIDAD, CONFIDENCIALIDAD
        </Typography>

        <PolicyParagraph>
          <b>6.1 Uso de los datos del cliente.</b> A menos que reciba el consentimiento previo por escrito del Cliente, AXELERATUM: (a) no
          accederá, procesará ni utilizará los Datos del Cliente de otro modo que no sea necesario para facilitar los Servicios; y (b) no
          otorgará intencionalmente a ningún tercero acceso a los Datos del Cliente, incluidos, entre otros, otros clientes de AXELERATUM,
          excepto los subcontratistas que estén sujetos a un acuerdo razonable de no divulgación. No obstante, lo anterior, AXELERATUM puede
          divulgar Datos del Cliente según lo requiera la ley aplicable o la autoridad legal o gubernamental correspondiente. A menos que lo
          prohíba la ley aplicable o la autoridad gubernamental, AXELERATUM notificará al Cliente con prontitud de cualquier demanda legal o
          gubernamental y cooperará razonablemente con el Cliente en cualquier esfuerzo por buscar una orden de protección o de otra manera
          para impugnar dicha divulgación requerida, a expensas del Cliente.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>6.2 Política de privacidad no aplicable a terceros.</b> Nuestra Política de privacidad se aplica solo a los Servicios y no se
          aplica a ningún sitio web o servicio de terceros vinculado o al que se accede a través de los Servicios.
        </PolicyParagraph>

        <PolicyParagraph>6.3 Riesgos específicos relacionados con los datos</PolicyParagraph>

        <PolicyParagraph>
          <b>6.3.1</b> El Cliente reconoce y acepta que el alojamiento de datos en línea implica riesgos de divulgación o exposición no
          autorizadas y que, al acceder y utilizar los Servicios, el Cliente asume dichos riesgos. AXELERATUM no ofrece representación,
          garantía o garantía de que los Datos del Cliente no serán expuestos o divulgados a través de errores o acciones de terceros.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>6.3.2 AXELERATUM</b> no tiene ninguna responsabilidad por la exactitud de los datos cargados a los Servicios por el Cliente o
          los Usuarios del Cliente.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>6.3.3 AXELERATUM</b> puede borrar permanentemente los Datos del Cliente si la cuenta del Cliente está atrasada, suspendida o
          cancelada por 30 días o más.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>6.4 Uso de los datos del cliente por parte de la empresa.</b> Sin perjuicio de las disposiciones anteriores de este Artículo 6,
          AXELERATUM puede usar, reproducir, vender, publicitar o explotar Datos agregados de cualquier manera, a su exclusivo criterio.
          ("Datos agregados" se refiere a los Datos del cliente con lo siguiente eliminado: información de identificación personal y los
          nombres y direcciones del Cliente y cualquiera de sus Usuarios o clientes).
        </PolicyParagraph>

        <PolicyParagraph>
          <b>6.5 Información confidencial.</b> “Información confidencial” se refiere a los siguientes elementos que AXELERATUM revela al
          Cliente: (a) cualquier documento que AXELERATUM marque como “Confidencial”; (b) cualquier información que AXELERATUM designe
          verbalmente como "Confidencial" en el momento de la divulgación; (c) la Documentación, esté o no marcada o designada como
          confidencial; y (d) cualquier otra información confidencial y no pública que el Cliente deba considerar razonablemente como un
          secreto comercial o de otro modo confidencial. No obstante, lo anterior, la Información Confidencial no incluye información que:
          (i) esté en posesión del Cliente en el momento de la divulgación; (ii) es desarrollado de forma independiente por el Cliente sin
          el uso o referencia a la Información Confidencial; (iii) se da a conocer públicamente, antes o después de la divulgación, excepto
          como resultado de una acción o inacción indebida del Cliente; o (iv) está aprobado para su divulgación por escrito por AXELERATUM.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>6.5.1. No divulgación.</b> El Cliente no utilizará la Información Confidencial para ningún propósito que no sea el uso de la
          Plataforma o Servicios por parte del Cliente (el "Propósito"). Cliente: (a) no divulgará Información Confidencial a ningún
          empleado o contratista del Cliente a menos que dicha persona necesite acceso para facilitar el Propósito y ejecute un acuerdo de
          no divulgación con el Cliente con términos no menos restrictivos que los de este Artículo 6; y (b) no divulgará Información
          Confidencial a ningún otro tercero sin el consentimiento previo por escrito de AXELERATUM. Sin limitar la generalidad de lo
          anterior, el Cliente protegerá la Información Confidencial con el mismo grado de cuidado que usa para proteger su propia
          información confidencial de naturaleza e importancia similares, pero con un cuidado no menos que razonable. El Cliente notificará
          de inmediato a AXELERATUM sobre cualquier uso indebido o apropiación indebida de la Información confidencial que llegue a su
          conocimiento. No obstante, lo anterior, el Cliente puede divulgar Información Confidencial según lo requiera la ley aplicable o la
          autoridad legal o gubernamental correspondiente. El Cliente notificará rápidamente a AXELERATUM de cualquier demanda legal o
          gubernamental y cooperará razonablemente con AXELERATUM en cualquier esfuerzo por buscar una orden de protección o de otra manera
          para impugnar dicha divulgación requerida, a expensas de AXELERATUM.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>6.5.2 Mandato judicial.</b> El Cliente acepta que el incumplimiento de este Artículo 6 causaría daños irreparables a
          AXELERATUM, por los cuales un resarcimiento monetario no proporcionaría una compensación adecuada, y que, además de cualquier otro
          pago, AXELERATUM tendrá derecho a una medida cautelar contra dicho incumplimiento o amenaza de incumplimiento, sin demostrar que
          sea real, o bien a una fianza u otra garantía.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>6.5.3 Terminación y devolución.</b> Con respecto a cada elemento de Información confidencial, las obligaciones de la cláusula
          6.5.1 anterior (No divulgación) terminarán dos años después de la fecha de divulgación; en el entendido de que dichas obligaciones
          relacionadas con la Información Confidencial que constituye los secretos comerciales de AXELERATUM continuarán mientras dicha
          información permanezca sujeta a la protección del secreto comercial de conformidad con la ley aplicable. Tras la terminación de
          este Acuerdo, el Cliente deberá devolver todas las copias de la Información Confidencial a AXELERATUM o certificar, por escrito,
          la destrucción de la misma.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>6.5.4 Reserva de derechos.</b> Este Acuerdo no transfiere la propiedad de la Información Confidencial ni otorga una licencia a
          la misma. AXELERATUM conservará todos los derechos, títulos e intereses en y para toda la Información Confidencial.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>6.5.5 Excepción e inmunidad.</b> De conformidad con la Ley Federal de Protección a la Propiedad Industrial, el Destinatario
          recibe notificación y reconoce que, sin perjuicio de lo anterior o de cualquier otra disposición de este Acuerdo:
        </PolicyParagraph>

        <PolicyList>
          <PolicyParagraph>
            <b>(a) Inmunidad.</b> Un individuo no será responsable penal o civilmente bajo ninguna ley de secreto comercial federal o
            estatal por la divulgación de un secreto comercial que- (A) se hace- (i) en confianza a un funcionario del gobierno federal,
            estatal o local, ya sea directa o indirectamente, o a un abogado; y (ii) únicamente con el propósito de informar o investigar
            una presunta infracción de la ley; o (B) se hace en una queja u otro documento presentado en una demanda u otro procedimiento,
            si dicha presentación se realiza bajo sello.
          </PolicyParagraph>

          <PolicyParagraph>
            <b>(b) Uso de información secreta comercial en una demanda contra represalias.</b> Una persona que presenta una demanda por
            represalia por parte de un empleador por denunciar una presunta infracción de la ley puede revelar el secreto comercial al
            abogado de la persona y utilizar la información del secreto comercial en el procedimiento judicial, si la persona: (A) presenta
            algún documento que contenga el secreto comercial sellado; y (B) no divulga el secreto comercial, excepto de conformidad con una
            orden judicial.
          </PolicyParagraph>
        </PolicyList>

        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          REPRESENTACIONES Y GARANTÍAS
        </Typography>

        <PolicyParagraph>
          <b>7.1. Por empresa.</b> AXELERATUM declara y garantiza que es la propietaria de la Plataforma y Servicios y de todos y cada uno
          de los componentes de los mismos, o el destinatario de una licencia válida para los mismos, y que tiene y mantendrá el poder y la
          autoridad plenos para otorgar los derechos otorgados en este Acuerdo sin el consentimiento adicional de ningún tercero. En caso de
          incumplimiento de la garantía en esta Sección 7.1, AXELERATUM, por su propia cuenta, tomará de inmediato las siguientes acciones:
          (a) asegurar para el Cliente el derecho de continuar usando la Plataforma y Servicios; (b) reemplazar o modificar la Plataforma o
          Servicios para que no sean infractores; o (c) rescindir las características infractoras de la Plataforma o Servicios y reembolsar
          al Cliente las tarifas prepagadas por dichas características, en proporción a la parte del Plazo restante después de dicha
          rescisión.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>7.2. Por el cliente.</b> El Cliente declara y garantiza que: (a) tiene el derecho y la autoridad plenos para celebrar, ejecutar
          y cumplir con sus obligaciones en virtud de este Acuerdo y que ningún reclamo o litigio pendiente o amenazado conocido por él
          tendría un impacto material adverso en su capacidad realizar lo requerido por este Acuerdo; (b) se ha identificado con precisión y
          no ha proporcionado ninguna información inexacta sobre sí mismo a través de la Plataformay Servicios; y (c) es una corporación,
          propiedad exclusiva de una persona mayor de 18 años, u otra entidad autorizada para hacer negocios de conformidad con la ley
          aplicable.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>7.3. Renuncias de garantía.</b> Excepto en la medida establecida en cualquier SLA vigente en ese momento y en la cláusula 7.1
          anterior, EL CLIENTE ACEPTA LA PLATAFORMA Y SERVICIOS "TAL CUAL" Y COMO ESTÁN DISPONIBLES, SIN REPRESENTACIÓN O GARANTÍA DE NINGÚN
          TIPO, EXPRESA O IMPLÍCITA, INCLUYENDO SIN LIMITACIÓN GARANTÍAS IMPLÍCITAS DE COMERCIALIZACIÓN, APTITUD PARA UN PROPÓSITO EN
          PARTICULAR O NO INFRACCIÓN DE LOS DERECHOS DE PROPIEDAD INTELECTUAL O CUALQUIER GARANTÍA IMPLÍCITA QUE SURJA DE ESTATUTO, CURSO DE
          NEGOCIACIÓN, CURSO DE RENDIMIENTO O USO COMERCIAL. SIN LIMITAR LA GENERALIDAD DE LO ANTERIOR: (a) AXELERATUM NO TIENE LA
          OBLIGACIÓN DE INDEMNIZAR O DEFENDER AL CLIENTE O USUARIOS CONTRA RECLAMOS RELACIONADOS CON LA INFRACCIÓN DE LA PROPIEDAD
          INTELECTUAL; (b) AXELERATUM NO DECLARA NI GARANTIZA QUE LA PLATAFORMA Y SERVICIOS FUNCIONARÁN SIN INTERRUPCIÓN O ERROR;
        </PolicyParagraph>

        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          INDEMNIZACIÓN
        </Typography>

        <PolicyParagraph>
          <b>8.1</b> Usted acepta indemnizar y mantener indemne a AXELERATUM (y a sus funcionarios, empleados y agentes), incluidos los
          costos y los honorarios de los abogados, de cualquier reclamo o demanda realizada por un tercero debido a o que surja de (a) su
          uso de la Plataforma y Servicios, (b) su violación de estos Términos, (c) su violación de las leyes o regulaciones aplicables o
          (d) su Contenido de Usuario. AXELERATUM se reserva el derecho, a su cargo, de asumir la defensa y el control exclusivos de
          cualquier asunto por el que deba indemnizarnos, y usted acepta cooperar con nuestra defensa de estos reclamos. Usted acepta no
          resolver ningún asunto sin el consentimiento previo por escrito de AXELERATUM. AXELERATUM hará todos los esfuerzos razonables para
          notificarle sobre cualquier reclamo, acción o procedimiento una vez que tenga conocimiento de ello.
        </PolicyParagraph>

        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          LIMITACIÓN DE RESPONSABILIDAD
        </Typography>

        <PolicyParagraph>
          EN LA MÁXIMA MEDIDA PERMITIDA POR LA LEY, EN NINGÚN CASO AXELERATUM (O NUESTROS PROVEEDORES) SERÁN RESPONSABLES ANTE USTED O
          CUALQUIER TERCERO POR LA PÉRDIDA DE BENEFICIOS, DATOS PERDIDOS, COSTOS DE ADQUISICIÓN DE PRODUCTOS SUSTITUTOS O CUALQUIER
          INDIRECTO, CONSECUENTE, EJEMPLAR, INCIDENTAL, DAÑOS ESPECIALES O PUNITIVOS QUE SURJAN O ESTÉN RELACIONADOS CON ESTOS TÉRMINOS O SU
          USO O INCAPACIDAD DE USO DE LA PLATAFORMA O SERVICIOS, INCLUSO SI AXELERATUM HA SIDO AVISADA DE LA POSIBILIDAD DE DICHOS DAÑOS. EL
          ACCESO Y EL USO DE LA PLATAFORMA ES BAJO SU PROPIA DISCRECIÓN Y RIESGO, Y USTED SERÁ EL ÚNICO RESPONSABLE DE CUALQUIER DAÑO A SU
          DISPOSITIVO O COMPUTADORA, O DE LA PÉRDIDA DE DATOS QUE RESULTEN DE LOS MISMOS.
        </PolicyParagraph>

        <PolicyParagraph>
          EN LA MÁXIMA MEDIDA PERMITIDA POR LA LEY, A PESAR DE LO CONTENIDO EN ESTE DOCUMENTO, NUESTRA RESPONSABILIDAD ANTE USTED POR
          CUALQUIER DAÑO QUE SURJA O ESTÉ RELACIONADO CON ESTE ACUERDO (POR CUALQUIER CAUSA E INDEPENDIENTEMENTE DE LA FORMA DE LA ACCIÓN),
          SERÁ EN TODO MOMENTO LIMITADO A CINCUENTA MIL PESOS MONEDA DE CURSO LEGAL EN LOS ESTADOS UNIDOS MEXICANOS (MXN $50.000) O LA
          CANTIDAD REALMENTE PAGADA POR EL CLIENTE A AXELERATUM EN LOS DOCE MESES ANTERIORES UNICAMENTE POR EL LICENCIAMIENTO DE LA
          PLATAFORMA EXCLUYENDO SERVICIOS DE CONFIGURACION, INTEGRACION, MESA DE AYUDA, SOPORTE TECNICO O SIMILARES. LA EXISTENCIA DE MÁS DE
          UN RECLAMO NO AMPLIARÁ ESTE LÍMITE. USTED ACEPTA QUE NUESTROS PROVEEDORES NO TENDRÁN NINGUNA RESPONSABILIDAD DE NINGÚN TIPO QUE
          SURJA O ESTÉ RELACIONADA CON ESTE ACUERDO.
        </PolicyParagraph>

        <PolicyParagraph>
          ALGUNAS JURISDICCIONES NO PERMITEN LA LIMITACIÓN O EXCLUSIÓN DE RESPONSABILIDAD POR DAÑOS INCIDENTALES O CONSECUENTES, POR LO QUE
          LA LIMITACIÓN O EXCLUSIÓN ANTERIOR PUEDE NO APLICARSE EN SU CASO
        </PolicyParagraph>

        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          DURACIÓN Y TERMINACIÓN
        </Typography>

        <PolicyParagraph>
          <b>10.1</b> A menos que se rescinda en virtud del presente, el Plazo de su Pedido se especificará en su Pedido.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>10.2</b> Sujeto a esta Sección, estos Términos permanecerán en pleno vigor y efecto mientras utilice la Plataforma y Servicios.
          Podemos suspender o rescindir sus derechos de uso de la Plataforma y Servicios (incluida su Cuenta) en cualquier momento y por
          cualquier motivo a nuestro exclusivo criterio, incluso para cualquier uso de la Plataforma o Servicios en violación de estos
          Términos. Tras la terminación de sus derechos en virtud de estos Términos, su Cuenta y el derecho a acceder y utilizar la
          Plataforma y Servicios finalizarán de inmediato. A menos que se indique expresamente en este documento, AXELERATUM no tendrá
          responsabilidad alguna ante el Cliente por la terminación de sus derechos en virtud de estos Términos, incluida la cancelación de
          su Cuenta. Incluso después de que se rescindan sus derechos en virtud de estos Términos, las siguientes disposiciones de estos
          Términos permanecerán en vigor: Cláusula 4, 5, 6, 8, 9 y la totalidad de la 12.2.
        </PolicyParagraph>

        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          ENLACES Y ANUNCIOS DE TERCEROS; OTROS USUARIOS
        </Typography>

        <PolicyParagraph>
          <b>11.1 Enlaces y anuncios de terceros.</b> La Plataforma puede contener enlaces a sitios web y servicios de terceros, y / o
          mostrar anuncios de terceros (en conjunto, "Enlaces y anuncios de terceros"). Dichos enlaces y anuncios de terceros no están bajo
          el control de la empresa, y la empresa no es responsable de los enlaces y anuncios de terceros. AXELERATUM proporciona acceso a
          estos Vínculos y Anuncios de Terceros solo para su conveniencia, y no revisa, aprueba, monitorea, respalda, garantiza ni hace
          ninguna declaración con respecto a los Vínculos y Anuncios de Terceros. Usted utiliza todos los enlaces y anuncios de terceros
          bajo su propio riesgo y debe aplicar un nivel adecuado de precaución y discreción al hacerlo. Cuando hace clic en cualquiera de
          los Vínculos y anuncios de terceros, se aplican los términos y políticas aplicables del tercero, incluidas las prácticas de
          privacidad y recopilación de datos del tercero.
        </PolicyParagraph>

        <Typography variant="subtitle1" style={{ textAlign: "center" }}>
          GENERAL
        </Typography>

        <PolicyParagraph>
          Cambios. Estos Términos están sujetos a revisiones ocasionales y, si hacemos algún cambio, cambiaremos la fecha de Última
          actualización anterior. El uso continuado de nuestra Plataforma después de dicho aviso de dichos cambios indicará su
          reconocimiento de dichos cambios y su aceptación de estar sujeto a los términos y condiciones de dichos cambios.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>12.1</b> Resolución de disputas Este Acuerdo se regirá por las leyes federales de la República Mexicana. Cualquier reclamo,
          disputa o controversia ("Reclamo") que surja de o esté relacionado con este Acuerdo o las relaciones entre las partes del mismo
          será resuelto por juez federal competente con sede en la Ciudad de México, México, por lo que las partes renuncian expresamente a
          cualquier otra jurisdicción que con motivo de sus domicilios presentes o futuros pudieran corresponderles.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>12.2 Comunicaciones electrónicas.</b> Las comunicaciones entre usted y AXELERATUM utilizan medios electrónicos, ya sea que
          utilice la Plataforma o nos envíe correos electrónicos, o si AXELERATUM publica avisos en la Plataforma o se comunica con usted
          por correo electrónico. Para fines contractuales, usted (a) acepta recibir comunicaciones de AXELERATUM en forma electrónica,
          incluyendo aquellos documentos firmados con una firma electrónica avanzada; y (b) aceptar que todos los términos y condiciones,
          acuerdos, avisos, divulgaciones y otras comunicaciones que AXELERATUM le proporcione electrónicamente satisfacen cualquier
          requisito legal que dichas comunicaciones cumplirían si estuvieran en una copia impresa. Lo anterior no afecta sus derechos
          irrenunciables.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>12.3 Términos completos.</b> Estos Términos constituyen el acuerdo completo entre usted y nosotros con respecto al uso de la
          Plataforma. El hecho de que no ejerzamos o hagamos cumplir cualquier derecho o disposición de estos Términos no funcionará como
          una renuncia a dicho derecho o disposición. Los títulos de las secciones en estos Términos son solo para conveniencia y no tienen
          ningún efecto legal o contractual. La palabra "incluido" significa "incluido sin limitación". Si alguna disposición de estos
          Términos es, por cualquier motivo, considerada inválida o inaplicable, las otras disposiciones de estos Términos no se verán
          afectadas y la disposición inválida o inaplicable se considerará modificada para que sea válida y ejecutable en la máxima medida
          permitida. por ley. Su relación con AXELERATUM es la de un contratista independiente y ninguna de las partes es agente o socio de
          la otra. Estos Términos, y sus derechos y obligaciones aquí, no podrá ser cedido, subcontratado, delegado o transferido por usted
          sin el consentimiento previo por escrito de AXELERATUM, y cualquier intento de cesión, subcontrato, delegación o transferencia en
          violación de lo anterior será nulo y sin efecto. AXELERATUM puede asignar libremente estos Términos. Los términos y condiciones
          establecidos en estos Términos serán vinculantes para los cesionarios.
        </PolicyParagraph>

        <PolicyParagraph>
          <b>12.4 Contacto.</b> Agradecemos sus comentarios o preguntas sobre estos Términos. Por favor contáctenos en: info@kivit.mx.
        </PolicyParagraph>

        <Typography style={{ textAlign: "center" }}>Ciudad de México, a 13 de octubre de 2021</Typography>
      </PolicyParagraph>
    </>
  );
};

export default TermsAndConditions;
