import React from 'react';
import { Modal, Backdrop, Fade, makeStyles, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #e2e8f0',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    width: '80%',
    maxHeight: '80vh',
    overflow: 'auto',
  },
  pdfContainer: {
    width: '100%',
    height: '100%',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const PdfModal = ({ open, handleClose, pdfBlobUrl }) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="pdf-modal-title"
      aria-describedby="pdf-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <IconButton 
            aria-label="close" 
            className={classes.closeButton} 
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <div className={classes.pdfContainer}>
            <object width="100%" height={800} data={pdfBlobUrl} type="application/pdf" />
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default PdfModal;
