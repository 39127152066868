import React, { useState } from 'react';
import { makeStyles, Card, CardContent, CardHeader, Typography, Switch, FormControlLabel, Box, Grid, Button, CardProps, withStyles, Modal, IconButton } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import DotsMenuCard from './DotsMenuCard';
import { purple, red } from '@material-ui/core/colors';
import useGeneratorClientState from '../../../../../store/generadores/generadorClientState';
import useUiState from '../../../../../store/uiState';
import useCustomToast from '../../../../../core/hooks/useCustomToast';
import TableJson from './TableJsonCatalogue';
import useGlobalState from '../../../../../store/globalState';
import { GeneradoresServices } from '../../../../../core/services/generadores.service';
import { AlertConfirm } from '../../../services/AlertConfirm';

interface PropsData {
  fileName?: string;
  startDate?: string;
  endDate?: string;
  nombreCatalogo?: string;
}

interface cardProps {
  dataCard: PropsData;
}

const CustomSwitch = withStyles({
  switchBase: {
    '&$checked + $track': {
      backgroundColor: red[800],
    },
  },
  checked: {},
  track: {
    backgroundColor: 'rgba(82, 198, 186, 1)',
  },
})(Switch);

interface Sheet {
  name: string;
  rowAxList: {
    cellAxList: string[];
    cssList?: string;
  }[];
}
//const CatalogCard = ({ dataCard }: cardProps) => {
  const CatalogCard = ({ dataCard }) => {

  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const { getCatalogueJson, getCatalogues, deleteCatalogue, enableCatalogue, disableCatalogue} = useGeneratorClientState();
  const { setShowGenericLoader } = useUiState();
  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [json, setJson] = useState<Sheet[]>([]);
  const { mainCompany  } = useGlobalState();
  const companyId = mainCompany.id;
  const toast = useCustomToast();

  const handleToggleChange = async (dataCard) => {

    try {
      setShowGenericLoader(true);

      console.log(dataCard)
      if (dataCard.status === true){
       const disabled = await disableCatalogue(dataCard.id);
       if(disabled){
        getCatalogues(companyId);
        setShowGenericLoader(false);
        toast.success("Estatus actualizado con éxito");
       }else{
        toast.error("Ocurrio un problema al actualizar el estatus");
       }

      }else{
      const enabled = await enableCatalogue(dataCard.id);
       if(enabled){
        getCatalogues(companyId);
        setShowGenericLoader(false);
        toast.success("Estatus actualizado con éxito");
       }else{
        toast.error("Ocurrio un problema al actualizar el estatus");
       }

      }
    } catch (error) {
      setShowGenericLoader(false);
      console.error("Error al actualizar estatus del archivo", error);
      return null;
    }
  
    //setChecked(event.target.checked);
  };

  const decodeBase64ToBlob = (base64String, mimeType = 'application/octet-stream') => {
    try {
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mimeType });
    } catch (error) {
      console.error("Error al decodificar el base64:", error);
      return null;
    }
  };

  const handleOpenFile = async () => {
    setShowGenericLoader(true);
    try {
      const jsonFile = await getCatalogueJson(dataCard.idFile);
      console.log("get jsonFile", jsonFile);
      setJson(jsonFile.workbookAx.sheetAxList);
      setShowGenericLoader(false);
      setOpenModal(true);

    } catch (error) {
      console.error("Error al obtener el archivo JSON:", error);
      setShowGenericLoader(false);
      toast.error("Ocurrió un error al obtener el archivo. Por favor, inténtalo de nuevo más tarde.");
      
    }
  };
  
  const handleDeleteCatalogue = async (item:any) => {
    console.log("item",item)
    setShowGenericLoader(true);
    try {
      const jsonFile = await deleteCatalogue(dataCard.idFile);
      console.log("get jsonFile", jsonFile);
      getCatalogues(companyId);
      setShowGenericLoader(false);
      toast.success("Archivo eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar el archivo:", error);
      setShowGenericLoader(false);
      toast.error("Ocurrió un error al eliminar el archivo. Por favor, inténtalo de nuevo más tarde."); 
    }
  };
  
  function base64ToObjectURL(base64) {
    try {
      let byteString = atob(base64);
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
  
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
  
      let blob = new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
      let url = URL.createObjectURL(blob);
  
      return url;
    } catch (error) {
      console.error('Error al convertir base64 a objeto URL:', error);
      throw error;
    }
  }
  
  const handleDownloadFile = async (item) => {
    try {
      setShowGenericLoader(true);

      const base64Data = await GeneradoresServices.getInstance().getImage(item.idFile);
      
      const fileURL = await base64ToObjectURL(base64Data.file);
      
      if (fileURL) {
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = 'Catalogo.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setShowGenericLoader(false);
        toast.success("Archivo descargado con éxito");

      } else {
        console.error('No se pudo obtener el URL del archivo XLSX');
        setShowGenericLoader(false);
        toast.error("No se pudo obtener el archivo");

      }
    } catch (error) {
      console.error('Error al manejar la descarga del archivo:', error);
    }
  }
  

  const formatISODateToDateString = (isoDate) => {
    if (!isoDate) {
      return 'N/A';
    }
  
    const date = new Date(isoDate);
    if (isNaN(date.getTime())) {
      return 'N/A';
    }
  
    return date.toISOString().split('T')[0];
  } 

const isDisabled = () => dataCard.status === false;
  
  return (
    <Grid item xs={3} className={isDisabled() ? classes.disabledCard : ''}>
      <CardContent className={classes.contentContainer}>
        <Box display={'flex'} justifyContent={'end'}>
          <CustomSwitch
            name="checked"
            color="primary"
            className={classes.switch}
            //value={checked}
            checked={dataCard.status}           
            onChange={() => setOpenAlert(true)}
          />
        </Box>
        <Typography variant="h4" className={classes.title}>
          {dataCard.fileName}
        </Typography>
        <Card className={classes.card}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'top'}>
            <Typography variant="body2" className={classes.subtitle}>
              Detalles del Uso
            </Typography>
            <DotsMenuCard disabled={false} handleDelete={handleDeleteCatalogue} item={dataCard} />
          </Box>
          <Typography variant="body2" className={classes.text}>
            Fecha inicial de uso: <b>{formatISODateToDateString(dataCard.startDate)}</b>
          </Typography>
          <Typography variant="body2" className={classes.text}>
            Fecha final de uso: <b>{formatISODateToDateString(dataCard.endDate)}</b>
          </Typography>
          <Typography variant="body2" className={classes.text}>
            Agregado el: <b>{formatISODateToDateString(dataCard.lastModifiedDate)}</b>
          </Typography>
        </Card>
      </CardContent>
      <Grid container spacing={1} className={classes.buttonsSection}>
        <Grid item xs={6}>
       {/* disabled={isDisabled()}*/}
          <Button   onClick={() => handleOpenFile()} className={classes.button} startIcon={<Visibility />} >Vizualizar catálogo</Button>
        </Grid>
        <Grid item xs={6}>
          <Button disabled={isDisabled()} onClick={() => handleDownloadFile(dataCard)} className={classes.button} >Descargar catálogo</Button>
        </Grid>
      </Grid>
      {
       /* openModal&&(
          //  onClose={ () => setOpenModal(true)} 
        )*/
      }
        <Modal
        open={openModal}
        onClose={()=> setOpenModal(false)}
        aria-labelledby="alert-modal-title"
        aria-describedby="alert-modal-description"
      >
        <div className={classes.modalStyle}>
          <header className={classes.header}>
            <IconButton
              aria-label="close"
              onClick={ ()=> setOpenModal(false)}
              className={classes.closeButton}
            >
              X
            </IconButton>
          </header>
          <Box id="alert-modal-description" className={classes.content}>
          <TableJson sheets={json} readyOnly={true}/>           
          </Box>
        </div>
      </Modal>
      
      <AlertConfirm
        open={openAlert}
        title={"¡Cuidado!"}
        textContent={`Estás a punto de habilitar este archivo ${dataCard?.fileName} ¿estás seguro?`}
        onConfirm={() => {
          setOpenAlert(false);
          handleToggleChange(dataCard);
        }}
        onCancel={() => setOpenAlert(false)}
      />
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    position: 'relative'
  },
  card: {
    padding: theme.spacing(2),
    marginTop: '15px'

  },
  buttonsSection: {
    marginTop: '15px'
  },
  button: {
    backgroundColor: 'rgba(82, 198, 186, 1)',
    color: 'white',
    borderRadius: '8px',
    padding: theme.spacing(1, 1),
    textAlign: 'center',
    width: '100%',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: 'rgba(82, 198, 186, 0.9)',
    },
  },
  switch: {
    margin: '-12px',
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(5),
    color: 'rgba(82, 198, 186, 1)',
  },
  toggle: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  contentContainer: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.lightBlue.light
  },
  subtitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  disabledCard: {
    opacity: '.6',
  },
  text: {

  },
  modalStyle: {
    overflowY: 'scroll',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    boxShadow: theme.shadows[5],
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    border: '2px solid rgba(35, 71, 173, 1)',
    boxShadow: theme.shadows[5],
    backgroundColor: 'rgba(35, 71, 173, 1)',
  },
  closeButton: {
    color: 'rgba(255, 255, 255, 1)',
  },
  content: {
    marginTop: theme.spacing(10),
  }
}));

export default CatalogCard;
