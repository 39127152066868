import { authHeader, axiosPagareRequest } from "../../configs/axios.config";
import { PolicyData } from "../models/settings.models";

export class SettingsService {
  private static instance: SettingsService;

  private constructor() { }

  public static getInstance(): SettingsService {
    if (!SettingsService.instance) {
      SettingsService.instance = new SettingsService();
    }
    return SettingsService.instance;
  }

  async getPoliciesByCompanyList(companyId: string) {
    try {
      const { data } = await axiosPagareRequest.get(`/general/companyPasswordPolicy/companyId/${companyId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPoliciesByUserList(userId: string) {
    try {
      const { data } = await axiosPagareRequest.get(`/general/companyPasswordPolicy/userId/${userId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updatePoliciesList(body: PolicyData) {
    try {
      const { data } = await axiosPagareRequest.patch(`/general/companyPasswordPolicy`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCompanyUsers(companyId: string) {
    try {
      const {data} = await axiosPagareRequest.get(`/general/user/byCompany/${companyId}`, {
        headers: authHeader(),
      });
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

  async getNotApplicableUsers(companyId: string) {
    /** */
    try {
      const {data} = await axiosPagareRequest.get(`/general/otpConfig/notApplicableUsers/companyId/${companyId}`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  async saveNotApplicableUsers(body: any, companyId: string) {
    try {
      const {data} = await axiosPagareRequest.post(`/general/otpConfig/companyId/${companyId}`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  async validateOtpCode ( id: string, code: string ) {
    /** */
    try {
        /** */
        const validateOtpPath = `/general/otpConfig/validOtp/otpNodeId/${id}/otpValue/${code}`;

        return await axiosPagareRequest.post( validateOtpPath, null )
        .catch( error => {
            return error.response;
        });
    } catch ( error ) {
        /** */
        console.log( error );
        return error;
    }
}
}
