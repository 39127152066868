import { createTheme } from "@material-ui/core/styles";

/* -------------------------------------------------------------------------- */
/*                               original theme                               */
/* -------------------------------------------------------------------------- */

export const kivitTheme = createTheme({
  palette: {
    primary: { main: "#2347AD" },
    secondary: { main: "#0092F6" },
    success: { main: "#95D97D" },
    warning: { light: "#F9C372", main: "#F4CD69" },
    error: { main: "#F45F5F" },
    label: { main: "rgb(192, 204, 230)" },
    text: {
      primary: "#03264c",
    },
    orange: { light: "#f79954", main: "#f47b24", dark: "#db620b" },
    purple: { main: "#7678ED", dark: "#383BDD" },
    lightBlue: {
      light: "rgba(0, 112, 158, 0.13)",
      main: "rgba(0, 112, 158, 0.4)",
    },
    black: { light: "#03264c", main: "#4A4A4A", dark: "#000" },
  },
  typography: {
    fontFamily: [
      '"Archivo"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
    ].join(","),
    h3: { fontSize: "1.75rem", fontWeight: "bold" },
    h4: { fontSize: "1.5rem", fontWeight: "bold" },
    h5: { fontSize: "1.375rem", fontWeight: "bold" },
    subtitle1: { fontSize: "1.125rem", fontWeight: "bold", lineHeight: "24px" },
    subtitle2: { fontSize: "1rem", fontWeight: "bold" },
    body1: { fontSize: "1rem", lineHeight: "20px" },
    body2: { fontSize: "0.875rem", lineHeight: "20px" },
    caption: { fontSize: "0.75rem", lineHeight: "16px" },
  },
  //Override default props for some components
  props: {
    MuiSwitch: {
      color: "primary",
    },
    MuiSelect: {
      MenuProps: {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        getContentAnchorEl: null,
        MenuListProps: {
          disablePadding: true,
        },
      },
    },
    MuiMenu: {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "center",
      },
      getContentAnchorEl: null,
    },
    MuiFilledInput: {
      disableUnderline: true,
    },
    MuiButton: {
      disableElevation: true,
    },
  },
});

kivitTheme.typography.h1 = {
  ...kivitTheme.typography.h1,
  fontSize: "3.75rem",
  fontWeight: "bold",
  [kivitTheme.breakpoints.down("md")]: {
    fontSize: "3.125rem",
  },
};
kivitTheme.typography.h2 = {
  ...kivitTheme.typography.h2,
  fontSize: "2.875rem",
  fontWeight: 600,
  [kivitTheme.breakpoints.down("md")]: {
    fontSize: "2.25rem",
  },
};
kivitTheme.typography.h6 = {
  ...kivitTheme.typography.h6,
  fontSize: "1.25rem",
  fontWeight: "bold",
  [kivitTheme.breakpoints.down("md")]: {
    fontSize: "1.125rem",
  },
};

kivitTheme.overrides = {
  MuiAppBar: {
    colorPrimary: {
      color: `${kivitTheme.palette.primary.contrastText} !important`,
      backgroundColor: `${kivitTheme.palette.primary.main} !important`,
    },
  },
  MuiCheckbox: { root: { color: kivitTheme.palette.primary.main } },
  MuiSelect: { select: { "&:focus": { backgroundColor: "unset" } } },
  MuiSwitch: {
    root: {
      height: 40,
    },
    colorPrimary: {
      "&$checked": {
        color: "white",
      },
      "&$checked + $track": {
        opacity: 1,
      },
    },
    checked: {},
  },
  MuiButton: {
    root: {
      borderRadius: 8,
      textTransform: "unset",
    },
    outlinedPrimary: {
      border: `1px solid ${kivitTheme.palette.primary.main}`,
      backgroundColor: "white",
      "&:hover": {
        border: `1px solid ${kivitTheme.palette.primary.dark}`,
      },
    },
    outlinedSecondary: {
      color: `${kivitTheme.palette.secondary.main} !important`,
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 8,
      backgroundColor: "white",
      "& $notchedOutline": {
        borderColor: kivitTheme.palette.grey[500],
      },
      "&:hover": {
        "& $notchedOutline": {
          borderColor: kivitTheme.palette.grey[500],
          borderWidth: 2,
        },
      },
    },
    notchedOutline: {
      borderColor: kivitTheme.palette.primary.main,
    },
  },
  MuiFilledInput: {
    root: {
      backgroundColor: "#FFF",
    },
    inputMarginDense: {
      paddingTop: 6,
      paddingBottom: 6,
    },
  },
  MuiBackdrop: {
    root: {
      background:
        "linear-gradient(107.74deg, rgba(35, 71, 173, 0.9) 0%, rgba(82, 198, 186, 0.9) 48.55%, rgba(118, 120, 237, 0.9) 99.17%)",
    },
  },
  MuiDialog: {
    root: {
      inset: "72px 0 0 0 !important",
    },
    paper: { borderRadius: 12 },
  },
  MuiDialogActions: { root: { padding: 24 } },
  MuiBreadcrumbs: { li: { fontWeight: 700 } },
  MuiMenuItem: {
    root: {
      color: kivitTheme.palette.primary.main,
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  MuiMenu: {
    paper: {
      borderRadius: "6px",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
  MuiListItem: {
    root: {
      "&$selected": {
        backgroundColor: "rgba(0, 112, 158, 0.1)",
        "&:hover": {
          backgroundColor: "rgba(0, 112, 158, 0.2)",
        },
      },
    },
    gutters: {
      paddingLeft: "16px !important",
      paddingRight: "16px !important",
    },
    button: {
      "&:hover": {
        backgroundColor: "rgba(0, 112, 158, 0.1)",
      },
    },
    selected: {},
    secondaryAction: {
      paddingRight: "48px !important",
    },
  },
};

/* -------------------------------------------------------------------------- */
/*                           theme for cumplimiento                           */
/* -------------------------------------------------------------------------- */

export const kivitCumplimiento = Object.assign(
  Object.create(Object.getPrototypeOf(kivitTheme)),
  kivitTheme
);
kivitCumplimiento.palette = {
  ...kivitTheme.palette,
  secondary: { main: "#7678ED", contrastText: "#fff", dark: "#8e90f1" },
};

/* -------------------------------------------------------------------------- */
/*                           theme for reportes                           */
/* -------------------------------------------------------------------------- */

export const kivitReportes = Object.assign(
  Object.create(Object.getPrototypeOf(kivitTheme)),
  kivitTheme
);
kivitReportes.palette = {
  ...kivitTheme.palette,
  secondary: { main: "#52C6BA", contrastText: "#fff", dark: "#28867d" },
};

/* -------------------------------------------------------------------------- */
/*                           theme for new features                           */
/* -------------------------------------------------------------------------- */

export const themeV2 = Object.assign(
  Object.create(Object.getPrototypeOf(kivitTheme)),
  kivitTheme
);
themeV2.palette = { ...kivitTheme.palette, success: { main: "#25c68a" } };
themeV2.overrides = {
  ...kivitTheme.overrides,
  MuiCheckbox: {
    root: {
      color: themeV2.palette.black.main,
      "&$checked": {
        color: themeV2.palette.black.dark,
      },
      padding: "4px",
    },
  },

  MuiRadio: {
    root: {
      color: themeV2.palette.black.main,
      padding: "4px",
    },
    colorSecondary: {
      "&$checked": {
        color: themeV2.palette.primary.main,
      },
    },
  },

  MuiButton: {
    root: {
      borderRadius: "8px",
      textTransform: "unset",
    },
    outlinedPrimary: {
      border: `1px solid ${themeV2.palette.primary.main}`,
      backgroundColor: "white",
      "&:hover": {
        border: `1px solid ${themeV2.palette.primary.dark}`,
      },
    },
    outlinedSecondary: {
      color: `${themeV2.palette.secondary.main} !important`,
    },
    containedSecondary: {
      color: themeV2.palette.primary.main,
    },
  },

  MuiInput: {
    input: {
      "&::placeholder": {
        color: "red",
      },
      color: "red", // if you also want to change the color of the input, this is the prop you'd use
    },
  },

  MuiOutlinedInput: {
    root: {
      borderRadius: 8,
      backgroundColor: "white",
      "& $notchedOutline": {
        borderColor: themeV2.palette.grey[500],
      },
      "&:hover": {
        "& $notchedOutline": {
          borderColor: themeV2.palette.grey[500],
          borderWidth: 2,
        },
      },
    },
    input: {
      "&::placeholder": {
        color: "#000",
        opacity: 0.8,
        fontWeight: 400,
        fontSize: "14px",
      },
    },
    notchedOutline: {
      borderColor: themeV2.palette.primary.main,
    },
  },

  MuiDialog: {
    root: {
      inset: "72px 0 0 0 !important",
    },
    paper: {
      borderRadius: 8,
      //top:-80
    },
  },

  MuiMenuItem: {
    root: {
      color: kivitTheme.palette.black.main,
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 8,
      paddingRight: 8,
      fontSize: "13px",
      fontWeight: 600,
    },
  },
  MuiListItem: {
    root: {
      color: kivitTheme.palette.black.main,
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  MuiMenu: {
    paper: {
      borderRadius: 0,
      padding: 0,
    },
  },
  MuiChip: {
    root: {
      paddingLeft: 0,
    },
  },
};
