import { makeStyles } from '@material-ui/core/styles';
import onboardingBG from "../../../assets/img/OnboardingBG.png";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 'auto',
    minHeight: '840px',
    display: 'start',
    flexDirection: 'column',
    alignItems: 'center',
  },
  options: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  optionButton: {
    borderRadius: '8px 8px 0 0',
    marginLeft: '5px',
    padding: theme.spacing(1, 2),
    cursor: 'pointer',
    color: 'white',
    backgroundColor: '#2347AD',
    border: `2px solid #2347AD`,
    transition: 'background-color 0.3s, border-color 0.3s',
  },
  active: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.common.white,
    color: '#2347AD',
    border: `2px solid #2347AD`,
  },
  separator: {
    width: '100%',
    borderTop: `2px solid #2347AD`,
    marginBottom: theme.spacing(0),
  },
  formContainer: {
    width: '100%',
    padding: theme.spacing(2),
  },
  header: {
    marginTop: '-30px',
    width: '100%',
    display: 'flex', 
  },
  banner: {
    padding: "1%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "inherit",
    color: "white",
  },
}));
