import React from 'react';
import Dropzone from 'react-dropzone';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useInputFormStyles } from '../../hooks/useInputFormStyles';


interface DropzoneFilesProps {
  label?: string;
  file?: File[];
  onChange?: (files: File[]) => void;
  accept?: string;
  dropzoneLabel?: string;
}

function DropzoneFiles(props: DropzoneFilesProps) {
  const classes = useStyles();
  const genClasses = useInputFormStyles();

  return (
    <Box display="flex" flexDirection="column">
      {props.label && (
        <label className={genClasses.fieldLabel} style={{ marginBottom: 10, marginTop: 10 }}>
          {props.label}
        </label>
      )}
      <Dropzone onDrop={(filesToUpload) => props.onChange?.(filesToUpload)}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className={classes.dropzone}>
              <input type="file" {...getInputProps()} accept={props.accept} />
              <Typography variant="subtitle1" color="primary" style={{ fontWeight: 'normal', fontSize: '0.875rem' }}>
                {props.dropzoneLabel ?? 'Arrastra aquí el documento para subirlo'}
              </Typography>
            </div>
          </section>
        )}
      </Dropzone>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  dropzone: {
    cursor: 'pointer',
    backgroundColor: '#F4F4F4',
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: 6,
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '&:focus': {
      outline: 'none'
    }
  },
  alertMessage: {
    marginLeft: '27px',
    marginTop: '28px',
    marginRight: '28px'
  }
}));

export default DropzoneFiles;
