import React, { VFC,useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {ImageByName} from "../comps/helper-document/document-image-by-case.js";
import BlockerLoadPage from "../../general/BlockerLoadPage.jsx";
interface ContractHelpDialogProps {
  open: boolean;
  onClose?: () => void;
  document: any;
}

const ContractHelpDialog: VFC<ContractHelpDialogProps> = (props) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(true);

  const handleClose = () => {
    props.onClose();
  };

  useEffect(() => {
    setTimeout(() =>{
      setOpenDialog(false)
    },300);
  }, [props]);

  return (
    <>
      <Dialog open={props.open} maxWidth="xl">
        <DialogTitle disableTypography className={classes.dialogTitle}>
         <span className={classes.dialogTitleText}>{/*props.document*/}</span>
          <IconButton edge="end" onClick={handleClose}>
            <CloseIcon style={{ color: "#7A92C5" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {ImageByName(props.document) }
          { openDialog && <BlockerLoadPage/>}
        </DialogContent>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles({
  modal :{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContentRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 20,
  },
  dialogTitleText: {
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "21.33px",
  },
  dialogContentText: {
    fontSize: "0.875rem",
    lineHeight: "17px",
    color: "#7A92C5",
    marginBottom: 40,
  },
});
export default ContractHelpDialog;
