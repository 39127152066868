import React, { Component } from 'react';
import { Dialog, Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';

export class AlertOld extends Component {
  render() {
    const { open, title, onConfirm, type } = this.props;

    return (
      <Dialog open={ open }>
        <div style={{padding:"2rem", textAlign:"center"}}>
          <div>
            {
              type === 'success' && 
                <CheckCircleIcon className="text-success dialog-icon" />
            }

            {
              type === 'error' && 
                <ErrorIcon className="text-error dialog-icon" />
            }
          </div>

          <h4>{ title }</h4>

          <div>
            <Button
              color="primary"
              variant="contained" 
              onClick={ () => onConfirm && onConfirm() }
            >
              Aceptar
            </Button>
          </div> 
        </div>
      </Dialog>
    );
  }
}

AlertOld.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onConfirm: PropTypes.func,
  type: PropTypes.string
}
