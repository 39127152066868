import React, { FC } from 'react'
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";

interface PolicyContainerProps {
  onReturn: () => void;
  open: boolean;
  onAccept: () => void;
  onReject: () => void;
  title: string;
}

const PolicyDialog: FC<PolicyContainerProps> = ({ children, onReturn, open, onAccept, onReject, title }) => {
  return (
    <Dialog open={open} maxWidth="xl">
      <DialogTitle>
        <Grid container alignItems="center" >

          <Grid item xs>
            <Typography variant="h3" color="secondary">{title}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              edge="start"
              onClick={onReturn}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent >
        {/* <img className="terms-and-condition-img" src={imgTc} alt="test" /> */}
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default PolicyDialog;
