import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import { Reporte } from "../components/reportes/reportes.types";
import { ReportesService } from "../core/services/reportes.service";
const reportesService = ReportesService.getInstance();

interface State {
  reporte: Reporte;
  getReportes: (clientId: string, providerId: string) => Promise<boolean>;
}

const useReportesState = create<State>((set, get) => ({
  reporte: null,
  getReportes: getReportes(set, get),
}));

export default useReportesState;

//FUNCTIONS
function getReportes(set: NamedSet<State>, get?: GetState<State>) {
  return async (clientId, providerId) => {
    const res = await reportesService.getReportes(clientId, providerId);
    if (res.ok) {
      set({ reporte: (res.data || null) as Reporte });
      return true;
    } else {
      return false;
    }
  };
}
