export const cumplimiento = [
   //'Acta constitutiva',
   '32D Infonavit positivo',
   '32D SAT positivo', 
   '32D IMSS positivo',
   'Determinación de prima de riesgo vigente',
   'SIPARE', 
   'Pago SIPARE',
   'Pago de impuestos sobre nomina ISN', 
   'Comprobante de pago ISN', 
   'Registro REPSE', 
   'Constancia de situación fiscal CSF (RFC)',
   'Constancia de situación fiscal',
   'Comprobante de pago SIPARE',
   //'Comprobante de pago en IMSS (transferencia SIPARE)',
   'Declaración del ISR retenido por salarios',
   'Declaración de impuestos ISR retenido por salarios',
   'Acuse declaración IVA por salarios',
   'Acuse declaración ISR retenido por salarios',
   'Acuse declaración IVA por salarios',
   'Pago ISR retenido por salarios',
   'Pago de impuestos sobre nómina ISN',
   'Comprobante de pago de ISR retenido por salarios',
   'Comprobante de pago de ISR retenido por salarios ',
   'Declaracion del IVA',
   'Declaración de impuestos IVA por salarios', 
   'Pago IVA',
   'Comprobante de pago de IVA',
   'SUA Mensual (EMA)',
   'Liquidación SUA mensual', 
   'SUA Bimestral (EBA)',
   'Liquidación SUA bimestral',
   'Infonavit CFDI',
   'CFDI Infonavit (subir en formato XML)',
   'CFDI Infonavit',
   'CFDI Nónima (subir en formato XML)', 
   'IMSS CFDI',
   'CFDI de servicios (subir en formato XML)',
   'CFDI de factura de servicios',
   'CFDI IMSS (subir en formato XML)',
   'CFDI IMSS',
   'CFDI de servicios',
   'Comprobante de pago de CFDI',
   'CFDI por Comprobante de pago de factura de servicios',
   'Comprobante de pago en IMSS (transferencia SIPARE)',
   'Lista de nómina pagada'
   ];
   
  export const expediente = [
     'Identificación oficial del representante legal vigente',
     'Identificación oficial del rep. legal (vigente)',
     'Comprobante domiciliario (3 meses antigüedad)',
     'Comprobante de domicilio (antigüedad no mayor a 3 meses)',
     'Comprobante de domicilio vigente',
     'Tarjeta patronal vigente',
     'Tarjeta patronal',
     'Constancia de situación fiscal',
     'Registro REPSE',
     'CÉDULA DE DETERMINACIÓN DE CUOTAS "SUA MENSUAL"',
     'CÉDULA DE DETERMINACIÓN DE CUOTAS "SUA BIMESTRAL"',
     'Acuse de ISR retenido por salarios',
     'Declaración de impuesto sobre nómina',
     'Comprobante de pago de impuesto sobre nómina',
     'Acuse de IVA'
   ];
     //  'Acta constitutiva',
     //'Poder notarial'
     
  export const reporteo = [   
     'Acuse ICSOE "IMSS"',
     'Acuse ISCOE "IMSS"',
     'Acuse SISUB "INFONAVIT"',
     ];

