import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect, useState } from "react";

const useToast = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [key, setKey] = useState("initial");

  function showToast(msg: string, severity: "success" | "error" | "warning" | "info") {
    setKey(Math.random().toString());
    setShow(false);
    setSeverity(severity);
    setMessage(msg);
  }

  useEffect(() => {
    if (key !== "initial") {
      setShow(true);
    }
  }, [key]);

  const Toast = () => (
    <>
      {show && message && (
        <Snackbar
          open={true}
          autoHideDuration={10000}
          onClose={() => setShow(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert style={{ marginTop: "1rem" }} variant="filled" onClose={() => setShow(false)} severity={severity as any}>
            <span dangerouslySetInnerHTML={{ __html: message }}></span>
          </Alert>
        </Snackbar>
      )}
    </>
  );

  return { Toast, showToast };
};

export default useToast;
