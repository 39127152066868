import React, { useState } from 'react';
import { Box, Chip, makeStyles, MenuItem, TextField, Tooltip } from '@material-ui/core';
import CancelIcon from "@material-ui/icons/Cancel";
import { useReduxFormStyles } from './useReduxFormStyles';
import { SelectOption } from '../../types/global';

interface Props {
  value: unknown[],
  onChange: (value: unknown) => void,
  name: string,
  touched?: boolean,
  error?: string,
  options: readonly SelectOption[];
  label?: string;
  externLabel?: boolean;
  required?: boolean;
  variant?: "filled" | "standard" | "outlined";
}

const RenderMultipleSelect = ({ options, value, onChange, name, label, externLabel, required, touched, error, variant = "outlined" }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const formClasses = useReduxFormStyles();

  const handleDelete = (e: React.MouseEvent, chipValue: string) => {
    const newValue = value.filter((option: string) => option !== chipValue);
    onChange(newValue);
    //onChange(newValue);
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(e.target.value);
    setOpen(false);
  }

  const Option = (selected: string[]) => (
    <div className={classes.chips}>
      {selected.map((optionValue) => {
        const label = options.find((option) => option.value === optionValue)?.label ?? optionValue;
        return (
          <Tooltip title={label} key={optionValue}>
            <Chip
              className={classes.chip}
              color="primary"
              deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
              key={optionValue}
              label={label}
              onDelete={(e) => handleDelete(e, optionValue)}
              size="small"
            />
          </Tooltip>
        );
      })}
    </div>
  );

  const RootComponent = externLabel ? Box : React.Fragment;
  const rootComponentProps = externLabel ? {display: 'inline-flex', width:'100%', flexDirection:'column' } : null;

  return (
    <RootComponent {...rootComponentProps}>
      {!!label && externLabel && <label className={formClasses.fieldLabel}>{label}</label>}
      <TextField
        name={name}
        id="outlined-select-currency"
        size="small"
        margin="none"
        select
        fullWidth
        error={touched && !!error}
        helperText={touched && error}
        SelectProps={{
          multiple: true,
          renderValue: Option,
          open,
          onOpen: () => setOpen(true),
          onClose: () => setOpen(false)
        }}
        label={!!label && !externLabel ? label : undefined}
        placeholder={label}
        required={required}
        value={value}
        onChange={handleChange}
        variant={variant}
      >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label ?? option.value}
        </MenuItem>
      ))}
    </TextField>
    </RootComponent>
  )
}

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    maxWidth: 80
  }
}));

export default RenderMultipleSelect;
