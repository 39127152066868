import { Box, MenuItem, TextField } from '@material-ui/core';
import { SelectOption } from 'materialidad-outsourcing/dist/types';
import React from 'react';
import { useInputFormStyles } from '../../hooks/useInputFormStyles';


interface Props {
  id?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  name?: string;
  label?: string;
  touched?: boolean;
  error?: string;
  required?: boolean;
  options: readonly SelectOption[];
  externLabel?: boolean;
}

const RenderSelect = ({ label, value, onChange, name, required, options, externLabel, error, touched, id }: Props) => {
  const formClasses = useInputFormStyles();
  const RootComponent = externLabel ? Box : React.Fragment;
  const rootComponentProps = externLabel ? { display: 'inline-flex', width: '100%', flexDirection: 'column' } : null;

  return (
    <RootComponent {...rootComponentProps}>
      {!!label && externLabel && <label className={formClasses.fieldLabel}>{`${label} ${required ? '*' : ''}`}</label>}
      <TextField
        value={value}
        onChange={onChange}
        id={id}
        name={name}
        size="small"
        margin="dense"
        select
        fullWidth
        label={!!label && !externLabel ? label : undefined}
        placeholder={label}
        required={required}
        variant="outlined"
        error={touched && !!error}
        helperText={touched && error}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </RootComponent>
  );
};

export default RenderSelect;
