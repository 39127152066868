import { Dialog, DialogContent, DialogTitle, Grid, makeStyles, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useState } from "react";
import { Sat69Service } from "../../core/services/sat69.service";
import { validRfc } from "../../core/utils/utils";
import RenderTextField from "../inputs/RenderTextField";
import MatFisButton from "../stages/comps/MatFisButton";

const Rfc69Modal = ({ open, onClose, onSubmit }) => {
  const classes = useStyles();
  const [rfc, setRfc] = useState("");
  const [formatError, setFormatError] = useState(false);

  function handleChange(event) {
    setRfc(event.target.value);
  }

  async function handleSubmit() {
    if (!validRfc(rfc)) {
      setFormatError(true);
      return;
    } else {
      setFormatError(false);
    }
    const res = await Sat69Service.getInstance().getRfcInfo(rfc);
    onSubmit(rfc, res?.respuesta[0]);
  }

  return (
    <Dialog open={true} maxWidth="xs">
      <button onClick={onClose} style={{ all: "unset", cursor: "pointer", position: "absolute", right: "10px", top: "10px" }}>
        <CloseIcon htmlColor="#70757A" />
      </button>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography
          color="primary"
          style={{ fontWeight: 400, fontSize: "45px", lineHeight: "1", textAlign: "center", marginTop: "1.5rem" }}
        >
          Consulta un nuevo proveedor
        </Typography>
      </DialogTitle>
      <DialogContent style={{ padding: "0 3rem", position: "relative" }}>
        <div style={{ fontSize: "18px", textAlign: "center", maxWidth: "250px", margin: "0 auto", lineHeight: "1" }}>
          Escribe el RFC del proveedor para saber si pertenece o no a la lista negra del SAT
        </div>
        <form onSubmit={(e) => e.preventDefault()} className={classes.dialogContentRoot}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <RenderTextField
                id="employeesCount"
                value={rfc}
                onChange={handleChange}
                touched={Boolean(rfc)}
                placeholder="RFC"
                error={formatError ? "Formato invalido" : ""}
              />
            </Grid>
          </Grid>
          <MatFisButton variant="contained" color="primary" disabled={false} type="submit" onClick={handleSubmit}>
            Consultar
          </MatFisButton>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContentRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1rem",
    gap: "1rem",
    marginBottom: "2rem",
  },
  dialogContentText: {
    fontSize: "0.875rem",
    lineHeight: "17px",
    color: "#7A92C5",
    marginBottom: 40,
  },
});

export default Rfc69Modal;
