import { Grid } from "@material-ui/core";
import { FormikErrors, FormikTouched } from "formik";
import ProfileInput from "./ProfileInput";

type ContactsFormProps = {
  rootName: string;
  values: ContactList;
  errors: FormikErrors<ContactList>;
  touchedFields: FormikTouched<ContactList>;
  handleChangeField: (e: React.ChangeEvent<any>) => void;
  isEditing?: boolean;
};

const ContactsForm = ({ rootName, isEditing, values, errors, touchedFields, handleChangeField }: ContactsFormProps) => {
  return (
    <Grid container alignItems="center" spacing={1}>
      <ProfileInput
        label="Nombre(s)"
        id={`${rootName}.name`}
        error={errors?.name}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields?.name}
        value={values.name}
      />

      <ProfileInput
        label="Apellido Paterno"
        id={`${rootName}.lastName`}
        error={errors?.lastName}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields?.lastName}
        value={values.lastName}
      />

      <ProfileInput
        label="Apellido Materno"
        id={`${rootName}.motherLastName`}
        error={errors?.motherLastName}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields?.motherLastName}
        value={values.motherLastName}
      />

      <ProfileInput
        label="Email"
        id={`${rootName}.email`}
        error={errors?.email}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields?.email}
        value={values.email}
      />

      <ProfileInput
        label="Teléfono"
        id={`${rootName}.phone`}
        error={errors?.phone}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields?.phone}
        value={values.phone}
      />
    </Grid>
  );
};

export default ContactsForm;
