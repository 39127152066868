import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
//import { Stage, StageLite } from "../core/models/stage.models";
import { GeneradorClientServices } from "../../core/services/generadorClient.service";
import { GeneradorCatalogue } from "../../core/services/generadorCatalogue.service";

const generadorService = GeneradorClientServices.getInstance();
const catalogueService = GeneradorCatalogue.getInstance();

interface State {
  catalogues: any;
  currentCatalogue: any;
  catalogueFileJson: any;
  generators: any;
  generatorsLite: any;
  currentGenerator: any;
  externalTreeCatalogue: any;
  setCurrentCatalogue: (catalogue: any) => void;
  setCurrentGenerator: (generator: any) => void;
  setCurrentCatalogueFileJson: (json: any) => void;
  setExternalTreeCatologue: (data : any) => void;
  createCatalogue: (documentId : string , body : any) => Promise<any>;
  getCatalogues: (companyId?: string) => Promise<any>;
  enableCatalogue: (catalogueId: string) => Promise<any>;
  disableCatalogue: (catalogueId: string) => Promise<any>;
  deleteCatalogue: (catalogueId: string) => Promise<any>;
  getCatalogueJson: (companyId: string) => Promise<any>;
  getGenerators: (companyId: string) =>Promise<any>;
  getGeneratorsLite: (companyId: string) =>Promise<any>;
  getExternalTreeCatalogue : (companyId : string) => Promise<any>;
}

const useGeneratorClientState = create<State>((set, get) => ({
    catalogues: null,
    currentCatalogue: null,
    catalogueFileJson: null,
    generators: null,
    generatorsLite: null,
    currentGenerator: null,
    externalTreeCatalogue: null,
    setCurrentCatalogue:  (catalogue: any) => set({ currentCatalogue: catalogue }),
    setCurrentGenerator:  (generator: any) => set({ currentGenerator: generator }),
    setCurrentCatalogueFileJson:  (json: any) => set({ setCurrentCatalogueFileJson: json }),
    setExternalTreeCatologue: (data : any) => set({ externalTreeCatalogue: data }),
    createCatalogue: createCatalogue(set, get),
    getCatalogues: getCatalogues (set, get),
    enableCatalogue: enableCatalogue (set, get),
    disableCatalogue: disableCatalogue(set, get),
    deleteCatalogue: deleteCatalogue(set, get),
    getCatalogueJson: getCatalogueJson(set, get),
    getGenerators: getGenerators(set, get),
    getGeneratorsLite:getGeneratorsLite(set, get),
    getExternalTreeCatalogue : getExternalTreeCatalogue(set, get)
}));

export default useGeneratorClientState;

function getGenerators(set: NamedSet<State>, get?: GetState<State>) {
  return async (companyId) => {
    const res = await generadorService.getAllGeneradores(companyId);
    if (res?.codigo === 0) {
      set({ generators: res.respuesta });
      return true;
    }
    return false;
  };
}

function getGeneratorsLite(set: NamedSet<State>, get?: GetState<State>) {
    return async (companyId) => {
      const res = await generadorService.getAllLiteGeneradores(companyId);
      if (res?.codigo === 0) {
        set({ generatorsLite: res.respuesta });
        return true;
      }
      return false;
    };
  }

  function createCatalogue(set: NamedSet<State>, get?: GetState<State>) {
    return async (companyId, body) => {
      const res = await catalogueService.createCatalogue(companyId,body);
      if (res?.codigo === 0) {
        //set({ generators: res.respuesta });
        return true;
      }
      return false;
    };
  }

  function getCatalogues(set: NamedSet<State>, get?: GetState<State>) {
    return async (companyId) => {
      const res = await catalogueService.getAllCatalogue(companyId);
      if (res?.codigo === 0) {
        set({ catalogues: res.respuesta });
        return true;
      }
      return false;
    };
  }

  function enableCatalogue(set: NamedSet<State>, get?: GetState<State>) {
    return async (catalogueId) => {
      const res = await catalogueService.enableCatalogue(catalogueId);
      if (res?.codigo === 0) {
        return true;
      }
      return false;
    };
  }

  function disableCatalogue(set: NamedSet<State>, get?: GetState<State>) {
    return async (catalogueId) => {
      const res = await catalogueService.disabledCatalogue(catalogueId);
      if (res?.codigo === 0) {
        return true;
      }
      return false;
    };
  }

  function deleteCatalogue(set: NamedSet<State>, get?: GetState<State>) {
    return async (catalogueId) => {
      const res = await catalogueService.deleteCatalogue(catalogueId);
      if (res?.codigo === 0) {
        return true;
      }
      return false;
    };
  }

  function getCatalogueJson(set: NamedSet<State>, get?: GetState<State>) {
    return async (catalogueId) => {
      const res = await catalogueService.getCatalogueJson(catalogueId);
      if (res?.codigo === 0) {
        set({ catalogueFileJson: res.respuesta }) ;      
        return res.respuesta;
      }
      return res;
    };
  }
  
  function getExternalTreeCatalogue(set: NamedSet<State>, get?: GetState<State>) {
    return async (companyId) => {
      const res = await catalogueService.getExternalTreeCatalogue(companyId);
      if (res?.codigo === 0) {
        set({ externalTreeCatalogue : res.respuesta }) ;      
        return res.respuesta;
      }
      return res;
    };
  }
  