import React, { useEffect, useState } from "react";
import { Dialog, Grid, Button, LinearProgress } from "@material-ui/core";

import { ServicesService } from "../../../core/services/services.service";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "../../inputs/inputs-v2/RenderTextFieldV2";
import moment from "moment";
import { TypeForm } from "../../../types/global";
import useGlobalState from "../../../store/globalState";
import useDashboardUsersState from "../../../store/dashboardUsersState";
import RenderSelectV2 from "../../inputs/inputs-v2/RenderSelectV2";
import usePlanState from "../../../store/planState";
import useCustomToast from "../../../core/hooks/useCustomToast";

interface DialogFormServiceProps {
  typeForm: TypeForm;
  serviceSelected: Service | null;
  submitActions: () => void;
  open: boolean;
  onCancel: () => void;
  collaborator: any; //CompanyCollaborator;
  collaboratoruserType: "proveedor" | "cliente";
}

type FormFields = {
  name: string;
  serviceTypeId: string;
  serviceInitDate: string;
  serviceEndDate: string;
};

const DialogFormService = ({
  serviceSelected,
  typeForm,
  open,
  onCancel,
  collaborator,
  submitActions,
  collaboratoruserType,
}: DialogFormServiceProps) => {
  const toast = useCustomToast();
  const { serviceTypes, setServiceTypes } = useDashboardUsersState();
  const { mainCompany, setAlertInfo } = useGlobalState();
  const [saving, setSaving] = useState(false);
  const { plan } = usePlanState();

  useEffect(() => {
    if (!serviceTypes.length) {
      getServiceTypes();
    }

    // if (typeForm === 'update') {
    //   servicesHttp.findServiceById(
    //     serviceSelected.id,
    //     (resp: any) => {
    //       props.initialize({
    //         name: resp ? resp.name : '',
    //         serviceTypeId: resp ? resp.serviceTypeId : '',
    //         serviceEndDate: resp ? resp.serviceEndDate.substring(0, 10) : '',
    //       });
    //     },
    //     console.log
    //   );
    // } else {
    //   props.initialize({ serviceEndDate: new Date().toISOString().substring(0, 10) });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServiceTypes = async () => {
    const { data } = await ServicesService.getInstance().findAllGeneratorServiceTypes(mainCompany?.id);
    if (data) {
      const mappedServiceTypes = data.map((item: { id: any; name: any }) => {
        return { value: item.id, label: item.name };
      });
      setServiceTypes(mappedServiceTypes);
    }
  };

  const formik = useFormik<FormFields>({
    initialValues: {
      name: serviceSelected?.name || "",
      serviceTypeId: serviceSelected?.serviceTypeId || "",
      serviceInitDate: serviceSelected?.serviceInitDate ? moment(serviceSelected?.serviceInitDate).add(7, "hour").format("YYYY-MM-DD") : "", //TODO: revisar
      serviceEndDate: serviceSelected?.serviceEndDate ? moment(serviceSelected?.serviceEndDate).add(7, "hour").format("YYYY-MM-DD") : "", //TODO: revisar
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      name: Yup.string().required("Campo requerido"),
      serviceEndDate: Yup.string().required("Campo requerido"),
    }),
  });

  async function handleSubmit({ name, serviceTypeId, serviceEndDate, serviceInitDate }: FormFields) {
    setSaving(true);
    //validate valid date
    const initDate = moment(serviceInitDate);
    const endDate = moment(serviceEndDate);

    if (!initDate.isValid()) {
      formik.setFieldError("serviceInitDate", "Fecha inválida");
      setSaving(false);
      return;
    }

    if (!endDate.isValid()) {
      formik.setFieldError("serviceEndDate", "Fecha inválida");
      setSaving(false);
      return;
    }

    if (endDate.isBefore(initDate)) {
      formik.setFieldError("serviceEndDate", "La fecha de finalización debe ser mayor a la fecha de inicio");
      setSaving(false);
      return;
    }

    const body: CreateServiceReqBodyV2 = {
      name,
      serviceTypeId: serviceTypeId,
      serviceInitDate: new Date(`${serviceInitDate}:`).toISOString(),
      serviceEndDate: new Date(`${serviceEndDate}:`).toISOString(),
      provider: collaboratoruserType === "proveedor" ? collaborator.companySelf?.id : mainCompany?.id,
      companyId: collaboratoruserType === "proveedor" ? mainCompany?.id : collaborator.companySelf?.id,
      platform: "KIVIT",
    };
    console.log("Enmtro",body)

    if (typeForm === "create") {
      try {
       const x = await ServicesService.getInstance().createGeneratorService(body);  

       console.log("x",x)
        return submitActions();
      } catch (error) {
        //@ts-ignore
        if (error.isAxiosError) {
          const { response } = error;
          if (response?.data?.message?.includes("Conflict Exception (409)")) {
            //nombre duplicado
            formik.setFieldError("name", "Ya existe un servicio con este nombre");
          } else {
            setAlertInfo({
              type: "error",
              message: "Hubo un error al registrar el servicieeo",
            });
          }
        } else {
          setAlertInfo({
            type: "error",
            message: "Hubo un error al registrar el servicio",
          });
        }
      }
    } else if (typeForm === "update") {
      if (!serviceSelected) return;
      try {
        const data = await ServicesService.getInstance().updateGeneratorService(serviceSelected.id, { name, serviceEndDate, serviceInitDate });
        if (data?.ok) {
          return submitActions();
        } else {
          throw new Error();
        }
      } catch (error: any) {
        //@ts-ignore
        if (error.isAxiosError) {
          const { response } = error;
          if (response?.data?.message?.includes("Conflict Exception (409)")) {
            //nombre duplicado
            formik.setFieldError("name", "Ya existe un servicio con este nombre");
          } else {
            console.log(error);
            toast.error("Ocurrío un error inesperado");
          }
        }
      }
    }

    setSaving(false);
  }

  return (
    <Dialog maxWidth="sm" open={open}>
      <form style={{ padding: "2rem" }} onSubmit={(e) => e.preventDefault()}>
        {saving && <LinearProgress style={{ marginBottom: "10px" }} />}
        <Grid container>
          <Grid item xs={12}>
            <h3>{typeForm === "update" ? "Editar" : "Crear"} Servicio</h3>
            <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <TextField
                error={formik.errors.name}
                onChange={formik.handleChange}
                touched={formik.touched.name}
                value={formik.values.name}
                name="name"
                placeholder="Nombre aquí"
                label="Nombre del servicio"
                size="small"
              />

              {plan === "CORPORATIVO" && (
                <RenderSelectV2
                  margin="none"
                  size="small"
                  disabled={typeForm === "update"}
                  defaultValue={serviceSelected?.serviceTypeId || ""}
                  name="serviceTypeId"
                  touched={formik.touched.serviceTypeId}
                  error={formik.errors.serviceTypeId}
                  label="Tipo de servicio"
                  onChange={(e: any) => {
                    formik.setFieldValue("serviceTypeId", e.target.value);
                  }}
                >
                  <>
                    <option value="" disabled>
                      Seleccionar tipo de servicio&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </option>
                    {serviceTypes?.length &&
                      serviceTypes.map((item) => {
                        return (
                          <option style={{ textTransform: "capitalize" }} key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                  </>
                </RenderSelectV2>
              )}
              <TextField
                size="small"
                type="date"
                error={formik.errors.serviceInitDate}
                onChange={formik.handleChange}
                touched={formik.touched.serviceInitDate}
                value={formik.values.serviceInitDate}
                placeholder="Fecha inicio del servicio"
                name="serviceInitDate"
                label="Fecha inicio del servicio"
              />

              <TextField
                size="small"
                type="date"
                error={formik.errors.serviceEndDate}
                onChange={formik.handleChange}
                touched={formik.touched.serviceEndDate}
                value={formik.values.serviceEndDate}
                placeholder="Fecha fin del servicio"
                name="serviceEndDate"
                label="Fecha fin del servicio"
              />
            </div>
          </Grid>

          <Grid className="mt-3" container justifyContent="flex-end">
            <React.Fragment>
              <Button type="button" variant="outlined" color="primary" onClick={() => onCancel()} disabled={saving}>
                Cancelar
              </Button>

              <Button
                onClick={() => formik.handleSubmit()}
                className="ml-2"
                type="button"
                variant="contained"
                color="primary"
                disabled={saving}
              >
                Guardar
              </Button>
            </React.Fragment>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default DialogFormService;
