/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { Button,
  Grid,
  IconButton, 
  Typography,
  makeStyles,
  Backdrop,
  CircularProgress,
  FormControl, 
  Select as MatSelect,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "../../../inputs/inputs-v2/RenderTextFieldV2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CompaniesService } from "../../../../core/services/companies.service";
import { FormEntityType } from "../../../../types/global";
import { CreateCompanyReqBody } from "../../../../types/companies-service";
import useGlobalState from "../../../../store/globalState";
import useCustomToast from "../../../../core/hooks/useCustomToast";
import useDashboardUsersState from "../../../../store/dashboardUsersState";

const emailReg = new RegExp(
  /^(([^<>()\[\]\.,;:\s@\']+(\.[^<>()\[\]\.,;:\s@\']+)*)|(\'.+\'))@(([^<>()[\]\.,;:\s@\']+\.)+[^<>()[\]\.,;:\s@\']{1,})$/i
);

type Props = {
  onCancel: (args?: any) => any;
  onSubmit: (args?: any) => any;
  formType: FormEntityType;
};

type FormFields = {
  name: string;
  email: string;
  phone: string;
  providerGeneradorId: string;
  externalSapId: string
  catalogs: string[];
};

const LIST_CATALOGS = 
[
"Banamex Fibra óptica",
"Banamex Materiales",
"Adecuaciones (E)",
"Radio Base",
"Microondas",
"Fibra óptica (PE) servicios",
"Fibra óptica (P)E Materiales",
"Destajo CHC",
"Mano de Obra CHC"
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function FormCreateNewUser({ onCancel, onSubmit, formType }: Props) {
  const classes = useStyles();
  const { mainCompany, getMainCompany } = useGlobalState();
  const toast = useCustomToast();
  const { activatedGenerator } = useDashboardUsersState();

  const [loading, setLoading] = useState(false);

  const [catalogsSelected, setCatalogs] = React.useState([" "]);
  
  const pdfBlobUrl = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'; // URL de muestra para el PDF

  const handleChange = (event) => {
    const values: string[] = event.target.value;
    if (values.length === 0) {
      setCatalogs([" "]);
    } else {
      setCatalogs(values.filter((e) => e !== " "));
    }
  };
  
  const handleSubmit = async (values: FormFields) => {
    const body: CreateCompanyReqBody = {
      rfc: "",
      enable: true,
      status: "TEMP",
      platform: "KIVIT",
      externalId: values.providerGeneradorId,
      idProvider: mainCompany.id,
      externalSapId: values.externalSapId,
      externalTreeCatalogue:{
        catalogue: values.catalogs,
      },
      user: {
        username: values.email,
        name: values.name,
        rfc: "",
        email: values.email,
        roles: ["Admin"],
        status: true,
        phone: values.phone,
      }
    };

    //invita como cliente o proveerdor segun corresponda
    if (formType === "provider") {
      body.clients = [
        {
          clientId: mainCompany?.id,
        },
      ];
    } else if (formType === "user") {
      body.providers = [
        {
          providerId: mainCompany?.id,
        },
      ];
    }

    setLoading(true);
    try {
      const res = await CompaniesService.getInstance().createCompanyInit(body, activatedGenerator);
      if (res.id) {
        setLoading(false);
        onSubmit(true);

        if (!activatedGenerator) {
          // evitar perder valores de vistas generador
         getMainCompany();
      }
       // getMainCompany();
      } else {
        throw new Error();
      }
    } catch (error) {
      toast.error("Ocurrio un error inesperado");
    }

    setLoading(false);
  };

  const formik = useFormik<FormFields>({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      providerGeneradorId: "",
      externalSapId:"",
      catalogs: ['']
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      name: Yup.string().required("Campo requerido"),
      email: Yup.string().matches(emailReg, { message: "Formato inválido" }).required("Campo requerido"),
      phone: activatedGenerator ? Yup.string().required("Campo requerido") : null,
      providerGeneradorId: activatedGenerator ? Yup.string().required("Campo requerido") : null,
      externalSapId: activatedGenerator ? Yup.string().required("Campo requerido") : null,
      catalogs: activatedGenerator ? Yup.array(Yup.string().min(2, "Seleccione almenos 1 servicio")).required("Campo requerido") : null,
    }),
  });
console.log("#activatedGenerator 3",activatedGenerator)
console.log("mainCompany",mainCompany)

useEffect(() => {
  formik.setFieldValue("catalogs", catalogsSelected);
  console.log(formik.values);
}, [catalogsSelected]);

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container>
        <Grid item md={12}>
          <Grid container xs={12} justifyContent="space-between" alignItems="center">
            <Typography className={classes.title}>{`Agregar ${
              formType === "provider" ? "proveedor" : "cliente"
            } ${activatedGenerator ? 'generador' : ''} nuevo`}</Typography>
            <IconButton aria-label="close" onClick={() => onCancel()}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Grid>

          <div className={classes.subContainer}>
            <Typography className={classes.subtitle}>
              {`Para empezar a trabajar con un nuevo ${
                formType === "provider" ? "proveedor" : "cliente"
              }, necesitamos capturar los datos de la persona de contacto en la
                empresa ${
                  formType === "provider" ? "proveedora" : "cliente"
                }. Esta persona recibirá un correo con los pasos a seguir para crear su cuenta en la plataforma.`}
            </Typography>

            <div className="mt-4 mb-2">
              <Typography className={classes.subtitle}>Datos del Administrador / Contacto de la empresa.</Typography>
            </div>

            <TextField
              error={formik.errors.name}
              onChange={formik.handleChange}
              touched={formik.touched.name}
              value={formik.values.name}
              placeholder="Nombre completo *"
              name="name"
            />

            <TextField
              error={formik.errors.email}
              onChange={formik.handleChange}
              touched={formik.touched.email}
              value={formik.values.email}
              placeholder="Correo Electŕonico *"
              name="email"
            />  

          {activatedGenerator && (
            <TextField
            error={formik.errors.providerGeneradorId}
            onChange={formik.handleChange}
            touched={formik.touched.providerGeneradorId}
            value={formik.values.providerGeneradorId}
            placeholder="Id Proveedor *"
            name="providerGeneradorId"
          />
          )}

           {activatedGenerator && (
            <TextField
            error={formik.errors.externalSapId}
            onChange={formik.handleChange}
            touched={formik.touched.externalSapId}
            value={formik.values.externalSapId}
            placeholder="Id Sap *"
            name="externalSapId"
          />
          )}
   
          <TextField
              error={formik.errors.phone}
              onChange={formik.handleChange}
              touched={formik.touched.phone}
              value={formik.values.phone}
              placeholder="Teléfono *"
              name="phone"
              type="number"
            />
            
         {activatedGenerator && (
          <FormControl variant="outlined" fullWidth margin="dense" error={!!formik.touched.catalogs && !!formik.errors.catalogs}>
              <MatSelect
                name="catalogs"
                value={formik.values.catalogs}
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" />}
                renderValue={(selected: any) => {
                  return (
                    <div>
                      {selected[0] === " " && <span>Catálogos ofrecidos (uno o varios) *</span>}
                      {selected.map(
                        (value: string, idx: number) =>
                          !!value &&
                          value !== " " && (
                            <span key={value}>
                              {idx > 0 && ", "}
                              {value.substr(0, 24)} {value.length > 24 && "..."}
                            </span>
                          )
                      )}
                    </div>
                  );
                }}
                MenuProps={MenuProps}
              >
                {LIST_CATALOGS.map((name) => (
                <option
                key={name}
                value={name}
                style={{
                  backgroundColor: `${catalogsSelected.includes(name) ? "#3297FD" : "unset"}`,
                  color: `${catalogsSelected.includes(name) ? "#fff" : "unset"}`,
                  cursor: "pointer",
                }}
              >
                {name}
              </option>
            ))}
              </MatSelect>
              <FormHelperText>{!!formik.touched.catalogs && formik.errors.catalogs}</FormHelperText>
            </FormControl>
            )}

          </div>

          <Grid container xs={12} justifyContent="center">
            <div className="text-center">
              <Typography style={{ display: "block" }} variant="caption">
                * Campos obligatorios
              </Typography>
              <Button className="mt-2" type="submit" variant="contained" color="primary" disabled={loading}>
                ENVIAR
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop style={{ zIndex: 2000 }} open={loading} onClick={() => {}}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  subtitle: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  subContainer: {
    marginBottom: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

export default FormCreateNewUser;
