import { SvgIcon, SvgIconProps } from '@material-ui/core';

const UserAdminIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="26" height="26" viewBox="0 0 26 26">
      <path d="M13 0C15.5262 0.00479549 17.9964 0.743471 20.1099 2.12605C22.2234 3.50864 23.8888 5.47545 24.9034 7.78693C25.918 10.0984 26.2379 12.6548 25.8241 15.1447C25.4104 17.6346 24.2809 19.9506 22.5732 21.8105C20.231 24.3471 16.9763 25.8503 13.525 25.9894C10.0736 26.1285 6.70826 24.8922 4.16923 22.5523C3.9089 22.3182 3.66108 22.0706 3.42682 21.8105C1.71911 19.9506 0.589605 17.6346 0.175875 15.1447C-0.237856 12.6548 0.0820446 10.0984 1.09661 7.78693C2.11118 5.47545 3.77663 3.50864 5.89009 2.12605C8.00356 0.743471 10.4738 0.00479549 13 0ZM3.9999 18.2188C5.16362 16.23 6.9547 14.6831 9.09258 13.8203C8.4294 13.0689 7.99729 12.1424 7.8481 11.1518C7.69891 10.1612 7.83899 9.14859 8.2515 8.23554C8.66403 7.3225 9.33147 6.54778 10.1738 6.00435C11.016 5.46092 11.9974 5.17186 13 5.17186C14.0026 5.17186 14.984 5.46092 15.8262 6.00435C16.6685 6.54778 17.336 7.3225 17.7485 8.23554C18.161 9.14859 18.3011 10.1612 18.1519 11.1518C18.0027 12.1424 17.5706 13.0689 16.9074 13.8203C19.0453 14.6831 20.8364 16.23 22.0001 18.2188C22.9281 16.6406 23.4182 14.8438 23.4198 13.0134C23.4198 10.2523 22.322 7.60431 20.3679 5.65192C18.4138 3.69953 15.7635 2.60269 13 2.60269C10.2365 2.60269 7.58619 3.69953 5.6321 5.65192C3.67801 7.60431 2.58021 10.2523 2.58021 13.0134C2.58182 14.8438 3.07189 16.6406 3.9999 18.2188Z" />
    </SvgIcon>
  )
}



export default UserAdminIcon;