import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC, useEffect } from "react";

interface MatDialogProps {
  open: boolean;
  action: () => void;
  actionText?: string;
  onClose?: () => void;
  title: string;
  width?: number | string;
  height?: number | string;
}

const MatDialog: FC<MatDialogProps> = (props) => {
  const classes = useStyles(props);

  useEffect(() => {
    const container = document.getElementById("root");
    if (props.open) container!.className = "main-content-blurred";
    return () => {
      container!.className = "";
    };
  }, [props.open]);

  return props.open ? (
    <Dialog open={props.open} fullWidth maxWidth="md" classes={{ paper: classes.rootDialog }}>
      <DialogTitle disableTypography className={classes.matDialogTitle}>
        <Box fontSize="h3.fontSize" color="primary.main">
          {props.title}
        </Box>
        <IconButton size="small" aria-label="close" onClick={props.onClose}>
          <CloseIcon fontSize="medium" />
        </IconButton>
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      {!!props.action && (
        <DialogActions>
          <Button variant="contained" color="primary" disabled={!props.action} onClick={props.action}>
            {props.actionText ?? "Aceptar"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  ) : null;
};

const useStyles = makeStyles({
  rootDialog: (props: MatDialogProps) => ({
    width: props.width,
    height: props.height,
  }),
  matDialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default MatDialog;
