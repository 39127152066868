import React from "react";
import { Avatar, Box, Collapse, IconButton, makeStyles, Table, TableBody, TableCell, TableRow, Tooltip } from "@material-ui/core";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useCustomToast from "../../../../core/hooks/useCustomToast";

import { currencyFormat } from "../../utils";
import { CDFIData } from "../../types";

const CollapsableRow = (props: CDFIData) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const toast = useCustomToast();

  const formatCFDIValues = (status: string, value?: number) => {
    // eslint-disable-next-line use-isnan
    if (value === undefined) {
      if (status === "Bajo revisión") return "-";
      else return "MX$0.00";
    } else return currencyFormat(value ?? 0);
  };

  const handleClipBoard = (name: string) => {
    navigator.clipboard.writeText(name);
    toast.success(`Se ha copiado el texto ${name}`);
  };

console.log("styles mod")
  return (
    <>
      <TableRow className={props.validation.status === "Rechazado" ? classes.errorRow : undefined}>
        <TableCell align="center" padding="none">
          <Tooltip arrow title="Ver detalle">
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>{props.completeName}</TableCell>
        <TableCell padding="none">
          <Box display="flex" justifyContent="center" gridGap={7}>
            <div onClick={() => handleClipBoard(props.imms)}>
              <Tooltip arrow title={props.imms}>
                <Avatar className={`${classes.avatarIds} ${props.validation.status === "Rechazado" ? classes.errorAvatar : classes.indicatorAvatar}`}>NSS</Avatar>
              </Tooltip>
            </div>
            <div onClick={() => handleClipBoard(props.curp)}>
              <Tooltip arrow title={props.curp}>
                <Avatar className={`${classes.avatarIds} ${props.validation.status === "Rechazado" ? classes.errorAvatar : classes.indicatorAvatar}`}>CURP</Avatar>
              </Tooltip>
            </div>
            <div onClick={() => handleClipBoard(props.rfc)}>
              <Tooltip arrow title={props.rfc}>
                <Avatar className={`${classes.avatarIds} ${props.validation.status === "Rechazado" ? classes.errorAvatar : classes.indicatorAvatar}`}>RFC</Avatar>
              </Tooltip>
            </div>
          </Box>
        </TableCell>
        <TableCell align="center">{formatCFDIValues(props.validation.status, props.dailySalary)}</TableCell>
        <TableCell align="center">{formatCFDIValues(props.validation.status, props.sbc)}</TableCell>

        {/* <TableCell align="center" padding="none">
          <StatusButton
            hasTooltip={!!props.validation.comments}
            bgColor={"warning"}
            tooltipTitle={props.validation.comments}
          >
            {props.validation.status}
          </StatusButton>
        </TableCell> */}

        {/* <TableCell>
          {props.validation.status === "Rechazado" && (
            <IconButton size="small">
              <EditIcon fontSize="small" htmlColor="#f44336" />
            </IconButton>
          )}
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
             
              <Table size="small" className={classes.subtable}>
                <TableBody>
                  <TableRow className={classes.rowBottomBordered}>
                    <TableCell component="th" padding="none" style={{textTransform: 'uppercase'}}>
                     {/*PERCEPCIÓN MENSUAL*/} PERIODICIDAD {props.periodicidadPagoDescripcion}
                    </TableCell>
                    <TableCell>NÚMERO DE DÍAS PAGADOS</TableCell>
                    <TableCell><span style={{alignItems:'center',paddingLeft:'20px'}}>{props.numDiasPagados}</span></TableCell>  
                  </TableRow>

               

                  <TableRow>
                    <TableCell component="th" padding="none" rowSpan={7}>
                      {/*DEDUCCIONES*/} DETALLE DE CFDI
                    </TableCell>
                    <TableCell>OTRAS DEDUCCIONES </TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status, props.totalOtrasDeducciones)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>IMPUESTOS RETENIDOS</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status, props.totalImpuestosRetenidos)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{fontWeight:'bold'}}>DESCUENTO</TableCell>
                    <TableCell style={{fontWeight:'bold'}}>{formatCFDIValues(props.validation.status, props.descuento)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>OTROS PAGOS</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status, props.totalOtrosPagos)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>SUELDO</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status, props.sueldo)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{fontWeight:'bold'}}>SUBTOTAL</TableCell>
                    <TableCell style={{fontWeight:'bold'}}>{formatCFDIValues(props.validation.status, props.subTotal)}</TableCell>
                  </TableRow>
                  {/**<TableRow>
                    <TableCell>INFONAVIT</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status, props.infonavitDeduction)}</TableCell>
                  </TableRow> */}
                  <TableRow className={classes.rowBottomBordered}>
                    <TableCell align="right">TOTAL</TableCell>
                    <TableCell>
                    {formatCFDIValues(props.validation.status, props.total )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" padding="none" rowSpan={3}>
                      {/*DEDUCCIONES*/} IMPUESTOS RETENIDOS
                    </TableCell>
                    <TableCell>ISR</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status, props.isrDeduction)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>IMSS</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status, props.imssDeduction)}</TableCell>
                  </TableRow>
                  {/**<TableRow>
                    <TableCell>INFONAVIT</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status, props.infonavitDeduction)}</TableCell>
                  </TableRow> */}
                  <TableRow className={classes.rowBottomBordered}>
                    <TableCell align="right">TOTAL</TableCell>
                    <TableCell>
                      {formatCFDIValues(
                        props.validation.status,
                        (parseFloat(props.isrDeduction ? props.isrDeduction : 0) ?? 0) + (parseFloat(props.imssDeduction ? props.imssDeduction : 0) ?? 0) + (parseFloat(props.infonavitDeduction ? props.infonavitDeduction : 0) ?? 0)
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" padding="none" rowSpan={4}>
                      MONTOS COMPARATIVOS DE ISR
                    </TableCell>
                    <TableCell>DECLARADO</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status, props.isrConcept["amount"]?  props.isrConcept["amount"]: 0)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>SUBSIDIOS</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status,props.isrConcept["aportaciones"]?  props.isrConcept["aportaciones"]: 0)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>CALCULADO</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status, props.isrConcept["calculado"]?  props.isrConcept["calculado"]: 0)}</TableCell>
                  </TableRow>
                  <TableRow
                    // eslint-disable-next-line no-mixed-operators
                    className={`${classes.rowBottomBordered} ${ props.isrConcept["diferencia"] > 1 && props.isrConcept["diferencia"] > -1 || props.isrConcept["diferencia"] < 1 && props.isrConcept["diferencia"] < -1 ? classes.errorRow : classes.correctRow}`}
                  >
               
                    <TableCell align="right">DIFERENCIA</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status,props.isrConcept["diferencia"]?  props.isrConcept["diferencia"]: 0)}</TableCell>
                  </TableRow>
                  

                  <TableRow>
                    <TableCell component="th" padding="none" rowSpan={4}>
                      MONTOS COMPARATIVOS DE IMSS
                    </TableCell>
                    <TableCell>DECLARADO</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status, props.imssConcept["amount"]?  props.imssConcept["amount"]: 0)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>APORTACIONES</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status,props.imssConcept["aportaciones"]?  props.imssConcept["aportaciones"]: 0)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>CALCULADO</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status,props.imssConcept["calculado"]?  props.imssConcept["calculado"]: 0)}</TableCell>
                  </TableRow>

                  <TableRow
                    // eslint-disable-next-line no-mixed-operators
                    className={`${classes.rowBottomBordered} ${ props.imssConcept["diferencia"] > 1 && props.imssConcept["diferencia"] > -1 || props.imssConcept["diferencia"] < 1 && props.imssConcept["diferencia"] < -1 ? classes.errorRow : classes.correctRow}`}
                  >
                    <TableCell align="right">DIFERENCIA</TableCell>
                    <TableCell >{formatCFDIValues(props.validation.status, props.imssConcept["diferencia"] ?  props.imssConcept["diferencia"]: 0)}</TableCell>
                  </TableRow>
                  

                  {/* <TableRow>
                    <TableCell component="th" padding="none" rowSpan={3}>
                      MONTOS COMPARATIVOS DE SALARIO
                    </TableCell>
                    <TableCell>DECLARADO</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status, props.declaredSalary)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>CALCULADO</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status, props.calculatedSalary)}</TableCell>
                  </TableRow>
                  <TableRow
                    className={`${classes.rowBottomBordered} ${
                      props.salaryDifference !== undefined &&
                      Math.abs(props.salaryDifference) >= 0.5 &&
                      props.validation.status !== "Bajo revisión"
                        ? classes.errorRow
                        : ""
                    }`}
                  >
                    <TableCell align="right">DIFERENCIA</TableCell>
                    <TableCell>{formatCFDIValues(props.validation.status, props.salaryDifference)}</TableCell>
                  </TableRow>*/}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  avatarIds: {
    width: 30,
    height: 30,
    fontSize: "0.5rem",
  },
  subtable: {
    "&> tbody": {
      "& td": {
        borderRight: "unset",
      },
      "& th": {
        borderBottom: "1px solid #E0E1F2",
        color: theme.palette.primary.main,
        fontWeight: "bold",
      },
    },
  },
  rowBottomBordered: {
    "& td": {
      borderBottom: "1px solid #E0E1F2 !important",
      fontWeight: "bold",
    },
  },
  errorRow: {
    "& td": {
      color: theme.palette.error.main,
    },
  },
  correctRow: {
    "& td": {
      color: theme.palette.primary.main,
    },
  },
  undefinedRow: {
    "& td": {
      color: theme.palette.secondary.main,
    },
  },
  indicatorAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  errorAvatar: {
    backgroundColor: theme.palette.error.main,
  },
}));

export default CollapsableRow;
