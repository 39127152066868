import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, makeStyles } from '@material-ui/core'
import CloseIcon from "@material-ui/icons/Close";
import CatalogFormModal from './CatalogFormModal';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { validateYupSchema } from 'formik';

interface PropsData {
  file?: File;
  fechaInicial?: string;
  fechaFinal?: string;
  nombreCatalogo?: string;
}

interface PropsModal {
  open: boolean;
  handleClose: () => void;
  addNewCatalog: Dispatch<SetStateAction<PropsData[]>>
  dataCatalog?: PropsData[];
}

function AddCatalogModal({ open, handleClose, addNewCatalog, dataCatalog }: PropsModal) {

  const classes = useStyles();

  const handleAddNewCatalog = (value: PropsData) => {
   /* addNewCatalog([...dataCatalog, {
      file: value.file,
      fechaInicial: value.fechaInicial,
      fechaFinal: value.fechaFinal,
      nombreCatalogo: value.nombreCatalogo
    }])*/
    handleClose()
  }

  return (
    <Dialog maxWidth='md' open={open} onClose={handleClose}>
      <DialogTitle disableTypography={true} className={classes.dialogTitle}>
        <span className={classes.dialogTitleText}>Subir documento</span>
        <IconButton edge="end" onClick={handleClose}>
          <CloseIcon style={{ color: "#7A92C5" }} />
        </IconButton>

      </DialogTitle>
      <DialogContent className={classes.modalContent}>
        <CatalogFormModal addNewCatalog={(value: PropsData) => handleAddNewCatalog(value)} />
      </DialogContent>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTitleText: {
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "21.33px",
  },
  modalContent: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  modalActions: {
    display: 'block',
    textAlign: 'center'
  },
  typography: {
    fontSize: '14px',
    colot: theme.palette.primary.dark,
    marginBottom: '5px',
  }
}));

export default AddCatalogModal