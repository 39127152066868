import { Backdrop, CircularProgress, Container, makeStyles, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { useEffect, useState } from "react";
import CleanTopBar from "../components/onboarding/CleanTopBar";
import { PaymentService } from "../core/services/payment.service";
import useGlobalState from "../store/globalState";
import PymePlanCard from "../components/payment-plans/PymePlanCard";
import { PayProduct } from "../core/models/payment.models";
import PaymentForm from "../components/payment-plans/PaymentForm";

const PlansPage = () => {
  const classes = useStyles();
  const mainCompany = useGlobalState((s) => s.mainCompany);
  const [errorMessage, seterrorMessage] = useState<string>();
  const [products, setProducts] = useState<PayProduct[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const [orderId, setOrderId] = useState<string>(null);

  const paymentService = PaymentService.getInstance();

  useEffect(() => {
    uploadPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadPlans = async () => {
    try {
      setIsLoadingPlans(true);
      const products = await paymentService.getAllProducts();
      setProducts(products);
    } catch (error) {
      if (axios.isAxiosError(error) && !!error.response?.data?.message) seterrorMessage(error.response?.data?.message);
      else seterrorMessage("Hubo un error al obtener los planes");
    } finally {
      setIsLoadingPlans(false);
    }
  };

  const handleSelectProduct = ({ gigas, employees }: { gigas: number; employees: number }) => {
    const employeesProduct = products.find((p) => p.name === `${employees}_empleados`);
    const gigasProduct = products.find((p) => p.name === `${gigas}_gb`);

    if (!employeesProduct || !gigasProduct) return;

    setIsLoading(true);
    paymentService
      .createOrder({
        companyId: mainCompany?.id,
        companyRfc: mainCompany?.rfc,
        products: [employeesProduct._id, gigasProduct._id],
      })
      .then((orderId) => {
        if (orderId) {
          setOrderId(orderId);
        }
      })
      .catch((error) => {
        seterrorMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseSnackBar = () => {
    seterrorMessage(undefined);
  };

  return (
    <div className={classes.gradientBg}>
      <CleanTopBar />
      <Container maxWidth="lg" className={classes.plansPageRoot}>
        {!!orderId && <PymePlanCard onSelect={handleSelectProduct} />}
        {!orderId && <PaymentForm orderId={orderId} setIsLoading={setIsLoading}/>}
        <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={handleCloseSnackBar}>
          <Alert onClose={handleCloseSnackBar} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Backdrop style={{ zIndex: 2000 }} open={isLoadingPlans || isLoading}>
          <CircularProgress color="primary" />
        </Backdrop>

        {/* <Typography variant="h2" color="primary" align="center" style={{fontSize:"2rem", marginTop:"1rem"}}>
        Selecciona un plan para tu empresa
      </Typography>
      <Box display="flex" justifyContent="space-evenly" marginTop={10}>
        {isLoadingPlans ? (
          <CircularProgress color="primary" />
        ) : (
          plans.map((plan) => (
            <PlanCard
              key={plan.id}
              name={plan.productObject.name}
              price={plan.unitAmount / 100}
              description={plan.productObject.description}
              imageUrl={plan.productObject.images[0]}
              recurring={plan.recurring.interval}
              onClickSelect={() => handleSelectPlan(plan)}
            />
          ))
        )}
      </Box>
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={handleCloseSnackBar}>
        <Alert onClose={handleCloseSnackBar} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Backdrop style={{ zIndex: 2000 }} open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop> */}
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  plansPageRoot: {
    minHeight: "100vh",
    paddingTop: 72,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gradientBg: {
    background: "linear-gradient(107.74deg, #2347AD 0%, #52C6BA 48.55%, #7678ED 99.17%)",
  },
});

export default PlansPage;
