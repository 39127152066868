import { SvgIcon, SvgIconProps } from '@material-ui/core';

const RedPinIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="25" height="25" viewBox="0 0 35 35">
        <path d="M27.7031 2.25006C21.4219 -3.93744 17.6953 5.50787 17.6953 5.50787L10.7578 11.0391L4.73438 9.67974C4.59375 9.65631 4.42969 9.63287 4.28906 9.63287C3.46875 9.63287 2.69531 10.0782 2.32031 10.7813C1.85156 11.6485 1.96875 12.7266 2.625 13.3829L8.8125 19.5469L0 28.2891L1.57031 29.836L10.3594 21.0938L16.4531 27.1641C16.8281 27.5391 17.3672 27.7501 17.9297 27.7501C18.3281 27.7501 18.7031 27.6563 19.0547 27.4688C19.8984 27.0001 20.3672 25.9922 20.1562 25.0547L18.7969 19.0547L24.3516 12.1407C24.3516 12.1407 34.3828 8.81256 27.7031 2.25006ZM18 25.5469C18 25.5469 17.9766 25.5469 17.9766 25.5704L4.21875 11.8829C4.21875 11.8829 4.21875 11.8829 4.21875 11.8594C4.21875 11.8594 4.24219 11.836 4.26562 11.836L9.72656 13.0782L16.7578 20.086L18 25.5235C18 25.5235 17.9766 25.5469 18 25.5469ZM17.25 17.461L12.3516 12.586L18.6328 7.57037L22.2656 11.1797L17.25 17.461ZM24.1172 9.91412L19.9453 5.74225C19.9453 5.74225 22.3125 -0.023379 26.25 3.91412C29.9766 7.61725 24.1172 9.91412 24.1172 9.91412Z" fill="#F45F5F"/>
    </SvgIcon>
  )
}

export default RedPinIcon;