import { DownloadedDocument } from "materialidad-outsourcing/dist/types";
import { useEffect, useState } from "react";
import { DocumentsService } from "../../../core/services/documets.service";
import { useHandleVault } from "./useHandleVault";

export const useGetDocument = (docId: string, getOnBuildComponent?: boolean) => {
  const [fileBlob, setFileBlob] = useState<Blob>();
  const [fileMIME, setFileMIME] = useState<string | false>("");
  const [fileName, setFileName] = useState("");
  const [fileURL, setFileURL] = useState("");
  const [fileStatus, setFileStatus] = useState("Cargando documento...");

  const handleDownloadDocument = async (documentId: string): Promise<DownloadedDocument> => {
    try {     
      const document = await DocumentsService.getInstance().downloadDocument(docId);
      const info = await DocumentsService.getInstance().getDocumentInfo(docId);

      // try {
      // const json: HistoryBlockChainReqBody = {
      //   action: "READ",
      //   fileId: info.id,
      //   user: sessionStorage.getItem("currentUser") ?? "",
      //   fileName: info.name + info.extension,
      // };
      //   await blockChainService.addDocumentHistoryKaliedoContract(json);
      // } catch (error) {
      //   console.log('Error on set file history.');
      // }

      return {
        document,
        documentInfo: {
          name: info.name,
          id: info.id,
          extension: info.extension,
        },
      };
    } catch (error) {
      throw error;
    }
  };

  const { downloadDocument } = useHandleVault(handleDownloadDocument);

  useEffect(() => {
    if (getOnBuildComponent) getDocument();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDocument = () => {
    downloadDocument(docId)
      .then((info) => {
        setFileName(info.name);
        setFileMIME(info.MIME);
        setFileBlob(info.blob);
        setFileURL(info.url);
        setFileStatus("Documento cargado.");
      })
      .catch((e) => {
        console.log(e);
        
        setFileStatus("El documento no se pudo cargar.");
      });
  };

  return { fileName, fileBlob, fileMIME, fileStatus, fileURL, ...(getOnBuildComponent ? {} : { getDocument }) };
};
