/* eslint-disable no-useless-escape */
import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Backdrop,
  CircularProgress,
  Box,
  Tooltip,
} from "@material-ui/core";
import { useFormik } from "formik";
import LogoutOnLeave from "../../LogoutOnLeave";
import DropzoneFiles from "../../inputs/DropzoneFiles";
import useGeneralFormCompleteState from "../../../store/generadores/generalFormState";
import { GeneradoresServices } from "../../../core/services/generadores.service";
import useCustomToast from "../../../core/hooks/useCustomToast";
import useUiState from "../../../store/uiState";
import { Delete } from "@material-ui/icons";

type FormFields = {
  File: any;
};

const FormAnexos = () => {
  const classes = useStyles();
  const toast = useCustomToast();
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState<string | null>(null);
  const idForm = useGeneralFormCompleteState((state) => state.idForm);
  const idAttachedFile = useGeneralFormCompleteState((state) => state.attachedFileId);
  const setAttachedFileId = useGeneralFormCompleteState((state) => state.setAttachedFileId);
  const { setShowGenericLoader } = useUiState();

  const formik = useFormik<FormFields>({
    initialValues: {
      File: null,
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    if (formik.values.File) {
      const file = formik.values.File;
      if (file && typeof file !== 'string') {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPdfPreviewUrl(reader.result as string);
        };
        reader.readAsDataURL(file);

        const formData = new FormData();
        formData.append('file', file);
        try {
          GeneradoresServices.getInstance().postImage(formData).then(async (data) => {
            await GeneradoresServices.getInstance().patchAttachedFile(idForm, data.respuesta).then((file) => {
              setAttachedFileId(file.respuesta.attachedFile.idFile);
              toast.success("Anexo final agregado con éxito.");
            });
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
    setShowGenericLoader(false);
  }, [formik.values.File]);

  useEffect(() => {
    const getFile = async () => {
      if (idAttachedFile !== '') {
        setShowGenericLoader(true);
        await GeneradoresServices.getInstance().getImage(idAttachedFile).then((data) => {
          const base64 = data.file;
          const binaryString = atob(base64);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          const blob = new Blob([bytes], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          formik.setFieldValue('File', url);
          console.log(data);
          setShowGenericLoader(false);
        });
      } else {
        toast.info("Suba aquí el anexo final para el reporte.");
      }
    };

    getFile();
  }, []);

  return (
    <form className={classes.form}>
      <LogoutOnLeave />
      <Grid container>
        <Grid item md={12}>
          <div className={classes.subContainer}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
              <h3 style={{ margin: 0 }}>PDF Anexo</h3>
              <Tooltip title="Eliminar archivo" arrow>
                <Delete 
                  style={{
                    cursor: 'pointer',
                    color: 'white',
                    backgroundColor: '#2347AD',
                    borderRadius: '50%',
                    padding: 5,
                  }}
                  onClick={() => {
                    formik.setFieldValue('File', null);
                  }}
                />
              </Tooltip>
            </div>
            <div className={classes.imageField}>
              <DropzoneFiles
                icon={false}
                disabled={false}
                accept="application/pdf"
                file={formik.values.File}
                onChange={(file) => {
                  setShowGenericLoader(true);
                  formik.setFieldValue('File', file);
                }}
                label=""
                labelBox="Arrastra el PDF aquí para subirlo"
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  subContainer: {
    marginBottom: theme.spacing(2),
  },
  imageField: {
    marginTop: '5%',
  },
  pdfPreview: {
    width: '100%',
    height: '500px',
    border: 'none',
    marginTop: theme.spacing(2),
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #2347AD',
    boxShadow: theme.shadows[5],
    textAlign: 'center',
    padding: theme.spacing(2, 4, 3),
    borderRadius: '8px',
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  }
}));

export default FormAnexos;
