import { axiosRequestBlockChain, authHeader } from "../../configs/axios.config"

export class FoldersService {
  private static instance: FoldersService;

  private constructor() { };

  public static getInstance(): FoldersService {
    if (!FoldersService.instance) {
      FoldersService.instance = new FoldersService();
    }
    return FoldersService.instance;
  }

  async getFoldersCompanies(idCompany: string) {
    try {
      const { data } = await axiosRequestBlockChain.get<FolderContentResponse>(`v1/folders/company/${idCompany}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFolder(documentId: string) {
    try {
      const { data } = await axiosRequestBlockChain.delete(`v1/folders/${documentId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDocumentsByFolder(folderId: string, companyId: string) {
    try {
      const { data } = await axiosRequestBlockChain.get<FolderContentResponse>(`v1/folders/${folderId}/company/${companyId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }


  async checkKaleidoConnection(companyId: string) {
    return "OK";
  }

}
