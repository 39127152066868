import { Box, Button, CircularProgress, Grid, IconButton, makeStyles, Modal, ThemeProvider, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { kivitCumplimiento } from "../../../styles/common";
import { ArrowDownward } from "@material-ui/icons";
import { useParams } from "react-router";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import useGeneradoresStagesState from "../../../store/generadores/generadoresStagesState";
import { ServiceStage } from "../../../core/models/service.models";
import StageNavigation from "../../../components/stages/comps/StageNavigation";
import useGlobalState from "../../../store/globalState";
import DownloadDocsDialog from "../../../components/stages/comps/DownloadGeneradoresDocsServiceDialog";
import StageGroupCard from "../../../components/stages/comps/StageGroupCard";
import StageContractCard from "../../../components/stages/comps/StageGeneratorContractCard";
import { useHandleModal } from "../../../components/stages/hooks/useHandleModal";
import { sliceText } from "../../../core/utils/utils";
import StageSummaryDialog from "../../../components/stages/comps/StageSummaryDialog";
import HomeGenerator from "./HomeGenerator";
import useGeneralFormCompleteState from "../../../store/generadores/generalFormState";
import { useHistory } from "react-router";

const Generators = () => {
  const { serviceId }: any = useParams();
  const classes = useStyles();
  const history = useHistory();
  //const theme = useTheme();
  const { currentService, getGeneratorService, setCurrentService } = useGeneradoresStagesState();
  const mainCompanyId = useGlobalState((s) => s.mainCompany?.id);
  //const [counters, setCounters] = useState<Counter[]>([]);
  const [showDownloadDocsDialog, onShowDownloadDocsDialog, onCloseDownloadDocsDialog] = useHandleModal();
  const [showStageSummaryDialog, onShowStageSummaryDialog, onCloseStageSummaryDialog] = useHandleModal();
  const [loading, setLoading] = useState(false);
  const [selectedServiceStageIdx, setSelectedServiceStageIdx] = useState<number>(0);
  const [selectedServiceStage, setSelectedServiceStage] = useState<ServiceStage>(null);
  const serviceStages = currentService?.ntp?.documentSet?.stages;
  const companyType = currentService?.provider === mainCompanyId ? "Cliente" : "Proveedor";
  const companyName = companyType === "Cliente" ? currentService?.clientName : currentService?.providerName;
  const currentContract = currentService?.ntp?.contract;
  const showList = useGeneralFormCompleteState((state) => state.showGeneratorsList);
  const pathPrefix = history.location.pathname.includes('providers') ? 'providers' : 'clients';

  function onDownloadNominaXls() {
    window.open("/docs/Lista_nomina.xlsx", "_blank");
  }

  useEffect(() => {
    if (serviceId) {
      setLoading(true);
      getGeneratorService(serviceId).finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId]);

  useEffect(() => {
    if (serviceStages?.length) {
      setSelectedServiceStage(serviceStages[selectedServiceStageIdx]);
    
    }
  }, [currentService, selectedServiceStageIdx]);

  useEffect(() => {
    //setCounters(mapStageCounters(selectedServiceStage, theme));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServiceStage]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => setCurrentService(null), []);


const hasExpediente = () => {
  const regex = /expediente/i;
  if (regex.test(selectedServiceStage.name)){
    return true;
   } else {
    return false;
   }
}

 
  return (
    <>
    {showList ? (
      <Modal
        open={showList}
        aria-labelledby="alert-modal-title"
        aria-describedby="alert-modal-description"
      >
        <div className={classes.modalStyle}>
          <Box id="alert-modal-description">
            <HomeGenerator />
          </Box>
        </div>
      </Modal>
    ) : (
      <ThemeProvider theme={kivitCumplimiento}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end" style={{ gap: "1rem" }}>
          <Grid item>
            <Button size="small" startIcon={<ChevronLeftIcon />} variant="contained" onClick={() => history.replace(`/dashboard/home/${pathPrefix}`)}>
                Regresar
            </Button>
            </Grid>
          {/* <StatusBar value={+(selectedServiceStage?.percent || 0).toFixed(2)} stageCompleted /> */}
        </Box>

        {!selectedServiceStage && loading && (
          <div className={classes.placeholderWrapper}>
            <CircularProgress color="primary" />
          </div>
        )}

        {!loading && !selectedServiceStage?.groups?.length && (
          <div className={classes.placeholderWrapper}>
            <Typography variant="subtitle2">No se encontraron datos</Typography>
          </div>
        )}

        {!!selectedServiceStage && (
          <Box display="flex" justifyContent="space-between" alignItems="center" py={2} style={{ gap: "1rem" }}>
            {/* <Typography variant="h3" color="secondary">
              {capitalizeFirstLetter(selectedServiceStage?.name)}
            </Typography> */}
            {/* <StageDocumentsCounters countersList={counters} companyType={companyType} companyName={companyName} /> */}

            <Typography variant="h3" color="textPrimary">
              {sliceText(companyName, 36)}
            </Typography>

            <div style={{ display: "flex", gap: "1rem" }}>
              {/*
                <Button
                variant="contained"
                color="secondary"
                style={{ padding: ".5rem 1rem" }}
                endIcon={<ArrowDownward fontSize="inherit" />}
                onClick={onDownloadNominaXls}
              >
                DESCARGAR <br /> XLS LISTA NOMINA
              </Button>*/}
              <Button
                variant="contained"
                color="secondary"
                style={{ padding: ".5rem 1rem" }}
                endIcon={<ArrowDownward fontSize="inherit" />}
                onClick={onShowDownloadDocsDialog}
              >
                DESCARGAR DOCUMENTOS
              </Button>
            </div>
          </Box>
        )}

        {Boolean(serviceStages?.length) && (
          <>
            {/* ---------------------------- STAGE NAVIGATION ---------------------------- */}
            <StageNavigation
              stages={serviceStages?.map((stage, idx) => ({ label: stage.name }))}
              value={selectedServiceStageIdx}
              onChange={(newIdx) => setSelectedServiceStageIdx(newIdx)}
            />
            {/* ------------------------------ service title ------------------------------ */}
            <Grid container style={{ backgroundColor: "#fff", padding: "1rem", marginTop: "1rem", marginBottom: "1rem" }}>
              <Typography variant="h5">
                <span style={{ fontWeight: "lighter" }}>Servicio:</span> {currentService?.name || "---"}
              </Typography>
            </Grid>
            {/* ------------------------------ CONTRACT ROW ------------------------------ */}
            <StageContractCard contract={currentContract} service={currentService} refresh={() => getGeneratorService(serviceId)} />

            {/* ------------------------------- GROUPS ROWS ------------------------------ */}
            {selectedServiceStage?.groups?.map((group, idx) => (
              <StageGroupCard key={group.name + idx} group={group} refresh={() => getGeneratorService(serviceId)} />
            ))}
          </>
        )}

        {/* ----------------------------- MODALS & OTHERS ---------------------------- */}
        {showDownloadDocsDialog && (
          <DownloadDocsDialog
            stage={selectedServiceStage as any}
            companyId={mainCompanyId}
            serviceId={serviceId}
            onClose={onCloseDownloadDocsDialog}
          />
        )}

        {showStageSummaryDialog && (
          <StageSummaryDialog onClose={onCloseStageSummaryDialog} serviceId={serviceId} selectedStage={selectedServiceStage}/>
        )}
      </ThemeProvider>
    )}
    </>
  );
};

export default Generators;

const useStyles = makeStyles((theme) => ({
  placeholderWrapper: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalStyle: {
    overflowY: 'scroll',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    padding: 0,
    background: "linear-gradient(107.74deg, rgba(35, 71, 173, 0.9) 0%, rgba(82, 198, 186, 0.9) 48.55%, rgba(118, 120, 237, 0.9) 99.17%)",
    boxShadow: theme.shadows[5],
  },
}));
