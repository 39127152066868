import React, { VFC, useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import mime from "mime-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import RenderFileField from "./inputs/RenderFileField";
import MatFisButton from "./MatFisButton";
import LinearProgress from '@material-ui/core/LinearProgress';

interface UploadCDFIDialogProps {
  open: boolean;
  onClose?: () => void;
  onUploadFile: (files: File[]) => void;
  employeesCount?: number;
  uploadFilesResponse?: string[];
  uploadFinished?: boolean;
}

const UploadCDFIDialog: VFC<UploadCDFIDialogProps> = (props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const formik = useFormik<{ employeesFiles?: File[] }>({
    validateOnMount: true,
    initialValues: {
      employeesFiles: [],
    },
    validationSchema: Yup.object({
      employeesFiles: Yup.mixed()
        .required("Campo requerido")
        .test({
          name: "files-length",
          message: `Se sugiere que el número de archivos corresponda al número de empleados, (${props.employeesCount} empleados)`,
          test: (files: File[]) => files.length > 0,
        })
        .test({
          name: "file-type",
          message: `Uno o mas documentos no son de tipo .xml`,
          test: (files: File[]) => files.every((file) => mime.lookup(file.name) === "application/xml"),
        }),
    }),
    onSubmit: handleSubmit,
  });

  function handleSubmitTemp(values) {
    props.onUploadFile(values.employeesFiles as File[]);
  }

  function handleSubmit(values) {
   //setLoading(true);
    props.onUploadFile(values.employeesFiles as File[])
      //.finally(() => setLoading(false));
  }
  

  const handleClose = () => {
    formik.setFieldValue("employeesFiles", []);
    if (props.onClose) props.onClose();
  };

  const handleChange = async (files?: File[]) => {
    try {
      setUploading(true);
      formik.setFieldTouched("employeesFiles", true);
      formik.setFieldValue("employeesFiles", files);
  
      // Aquí puedes realizar la lógica de subida de archivos
    //  await handleCDFIFilesUploadByBatch(files);
      
    } catch (error) {
      // Maneja errores si es necesario
      console.error("Error en la subida de archivos:", error);
    } finally {
      setUploading(false);
    }
  };
  

  const someFileFailed = props.uploadFilesResponse?.some((msg) => msg.includes("Ya se ha subido"))

  return (
    <Dialog open={props.open} maxWidth="sm">
       {uploading && (
            <LinearProgress />
      )}
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <span className={classes.dialogTitleText}>Subir CFDI de nómina</span>
        <IconButton edge="end" onClick={handleClose}>
          <CloseIcon style={{ color: "#7A92C5" }} />
        </IconButton>
       
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} className={classes.dialogContentRoot}>
          <RenderFileField
            label={`No de empleados: ${props.employeesCount || "N/A"}`}
            accept="application/xml"
            value={formik.values.employeesFiles}
            onChange={handleChange}
            selectFileButtonLabel="Seleccionar archivos .xml"
            dropzoneLabel="Arrastra aquí archivos .xml"
            touched={formik.touched.employeesFiles as any}
            error={formik.errors.employeesFiles as any}
            failedFilesRes={props.uploadFilesResponse?.map((msg) => (msg.includes("Ya se ha subido") ? msg : undefined))}
            fullDisabled={someFileFailed}
            multiple
          />
          <MatFisButton variant="contained" color="primary" disabled={!formik.isValid || someFileFailed} type="submit">
            Subir
          </MatFisButton>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogContentRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 20,
  },
  dialogTitleText: {
    fontWeight: 600,
    fontSize: "1.125rem",
    lineHeight: "21.33px",
  },
  dialogContentText: {
    fontSize: "0.875rem",
    lineHeight: "17px",
    color: "#7A92C5",
    marginBottom: 40,
  },
});
export default UploadCDFIDialog;
function handleCDFIFilesUploadByBatch(files: File[]) {
  throw new Error("Function not implemented.");
}

