import React, { useState } from "react";
import {
  OutlinedInput,
  FormHelperText,
  FormControl,
  Box,
  InputAdornment,
  IconButton,
  Typography,
  FilledInput,
  Input,
  OutlinedInputClassKey,
} from "@material-ui/core";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

interface RenderTextFieldProps {
  id?: string;
  name?: string;
  label?: string;
  className?: string;
  classes?: Partial<ClassNameMap<OutlinedInputClassKey>>;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  error?: string;
  touched?: boolean;
  value?: unknown;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  type?: string;
  passwordAction?: boolean;
  size?: "small" | "medium";
  variant?: "filled" | "standard" | "outlined";
  margin?: "dense" | "none" | "normal";
  minDate?: string;
  maxLength?: number; // Agregado para definir un límite de caracteres
}

const RenderTextFieldV2 = ({
  id,
  name,
  label,
  required,
  className,
  classes,
  fullWidth = true,
  style,
  disabled,
  onChange,
  error,
  touched,
  value = "",
  placeholder,
  startIcon,
  endIcon,
  type = "text",
  passwordAction,
  size,
  variant = "outlined",
  margin = "none",
  minDate,
  maxLength // Valor predeterminado de longitud máxima
}: RenderTextFieldProps) => {
  const rootComponentProps = { display: "inline-flex", width: fullWidth ? "100%" : undefined, flexDirection: "column" };
  const [showPassword, setShowPassword] = useState(false);

  // Actualiza la longitud del texto y permite limitar los caracteres
  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!maxLength || event.target.value.length <= maxLength) {
      onChange(event);
    }
  };

  const InputComponent = variant === "filled" ? FilledInput : variant === "standard" ? Input : OutlinedInput;

  return (
    <Box {...rootComponentProps} style={style}>
      {label && <Typography variant="body2">{label}</Typography>}
      <FormControl size={size} variant={variant} color="primary" fullWidth margin={margin} error={touched && !!error}>
        <InputComponent
          className={className}
          classes={classes}
          type={passwordAction ? (showPassword ? "text" : "password") : type}
          id={id}
          name={name}
          disabled={disabled}
          onChange={handleInputChange}
          placeholder={placeholder || ""}
          required={required}
          value={value}
          style={disabled ? { color: 'black' } : {}}
          inputProps={type === "date" ? { max: "2999-12-31", min: minDate } : undefined}
          endAdornment={
            <>
              <InputAdornment position="end">
                {passwordAction ? (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((oldShowPassword) => !oldShowPassword)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ) : (
                  <>{endIcon}</>
                )}
              </InputAdornment>
            </>
          }
        />
        <FormHelperText>{touched && error}</FormHelperText>
        {/* Muestra el contador de caracteres */}
        {maxLength &&
          <FormHelperText className="mb-2 !ml-0">
            {(value as string).length}/{maxLength} caracteres
          </FormHelperText>
        }
      </FormControl>
    </Box>
  );
};

export default RenderTextFieldV2;
