import { Button, Grid, Tooltip, Typography, useTheme } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { FormikErrors, FormikTouched } from "formik";
import { sliceText } from "../../../core/utils/utils";
import ColoredButton from "../../general/ColoredButton";
import { companyActivities, servicesOptions } from "./profileFormUtils";
import ProfileInput from "./ProfileInput";
import { CompaniesService } from "../../../core/services/companies.service";
import { useEffect, useState } from "react";
import useGlobalState from "../../../store/globalState";

type ProfileFormProps = {
  title: string;
  isEditing?: boolean;
  values: ProfileFormFields;
  errors: FormikErrors<ProfileFormFields>;
  touchedFields: FormikTouched<ProfileFormFields>;
  handleChangeField: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void> | Promise<FormikErrors<ProfileFormFields>>;
  onSaveForm: () => void;
  onCancelEdit: () => void;
  onEditInfo: () => void;
};

const ProfileForm = ({
  title,
  onSaveForm: onSave,
  onEditInfo,
  onCancelEdit,
  isEditing,
  values,
  errors,
  touchedFields,
  handleChangeField,
  setFieldValue,
}: ProfileFormProps) => {
  const theme = useTheme();
  const [servicesGenerator, setServiceGenerator] = useState([]);
  const mainCompanyId = sessionStorage.getItem("companyId");
  const { typeService } = useGlobalState();

  useEffect(() => {
    console.log();
    fetchServiceOffered(mainCompanyId);
  }, []);

  const fetchServiceOffered = async (companyId: string) => {
    try {
      const servicesG = await CompaniesService.getInstance().getServiceOffered(companyId);
      console.log("res servicesG", servicesG.respuesta); // Muestra la respuesta en la consola

      const serviceDescriptions = [];
      servicesG.respuesta.map((service) => {
        serviceDescriptions.push({ value: service.description });
      });
      setServiceGenerator(serviceDescriptions); // Guarda la respuesta en el estado
    } catch (error) {
      console.error("Error fetching service offered:", error); // Maneja el error
    }
  };

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid container item xs={8} style={{ marginBottom: "1rem" }}>
        <Tooltip title={title?.length > 20 ? title : ""}>
          <Typography variant="h6" style={{ lineHeight: "1.2" }}>
            {sliceText(title, 30)}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={4} style={{ marginBottom: "1rem", display: "flex", gap: 10 }}>
        {!!onSave && isEditing ? (
          <>
            <ColoredButton variant="contained" color={theme.palette.success.main} onClick={onSave}>
              Guardar
            </ColoredButton>
            <ColoredButton variant="contained" color={theme.palette.error.main} onClick={onCancelEdit}>
              Cancelar
            </ColoredButton>
          </>
        ) : (
          <Button variant="contained" color="primary" size="small" startIcon={<EditIcon />} onClick={onEditInfo}>
            Editar datos
          </Button>
        )}
      </Grid>

      {isEditing && (
        <ProfileInput
          label="Nombre / Razón social"
          id="name"
          error={errors.name}
          isEditing={isEditing}
          onChange={handleChangeField}
          touched={touchedFields.name}
          value={values.name}
        />
      )}

      <ProfileInput
        label="Regimen fiscal"
        id="taxRegime"
        error={errors.taxRegime}
        isEditing={isEditing}
        onChange={handleChangeField}
        options={[
          { value: "moral", label: "Persona moral" },
          { value: "fisica", label: "Persona física" },
        ]}
        touched={touchedFields.taxRegime}
        type="select"
        value={values.taxRegime}
      />

      <ProfileInput
        label="RFC"
        id="RFC"
        error={errors.RFC}
        isEditing={isEditing}
        onChange={handleChangeField}
        touched={touchedFields.RFC}
        value={values.RFC}
      />

      {typeService !== 'generators' &&
        <>
          <ProfileInput
            label="Sitio web"
            id="website"
            error={errors.website}
            isEditing={isEditing}
            onChange={handleChangeField}
            touched={touchedFields.website}
            value={values.website}
          />

          <ProfileInput
            label="Sector al que pertenece"
            id="businessActivity"
            error={errors.businessActivity}
            isEditing={isEditing}
            onChange={handleChangeField}
            options={companyActivities}
            touched={touchedFields.businessActivity}
            type="select"
            value={values.businessActivity}
          />

          <ProfileInput
            label="No de empleados"
            id="employeesCount"
            error={errors.employeesCount}
            isEditing={isEditing}
            onChange={handleChangeField}
            touched={touchedFields.employeesCount}
            value={values.employeesCount}
          />
        </>
      }

      <ProfileInput
        label="Servicios ofrecidos"
        id="offeredServices"
        error={errors.offeredServices as string}
        isEditing={isEditing}
        onChange={(value) => {
          setFieldValue("offeredServices", value);
        }}
        options={typeService === 'generators' ? servicesGenerator : servicesOptions}
        touched={touchedFields.offeredServices}
        type="multiple"
        value={values.offeredServices}
      />
    </Grid>
  );
};

export default ProfileForm;
