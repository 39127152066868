import { Backdrop, CircularProgress } from "@material-ui/core";

const GenericLoader = () => {
  return (
    <Backdrop style={{ zIndex: 2000, color: "white" }} open={true} onClick={() => {}}>
      <div style={{width:"220px",height:"100px", backgroundColor:"white", display:"flex", justifyContent:"center", alignItems:"center", gap:"2rem", borderRadius:"15px"}}>
        <CircularProgress color="secondary" />
        <p style={{color:"#000"}}>Procesando...</p>
      </div>
    </Backdrop>
  );
};

export default GenericLoader;