import { Box, Button, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useFormik } from "formik";
import * as Yup from "yup";

import LogoutOnLeave from "../LogoutOnLeave";
import RenderTextFieldV2 from "../inputs/inputs-v2/RenderTextFieldV2";
import { useHistory } from "react-router";
import { AuthService } from "../../core/services";
import OnboardingTemplate from "./OnboardingTemplate";
import useGlobalState from "../../store/globalState";
import { useEffect, useState } from "react";
import SafetyPolicies from "./SafetyPolicies";
import useSettingsState from "../../store/settingsState";
import { PasswordPolicyList } from "../../core/models/settings.models";

interface FormFields {
  newPassword: string;
  newPasswordConfirm: string;
}

const NewPassword = () => {
  //let { token_restore } = useParams<{token_restore: string}>();
  //get token from url query params
  const token_restore = new URLSearchParams(window.location.search).get("token");
  const getPoliciesByUserList = useSettingsState(e => e.getPoliciesByUserList);
  const [validToken, setValidToken] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const { alertInfo, setAlertInfo } = useGlobalState();
  const [errors, setErrors] = useState(null);
  const [policies, setPolicies] = useState<PasswordPolicyList[]>([]);
  const history = useHistory();
  const authService = new AuthService();

  const handleSubmit = (values: FormFields) => {
    const userBody = {
      ...userData,
      updatedPassword: true,
      password: values.newPassword,
    };
    authService.confirmResetPassword(userBody)
      .then((response) => {
        if (response.data.codigo === 0) {
          setAlertInfo({
            message: "Contraseña actualizada correctamente",
            type: "success",
          });

          setTimeout(() => {
            history.push("/");
          }, 700);
        } else {
          setErrors(response.data.respuesta);
        }
      })
  };

  const formik = useFormik<FormFields>({
    initialValues: {
      newPassword: "",
      newPasswordConfirm: "",
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("El campo es requerido"),
      /* .min(10, "La contraseña no puede ser menor a 10 caracteres")
      .max(20, "La contraseña no puede ser mayor a 20 caracteres"), */
      newPasswordConfirm: Yup.string()
        .required("El campo es requerido")
        .oneOf([Yup.ref("newPassword")], "Las contraseñas deben coincidir."),
    }),
    validateOnChange: false,
    validateOnBlur: false,
  });

  function launchTokenError() {
    setAlertInfo({
      message: "El token de restauración de contraseña es inválido",
      type: "error",
    });
  }

  useEffect(() => {
    setAlertInfo(null)
    authService
      .validateResetPassword(token_restore)
      .then((data) => {
        if (data.codigo === 0) {
          setValidToken(true);
          getPoliciesByUserList(data.respuesta.id).then((result) => {
            if (result.codigo === 0) {
              const passwordPolicyList = result.respuesta.passwordPolicyList;
              const activePolicies = passwordPolicyList.filter((item: PasswordPolicyList) => {
                if (item.active && item.applicableInUpdatePassword) return item;
              });
              setPolicies(activePolicies)
            }
          })
          setUserData(data.respuesta);
        } else {
          launchTokenError();
          setUserData(null);
        }
      })
      .catch((errorResponse) => {
        launchTokenError();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token_restore]);

  return (
    <OnboardingTemplate>
      <Box component="form" onSubmit={formik.handleSubmit} display="flex" gridGap={15} flexDirection="column" flex="1">
        <Typography variant="h2" color="primary" style={{ fontWeight: "normal" }}>
          Actualizar contraseña
        </Typography>
        <RenderTextFieldV2
          size="small"
          label="Contraseña nueva"
          error={formik.errors.newPassword}
          onChange={formik.handleChange}
          touched={formik.touched.newPassword}
          value={formik.values.newPassword}
          placeholder="Tu nueva contraseña"
          name="newPassword"
          type="password"
          passwordAction
        />
        <RenderTextFieldV2
          size="small"
          label="Confirmar contraseña"
          error={formik.errors.newPasswordConfirm}
          onChange={formik.handleChange}
          touched={formik.touched.newPasswordConfirm}
          value={formik.values.newPasswordConfirm}
          placeholder="Tu nueva contraseña"
          name="newPasswordConfirm"
          type="password"
          passwordAction
        />
        <div className="mt-10">
          {/* <SafetyIndicator password={formik.values.newPassword} /> */}
          <SafetyPolicies errorPolicies={errors} policies={policies} setPolicies={setPolicies} />
        </div>

        {alertInfo?.message && (
          <Alert variant="filled" severity={alertInfo?.type}>
            {alertInfo.message}
          </Alert>
        )}
        <Button type="submit" variant="contained" color="primary" fullWidth={true} disabled={!validToken}>
          Restablecer contraseña
        </Button>
        <LogoutOnLeave />
      </Box>
    </OnboardingTemplate>
  );
};

export default NewPassword;
