import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const VaultIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="25" height="28" viewBox="0 0 25 28">
      <path d="M5 9.9357V18.6447L12.6595 22.9983V14.2894L5 9.9357ZM13.3405 14.2894V23L21 18.6447V9.9357L13.3405 14.2894ZM5.33967 9.35532L12.9992 13.709L20.6569 9.35365L12.9992 5L5.33967 9.35532Z"/>
      <path d="M25 6.99916L12.5 0L0 7.00083L0.00172176 21.0008L12.5017 28L25 20.9992V6.99916ZM21.7063 18.9901L21.2138 19.2657L23.9669 20.8071L12.8461 27.0364V23.8817L12.1574 23.4959V27.0347L1.03478 20.8088L4.19422 19.0385L4.10985 18.9918V18.3137L0.690427 20.2293L0.688705 7.7724L4.10985 9.68794V9.13682L4.30613 9.0266L1.03306 7.19456L12.1556 0.965287V4.63104L12.8444 4.24526V0.965287L23.9669 7.19289L21.1019 8.7978L21.7063 9.13682V9.23035L24.3113 7.77073L24.313 20.2276L21.708 18.768L21.7063 18.9901Z"/>
    </SvgIcon>
  )
}


export default VaultIcon;