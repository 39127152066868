import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Button, TextField, Typography, Grid, Paper, Box, IconButton, FormHelperText } from '@material-ui/core';
import { CloudUpload, Delete } from '@material-ui/icons';
import Dropzone, { useDropzone } from 'react-dropzone';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { format, isValid, parse } from 'date-fns';
import useCustomToast from "../../../../../core/hooks/useCustomToast";
import { GeneradoresServices } from '../../../../../core/services/generadores.service';
import useGeneratorClientState from '../../../../../store/generadores/generadorClientState';
import useGlobalState from '../../../../../store/globalState';
import useUiState from '../../../../../store/uiState';

interface PropsData {
  file?: File;
  fechaInicial?: string;
  fechaFinal?: string;
  nombreCatalogo?: string;
}

interface PropsForm {
  addNewCatalog?: (value: PropsData) => void
}

const formatDateString = (dateString) => {
  const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
  return isValid(parsedDate) ? format(parsedDate, 'dd-MM-yyyy') : '';
};

const parseDateString = (dateString) => {
  const parsedDate = parse(dateString, 'dd-MM-yyyy', new Date());
  return isValid(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : '';
};

const CatalogFormModal = ({ addNewCatalog }: PropsForm) => {
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const toast = useCustomToast();
  const { getCatalogues,createCatalogue} = useGeneratorClientState();
  const { setShowGenericLoader } = useUiState();
  const { mainCompany  } = useGlobalState();
  const companyId = mainCompany.id;


  function convertDateFormat(dateString) {
    if (!dateString || !/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
      return 'N/A';
    }
  
    const [day, month, year] = dateString.split('-');
  
    return `${year}-${month}-${day}`;
  }

  
  const formik = useFormik({
    initialValues: {
      fechaInicial: formatDateString(new Date()),
      fechaFinal: formatDateString(new Date()),
      nombreCatalogo: '',
      file: null,
    },
    validationSchema: Yup.object({
      fechaInicial: Yup.string().required('Seleccione una fecha inicial de uso'),
      fechaFinal: Yup.string().required('Seleccione una fecha final de uso'),
      file: Yup.mixed()
        .required('Se requiere un archivo')
        .test('fileType', 'El tipo de archivo no es soportado', (value) => {
          return value && ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(value.type); // soporta archivos .xls y .xlsx
        }),

    }),
    onSubmit: async (values) => {
      console.log(values);
      setShowGenericLoader(true);
      /*const reader = new FileReader();
      reader.readAsDataURL(values.file);*/
      const formData = new FormData();
      formData.append('file', values.file);
     const upLoadFileId =  await GeneradoresServices.getInstance().postImage(formData).then(async (data) => {
       return data.respuesta;
      }).catch((error) => {
        console.log(error);
        setShowGenericLoader(false);
       // toast.success("Ocurrio un error al subir el catalogo ");
      }); 

      const body = {
        "comment": "",
        "endDate": convertDateFormat(values.fechaFinal),
        "fileName": values.nombreCatalogo,
        "idCompany": companyId,
        "startDate": convertDateFormat(values.fechaInicial)
      };
      
      try {
        const uploadCatalogue = await createCatalogue(upLoadFileId, body);
        if (uploadCatalogue) {
          console.log("funcione");
          getCatalogues(companyId);
          addNewCatalog(values);
          setShowGenericLoader(false);
          toast.success("Catálogo creado con éxito");
        } else {
          throw new Error("Error al subir el catálogo");
        }
      } catch (error) {
        setShowGenericLoader(false);
        console.log("error subi", error);
        toast.error("Ocurrió un error al subir el catálogo");
      }
      
     // addNewCatalog(values)
    },
  });

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = () => {
    formik.setFieldValue('file', null)
  };

  return (
    <Box className={classes.container}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} justifyContent='center'>
          <Grid container item xs={12} spacing={4}>
            <Grid item xs={12}>
              <label className={classes.labelInput} htmlFor="nombreCatalogo">Nombre del catálogo</label>
              <TextField
                size='small'
                name="nombreCatalogo"
                placeholder='Nombre del catálogo'
                value={formik.values.nombreCatalogo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={classes.textarea}
                variant="outlined"
                error={formik.touched.nombreCatalogo && Boolean(formik.errors.nombreCatalogo)}
                helperText={formik.touched.nombreCatalogo && formik.errors.nombreCatalogo}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={4} alignItems="center">
            {/* <Grid className={classes.zIndx} item xs={6}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={handleButtonClick}
              >
                Selecciona archivo
              </Button>
            </Grid>
            <Grid item className={classes.middleText}>
              <Typography>o</Typography>
            </Grid> */}
            <Grid className={classes.zIndx} item xs={12}>
              <Dropzone onDrop={(acceptedFiles) => formik.setFieldValue('file', acceptedFiles[0])}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className={classes.dropzone}>
                    <input {...getInputProps()} />
                    <CloudUpload className={classes.icon} />
                    <p>Seleccione</p>
                    <p>o</p>
                    <p>Arrastre aquí el documento para subirlo</p>
                    <Box display={'flex'} width={'100%'} justifyContent={'center'} marginBottom={1} marginLeft={2} >
                      <FormHelperText>Formato requerido: .xls, .xlsx</FormHelperText>
                    </Box>
                  </div>
                )}
              </Dropzone>
              <input
                type="file"
                ref={fileInputRef}
                className={classes.hiddenInput}
                onChange={(e) => formik.setFieldValue('file', e.target.files[0])}
              />
            </Grid>
            {formik.touched.file && formik.errors.file && (
              <Box display={'flex'} width={'100%'} marginBottom={1} marginLeft={2} >
                <FormHelperText error>{formik.errors.file}</FormHelperText>
              </Box>
            )}
            {formik.values.file && (
              <Box display={'flex'} width={'100%'} marginBottom={3} marginLeft={2} >
                <Typography variant="body1">{formik.values.file.name}</Typography>
                <IconButton
                  onClick={handleRemoveFile}
                  style={{ marginLeft: '16px', padding: 0 }}
                >
                  <Delete />
                </IconButton>
              </Box>
            )}
          </Grid>

          <Grid container item xs={12} spacing={4}>
            <Grid item xs={6}>
              <label className={classes.labelInput} htmlFor="fechaInicial">Fecha inicial de uso*</label>
              <TextField
                size='small'
                type="date"
                variant='outlined'
                name="fechaInicial"
                value={parseDateString(formik.values.fechaInicial)}
                onChange={(e) => formik.setFieldValue('fechaInicial', formatDateString(e.target.value))}
                onBlur={formik.handleBlur}
                className={classes.input}
                error={formik.touched.fechaInicial && Boolean(formik.errors.fechaInicial)}
                helperText={formik.touched.fechaInicial && formik.errors.fechaInicial}
              />
            </Grid>
            <Grid item xs={6}>
              <label className={classes.labelInput} htmlFor="fechaFinal">Fecha final de uso*</label>
              <TextField
                size='small'
                variant='outlined'
                type="date"
                name="fechaFinal"
                value={parseDateString(formik.values.fechaFinal)}
                onChange={(e) => formik.setFieldValue('fechaFinal', formatDateString(e.target.value))}
                onBlur={formik.handleBlur}
                className={classes.input}
                error={formik.touched.fechaFinal && Boolean(formik.errors.fechaFinal)}
                helperText={formik.touched.fechaFinal && formik.errors.fechaFinal}
              />
            </Grid>
          </Grid>
          <Box>
            <Typography className={classes.textOverButton}>*Campos Obligatorios</Typography>
            <Button color="primary" variant="contained" type="submit">
              SUBIR
            </Button>
          </Box>
        </Grid>
      </form>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  textOverButton: {
    marginBottom: '5px',
    marginTop: '15px'
  },
  hiddenInput: {
    display: 'none',
  },
  labelInput: {
    textAlign: 'left',
    fontWeight: 'bold',
    marginBottom: '10px',
    display: 'block'
  },
  input: {
    marginRight: theme.spacing(2),
    width: '100%',
    color: theme.palette.primary.dark
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(3),
    width: '100%',
    color: 'white',
    zIndex: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  dropzone: {
    border: '2px dashed #ccc',
    borderRadius: '4px',
    padding: theme.spacing(3),
    textAlign: 'center',
    idth: '100%',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 'bold'
  },
  zIndx: {
    zIndex: 1
  },
  middleText: {
    position: 'absolute',
    left: '0',
    right: '0'
  },
  dateField: {
    width: '100%',
  },
  textarea: {
    width: '100%',
    color: theme.palette.primary.dark
  },
  icon: {
    fontSize: '48px',
    color: theme.palette.primary.main,
  },
}));

export default CatalogFormModal;
