import { useMemo, useState } from "react";
import {
  Menu,
  Box,
  MenuList,
  makeStyles,
  ListSubheader,
  Button,
  Backdrop,
  MenuItem,
  Typography,
  IconButton,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { NotificationsService } from "../../core/services/notifications.service";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { timeAgo } from "../../core/utils";
import useNotificationState from "../../store/notificationState";
import { Notification } from "../../core/models/notification.models";
import { sliceText } from "../../core/utils/utils";
import useGlobalState from "../../store/globalState";

type Props = {
  onClose: () => void;
  anchorElement?: HTMLElement;
};

const Notifications = ({ onClose, anchorElement }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const { unReadNotifications, setUnreadNotifications } = useNotificationState();
  const [notifcationsCount, setNotificationsCount] = useState(15);
  const { typeService } = useGlobalState();
  

  const showedNotifications = useMemo(() => {
    return unReadNotifications.slice(0, notifcationsCount);
  }, [unReadNotifications, notifcationsCount]);

  function handleShowMore() {
    setNotificationsCount(notifcationsCount + 15);
  }

  function handleClickNotification(notification: Notification) {
    //TODO: TEMPORAL
    let url = '';
    const entity = notification.subText?.split(":")[0] === "Proveedor" ? "providers" : "clients";
    if (typeService === 'generators') {
      url = `/dashboard/home/${entity}/stages/generators/${notification.action?.split("/")?.pop()}`;
    } else {
      url = `/dashboard/home/${entity}/stages/${notification.action?.split("/")?.pop()}`;
    }

    if (url) {
      onClose();
      history.push(url);
    }
  }

  function markAsRead(id: string) {

    if(typeService === "generators"){
      NotificationsService.getInstance()
      .markAsReadGeneradores(id)
      .then(() => {
        setUnreadNotifications(unReadNotifications.filter((noti) => noti.id !== id));
      });
    } else {
      NotificationsService.getInstance()
      .markAsRead(id)
      .then(() => {
        setUnreadNotifications(unReadNotifications.filter((noti) => noti.id !== id));
      });
    }

  }

  const hasMoreNotifications = notifcationsCount < unReadNotifications.length;

  return (
    <Backdrop open={Boolean(anchorElement)}>
      <Menu
        id="simple-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={onClose}
        PaperProps={{
          style: {
            maxHeight: "70vh",
            width: "450px",
          },
        }}
      >
        <MenuList style={{ padding: 5, backgroundColor: "white", width: "100%" }}>
          <ListSubheader className={classes.notificationTitle}>Notificaciones</ListSubheader>
          {showedNotifications.length ? (
            showedNotifications.map((noti) => (
              <NotificationItem
                key={noti.id}
                notification={noti}
                onClick={() => handleClickNotification(noti)}
                onMarksAsRead={() => markAsRead(noti.id)}
              />
            ))
          ) : (
            <div style={{ width: "100%" }}>
              <Typography variant="body1" style={{ textAlign: "center", padding: "1rem" }}>
                No hay notificaciones
              </Typography>
            </div>
          )}
        </MenuList>
        {hasMoreNotifications && (
          <Box style={{ width: "100%", display: "flex", justifyContent: "center", padding: "1rem" }}>
            <Button onClick={handleShowMore} variant="outlined" color="primary" size="small">
              Ver más
            </Button>
          </Box>
        )}
      </Menu>
    </Backdrop>
  );
};

const NotificationItem = ({
  notification,
  onClick,
  onMarksAsRead,
}: {
  notification: Notification;
  onClick: () => void;
  onMarksAsRead?: () => void;
}) => {
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState(false);

  function handleMarksAsRead(e: any) {
    e.stopPropagation();
    onMarksAsRead && onMarksAsRead();
  }

  return (
    <MenuItem
      onClick={onClick}
      style={{ width: "100%", whiteSpace: "normal" }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div style={{ minWidth: "80px" }}>
        <img src={"/icons/" + getIconName(notification.type)} style={{ height: "60px" }} alt="icon" />
      </div>
      <Tooltip title={notification.subText}>
        <div style={{ width: "100%" }}>
          <p style={{ fontSize: "14px", color: "rgb(0,0,0,0.8)" }}>{isHovering ? notification.text : sliceText(notification.text, 90)}</p>
          <Typography variant="caption">{timeAgo.format(new Date(notification.createdAt))}</Typography>
        </div>
      </Tooltip>
      <Tooltip title="Marcar como leído">
        <IconButton style={{ padding: "0px" }} onClick={handleMarksAsRead}>
          <DoneAllIcon fontSize="small" htmlColor={theme.palette.primary.main} />
        </IconButton>
      </Tooltip>
    </MenuItem>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    notificationsMenuBox: {
      position: "relative",
      marginTop: "10px",
      "&::before": {
        backgroundColor: "white",
        content: '""',
        display: "block",
        position: "absolute",
        width: 12,
        height: 12,
        top: -6,
        transform: "rotate(45deg)",
        right: 164,
      },
    },
    notificationTitle: {
      fontSize: "1.255rem",
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      color: theme.palette.black.light,
    },
    notificationSubheader: {
      fontSize: "1.25rem",
      fontWeight: 600,
      color: theme.palette.black.light,
    },
  };
});

export default Notifications;

function getIconName(nameState: string) {
  switch (nameState) {
    case "REJECTEDDOCUMENT":
      return "doc-rejected.svg";
    case "DOCUMENT_REJECTED":
      return "doc-rejected.svg";
    case "DOCUMENT_UNDER_REVIEW":
      return "doc-review.svg";
    case "DOCUMENT_UPDATED":
      return "doc-updated.svg";
    case "DOCUMENT_ACCEPTED":
      return "doc-accepted.svg";
    case "PERCENT":
      return "doc-percent.svg";
    case "NOMINACOMPLETED":
      return "nomina-completed.svg";
    case "CFDIREJECTED":
      return "cfdi-rejected.svg";
    case "SERVICEADDED":
      return "service-added.svg";
    case "INVITATIONACEPTED":
      return "invitation-accepted.svg";
    case "INVITATIONREJECTED":
      return "invitation-rejected.svg";
    case "INVITATION":
      return "invitation.svg";
    default:
      return "default.svg";
  }
}
