import { toast } from "react-toastify";

const useCustomToast = () => {
  function success(message: string | JSX.Element) {
    toast.success(message, {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme:"light",
      progress: 0,
    });
  }

  function error(message: string | JSX.Element) {
    toast.error(message, {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme:"colored",
      progress: 0,
    });
  }
  
  function warnign(message: string | JSX.Element) {
    toast.warn(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme:"colored",
      progress: 0,
    });
  }

  function info(message: string | JSX.Element) {
    toast.info(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme:"colored",
      progress: 0,
    });
  }

  return { success, error , warnign , info };
};

export default useCustomToast;
