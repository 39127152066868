import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ContractMenuIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="379" height="108" viewBox="0 0 379 108" style={{ ...props.style,  width: '3.5em', height: '1em' }}>
      <path d="M250.523 14.8906H240.613V55.4433H250.523V14.8906Z" />
      <path d="M250.523 8.13477H240.613V11.3007H250.523V8.13477Z" />
      <path d="M330.072 8.13477H320.162V11.3007H330.072V8.13477Z" />
      <path d="M330.072 14.8906H320.162V55.4433H330.072V14.8906Z" />
      <path d="M378.307 23.7458V14.9042H358.41V4.4668H348.501V14.9042H336.918V23.7458H348.501V38.6876C348.353 42.3583 348.872 46.0254 350.032 49.511C350.494 50.764 351.203 51.9118 352.116 52.8866C353.029 53.8614 354.128 54.6435 355.348 55.1866C358.555 56.3951 361.974 56.9423 365.399 56.7954C369.495 56.7279 373.581 56.3666 377.625 55.7143L378.281 55.6113V46.8985L377.406 47.0143C369.337 48.0696 363.906 48.0825 361.422 47.0143C360.872 46.7799 360.379 46.4281 359.979 45.9835C359.579 45.5389 359.281 45.0122 359.105 44.4403C358.559 42.3412 358.324 40.1729 358.41 38.0054V23.7458H378.307Z" />
      <path d="M208.284 29.9365C214.801 22.7983 220.42 14.8888 225.015 6.38477L213.213 6.66794C206.521 17.5944 196.483 30.2711 185.723 33.8231L185.093 7.22133L175.016 7.46586L176.174 56.2809L186.251 56.0364L185.968 44.2219C191.247 42.9144 196.181 40.4824 200.433 37.0921C208.078 40.8758 217.64 48.9194 222.968 55.0326L235.117 54.7494C230.497 46.4355 217.846 35.6507 208.284 29.9365Z" />
      <path d="M291.847 55.4573C301.062 43.1023 313.996 23.3472 313.996 14.8789H303.919C303.919 18.2122 294.498 34.7498 285.348 47.3751C276.184 34.7498 266.828 18.2122 266.777 14.8789H256.738C256.738 23.3472 269.672 43.1023 278.9 55.4573H291.847Z" />
      <path d="M109.547 36.3957C107.204 34.3623 104.798 32.4318 102.34 30.5657C104.794 28.6867 107.196 26.7391 109.547 24.7228C118.059 17.3626 125.717 9.06973 132.378 0H104.592C83.8465 21.758 56.0754 35.4794 26.1895 38.738V60.3979C46.0967 58.4557 65.4126 52.5436 82.9965 43.0108C90.825 48.227 98.0506 54.2957 104.541 61.1057H132.326C125.685 52.04 118.043 43.751 109.547 36.3957Z" />
      <path d="M22.3797 0H0.925781V3.80945H22.3797V0Z" />
      <path d="M0.925781 61.1059H11.7108C15.2886 61.1059 18.8404 60.9644 22.3925 60.707V7.61914H0.925781V61.1059Z" />
      <path d="M15.7481 92.4038C15.7481 88.8646 13.8562 87.2559 10.214 87.2559C6.5719 87.2559 3.97237 89.3021 3.94663 94.5401C3.92089 99.7781 6.58493 102.133 10.3815 102.133C14.1781 102.133 15.8641 100.241 15.8641 96.9854V96.4063H18.438C18.438 96.6637 18.438 96.8567 18.438 97.1656C18.438 101.644 15.8641 104.386 10.4073 104.411C4.9505 104.437 1.39844 101.245 1.39844 94.5145C1.39844 88.3498 4.74458 84.875 10.4073 84.875C16.07 84.875 18.3865 87.7449 18.3865 91.9018C18.3865 92.2493 18.3865 92.5067 18.3865 92.8542C17.9746 92.8542 17.5499 92.8542 17.0995 92.8542C16.6491 92.8542 16.263 92.8542 15.8125 92.8542L15.7481 92.4038Z" />
      <path d="M35.3762 89.1113C35.3762 91.1576 35.2989 93.1653 35.2989 96.4342C35.2989 99.7032 35.299 102.251 35.3762 104.246H32.8023C32.8023 103.229 32.8024 102.432 32.9053 101.428H32.7765C31.7984 103.461 29.7651 104.516 26.8437 104.516C23.1629 104.516 21.1808 102.56 21.1808 98.931C21.1808 96.357 21.1808 95.5076 21.1808 93.9504C21.1808 92.3931 21.1807 90.7072 21.1035 89.1371H23.6775C23.6775 91.5309 23.6005 94.285 23.6005 98.429C23.6005 101.093 24.7845 102.534 27.7574 102.534C30.7303 102.534 32.9053 101.067 32.9053 97.8756C32.9053 92.5862 32.9053 90.7072 32.9053 89.1371L35.3762 89.1113Z" />
      <path d="M58.6052 104.245C58.6052 102.173 58.6824 98.5826 58.6824 94.5543C58.6824 92.1606 57.7299 90.9766 55.3104 90.9766C54.7261 90.9294 54.1388 91.012 53.5901 91.2184C53.0415 91.4248 52.5453 91.7499 52.137 92.1705C51.7287 92.5911 51.4183 93.0967 51.2283 93.6512C51.0383 94.2058 50.9731 94.7954 51.0376 95.378C51.0376 99.9339 51.0376 102.199 51.0376 104.245H48.5924C48.5924 102.148 48.6697 99.432 48.6697 94.6316C48.6697 92.212 47.7429 90.9766 45.2977 90.9766C44.7135 90.9321 44.1268 91.0163 43.5787 91.2234C43.0306 91.4305 42.5345 91.7555 42.1258 92.1753C41.7171 92.5951 41.4055 93.0995 41.213 93.6529C41.0206 94.2063 40.9519 94.7952 41.012 95.378C41.012 99.239 41.012 102.675 41.012 104.245H38.6055C38.6055 102.148 38.6055 100.063 38.6055 96.961C38.6055 93.6148 38.6055 91.3498 38.6055 89.1105H41.0892C41.0892 90.1143 41.0892 90.8479 41.012 91.8517H41.1408C41.5535 90.8933 42.2555 90.0878 43.1485 89.5478C44.0415 89.0079 45.081 88.7604 46.1215 88.8402C48.5668 88.8402 50.2782 89.8441 50.7029 91.9547H50.8575C51.2764 90.9565 51.9992 90.1157 52.9234 89.5519C53.8476 88.9882 54.9262 88.7301 56.0054 88.8145C59.1199 88.8145 60.9347 90.3717 60.9347 93.6406C60.9347 95.5068 60.8574 97.5016 60.8574 99.1746C60.8574 100.848 60.8571 102.727 60.9601 104.194L58.6052 104.245Z" />
      <path d="M66.9465 92.1348C67.4868 91.0562 68.3391 90.1652 69.3926 89.5777C70.4462 88.9901 71.6523 88.733 72.8539 88.8401C76.9851 88.8401 79.044 91.7358 79.044 96.562C79.044 101.388 76.7018 104.464 72.7251 104.464C71.6653 104.527 70.6042 104.36 69.6154 103.973C68.6266 103.587 67.7338 102.99 66.9981 102.225H66.8435C66.8435 103.074 66.8435 103.756 66.8435 104.696C66.8435 105.635 66.8435 106.549 66.8435 107.63H64.2695C64.3467 103.46 64.3468 99.4835 64.3468 95.6997C64.3468 91.916 64.3468 90.4231 64.3468 89.0074H66.9208C66.9208 89.6381 66.9208 90.1657 66.9208 90.8736C66.9208 91.2211 66.9208 91.5814 66.9208 92.0061L66.9465 92.1348ZM66.8435 100.989C68.3225 102.026 70.0959 102.558 71.9012 102.508C75.0672 102.508 76.6375 100.384 76.6375 96.6135C76.6375 92.8427 75.0673 90.8221 72.03 90.8221C69.2373 90.8221 66.8821 92.3407 66.8821 95.5324L66.8435 100.989Z" />
      <path d="M81.3848 104.245C81.462 101.143 81.4621 97.9513 81.4621 94.5536C81.4621 90.6026 81.462 88.0029 81.3848 84.7598H83.9587C83.8815 87.9257 83.9587 90.5254 83.9587 94.6437C83.9587 98.2215 83.9587 101.079 83.9587 104.245H81.3848Z" />
      <path d="M88.7867 87.4514C87.3839 87.4514 87.0234 86.8979 87.0234 86.2673C87.0234 85.6367 87.4097 85.1348 88.7867 85.1348C90.1638 85.1348 90.5755 85.5852 90.5755 86.2673C90.5755 86.9494 90.2282 87.4514 88.7867 87.4514ZM87.4998 104.246C87.577 101.672 87.5771 99.5875 87.5771 96.4087C87.5771 93.2299 87.577 91.4796 87.4998 89.1116H90.0737C89.9708 91.4796 89.9707 93.3972 89.9707 96.4859C89.9707 99.7806 89.9708 101.724 90.0737 104.208L87.4998 104.246Z" />
      <path d="M113.573 104.245C113.573 102.173 113.65 98.5826 113.65 94.5543C113.65 92.1606 112.698 90.9766 110.278 90.9766C109.694 90.9294 109.106 91.012 108.558 91.2184C108.009 91.4248 107.513 91.7499 107.105 92.1705C106.696 92.5911 106.386 93.0967 106.196 93.6512C106.006 94.2058 105.941 94.7954 106.005 95.378C106.005 99.9339 106.005 102.199 106.005 104.245H103.586C103.586 102.148 103.663 99.432 103.663 94.6316C103.663 92.212 102.723 90.9766 100.291 90.9766C99.7068 90.9321 99.1201 91.0163 98.572 91.2234C98.024 91.4305 97.5279 91.7555 97.1192 92.1753C96.7104 92.5951 96.3988 93.0995 96.2064 93.6529C96.014 94.2063 95.9455 94.7952 96.0056 95.378C96.0056 99.239 96.0056 102.675 96.0056 104.245H93.5859C93.5859 102.148 93.5859 100.063 93.5859 96.961C93.5859 93.6148 93.5859 91.3498 93.5859 89.1105H96.0826C96.0826 90.1143 96.0828 90.8479 96.0056 91.8517H96.1341C96.5453 90.8921 97.2469 90.0855 98.1403 89.5453C99.0337 89.0051 100.074 88.7585 101.115 88.8402C103.56 88.8402 105.272 89.8441 105.696 91.9547H105.851C106.27 90.9565 106.993 90.1157 107.917 89.5519C108.841 88.9882 109.92 88.7301 110.999 88.8145C114.113 88.8145 115.928 90.3717 115.928 93.6406C115.928 95.5068 115.851 97.5016 115.851 99.1746C115.851 100.848 115.851 102.727 115.954 104.194L113.573 104.245Z" />
      <path d="M120.804 87.4514C119.388 87.4514 119.041 86.8979 119.041 86.2673C119.041 85.6367 119.414 85.1348 120.804 85.1348C122.194 85.1348 122.58 85.5852 122.58 86.2673C122.58 86.9494 122.233 87.4514 120.804 87.4514ZM119.517 104.246C119.594 101.672 119.594 99.5875 119.594 96.4087C119.594 93.2299 119.594 91.4796 119.517 89.1116H122.091C121.988 91.4796 121.988 93.3972 121.988 96.4859C121.988 99.7806 121.988 101.724 122.091 104.208L119.517 104.246Z" />
      <path d="M138.642 99.1621C138.719 102.045 137.535 104.567 132.207 104.567C127.638 104.567 124.717 101.993 124.717 96.6911C124.717 91.3888 127.703 88.7891 132.259 88.7891C136.815 88.7891 138.694 91.2729 138.694 95.0309C138.694 95.8288 138.694 96.6139 138.694 97.2188C137.587 97.2188 135.502 97.2188 132.58 97.2188C130.637 97.2188 128.629 97.2188 127.021 97.2188C127.136 100.964 129.028 102.753 132.246 102.753C135.463 102.753 136.48 101.324 136.48 99.2136C136.802 99.2136 137.162 99.2136 137.638 99.2136C138.114 99.2136 138.333 99.1878 138.642 99.1621ZM127.059 95.7645C129.633 95.7645 134.434 95.7645 136.441 95.7645C136.441 95.4813 136.441 95.1338 136.441 94.7992C136.441 92.2253 135.154 90.6294 132.143 90.6294C129.131 90.6294 127.265 92.1867 126.995 95.7645H127.059Z" />
      <path d="M153.187 104.245C153.187 101.877 153.265 99.0971 153.265 95.0817C153.265 92.4048 152.106 90.9505 149.12 90.9505C146.135 90.9505 143.972 92.3791 143.972 95.5579C143.972 100.822 143.972 102.701 143.972 104.245H141.398C141.398 102.199 141.476 100.165 141.476 96.935C141.476 93.7047 141.476 91.0792 141.398 89.1101H143.972C143.972 90.114 143.972 90.9248 143.972 91.9286H144.114C145.105 89.8952 147.164 88.8398 150.06 88.8398C153.754 88.8398 155.697 90.7703 155.697 94.5798C155.697 97.0637 155.697 97.9002 155.697 99.406C155.697 101.118 155.697 102.649 155.774 104.219L153.187 104.245Z" />
      <path d="M157.369 91.2087C157.369 90.6038 157.369 89.7673 157.369 89.1109H159.66V85.9707H160.947C161.346 85.9707 161.771 85.9707 162.157 85.9707C162.157 86.9745 162.157 88.0556 162.157 89.1624C164.37 89.1624 166.751 89.1624 169.145 89.1624C169.145 89.8187 169.145 90.681 169.145 91.3503C167.086 91.3503 164.563 91.3503 162.182 91.3503C162.182 93.7569 162.182 96.4339 162.182 99.3424C162.182 101.337 163.135 102.393 165.722 102.393C166.889 102.39 168.047 102.186 169.145 101.788C169.222 102.521 169.402 103.396 169.505 104.001C168.187 104.379 166.823 104.573 165.451 104.58C161.372 104.58 159.763 102.573 159.763 99.5998C159.763 97.5407 159.763 94.4519 159.763 91.3245L157.369 91.2087Z" />
      <path d="M177.38 104.568C172.876 104.568 169.658 101.621 169.658 96.4855C169.658 91.3505 172.953 88.7637 177.38 88.7637C181.807 88.7637 185.102 91.3376 185.102 96.4855C185.102 101.633 181.91 104.568 177.38 104.568ZM177.38 90.9258C174.33 90.9258 172.09 92.6632 172.09 96.5628C172.09 100.462 174.278 102.457 177.38 102.457C180.482 102.457 182.682 100.411 182.682 96.5628C182.682 92.7147 180.443 90.9258 177.38 90.9258Z" />
      <path d="M206.827 99.1621C206.891 102.045 205.72 104.567 200.392 104.567C195.81 104.567 192.889 101.993 192.889 96.6911C192.889 91.3888 195.887 88.7891 200.443 88.7891C204.999 88.7891 206.878 91.2729 206.878 95.0309C206.878 95.8288 206.878 96.6139 206.878 97.2188C205.771 97.2188 203.674 97.2188 200.752 97.2188C198.822 97.2188 196.801 97.2188 195.192 97.2188C195.321 100.964 197.213 102.753 200.43 102.753C203.648 102.753 204.665 101.324 204.665 99.2136C204.986 99.2136 205.334 99.2136 205.81 99.2136C206.286 99.2136 206.544 99.1878 206.827 99.1621ZM195.167 95.7645C197.805 95.7645 202.541 95.7645 204.562 95.7645C204.562 95.4813 204.562 95.1338 204.562 94.7992C204.562 92.2253 203.275 90.6294 200.25 90.6294C197.226 90.6294 195.45 92.1867 195.167 95.7645Z" />
      <path d="M221.368 104.245C221.368 101.877 221.445 99.0971 221.445 95.0817C221.445 92.4048 220.287 90.9505 217.288 90.9505C214.29 90.9505 212.14 92.3791 212.14 95.5579C212.14 100.822 212.14 102.701 212.14 104.245H209.566C209.566 102.199 209.643 100.165 209.643 96.935C209.643 93.7047 209.644 91.0792 209.566 89.1101H212.14C212.14 90.114 212.14 90.9248 212.14 91.9286H212.295C213.273 89.8952 215.332 88.8398 218.228 88.8398C221.934 88.8398 223.865 90.7703 223.865 94.5798C223.865 97.0637 223.865 97.9002 223.865 99.406C223.865 101.118 223.865 102.649 223.929 104.219L221.368 104.245Z" />
      <path d="M247.727 89.1113C247.727 91.1576 247.65 93.1653 247.65 96.4342C247.65 99.7032 247.65 102.251 247.727 104.246H245.153C245.153 103.229 245.153 102.432 245.256 101.428H245.127C244.149 103.461 242.103 104.516 239.182 104.516C235.514 104.516 233.519 102.56 233.519 98.931C233.519 96.357 233.519 95.5076 233.519 93.9504C233.519 92.3931 233.519 90.7072 233.441 89.1371H236.015C236.015 91.5309 235.938 94.285 235.938 98.429C235.938 101.093 237.122 102.534 240.095 102.534C243.068 102.534 245.243 101.067 245.243 97.8756C245.243 92.5862 245.243 90.7072 245.243 89.1371L247.727 89.1113Z" />
      <path d="M262.745 104.245C262.745 101.877 262.822 99.0971 262.822 95.0817C262.822 92.4048 261.664 90.9505 258.665 90.9505C255.667 90.9505 253.517 92.3791 253.517 95.5579C253.517 100.822 253.517 102.701 253.517 104.245H250.943C250.943 102.199 251.021 100.165 251.021 96.935C251.021 93.7047 251.021 91.0792 250.943 89.1101H253.517C253.517 90.114 253.517 90.9248 253.517 91.9286H253.672C254.65 89.8952 256.709 88.8398 259.605 88.8398C263.311 88.8398 265.242 90.7703 265.242 94.5798C265.242 97.0637 265.242 97.9002 265.242 99.406C265.242 101.118 265.242 102.649 265.319 104.219L262.745 104.245Z" />
      <path d="M285.59 100.128C285.59 98.597 284.561 97.8119 280.34 97.3872C275.707 96.9368 274.342 95.4182 274.342 92.9987C274.342 90.1544 276.659 88.7773 280.996 88.7773C285.539 88.7773 287.559 90.2059 287.559 93.1788C287.559 93.552 287.559 93.7837 287.559 94.1569C287.263 94.1569 286.8 94.1569 286.375 94.1569C285.951 94.1569 285.526 94.1569 285.294 94.1569C285.294 93.9124 285.294 93.5778 285.294 93.4105C285.294 91.5701 284.11 90.7078 280.996 90.7078C277.882 90.7078 276.71 91.4929 276.71 93.0244C276.71 94.5559 277.598 95.2251 281.742 95.5983C286.401 96.1002 287.933 97.4387 287.933 100.128C287.933 102.818 285.848 104.53 281.189 104.53C276.157 104.53 274.098 102.921 274.098 99.8454C274.098 99.5751 274.098 99.3434 274.098 99.0474C274.394 99.0474 274.793 99.0474 275.282 99.0474C275.771 99.0474 276.105 99.0474 276.389 99.0474C276.376 99.2488 276.376 99.4509 276.389 99.6523C276.389 101.737 277.92 102.548 281.215 102.548C284.509 102.548 285.59 101.802 285.59 100.128Z" />
      <path d="M297.071 104.568C292.566 104.568 289.426 101.621 289.426 96.4855C289.426 91.3505 292.721 88.7637 297.071 88.7637C301.421 88.7637 304.793 91.3376 304.793 96.4855C304.793 101.633 301.601 104.568 297.071 104.568ZM297.071 90.9258C294.021 90.9258 291.781 92.6632 291.781 96.5628C291.781 100.462 293.982 102.457 297.071 102.457C300.159 102.457 302.386 100.411 302.386 96.5628C302.386 92.7147 300.147 90.9258 297.071 90.9258Z" />
      <path d="M307.043 104.245C307.043 101.143 307.043 97.9513 307.043 94.5536C307.043 90.6026 307.043 88.0029 307.043 84.7598H309.617C309.54 87.9257 309.617 90.5254 309.617 94.6437C309.617 98.2215 309.617 101.079 309.617 104.245H307.043Z" />
      <path d="M319.541 104.568C315.037 104.568 311.896 101.621 311.896 96.4855C311.896 91.3505 315.191 88.7637 319.541 88.7637C323.891 88.7637 327.263 91.3376 327.263 96.4855C327.263 101.633 324.071 104.568 319.541 104.568ZM319.541 90.9258C316.504 90.9258 314.264 92.6632 314.264 96.5628C314.264 100.462 316.452 102.457 319.541 102.457C322.63 102.457 324.856 100.411 324.856 96.5628C324.856 92.7147 322.617 90.9258 319.541 90.9258Z" />
      <path d="M347.249 98.2487C347.584 98.2487 347.957 98.2487 348.381 98.2487C348.806 98.2487 349.243 98.2487 349.565 98.2487C349.579 98.3813 349.579 98.515 349.565 98.6477C349.565 101.929 347.789 104.568 342.603 104.568C338.021 104.568 334.945 101.878 334.945 96.6143C334.945 91.3505 338.021 88.7637 342.667 88.7637C347.03 88.7637 349.424 90.7714 349.424 94.5294V94.9798H348.266H347.159V94.6066C347.159 92.1871 345.794 90.8743 342.629 90.8743C339.463 90.8743 337.262 92.5603 337.262 96.5885C337.262 100.617 339.372 102.406 342.641 102.406C345.91 102.406 347.172 100.823 347.172 98.5447L347.249 98.2487Z" />
      <path d="M352.062 104.245C352.14 101.143 352.139 97.9513 352.139 94.5536C352.139 90.6026 352.14 88.0029 352.062 84.7598H354.636C354.572 87.9257 354.636 90.5254 354.636 94.6437C354.636 98.2215 354.636 101.079 354.636 104.245H352.062Z" />
      <path d="M359.464 87.4514C358.049 87.4514 357.701 86.8979 357.701 86.2673C357.701 85.6367 358.075 85.1348 359.464 85.1348C360.854 85.1348 361.253 85.5852 361.253 86.2673C361.253 86.9494 360.893 87.4514 359.464 87.4514ZM358.178 104.246C358.255 101.672 358.254 99.5875 358.254 96.4087C358.254 93.2299 358.255 91.4796 358.178 89.1116H360.751C360.649 91.4796 360.648 93.3972 360.648 96.4859C360.648 99.7806 360.649 101.724 360.751 104.208L358.178 104.246Z" />
      <path d="M375.561 98.2487C375.896 98.2487 376.269 98.2487 376.694 98.2487C377.118 98.2487 377.556 98.2487 377.878 98.2487C377.892 98.3813 377.892 98.515 377.878 98.6477C377.878 101.929 376.089 104.568 370.915 104.568C366.334 104.568 363.258 101.878 363.258 96.6143C363.258 91.3505 366.334 88.7637 370.98 88.7637C375.343 88.7637 377.736 90.7714 377.736 94.5294V94.9798H376.578H375.471V94.6066C375.471 92.1871 374.107 90.8743 370.928 90.8743C367.749 90.8743 365.574 92.5603 365.574 96.5885C365.574 100.617 367.685 102.406 370.954 102.406C374.223 102.406 375.484 100.823 375.484 98.5447L375.561 98.2487Z" />
    </SvgIcon>
  )
}

export default ContractMenuIcon;
