import { axiosBusinessRequest, axiosRequestBlockChain, authHeader } from "../../configs/axios.config"

export class FoldersHttp {
  createFolder(id_company, name, success, error) {
    axiosBusinessRequest.post(`/folders?companyId=${id_company}`, {name}, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  createSubFolder(companyId, path, name, success, error) {
    axiosBusinessRequest.post(`/folders/create-subfolder?companyId=${companyId}`, {path: path, name: name}, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  async foldersCompanies(idCompany,success, error) {
    await axiosBusinessRequest.get(`/folders/folders-companies/${idCompany}`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  foldersCompaniesByUser(userId, success, error) {
    axiosBusinessRequest.get(`/folders/folders-companies/by-user/${userId}`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  async getFolderInfo(id_folder, companyId, success, error) {
    await axiosBusinessRequest.get(`/folders/folder-details/${id_folder}/${companyId}`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  getFoldersDetails(success, error) {
    axiosBusinessRequest.get(`/companies/all-companies-by-user`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  getContractFolderByCompanyId(companyId, path,success, error) {
    axiosBusinessRequest.get(`folders/subfolder-detail/${companyId}/${path}`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  getContractsSubFoldersByCompanyId(companyId, folderId, success, error) {
    axiosBusinessRequest.get(`/folders/folders-companies/${companyId}?folderId=${folderId}`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }

  async checkKaleidoConnection(companyId, success, error) {
    await axiosRequestBlockChain.get(`/folder/kaleido?companyId=${companyId}`, {headers: authHeader()})
    .then(({ data }) => success(data))
    .catch(errorResp => error(errorResp))
  }
}
