import { authHeader, axiosGeneradoresRequest, axiosOrkestadocRequest, axiosPagareRequest } from "../../configs/axios.config";

export class GeneradoresServices {
  private static instance: GeneradoresServices;

  private constructor() {}

  public static getInstance(): GeneradoresServices {
    if (!GeneradoresServices.instance) {
      GeneradoresServices.instance = new GeneradoresServices();
    }
    return GeneradoresServices.instance;
  }

  async postImage(formData: any) {
    try {
      const { data } = await axiosGeneradoresRequest.post<any>(`/util/uploadToSession`, formData, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getImage(id: string) {
    try {
      const { data } = await axiosPagareRequest.get<any>(`/general/vault/documentData/${id}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getVaultDocument(id: string) {
    try {
      const { data } = await axiosPagareRequest.get<any>(`/general/vault/documentData/${id}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }

  }
  
  async getPdf(id: string) {
    try {
      const { data } = await axiosGeneradoresRequest.post<any>(`/generador/pdfFileId/${id}`, {}, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async patchProviderLogo(generadorId: string, providerLogoId: string) {
    try {
      const { data } = await axiosGeneradoresRequest.patch<any>(`/generador/id/${generadorId}/providerLogoId/${providerLogoId}`, {}, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async patchGeolocationImage(generadorId: string, finalImageId: string) {
    try {
      const { data } = await axiosGeneradoresRequest.patch<any>(`/generador/geolocation/id/${generadorId}/finalImageId/${finalImageId}`, {}, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }
  /************************************SUBIR IMAGENES A FOTOGRAFICO******************************/
  async patchPhotographicImagesBeforeList(generadorId: string, beforeList: any) {
    try {
      const { data } = await axiosGeneradoresRequest.patch<any>(`/generador/photographic/beforeList/id/${generadorId}`, beforeList, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }
  async patchPhotographicImagesDuringList(generadorId: string, duringList: any) {
    try {
      const { data } = await axiosGeneradoresRequest.patch<any>(`/generador/photographic/duringList/id/${generadorId}`, duringList, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }
  async patchPhotographicImagesEndList(generadorId: string, endList: any) {
    try {
      const { data } = await axiosGeneradoresRequest.patch<any>(`/generador/photographic/endList/id/${generadorId}`, endList, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }
  /**********************************************************************************************/
  async createFormGenerador(idCompany: string, serviceId: string, idProvider: string) {
    try {
      const { data } = await axiosGeneradoresRequest.post<any>(`/generador/init/idCompany/${idCompany}/idProvider/${idProvider}/serviceId/${serviceId}`, {}, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getGeneradoresByProvider(idProvider: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get<any>(`/generador/idProvider/${idProvider}/allLite`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getGeneradoresByProviderAndService(idProvider: string, serviceId: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get<any>(`/generador/idProvider/${idProvider}/serviceId/${serviceId}/allLite`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getGeneradorById(id: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get<any>(`/generador/id/${id}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }
   
  async patchGeneralForm(id: string, body: any) {
    try {
      const { data } = await axiosGeneradoresRequest.patch<any>(`/generador/general/id/${id}`, body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async patchGeolocationForm(id: string, body: any) {
    try {
      const { data } = await axiosGeneradoresRequest.patch<any>(`/generador/geolocation/id/${id}`, body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async patchPhotographicForm(id: string, body: any) {
    try {
      const { data } = await axiosGeneradoresRequest.patch<any>(`/generador/photographic/id/${id}`, body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getServicesByCompany(id: string) {
    try {
      const { data } = await axiosGeneradoresRequest.patch<any>(`/serviceOffered/idCompany/${id}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRegionManagerByCompany(idCompany: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get<any>(`/regionManager/fileToJson/${idCompany}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getGeneratorPDF(id: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get<any>(`/generador/generadorPdf/${id}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async patchAttachedFile(id: string, documentId: string) {
    try {
      const { data } = await axiosGeneradoresRequest.patch<any>(`/generador/id/${id}/attachedFile/${documentId}`, {}, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async generadorPDFandProcces( id : string){
    try {
      const { data } = await axiosGeneradoresRequest.post<any>(`/generador/generadorPdfAndProcess/${id}`, {}, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async disableGenerator(id :string){
    try {
      const { data } = await axiosGeneradoresRequest.delete<any>(`/generador/disableGenerador/${id}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteImageList(id :string, imageId: string, list: string){
    try {
      const { data } = await axiosGeneradoresRequest.delete<any>(`/generador/photographic/${list}/id/${id}/imageId/${imageId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDocumentGeneratorData(biddingId :string){
    try {
      const { data } = await axiosOrkestadocRequest.get<any>(`/generadorBidding/biddingId/${biddingId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async patchSaveSummaryGenerator(id: string, body: any) {
    try {
      const { data } = await axiosGeneradoresRequest.patch<any>(`/generador/summary/id/${id}`, body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

}


