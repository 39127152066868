import { DashboardColumn, DashboardCounters } from "materialidad-outsourcing/dist/types";

/** Receives a number and returns its currency format. */
export function currencyFormat(amount: number, currency = "MXN"): string {
  const options = { style: "currency", currency: currency };
  const numberFormat = new Intl.NumberFormat("en-US", options);
  return numberFormat.format(amount);
}

export const dateFormat = (date: Date) => {
  return date.toLocaleDateString("es-MX", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const getStatusColor = (status?: string) => {
  switch (status?.toLowerCase()) {
    case "aceptado":
    case "terminado":
      return "success";
    case "rechazado":
      return "error";
    case "bajo revisión":
    case "bajo revision":
    case "pendiente":
      return "warning";
    default:
      return "secondary";
  }
};

/**
 * Function that returns a percent based on the points_value of every document in every documentsColumn
 * divided by the contract required points to alliw the user to get a contract document.
 *
 * If required points exist and the sum of points of accepted documents is less than the required points, the function will return currentPoints/requiredPoints * 100.
 *
 * If the sum of points of accepted documents is greater than the required points, the function will return 100.
 *
 * If requiredPoints doesn't exist, the function will return 0.
 *
 * @return (number) Documents accepted points percent.
 */
export const getPointsPercent = (requiredPoints: number, columns?: DashboardColumn[]) => {
  let currentPoints = 0;
  !!columns &&
    columns.forEach((column) => {
      column.cards.forEach((document) => {
        const isAccepted = document.files.length > 0 && document.files[document.files.length - 1].validationStatus.status === "Aceptado";
        if (isAccepted) currentPoints += document.points;
      });
    });
  return requiredPoints ? (currentPoints < requiredPoints ? Math.round((currentPoints / requiredPoints) * 100) : 100) : 0;
};

/**
 * Calculates the number of cards inside the dashboard column.
 * @param documentsColumns Dashboard column whose total ammount of cards will be calculated.
 * @returns Number of cards inside the column.
 */
export const getTotalCards = (documentsColumns?: DashboardColumn[]) =>
  documentsColumns?.reduce((prevValue, currentValue) => prevValue + currentValue.cards.length, 0) ?? 0;

/** Function that calculates the counter of uploeaded, in review and accepted document cards.
 */
export const getCardCounters = (documentsColumns?: DashboardColumn[]) => {
  // counter[0] -> Uploaded documents.
  // counter[1] -> In review documents.
  // counter[2] -> accepted documents.
  const counters: Omit<DashboardCounters, "total"> = {
    accepted: 0,
    inReview: 0,
    uploaded: 0,
  };
  !!documentsColumns &&
    documentsColumns.forEach((column) => {
      column.cards.forEach((document) => {
        // If the document has an url, the document has been uploaded and the counter of uploaded documents is increased.
        counters.uploaded = counters.uploaded + (document.files?.length > 0 ? 1 : 0);
        // If the document status is 'Bajo revisión' the counter of 'in review' documents is increased.
        counters.inReview =
          counters.inReview +
          (document.files?.some((file) => file.validationStatus?.status?.includes("Bajo ") /*=== "Bajo revisión" TODO*/) ? 1 : 0);
        // If the document status is 'Aceptado' the counter of accepted documents is increased.
        counters.accepted =
          counters.accepted +
          (document.files.length > 0 && document.files?.[document.files.length - 1].validationStatus.status === "Aceptado" ? 1 : 0);
      });
    });
  return counters;
};

export const monthSelectOptions = [
  { label: "Enero", value: "Enero" },
  { label: "Febrero", value: "Febrero" },
  { label: "Marzo", value: "Marzo" },
  { label: "Abril", value: "Abril" },
  { label: "Mayo", value: "Mayo" },
  { label: "Junio", value: "Junio" },
  { label: "Julio", value: "Julio" },
  { label: "Agosto", value: "Agosto" },
  { label: "Septiembre", value: "Septiembre" },
  { label: "Octubre", value: "Octubre" },
  { label: "Noviembre", value: "Noviembre" },
  { label: "Diciembre", value: "Diciembre" },
] as const;

export function getYearsRange(minYear: number) {
  const years = [];
  for (let i = minYear; i <= new Date().getFullYear(); i++) {
    years.push({ label: `${i}`, value: `${i}` });
  }

  return years.reverse();
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader.result as string)?.split("base64,")[1]);
    reader.onerror = (error) => reject(error);
  });
}


function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}