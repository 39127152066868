import { Grid, Button } from "@material-ui/core";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import Banner2 from "../components/dashboard-home/multi-company/Banner";
import CompanyLogo from "../components/dashboard-home/multi-company/CompanyLogo";
import MenuCard from "../components/dashboard-home/multi-company/MenuCard";
import TagTemplateFilter from "../components/general/TagTemplateFilter";
import useGlobalState from "../store/globalState";
import useUiState from "../store/uiState";

const filters = [
  {
    type: "fisica",
    label: "Persona física",
  },
  {
    type: "moral",
    label: "Persona moral",
  },
];

const DashboardGroupHomePage = () => {
  const { mainGroupCompany, groupChildrenCompanies,getCompaniesByGroup } = useGlobalState();
  const {setShowGenericLoader} = useUiState()
  const history = useHistory();
  const [showUsersType, setShowUsersType] = useState<string>("all");
  const [showArchived, setShowArchived] = useState(false);

  const filteredCompanies = useMemo(() => {
   
    let tempCompanies = []
    if (showUsersType === "all") {
      tempCompanies = groupChildrenCompanies;
    } else if (showUsersType === "latest") {
      tempCompanies = getLatestCompanies(groupChildrenCompanies);
    } else {
      tempCompanies = groupChildrenCompanies?.filter((comp) => comp.type === showUsersType);
    }


    if(showArchived){
      return tempCompanies;
    }else{
      return tempCompanies?.filter((comp) => comp.archived === "true" ? false : true);
    }
    //eslint-disable-next-line
  }, [showUsersType, groupChildrenCompanies,showArchived]);

  const handleEditProfile = () => history.push("/dashboard/management/edit-profile");

  function handleShowArchived() {
    setShowArchived(!showArchived);
  }

  useEffect(() => {
    if(history.action !== "REPLACE"){
      setShowGenericLoader(true)
      getCompaniesByGroup().finally(() => setShowGenericLoader(false))
    }
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: 10 }}>
        <Grid item style={{ width: 275 }}>
          <CompanyLogo
            onChangeCompany={null}
            companyName={mainGroupCompany?.name?.toUpperCase() || "no asignada"}
            onEditProfile={handleEditProfile}
            profilePictureId={mainGroupCompany?.profilePictureId}
          />
        </Grid>
        <Grid item xs>
          <Banner2 />
        </Grid>
      </Grid>
      <Grid item container xs={12} direction="row" style={{ marginTop: ".5rem" }}>
        <p style={{ fontWeight: "bold" }}>Mis empresas</p>
      </Grid>

      <Grid item container xs={12} direction="row" style={{ marginTop: "1rem" }}>
        <TagTemplateFilter
          label="Todos"
          selected={showUsersType === "all"}
          count={groupChildrenCompanies?.length}
          onClick={() => setShowUsersType("all")}
        />
        {filters?.map((filter, i) => (
          <TagTemplateFilter
            key={i}
            label={filter.label}
            selected={showUsersType === filter.type}
            count={groupChildrenCompanies?.filter((comp) => comp?.type === filter?.type).length}
            onClick={() => setShowUsersType(filter.type)}
          />
        ))}
        <TagTemplateFilter
          label="Ultimas agregadas"
          selected={showUsersType === "latest"}
          count={getLatestCompanies(groupChildrenCompanies)?.length}
          onClick={() => setShowUsersType("latest")}
        />
      </Grid>

      <Grid item container xs={12} direction="row" style={{ marginTop: ".2rem" }}>
        <Button variant="text" color="primary" size="small" onClick={handleShowArchived}>
          {showArchived ? "Ocultar archivadas" : "Mostrar archivadas"}
        </Button>
      </Grid>
      <Grid container style={{ gap: "2rem", marginTop: "1rem", paddingBottom: "2rem" }}>
        {filteredCompanies?.length > 0 ? filteredCompanies?.map((company) => (
          <MenuCard key={company.email} company={company} onLoading={setShowGenericLoader} />
        )) : (
          <p style={{ textAlign: "center", width: "100%" }}>No hay empresas para mostrar</p>
        )}
      </Grid>
    </>
  );
};

export default DashboardGroupHomePage;

function getLatestCompanies(companies: any[]) {
  return companies?.filter((comp) => {
    return moment(Date.now()).diff(moment(new Date(comp.updatedAt)), "days") <= 2;
  });
}
