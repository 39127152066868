import React from "react";
import { useHistory } from "react-router";


import CarouselInstructions from "./CarouselInstructions";
import CleanTopBar from "./CleanTopBar";

const Instructions = () => {
  const history = useHistory();
  return (
    <>
      <CleanTopBar />
      <CarouselInstructions end={() => history.go(-1)} />
    </>
  );
};

export default Instructions;
