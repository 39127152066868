import React from 'react';
import { List, ListSubheader, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import DashedButton from '../../general/DashedButton';
import ManagementRow from './ManagementRow';

interface ManagementTableProps<TableItem, ItemValue> {
  tableItemsValues?: ItemValue[];
  /** Item or rows info that will be shown by the table. */
  tableItems: ((values: ItemValue[]) => TableItem[]) | TableItem[];
  /** Table header data. */
  header: {
    key: keyof TableItem;
    title: string;
  }[];
  /** Props for the add button at the bottom of the table */
  addButtonProps?: {
    /** Text inside the add button. */
    label: string;
    /** Action fired when the user clicks add button.  */
    onAdd?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  } | null
  menuItems?: ManagementMenuItem<unknown extends ItemValue ? TableItem : ItemValue>[];
  addButtonOnTop?: boolean;
}


type ManagementTableType = <TableItem, ItemValue>(props: ManagementTableProps<TableItem, ItemValue>) => JSX.Element;
/** Table that shows the items of each section in Management screen. */
const ManagementTable: ManagementTableType = (props) => {
  const renderRow = () => {
    if(typeof props.tableItems === 'function' && props.tableItemsValues){
      return props.tableItems(props.tableItemsValues).map((item, i) => {
        const value = props.tableItemsValues![i];
        return(
          <ManagementRow
            key={i}
            itemValue={value}
            tableItem={item}
            header={props.header}
            menuItems={props.menuItems as ManagementMenuItem<typeof value>[]}
          />
        );
      })
    }
    else if(Array.isArray(props.tableItems))
      return props.tableItems.map((item, i) => {

        return(
          <ManagementRow
            key={i}
            itemValue={item}
            tableItem={item}
            header={props.header}
            menuItems={props.menuItems as ManagementMenuItem<typeof item>[]}
          />
        );
      });
  }


  const classes = useStyles();
  return (
    <div style={{marginTop:"1rem"}}>
      {props.addButtonProps && props.addButtonOnTop && (
        <DashedButton
          startIcon={<AddIcon />}
          fullWidth
          size="large"
          onClick={props.addButtonProps.onAdd}
        >
          {props.addButtonProps.label}
        </DashedButton>
      )}
      <List
        subheader={
          <ListSubheader className={classes.header}>
            {props.header.map((column) => <span key={column.key.toString()} className={classes.headerName}>{column.title}</span>)}
          </ListSubheader>
        }
      >
        {renderRow()}
        {props.addButtonProps && !props.addButtonOnTop && (
        <DashedButton
          startIcon={<AddIcon />}
          fullWidth
          size="large"
          onClick={props.addButtonProps.onAdd}
        >
          {props.addButtonProps.label}
        </DashedButton>
      )}
      </List>
    </div>
  )
}

const useStyles = makeStyles({
  header: { display: 'flex', lineHeight: 'unset', marginTop:".5rem" },
  headerName: { flex: 0.6 }
});


export default ManagementTable;