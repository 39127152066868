import React from "react";

const LogoutOnLeave = () => {
  //si el usuario intenta recargar en otra url se limpia el sesionStorage
  const leavePageListenerHandler = function (e: any) {
    window.sessionStorage.clear();
  };

  React.useEffect(() => {
    window.addEventListener("beforeunload", leavePageListenerHandler);
    
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });

    return () => {
      window.removeEventListener("beforeunload", leavePageListenerHandler);
    };
  }, []);

  return null;
};

export default LogoutOnLeave;
