import { SvgIcon, SvgIconProps } from '@material-ui/core';

const KivitIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="145" height="24" viewBox="0 0 145 24" style={{ ...props.style, width: '6em', height: '1em' }}>
      <path d="M95.7487 5.71289H91.9473V21.2694H95.7487V5.71289Z" fill="#F5FBFF" />
      <path d="M95.7487 3.12109H91.9473V4.33559H95.7487V3.12109Z" fill="#F5FBFF" />
      <path d="M126.264 3.12109H122.463V4.33559H126.264V3.12109Z" fill="#F5FBFF" />
      <path d="M126.264 5.71289H122.463V21.2694H126.264V5.71289Z" fill="#F5FBFF" />
      <path d="M144.766 9.10852V5.71681H137.133V1.71289H133.332V5.71681H128.889V9.10852H133.332V14.8404C133.275 16.2485 133.474 17.6553 133.919 18.9924C134.097 19.473 134.368 19.9133 134.719 20.2873C135.069 20.6612 135.49 20.9612 135.958 21.1696C137.189 21.6332 138.5 21.8431 139.814 21.7867C141.385 21.7609 142.953 21.6223 144.504 21.372L144.756 21.3325V17.9902L144.42 18.0346C141.325 18.4394 139.242 18.4444 138.289 18.0346C138.078 17.9447 137.889 17.8097 137.735 17.6392C137.582 17.4686 137.467 17.2666 137.4 17.0472C137.19 16.2419 137.1 15.4102 137.133 14.5787V9.10852H144.766Z" fill="#F5FBFF" />
      <path d="M79.5454 11.4839C82.0455 8.74564 84.2009 5.71147 85.9635 2.44922L81.4362 2.55785C78.8689 6.74935 75.0181 11.6123 70.8908 12.9749L70.6489 2.77014L66.7832 2.86394L67.2275 21.5899L71.0932 21.4961L70.9846 16.964C73.0096 16.4624 74.9025 15.5295 76.5338 14.2289C79.4663 15.6804 83.1345 18.766 85.1784 21.1111L89.8389 21.0024C88.0665 17.8131 83.2136 13.676 79.5454 11.4839Z" fill="#F5FBFF" />
      <path d="M111.601 21.2753C115.136 16.5358 120.097 8.95753 120.097 5.70898H116.232C116.232 6.98767 112.618 13.3317 109.108 18.1749C105.593 13.3317 102.003 6.98767 101.984 5.70898H98.1328C98.1328 8.95753 103.094 16.5358 106.634 21.2753H111.601Z" fill="#F5FBFF" />
      <path d="M41.6682 13.9618C40.7697 13.1818 39.8465 12.4412 38.9036 11.7254C39.8449 11.0046 40.7664 10.2574 41.6682 9.48394C44.9335 6.66049 47.8714 3.47925 50.4265 0H39.7675C31.8093 8.34662 21.156 13.6103 9.69141 14.8603V23.1693C17.328 22.4243 24.7379 20.1563 31.4832 16.4994C34.4863 18.5004 37.2581 20.8285 39.7478 23.4408H50.4068C47.859 19.9631 44.9276 16.7834 41.6682 13.9618Z" fill="#F5FBFF" />
      <path d="M8.22997 0H0V1.46135H8.22997V0Z" fill="#F5FBFF" />
      <path d="M0 23.44H4.13726C5.50975 23.44 6.87226 23.3857 8.23487 23.287V2.92188H0V23.44Z" fill="#F5FBFF" />
    </SvgIcon>
  )
}

export default KivitIcon;