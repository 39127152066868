import {  Button, Drawer, Grid,makeStyles, Typography } from "@material-ui/core";
import React, { VFC } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

interface Props {
  name: string;
  onReturn: () => void;
}

const WWatchContractDrawerDrawer: VFC<Props> = ({ name, onReturn }) => {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <Grid style={{ padding: "1rem" }}>
        <Grid item>
          <Button size="small" startIcon={<ChevronLeftIcon />} variant="contained" onClick={onReturn}>
            Regresar
          </Button>
        </Grid>
        <Typography variant="h4" color="primary" style={{ marginTop: "3rem", textTransform: "uppercase" }}>
          {name}
        </Typography>
      </Grid>
    </Drawer>
  );
};

const drawerWidth = "28vw";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100%",
  },
  drawer: {
    minWidth: "450px",
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    boxSizing: "content-box",
    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.12)",
    minWidth: "436px",
    width: drawerWidth,
    paddingLeft: 15,
    paddingRight: 15,
    borderBottomRightRadius: 20,
    borderTopRightRadius: 20,
  },
  // necessary for content to be below app bar
  toolbar: {
    display: "flex",
    alignItems: "center",
  },
  content: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  documentTitle: {
    fontSize: "1.25rem",
    color: theme.palette.primary.main,
    lineHeight: "25px",
    marginLeft: 18,
  },
}));

export default WWatchContractDrawerDrawer;
