import { axiosNtpRequestV1, authHeader, axiosGeneradoresRequest } from "../../configs/axios.config";
import { NotificationResponse } from "../../types/notification-service";
import { ServiceDashboardRes } from "../../types/ntp.types";
import useSessionState from "../../store/sessionState";

export class NotificationsService {
  private static instance: NotificationsService;



  public static getInstance(): NotificationsService {
    if (!NotificationsService.instance) {
      NotificationsService.instance = new NotificationsService();
    }
    return NotificationsService.instance;
  }

  async getAllNotifications() {
    const { data } = await axiosNtpRequestV1.get<ServiceDashboardRes<NotificationResponse[]>>(`/notifications/`, {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    });
    return data.data;
  }

  async getNotificationsSize() {
    const { data } = await axiosNtpRequestV1.get<ServiceDashboardRes<NotificationResponse[]>>(`/notifications/size`, {
      headers: { ...authHeader(), "Content-Type": "application/json" },
    });
    return data;
  }

  async markAsRead(id: string) {
    await axiosNtpRequestV1.patch<ServiceDashboardRes<NotificationResponse[]>>(`/notifications/${id}`, null, {
      headers: authHeader(),
    });
  }

  async handleNotificationAction(serviceId: string, notificationResponse: boolean) {
    await axiosNtpRequestV1.get<ServiceDashboardRes>(`/services/${serviceId}/authorize/?accept=${notificationResponse}`, {
      headers: authHeader(),
    });
  }
// GNERADORES

async getAllGeneradoresNotifications() {
  const { data } = await axiosGeneradoresRequest.get<ServiceDashboardRes<NotificationResponse[]>>(`/notifications/`, {
    headers: { ...authHeader(), "Content-Type": "application/json" },
  });
  return data.data;
}

async getGeneradoresNotificationsSize() {
  const { data } = await axiosGeneradoresRequest.get<ServiceDashboardRes<NotificationResponse[]>>(`/notifications/size`, {
    headers: { ...authHeader(), "Content-Type": "application/json" },
  });
  return data;
}

async markAsReadGeneradores(id: string) {
  await axiosGeneradoresRequest.patch<ServiceDashboardRes<NotificationResponse[]>>(`/notifications/${id}`, null, {
    headers: authHeader(),
  });
}

async handleGeneradoresNotificationAction(serviceId: string, notificationResponse: boolean) {
  await axiosGeneradoresRequest.get<ServiceDashboardRes>(`/services/${serviceId}/authorize/?accept=${notificationResponse}`, {
    headers: authHeader(),
  });
}

}

//

axiosNtpRequestV1.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle 401 error: Unauthorized
      handleUnauthorizedError();
    }
    return Promise.reject(error);
  }
);


axiosGeneradoresRequest.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle 401 error: Unauthorized
      handleUnauthorizedError();
    }
    return Promise.reject(error);
  }
)
axiosGeneradoresRequest.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 503) {
      // Handle 401 error: Unauthorized
      handleUnavaibleError();
    }
    return Promise.reject(error);
  }
)

function handleUnavaibleError() {
  useSessionState.getState().logout();

  alert("Inténtelo más tarde. Será redirigido al inicio.");
}


function handleUnauthorizedError() {
  useSessionState.getState().logout();

  alert("Su sesión ha expirado. Será redirigido al inicio.");
}

