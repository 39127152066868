import { axiosNtpRequestV1, authHeader, axiosGeneradoresRequest } from "../../configs/axios.config";

export class StagesService {
  private static instance: StagesService;

  private constructor() {}

  public static getInstance(): StagesService {
    if (!StagesService.instance) {
      StagesService.instance = new StagesService();
    }
    return StagesService.instance;
  }

  //GET ​/api​/v1​/stage​/companyId​/{companyId}
  async getCompanyStages(companyId: string) {
    try {
      const { data } = await axiosNtpRequestV1.get(`/stage/companyId/${companyId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //GET /api/v1/stage/companyId/{companyId}/stageTemplateId/{stageTemplateId}
  async getCompanyStage(companyId: string, stageTemplateId: string) {
    try {
      const { data } = await axiosNtpRequestV1.get(`/stage/companyId/${companyId}/stageTemplateId/${stageTemplateId}`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //POST /api/v1/stage/companyId/{companyId}/stageTemplateId/{stageTemplateId}/download
  async downloadCompanyStagesDocuments(companyId: string, stageTemplateId: string, reqIds?: string[]) {
    try {
      const { data } = await axiosNtpRequestV1.post(`/stage/companyId/${companyId}/stageTemplateId/${stageTemplateId}/download`, reqIds, {
        headers: authHeader(),
        responseType: "arraybuffer",
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //POST ​/api​/v1​/stage​/companyId​/{companyId}​/stageName​/{stageName}​/serviceId​/{serviceId}​/download
  async downloadServiceStagesDocuments(companyId: string, stageName: string, serviceId: string, reqIds?: string[]) {
    try {
      const { data } = await axiosNtpRequestV1.post(`/stage/companyId/${companyId}/stageName/${stageName}/serviceId/${serviceId}/download`, reqIds, {
        headers: authHeader(),
        responseType: "arraybuffer",
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //GET ​/api​/v1​/stage​/companyId​/{companyId}​/stageTemplateId​/{stageTemplateId}​/progress
  async getCompanyStageProgress(companyId: string, stageTemplateId: string) {
    try {
      const { data } = await axiosNtpRequestV1.get(`/stage/companyId/${companyId}/stageTemplateId/${stageTemplateId}/progress`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //POST ​/api​/v1​/stage​/companyId​/{companyId}​/stageTemplateId​/{stageTemplateId}​/requirements​/{requirementUuid}
  async uploadStageRequirementDocument(companyId: string, stageTemplateId: string, requirementUuid: string, body: any) {
    try {
      const { data } = await axiosNtpRequestV1.post(`/stage/companyId/${companyId}/stageTemplateId/${stageTemplateId}/requirements/${requirementUuid}`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //POST ​/api​/v1​/services​/{serviceId}​/requirements​/{requirementUid}
  async uploadServiceRequirementDocument(serviceId: string, requirementUid: string, body: any) {
    try {
      const { data } = await axiosNtpRequestV1.post(`/services/${serviceId}/requirements/${requirementUid}`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //PUT ​/api​/v1​/stage​/companyId​/{companyId}​/stageTemplateId​/{stageTemplateId}​/requirements​/{requirementUuid}
  async validateStageRequirement(companyId: string, stageTemplateId: string, requirementUuid: string, body: any) {
    try {
      const { data } = await axiosNtpRequestV1.put(`/stage/companyId/${companyId}/stageTemplateId/${stageTemplateId}/requirements/${requirementUuid}`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  //DELETE ​/api​/v1​/stage​/companyId​/{companyId}​/stageTemplateId​/{stageTemplateId}​/requirements​/{requirementUuid}​/documents​/{documentUuid}
  async deleteStageRequirementDocument(companyId: string, stageTemplateId: string, requirementUuid: string, documentUuid: string) {
    try {
      const { data } = await axiosNtpRequestV1.delete(
        `/stage/companyId/${companyId}/stageTemplateId/${stageTemplateId}/requirements/${requirementUuid}/documents/${documentUuid}`,
        { headers: authHeader() }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  //DELETE ​/api​/v1​/services​/{serviceId}​/requirements​/{requirementUid}
  async deleteServiceRequirementDocument(serviceId: string, requirementUid: string, document: any) {
    //todo: quitar document x documentId
    try {
      const res = await fetch(`${process.env.REACT_APP_API_BASE_URL_NTP}/api/v1/services/${serviceId}/requirements/${requirementUid}`, {
        method: "DELETE",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(document),
      }).then((res) => res.json());
      return res;
    } catch (error) {
      throw error;
    }
  }

  //GET ​/api​/v1​/stage​/create​/companyId​/{companyId}​/stageTemplateId​/{stageTemplateId}
  async createCompanyStage(companyId: string, stageTemplateId: string) {
    try {
      const { data } = await axiosNtpRequestV1.get(`/stage/create/companyId/${companyId}/stageTemplateId/${stageTemplateId}`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getServiceStages(serviceId: string) {
    try {
      const { data } = await axiosNtpRequestV1.get(`/services/${serviceId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getGeneratorServiceStages(serviceId: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get(`/services/${serviceId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPeriodCfdis(serviceId: string, periodUid: string) {
    try {
      const { data } = await axiosNtpRequestV1.get(`/services/${serviceId}/periods/${periodUid}/cfdis`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
}
