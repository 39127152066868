import { Grid, IconButton, Typography } from "@material-ui/core";
import { SelectOption } from "../../../types/global";
import RenderTextFieldV2 from "../../inputs/inputs-v2/RenderTextFieldV2";
import RenderMultipleSelect from "../../inputs/RenderMultipleSelect";
import RenderSelect from "../../inputs/RenderSelect";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

type ProfileInputProps = {
  error?: string;
  id: string;
  isEditing?: boolean;
  label: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  touched?: boolean;
  type?: "text" | "number" | "select" | "multiple";
  value?: unknown | unknown[];
  options?: readonly SelectOption[];
  placeholder?: string;
  onDelete?: () => void;
};

const ProfileInput = ({ isEditing, value, error, touched, label, id, onChange, onDelete, type, options, placeholder }: ProfileInputProps) => {
  const renderInput = () => {
    switch (type) {
      case "multiple":
        return (
          <RenderMultipleSelect
            name={id}
            value={value as unknown[]}
            onChange={onChange}
            options={options}
            error={error}
            touched={touched}
            variant="filled"
          />
        );
      case "select":
        return (
          <RenderSelect
            name={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            touched={touched}
            error={error}
            options={options}
            variant="filled"
          />
        );

      default:
        return (
          <>
            <RenderTextFieldV2
              error={error}
              name={id}
              onChange={onChange}
              size="small"
              touched={touched}
              type={type}
              value={value}
              variant="filled"
              fullWidth={false}
              style={{ flex: 1 }}
            />
            {!!onDelete && (
              <IconButton size="small" onClick={onDelete}>
                <DeleteOutlineIcon color="primary" />
              </IconButton>
            )}
          </>
        );
    }
  };

  const renderLabel = () => { 
    switch (type) {
      case 'multiple':
        return (value as string[]).join(", ");
      case 'select':
        const optionValue = options.find((option) => option.value === value);
        return optionValue?.label ?? optionValue?.value;
      default:
        return value;
    }
  }

  return (
    <>
      <Grid item xs={4}>
        <Typography variant="subtitle2" style={{lineHeight:"1.2"}}>{label}</Typography>
      </Grid>
      <Grid item container xs={8} style={{ gap: 16 }} alignItems="flex-start">
        {isEditing ? renderInput() : <Typography variant="body2">{renderLabel() || "---"}</Typography>}
      </Grid>
    </>
  );
};

export default ProfileInput;
