
import otpLogo from "../../assets/img/otp-logo.svg";
import { useEffect, useState } from "react";
import OtpInput from 'react-otp-input';
import { Button, Box, Typography, makeStyles, useMediaQuery, Theme } from "@material-ui/core";
import useSettingsState from "../../store/settingsState";
import useGlobalState from "../../store/globalState";
import useSessionState from "../../store/sessionState";
import { Alert } from "@material-ui/lab";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const useStyles = makeStyles((theme) => ({
  otpLogo: {
    width: "25%",
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    textAlign: "center",
    width: "60%",
    marginBottom: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(3),
    width: '100%'
  },
  alert: {
    marginBottom: '-15px',
    width: '100%'
  }
}));

const Otp = ({onReturn}) => {
  const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const classes = useStyles();
  const [otp, setOtp] = useState('');
  const [otpLength, setOtpLength] = useState<number>(5);
  const [otpExpiration, setOtpExpiration] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const { authPolicyData } = useSessionState(s => s);
  const setAuthPolicyData = useSessionState(s => s.setAuthPolicyData);
  const { validateOtpCode } = useSettingsState();
  const onLogin = useSessionState(s => s.onLogin);
  const { setAlertInfo, alertInfo } = useGlobalState();

  useEffect(() => {
    if (!authPolicyData?.userId) {
      setAuthPolicyData(JSON.parse(sessionStorage.getItem('authPolicyData')));
      //!authPolicyData && navigate('onboarding', { replace: true });
    }
  }, [authPolicyData, /* navigate, */ setAuthPolicyData]);

  useEffect(() => {
    if (authPolicyData) {
      for (const policy of authPolicyData.passwordPolicyList) {
        const { policyType, active, value } = policy;
        if (policyType === 'OTP_EXPIRATION' && active) {
          setOtpExpiration(Number(value) + 1);
        }
        if (policyType === 'OTP_LENGHT') setOtpLength(Number(value));
      }
    }
  }, [authPolicyData]);

  const handleSendCode = () => {
    validateOtpCode(authPolicyData.id, otp).then(result => {
      if (result.codigo === 0) {
        onLogin(result.respuesta);
      } else {
        setAlertInfo({
          type: "error",
          message: result.mensaje,
        });
      }
    })
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" flex="1" alignItems={'center'} gridGap={isLg ? 16 : 0}>
      <Box mb={3} display={'flex'} alignSelf={'start'}>
        <Button size="small" startIcon={<ChevronLeftIcon />} variant="contained" onClick={onReturn}>
          Regresar
        </Button>
      </Box>
      <img src={otpLogo} className={classes.otpLogo} alt="OTP Logo" />
      <Typography variant="h4" className={classes.title}>
        Autenticación a 2 pasos
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        Ingrese el código de verificación que hemos enviado al correo
        <span className="font-semibold"> {authPolicyData?.email}</span>
        {otpExpiration !== 0 && ` que tiene una validez de ${otpExpiration} ${otpExpiration === 1 ? 'minuto' : 'minutos'}`}
      </Typography>
      <div className="otp-inputs">
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={otpLength}
          renderInput={(props) => <input {...props} />}
        />
      </div>
      {alertInfo?.message && (
        <Alert className={classes.alert} variant="filled" severity={alertInfo.type}>
          {alertInfo.message}
        </Alert>
      )}
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleSendCode}
        disabled={isLoading || otp.length !== otpLength}
      >
        Verificar
      </Button>
    </Box>
  );
};

export default Otp;
