import { Box, Hidden, Typography } from '@material-ui/core';
import BannerImg from '../../../assets/img/head-banner.svg';

const Banner = () => {
  return (
    <Box padding="0 3%" height="100%" bgcolor="white" display="flex" alignItems="center" gridGap={30}>
      <Hidden smDown>
        <img src={BannerImg} alt="Img" />
      </Hidden>
      <Box display="flex" flexDirection="column" maxWidth={470}>
        <Typography variant="h3" style={{ fontSize:'2.25rem' }} color="textPrimary">Te damos la bienvenida</Typography>
        <Typography variant="h6" color="textPrimary" align="justify" style={{ marginLeft: 10 }}>
          a nuestra plataforma de gestión de documentos asociados al cumplimiento de la reforma laboral
        </Typography>
      </Box>
    </Box>
  )
}

export default Banner;


