import jwt from 'jwt-decode'

export const isExpired = (token) => {
  const exp = decodeToken(token).exp
  let date_r = new Date(exp*1000)

  if (Date.now() > date_r) {
      return true;
  }
  
  return false;
};

export function decodeToken(token) {
  const tokenDecoded = jwt(token)
  return tokenDecoded
}