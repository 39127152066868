import { Box, LinearProgress, List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

interface SafetyIndicatorProps {
  password: string;
  className?: string;
}

const SafetyIndicator = ({ password, className }: SafetyIndicatorProps) => {
  const classes = useStyles();
  const calculateSafetyLevel = () => {
    let level = 0;
    if (password.length >= 8) level += 25;
    if (password.length >= 10 ) level += 25;
    if (/[A-Z]+/.test(password) && password.length >= 15) level += 25;
    if (/[^a-zA-Z0-9]+/.test(password) && password.length >= 20) level += 25
    return level;
  }

  const getLevelData = (level: number) => {
    switch (level) {
      case 25:
        return {
          levelClasses: {
            root: `${classes.progressBar} ${classes.linealProgressLow}`,
            barColorPrimary: classes.barColorLow
          },
          levelLabel: 'Bajo',
          levelClassColor: classes.linealProgressLow
        };
      case 50:
        return {
          levelClasses: {
            root: `${classes.progressBar} ${classes.linealProgressMedium}`,
            barColorPrimary: classes.barColorMedium
          },
          levelLabel: 'Medio',
          levelClassColor: classes.linealProgressMedium
        };
      case 75:
        return {
          levelClasses: {
            root: `${classes.progressBar} ${classes.linealProgressTall}`,
            barColorPrimary: classes.barColorTall
          },
          levelLabel: 'Alto',
          levelClassColor: classes.linealProgressTall
        };
      case 100:
        return {
          levelClasses: {
            root: `${classes.progressBar} ${classes.linealProgressHigh}`,
            barColorPrimary: classes.barColorHigh
          },
          levelLabel: 'Muy alto',
          levelClassColor: classes.linealProgressHigh
        };
      default:
        return {
          levelClasses: {
            root: `${classes.progressBar} ${classes.linealProgressLow}`,
            barColorPrimary: classes.barColorLow
          },
          levelLabel: '',
          levelClassColor: classes.linealProgressLow
        };
    }
  }

  const level = calculateSafetyLevel();
  const { levelClasses, levelLabel, levelClassColor } = getLevelData(level);

  return (
    <Box>
      <span className={classes.smallTitle}>Nivel de seguridad de contraseña:</span>
      <Box textAlign="end">
        <Box component="span" fontSize={12} fontWeight="bold" className={levelClassColor}>{ levelLabel }</Box>
      </Box>
      <LinearProgress
        classes={levelClasses}
        variant="determinate"
        value={ level }
      />

      <List>
        <ListItem className={classes.newPasswordListItem}>
          <ListItemIcon>
            <DoneIcon className={classes.strongPassword} />
          </ListItemIcon>

          <ListItemText
            primary={<>La contraseña debe tener mínimo 10 caracteres y <br/> máximo 20 caracteres</>}
            className={classes.instruction}
          />
        </ListItem>

        <ListItem className={classes.newPasswordListItem}>
          <ListItemIcon>
            <DoneIcon className={classes.strongPassword} />
          </ListItemIcon>

          <ListItemText
            primary="Distingue mayúsculas y minúsculas"
            className={classes.instruction}
          />
        </ListItem>

        <ListItem className={classes.newPasswordListItem}>
          <ListItemIcon>
            <DoneIcon className={classes.strongPassword} />
          </ListItemIcon>

          <ListItemText
            primary="No deberá contener nombres propios"
            className={classes.instruction}
          />
        </ListItem>

        <ListItem className={classes.newPasswordListItem}>
          <ListItemIcon>
            <DoneIcon className={classes.strongPassword} />
          </ListItemIcon>

          <ListItemText
            primary="Debe contener algún carácter especial (+*%&/$#-_)"
            className={classes.instruction}
          />
        </ListItem>

        <ListItem className={classes.newPasswordListItem}>
          <ListItemIcon>
            <DoneIcon className={classes.strongPassword} />
          </ListItemIcon>

          <ListItemText
            primary="Alguno de los caracteres debe ser mayúscula"
            className={classes.instruction}
          />
        </ListItem>
      </List>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  barColor: {
    backgroundColor: '#F44336'
  },
  barColorLow: {
    backgroundColor: '#F44336'
  },
  linealProgressLow: {
    color: '#F44336'
  },
  barColorMedium: {
    backgroundColor: '#FF9800'
  },
  linealProgressMedium: {
    color: '#FF9800'
  },
  barColorTall: {
    backgroundColor: '#03A9F4'
  },
  linealProgressTall: {
    color: '#03A9F4'
  },
  barColorHigh: {
    backgroundColor: '#38B349'
  },
  linealProgressHigh: {
    color: '#38B349'
  },
  progressBar: {
    height: 8,
    borderRadius: 10
  },
  newPasswordListItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  strongPassword: {
    color: theme.palette.success.main
  },
  smallTitle: {
    fontFamily: '\'Barlow\', sans-serif',
    fontStyle: 'normal',
    fontSize: 13,
    lineHeight: '1rem',
  },
  instruction: {
    fontFamily: '\'Barlow\', sans-serif',
    fontSize: 12,
    lineHeight: '0.75rem',
    color: theme.palette.success.main,
  }
}));

export default SafetyIndicator;