import React, { FC } from 'react';
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PhotoIcon from '@material-ui/icons/Photo';

interface FileInfoProps {
  filename: string;
  size: number;
  onQuitFile?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const FileInfo: FC<FileInfoProps> = (props) => {
  const classes = useStyles();
  return (
    <ListItem ContainerComponent="div" classes={{ root: classes.fileInfo, container: classes.fileInfoContainer }}>
      <ListItemAvatar>
        <Avatar className={classes.avatarBlue}>
          <PhotoIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={props.filename} />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete" onClick={props.onQuitFile}>
          <CloseIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const useStyles = makeStyles((theme) => ({
  fileInfo: {
    border: '1px dashed #00709E',
    borderRadius: 10
  },
  fileInfoContainer: {
    width: '100%'
  },
  avatarBlue: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  }
}));
export default FileInfo;
