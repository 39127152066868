import React, { useState, useEffect, useRef } from 'react';
import Dropzone from 'react-dropzone';
import { Box, Button, makeStyles, Modal, Typography } from '@material-ui/core';
import { useReduxFormStyles } from '../inputs/useReduxFormStyles';
import { Delete } from "@material-ui/icons";
import Resizer from 'react-image-file-resizer';
import RedPinIcon from '../icons/RedPinIcon';

interface DropzoneFilesProps {
  label: string;
  file: File | null;
  onChange: (files: File | null) => void;
  accept?: string | string[];
  labelBox: string;
  disabled?: boolean;
  icon?: boolean | true;
  modal?: boolean | false; 
}

const resizeImage = (file, maxWidth, maxHeight, format = 'JPEG', quality = 70) => {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      format,
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      'blob'
    );
  });
};

const DropzoneFiles = (props) => {
  const classes = useStyles();
  const fieldClasses = useReduxFormStyles();
  const [preview, setPreview] = useState<string | null>(null);
  const [disableDropzone, setDisableDropzone] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setDisableDropzone(false);
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current!.click();
      }, 0);
    }
  };

  useEffect(() => {
    if (props.file) {
      if (typeof props.file === 'string') {
        setPreview(props.file);
      } else {
        const objectUrl = URL.createObjectURL(props.file);
        setPreview(objectUrl);

        // Liberar la memoria
        return () => URL.revokeObjectURL(objectUrl);
      }
    } else {
      setPreview(null);
    }
  }, [props.file]);

  const handleDrop = async (filesToUpload) => {
    const file = filesToUpload[0];
    try {
      if (file.type === 'application/pdf') {
        props.onChange(file); // Si es un PDF, pasa el archivo sin redimensionar
      } else {
        const resizedImage = await resizeImage(file, 800, 600, 'JPEG', 70);
        props.onChange(resizedImage); // Si es una imagen, redimensionar
      }
    } catch (error) {
      console.error('Error resizing image:', error);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      {props.label && (
        <label className={fieldClasses.fieldLabel} style={{ marginBottom: 10, marginTop: 10 }}>
          {props.label}
        </label>
      )}
      {preview ? (
        <div style={{ position: 'relative', width: '100%' }}>
          {props.accept === 'application/pdf' ? (
            <iframe src={preview} title="PDF Preview" style={{ width: '100%', height: '500px', border: 'none' }} />
          ) : (
            <img src={preview} alt="Preview" style={{ width: '100%', height: 'auto' }} />
          )}
          {props.icon ? (
            <Delete
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                cursor: 'pointer',
                color: 'white',
                backgroundColor: '#2347AD',
                borderRadius: '50%',
                padding: 5,
              }}
              onClick={() => {
                props.onChange(null);
                setPreview(null);
              }}
            />
          ) : null}
        </div>
      ) : (
        <div onClick={props.modal ? () => setOpenAlert(true) : ()=> inputRef.current!.click()}>
          <Dropzone onDrop={handleDrop} accept={props.accept} disabled={disableDropzone || props.disabled}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()} className={classes.dropzone}>
                  <input type="file" {...getInputProps()} ref={inputRef} />
                  <>
                    <Typography variant="subtitle1" color="primary" style={{ fontWeight: 'normal' }}>
                      {props.labelBox}
                    </Typography>
                    <Typography variant="body2" color="primary" style={{ fontWeight: 'bold' }}>
                      O
                    </Typography>
                    <Button variant="contained" color="primary">
                      Buscar en tus archivos
                    </Button>
                  </>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      )}
      {props.modal ? (
        <Modal
          open={openAlert}
          onClose={handleCloseAlert}
          aria-labelledby="alert-modal-title"
          aria-describedby="alert-modal-description"
        >
          <Box className={classes.modalStyle}>
            <RedPinIcon />
            <Typography id="alert-modal-description">
              ¡No olvides señalar el área trabajada antes de subir tu imagen!
            </Typography>
            <div className={classes.buttonContainer}>
              <Button onClick={handleCloseAlert} color="primary" variant="contained">
                Entendido
              </Button>
            </div>
          </Box>
        </Modal>
      ) : null}

    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  dropzone: {
    cursor: 'pointer',
    backgroundColor: '#F4F4F4',
    height: '138px',
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '&:focus': {
      outline: 'none'
    }
  },
  alertMessage: {
    marginLeft: '27px',
    marginTop: '28px',
    marginRight: '28px'
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #2347AD',
    boxShadow: theme.shadows[5],
    textAlign: 'center',
    padding: theme.spacing(2, 4, 3),
    borderRadius: '8px',
  },
}));

export default DropzoneFiles;
