import React, { FC } from "react";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PhotoIcon from "@material-ui/icons/Photo";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FileIcon from '@material-ui/icons/InsertDriveFile';

interface FileInfoProps {
  filename: string;
  size: number;
  failed?: string | undefined;
  showMode?: boolean;
  onQuitFile?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const FileInfo: FC<FileInfoProps> = (props) => {
  const classes = useStyles();
  const extension = props.filename?.split(".").pop();

  const Icon = () => {
    if (props.failed) return <HighlightOffIcon color="error" fontSize="large" />;
    if (props.showMode) return <CheckCircleOutlineIcon htmlColor="rgb(149, 217, 125)" fontSize="large" />;
    if (extension !== "jpg" && extension !== "png" && extension !== "jpeg" ) return <FileIcon />;
    return <PhotoIcon />;
  };

  const getAvatarClass = () => {
    if (props.failed) return classes.avatarError;
    if (props.showMode) return classes.avatarSuccess;
    return classes.avatarPrimary;
  };
  
  return (
    <ListItem ContainerComponent="div" classes={{ root: classes.fileInfo, container: classes.fileInfoContainer }}>
      <ListItemAvatar>
        <Avatar className={getAvatarClass()}>
          <Icon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={props.filename} />
      <ListItemSecondaryAction>
        {!props.showMode && (
          <IconButton edge="end" aria-label="delete" onClick={props.onQuitFile} >
            <CloseIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
      {props.failed && (
        <Tooltip title={props.failed}>
          <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}></div>
        </Tooltip>
      )}
    </ListItem>
  );
};

const useStyles = makeStyles((theme) => ({
  fileInfo: {
    border: "1px dashed #00709E",
    borderRadius: 10,
    overflow: "hidden",
  },
  fileInfoContainer: {
    width: "100%",
  },
  avatarPrimary: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  avatarSuccess: {
    color: theme.palette.success.contrastText,
    backgroundColor: "#fff",
  },
  avatarError: {
    color: theme.palette.error.contrastText,
    backgroundColor: "#fff",
  },
}));
export default FileInfo;
