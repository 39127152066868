import { Box, Hidden, Typography } from '@material-ui/core';
import IconImgGenerates from "../../../assets/services/generadores_icon.svg";

const BannerGenerator = () => {
  return (
    <Box padding="0 3%" height="100%" bgcolor="white" display="flex" alignItems="center" gridGap={30}>
      <Hidden smDown>
        <img src={IconImgGenerates} alt="Img" />
      </Hidden>
      <Box display="flex" flexDirection="column" maxWidth={470}>
        <Typography variant="h3" style={{ fontSize:'2.25rem' }} color="textPrimary">Te damos la bienvenida</Typography>
        <Typography variant="h6" color="textPrimary" align="justify" style={{ marginLeft: 10 }}>
          a nuestra plataforma para el control de tus servicios profesionales
        </Typography>
      </Box>
    </Box>
  )
}

export default BannerGenerator;


