import { axiosPagareRequest } from "../../configs/axios.config";
import { authHeader } from "../utils/ntpUtils";

export class Sat69Service {
  private static instance: Sat69Service;

  public static getInstance(): Sat69Service {
    if (!Sat69Service.instance) {
      Sat69Service.instance = new Sat69Service();
    }
    return Sat69Service.instance;
  }

  async getRfcInfo(rfc: string) {
    try {
      const { data } = await axiosPagareRequest.get(`/general/analisis69/rfc/${rfc}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  ///api/general/analisis69/descargaExcel
  async downloadExcel() {
    try {
      const res = await axiosPagareRequest.get(`/general/analisis69/descargaExcel`, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "*/*",
        },
      });
      return res;
    } catch (error) {
      throw error;
    }
  }
}
