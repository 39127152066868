import { VFC } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { LinearProgress, Theme, withStyles } from "@material-ui/core";


const BorderLinearProgress = withStyles((theme: Theme) => ({
  root: {
    marginBottom: 4,
    height: 16,
    borderRadius: 35,
    border: "1px solid #ABABAB",
    flexGrow: 1,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 35,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

interface StageStatusBarProps {
  value: number;
  stageCompleted?: boolean;
}

const StatusBar: VFC<StageStatusBarProps> = (props) => {
  const { value } = props;

  const classes = useStyles(props);

  return (
    <Box display="flex" alignItems="end" flexGrow={1}>
      <Box mr={2}>
        <div className={classes.text1}>{value}%</div>
        <div className={classes.text2}>Completado</div>
      </Box>
      <BorderLinearProgress variant="determinate" value={value} />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  text1: {
    color: theme.palette.black.dark,
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  text2: {
    color: theme.palette.black.light,
    fontSize: ".8rem",
  },
  stageStatusColor: {
    color: theme.palette.common.black,
  },
  valueText: (props: StageStatusBarProps) => ({
    color: "white",
    fontWeight: "bold",
    position: "absolute",
    // if percent value is greater or equal than 51% label will be at the center.
    // If the value is less than 51, the label will be just before the bar's end
    left: props.value >= 51 ? "45%" : props.value > 8 ? `calc(${props.value}% - 6%)` : "2%",
    top: 4,
  }),
}));

export default StatusBar;
