import { Button, Theme, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import CleanTopBar from "./CleanTopBar";
import clsx from 'clsx';
import KivitIcon from "../icons/KivitIcon";
import ImgRepse from "../../assets/services/repse.png";
import ColorKivitIcon from "../../assets/services/ColorKivitIcon.png"
import ImgGenerates from "../../assets/services/generadores.svg";
import IconImgGenerates from "../../assets/services/generadores_icon.svg";
import useGlobalState from "../../store/globalState";
import useDashboardUsersState from "../../store/dashboardUsersState";
import { useHistory } from "react-router";
import useSessionState from "../../store/sessionState";


const CompanyServiceSelection: React.FC = () => {
    /** */
    const classes = useStyles();
    const {setTypeService} = useGlobalState();
    const { showRepseService, showGeneratorService } = useDashboardUsersState();
    const history = useHistory();

    const handleOnService = (value: any) => {
        setTypeService(value);
        history.replace('/dashboard', { isAccesible: true });
    }

    useEffect(() => {
        console.log('showRepseService', showRepseService);
        console.log('showGeneratorService', showGeneratorService);
    });

    return (
        <>
            <div className={classes.wrapper}>
                <CleanTopBar />
                <div className={classes.information}>
                    <h1>Aplicaciones KIVIT</h1>
                    <h3>Bienvenido a Kivit, usted cuenta con los siguientes servicios:</h3>
                </div>
                <div className={classes.cards}>
                    <ul className={classes.listCard}>
                        {showRepseService && (
                            <li className={`${classes.card} ${classes.bgCards}`}>
                                <div className={classes.cardTitleNew}>
                                    {/* <KivitIcon htmlColor="white" fontSize="small" /> */}
                                    <img src={ColorKivitIcon} alt="Kivit color logo" />
                                    <h3 className={classes.cardTitleIcon}>REPSE</h3>
                                </div>
                                <img className={classes.cardImg} src={ImgRepse} alt="Servicio Repse" />
                                <Button className="mt-2 w-100" variant="contained" color="primary"
                                    onClick={() => handleOnService('repse')}>
                                    Entrar
                                </Button>
                            </li>
                        )}
                        {showGeneratorService && (
                            <li className={`${classes.card} ${classes.bgCards}`}>
                                <div className={classes.cardTitleNew}>
                                    {/* <KivitIcon htmlColor="white" fontSize="small" /> */}
                                    <img src={ColorKivitIcon} alt="Kivit color logo" />
                                    <h3 className={classes.cardTitleIcon}>Servicios profesionales</h3>
                                </div>
                                <img className={clsx(classes.cardImg, classes.cardImgGenerator)} src={IconImgGenerates} alt="Servicio Generadores" />
                                <Button className="mt-2 w-100" variant="contained" color="primary"
                                    onClick={() => handleOnService('generators')}>
                                    Entrar
                                </Button>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    /** */
    wrapper: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(12),
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        width: "100%",
        height: "100vh",
        background: "linear-gradient(107.74deg, rgba(35, 71, 173, 0.9) 0%, rgba(82, 198, 186, 0.9) 48.55%, rgba(118, 120, 237, 0.9) 99.17%)"
    },
    information: {
        fontFamily: theme.typography.fontFamily,
        color: "white"
    },
    cards: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(10)
    },
    listCard: {
        listStyle: "none",
        display: "flex",
        gap: theme.spacing(4)
    },
    card: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: "27.5rem",
        height: "18.8rem",
        borderRadius: "0.6rem",
        padding: "1rem 3rem 1.5rem 3rem",
        boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.5)"
    },
    cardTitle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: "#03264C",
        fontSize: "14px"
    },
    cardTitleNew: {
        textAlign: 'center',
        color: theme.palette.primary.dark,
        marginTop: '1rem'
    },
    cardImg: {
        width: "14rem"
    },
    cardImgGenerator: {
        width: "19rem"
    },
    cardTitleIcon: {
        marginTop: '10px',
        fontWeight: 'bold',
        
    },
    cardImgGenerators: {
        width: "18rem"
    },
    repse: {
        backgroundColor: "rgba(214, 236, 243, 0.6)"
    },
    generates: {
        backgroundColor: "#7678ED"
    },
    bgCards: {
        backgroundColor: "rgba(214, 236, 243, 0.61)",
    }
}));

export default CompanyServiceSelection;