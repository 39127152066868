export const ACTIVITIES_LIST = [
  "Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza",
  "Minería",
  "Generación, transmisión y distribución de energía eléctrica, suministro de agua y de gas por ductos al consumidor final",
  "Construcción",
  "Industrias manufactureras",
  "Comercio al por mayor",
  "Comercio al por menor",
  "Transportes, correos y almacenamiento",
  "Información en medios masivos",
  "Servicios financieros y de seguros",
  "Servicios inmobiliarios y de alquiler de bienes muebles e intangibles y Corporativos",
  "Servicios profesionales, científicos y técnicos",
  "Corporativos",
  "Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación",
  "Servicios educativos",
  "Servicios de salud y de asistencia social",
  "Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos",
  "Servicios de alojamiento temporal y de preparación de alimentos y bebidas",
  "Otros servicios excepto actividades gubernamentales",
  "Otro",
];

export const SERVICES_LIST = [
  "Agricultura",
  "Cría y explotación de animales",
  "Aprovechamiento forestal",
  "Servicios relacionados con las actividades agropecuarias y forestales",
  "Pesca, caza y captura",
  "Extracción de petróleo y gas",
  "Minería de minerales metálicos y no metálicos, excepto petróleo y gas",
  "Servicios relacionados con la minería",
  "Generación, transmisión, distribución y comercialización de energía eléctrica, suministro de agua y de gas natural por ductos al consumidor final",
  "Edificación",
  "Construcción de obras de ingeniería civil",
  "Trabajos especializados para la construcción",
  "Industria alimentaria",
  "Industria de las bebidas y del tabaco",
  "Fabricación de insumos textiles y acabado de textiles",
  "Fabricación de productos textiles, excepto prendas de vestir",
  "Fabricación de prendas de vestir",
  "Curtido y acabado de cuero y piel, y fabricación de productos de cuero, piel y materiales sucedáneos",
  "Industria de la madera",
  "Industria del papel",
  "Impresión e industrias conexas",
  "Fabricación de productos derivados del petróleo y del carbón",
  "Industria química",
  "Industria del plástico y del hule",
  "Fabricación de productos a base de minerales no metálicos",
  "Industrias metálicas básicas",
  "Fabricación de productos metálicos",
  "Fabricación de maquinaria y equipo",
  "Fabricación de equipo de computación, comunicación, medición y de otros equipos, componentes y accesorios electrónicos",
  "Fabricación de accesorios, aparatos eléctricos y equipo de generación de energía eléctrica",
  "Fabricación de equipo de transporte",
  "Fabricación de muebles, colchones y persianas",
  "Otras industrias manufactureras",
  "Comercio al por mayor de abarrotes, alimentos, bebidas, hielo y tabaco",
  "Comercio al por mayor de productos textiles y calzado",
  "Comercio al por mayor de productos farmacéuticos, de perfumería, artículos para el esparcimiento, electrodomésticos menores y aparatos de línea blanca",
  "Comercio al por mayor de materias primas agropecuarias y forestales, para la industria, y materiales de desecho",
  "Comercio al por mayor de maquinaria, equipo y mobiliario para actividades agropecuarias, industriales, de servicios y comerciales, y de otra maquinaria y equipo de uso general",
  "Comercio al por mayor de camiones y de partes y refacciones nuevas para automóviles, camionetas y camiones",
  "Intermediación de comercio al por mayor",
  "Comercio al por menor de abarrotes, alimentos, bebidas, hielo y tabaco",
  "Comercio al por menor de artículos de ferretería, tlapalería y vidrios",
  "Comercio al por menor de artículos de papelería, para el esparcimiento y otros artículos de uso personal",
  "Comercio al por menor de artículos para el cuidado de la salud",
  "Comercio al por menor de enseres domésticos, computadoras, artículos para la decoración de interiores y artículos usados",
  "Comercio al por menor de productos textiles, bisutería, accesorios de vestir y calzado",
  "Comercio al por menor de vehículos de motor, refacciones, combustibles y lubricantes",
  "Comercio al por menor en tiendas de autoservicio y departamentales",
  "Comercio al por menor exclusivamente a través de internet, y catálogos impresos, televisión y similares",
  "Transporte aéreo",
  "Transporte por ferrocarril",
  "Transporte por agua",
  "Autotransporte de carga",
  "Transporte terrestre de pasajeros, excepto por ferrocarril",
  "Transporte por ductos",
  "Transporte turísticas",
  "Servicios relacionados con el transporte",
  "Servicios postales",
  "Servicios de mensajería y paquetería",
  "Servicios de almacenamiento",
  "Edición de periódicos, revistas, libros, software y otros materiales, y edición de estas publicaciones integrada con la impresión",
  "Industria fílmica y del video, e industria del sonido",
  "Radio y televisión",
  "Telecomunicaciones",
  "Procesamiento electrónico de información, hospedaje y otros servicios relacionados",
  "Otros servicios de información",
  "Banca central",
  "Actividades bursátiles, cambiarias y de inversión financiera",
  "Compañías de seguros, fianzas, y administración de fondos para el retiro",
  "Instituciones de intermediación crediticia y financiera no bursátil",
  "Sociedades de inversión especializadas en fondos para el retiro y fondos de inversión",
  "Servicios inmobiliarios",
  "Servicios de alquiler de bienes muebles",
  "Servicios de alquiler de marcas registradas, patentes y franquicias",
  "Servicios profesionales, científicos y técnicos",
  "Corporativos",
  "Servicios de apoyo a los negocios",
  "Limpieza",
  "Servicios de mantenimiento",
  "Protección y Seguridad",
  "Manejo de residuos y servicios de remediación",
  "Servicios educativos",
  "Servicios médicos de consulta externa y servicios relacionados",
  "Hospitales pertenecientes al sector privado",
  "Residencias de asistencia social y para el cuidado de la salud",
  "Otros servicios de asistencia social",
  "Servicios artísticos, culturales y deportivos, y otros servicios relacionados",
  "Museos, sitios históricos, zoológicos y similares",
  "Servicios de entretenimiento en instalaciones recreativas y otros servicios recreativos",
  "Servicios de alojamiento temporal",
  "Servicios de preparación de alimentos y bebidas",
  "Servicios de reparación y mantenimiento",
  "Servicios personales",
  "Asociaciones y organizaciones",
  "Hogares con empleados domésticos",
  "Otro",
];
