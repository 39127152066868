import { Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles, SvgIconProps } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface DrawerMenuProps {
    /** Items shown on the top menu */
    menuItems: {
      /**Item tex */
      text: string,
      /** identifier each item used on map function as key prop. */
      name: string,
      /** Icon shown beside the name. */
      icon: (props: SvgIconProps) => JSX.Element,
      /** Link where the item will be redirect when the user clicks on it. */
      link: string,
    }[];
    open: boolean;
    onClose: () => void;
}

const DrawerMenu = (props: DrawerMenuProps) => {
  const classes = useStyles();
  return (
    <Drawer anchor="top" open={props.open} onClose={props.onClose}>
      <List className={classes.mainMenu} >
        {props.menuItems.map((item) => {
          const ItemIcon = item.icon;
          return (
            <ListItem
              className={classes.listItem}
              activeClassName={classes.listItemActive}
              button
              component={NavLink}
              to={`/dashboard${item.link}`}
              key={item.name}
              onClick={props.onClose}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <ItemIcon htmlColor="white" fontSize="small" />
              </ListItemIcon>
              <ListItemText className={classes.itemText} primary={item.text} />
            </ListItem>
          )
        })}
      </List>
    </Drawer>
  )
}

const useStyles = makeStyles((theme) => {
  return {
    menuItemIcon: { minWidth: 25 },
    itemText: {color: 'white'},
    listItem: {
      opacity: 0.5,
      transition: 'opacity 0.3s, background-color 0.3s',
      '&:hover': {
        opacity: 1,
        color: 'white',
      },
      '&:focus': {
        textDecoration: 'none',
        outline: 'none',
        color: 'white',
      },
      '& span': {
        fontSize: 14,
      },
    },
    listItemActive: {
      opacity: 1,
      '& span': {
        fontWeight: 'bold',
      },
    },
    mainMenu: {
      backgroundColor: theme.palette.primary.main
    },
  };
});

export default DrawerMenu;
